// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';

// ----------- import Internals
import {
  useStl,
  UseIcoMoon,
  useData,
  UseInitData,
} from '../../../../useMorfos';
import { FloatOptions } from '../..';
import { useDispatch } from 'react-redux';
import { primaryColor } from '../../../../config/styles';

export default ({ children }) => {
  const showRight = true;
  const condShow = showRight === true;

  return (
    condShow && (
      <UseInitData reducer={'comps_SideRight'}>
        <View style={stlSideMenu}>
          <MenuNav />
          <ScrollView style={stlChildren}>{children}</ScrollView>
        </View>
      </UseInitData>
    )
  );
};

const MenuNav = () => {
  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Data
  const typeAccount = useData('baseAuthUser.typeAccount');
  const pathRight = useData('baseRoute.pathRight');
  const confirm = useData('comps.sideRight.msgConfirm');
  const options = useData('comps.sideRight.options');
  const title = useData('comps.sideRight.title');
  const [sttJsx, setJsx] = React.useState('');

  // ----------- set Cond
  const isOrderPf = pathRight === 'orderPf';
  const isAdm = typeAccount === 'adm1';
  const condShowOpt = isAdm && options;

  // ----------- set Btn
  const closeRight = () => dispatch({ type: 'comps_CloseRight' });
  const btnCancel = () => dispatch({ type: 'comps_CancelDel' });
  const btnDelete = () => dispatch({ type: 'comps_DeleteItem' });
  const btnPrint = () => {
    dispatch({ type: 'S1d_InitPrint', value: sttJsx });
  };

  return (
    <>
      <View style={stlNav}>
        <TouchableOpacity style={stlLeftBox} onPress={closeRight}>
          <UseIcoMoon name={'arrow-left'} size={22} color={primaryColor} />
        </TouchableOpacity>
        <View style={stlCenterBox}>
          <Text style={stlSmallTitle}>Formulário de</Text>
          <Text style={stlTitle}>{title}</Text>
        </View>
        {isOrderPf && (
          <View style={stlViewPrint} ref={item => setJsx(item)}>
            <TouchableOpacity style={stlBtnPrint} onPress={btnPrint}>
              <UseIcoMoon name="printer" size={14} color="#444" />
              <Text style={stlTxtPrint}>Imprimir</Text>
            </TouchableOpacity>
          </View>
        )}
        <View style={stlRightBox}>{condShowOpt && <FloatOptions />}</View>
      </View>

      {confirm && (
        <View style={stlConfirmMsg}>
          <Text style={stlTxtMsg}>Você realmente quer excluir?</Text>
          <View style={stlBtnViewMsg}>
            <TouchableOpacity style={stlBtn1} onPress={btnCancel}>
              <Text style={stlTxtBtn1}>Não</Text>
            </TouchableOpacity>
            <TouchableOpacity style={stlBtn2} onPress={btnDelete}>
              <Text style={stlTxtBtn2}>Sim</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </>
  );
};

// #region :: STYLEs *********
const navHeight = { height: 50 };
const stlSideMenu = [useStl.rightBar, useStl.shadowRgtBar];

// --- NavBar
const stlNav = [
  useStl.flexRow,
  useStl.border(0, 0, 2, 0, '#eee'),
  navHeight,
  { zIndex: 10 },
];
const stlLeftBox = [useStl.flex1, navHeight, useStl.flexCenter];
const stlCenterBox = [useStl.flex6, navHeight, { justifyContent: 'center' }];
const stlRightBox = [useStl.flex1, navHeight, useStl.flexCenter];

// --- Content
const stlChildren = [useStl.pad20];
const stlSmallTitle = [{ fontSize: 10, color: '#999' }];
const stlTitle = [
  {
    fontSize: 11,
    color: '#333',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
];

const stlConfirmMsg = [
  useStl.flexRow,
  useStl.flexBetween,
  {
    backgroundColor: 'darkgreen',
    padding: 10,
  },
];
const stlInverseTxtMsg = [useStl.txtPrimaryInverse];
const stlTxtMsg = [stlInverseTxtMsg, useStl.flex3];
const stlTxtBtn1 = [{ fontSize: 10 }];
const stlTxtBtn2 = [stlInverseTxtMsg, { fontSize: 10 }];
const stlBtnViewMsg = [useStl.flexRow, useStl.flex1];
const baseBtn = {
  width: 35,
  height: 20,
  alignItems: 'center',
  justifyContent: 'center',
};
const stlBtn1 = [
  useStl.btn,
  { ...baseBtn, backgroundColor: '#fff', marginRight: 3 },
];
const stlBtn2 = [
  useStl.btn,
  { ...baseBtn, backgroundColor: '#333', height: 21 },
];
const stlViewPrint = [useStl.flexRow, useStl.flexEnd];
const stlBtnPrint = [
  useStl.flexRow,
  useStl.btn,
  { paddingHorizontal: 6, paddingVertical: 3 },
];
const stlTxtPrint = [{ marginLeft: 4, fontSize: 10, fontWeight: 600 }];
// #endregion *********
