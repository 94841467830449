// ----------- import Packs
import React from 'react';
import { View } from 'react-native';

// ----------- import Internals
import { useStl } from '../../../../../useMorfos';
import { FilterBar, TitleList } from '../../../../comps';

// #region :: STYLEs *********
const stlBodyView = [useStl.flex1];
const stlPadContent = [useStl.pad(30, 100)];

// #endregion *********

export default ({ info, children }) => {
  return (
    <>
      <View style={stlBodyView}>
        {/* ----------- PARTs */}
        <View style={stlPadContent}>{children}</View>
      </View>
    </>
  );
};
