// ----------- import Packs
import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';

// ----------- import Internals
import imgDefault from '../../images/default.jpg';
import { useStl, useData } from '../../../useMorfos';
import { currencyMask, ezLog } from '../../../useMorfos/utils';
import { useDispatch } from 'react-redux';
import { primaryColor } from '../../../config/styles';

export default ({ infoData }) => {
  // ---------- set Data & Conds
  const { itemId } = infoData;
  const shops = useData('M7.itemsInfo');
  const currShop = useData('M2.shopsInReach.itemsInfo', itemId);
  const cartProds = useData('basePersist.cart.totalAmount');
  const admPrice = useData('S4.admPar.deliveryPriceLimit');

  const dlvryPrice = admPrice?.toFixed(2);
  const shopName = shops[itemId]?.name ?? '...';
  const shopImg = shops[itemId]?.imgUrl;
  const appBuy = currShop?.appBuy ?? false;
  ezLog({ shops, itemId, appBuy });
  const avProds = shops[itemId]?.availableProds;
  const condThumbnail = shopImg ?? imgDefault;
  const condPrice = shops[itemId]?.totalPriceLabel ?? 0;
  const totalPrice = condPrice.toFixed(2);
  const condFreeDlvry = shops[itemId]?.condFreeDelivery;
  const condGoal = shops[itemId]?.goalToDelvry ?? 0;
  const goalToDelvry = condGoal.toFixed(2);

  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Dynamic Txts
  const currency = `R$ ${currencyMask(totalPrice, false)}`;
  const txtAvailProds = `Itens disponiveis: ${avProds.length} de ${cartProds} selecionados`;
  const txtDelivery = `Para compras acima de R$${currencyMask(
    dlvryPrice,
    false,
  )} a entrega é grátis`;
  const txtGoalDelvry = `R$${currencyMask(goalToDelvry, false)}`;

  // ---------- set Btns
  const btnSelectShop = () => {
    dispatch({ type: 'comps_HistoryPath' });
    dispatch({ type: 'M7_AddCurrShop', value: itemId });
  };

  // ---------- set Return
  const infoView = {
    condFreeDlvry,
    txtDelivery,
    txtGoalDelvry,
  };

  return (
    <TouchableOpacity style={stlBox2} onPress={btnSelectShop}>
      <View style={[useStl.flexRow, { marginBottom: 8 }]}>
        <Image source={condThumbnail} style={stlImg2} />
        <View style={{ marginLeft: 10 }}>
          <Text style={stlTxt1}>{shopName}</Text>
          {/* <View style={stlRow}>
            <UseIcoMoon name="star" size={12} color="green" />
            <UseIcoMoon name="star" size={12} color="green" />
            <UseIcoMoon name="star" size={12} color="green" />
            <UseIcoMoon name="star" size={12} color="green" />
            <UseIcoMoon name="star" size={12} color="#ccc" />
          </View> */}
        </View>
      </View>
      <View style={stlRow}>
        <Text style={stlTxt3}>Valor Total da Compra:</Text>
        <Text style={stlTxt4}>{currency}</Text>
      </View>
      <Text style={stlTxt5}>{txtAvailProds}</Text>
      <Text style={stlTxt6}>__________________________</Text>
      {appBuy ? (
        <>
          <Text style={stlTxt7}>Opções de Retirada</Text>
          <View style={stlRow}>
            <Text style={stlTxt8}>· Retirada no Local:</Text>
            <Text style={stlTxt9}>Grátis</Text>
          </View>{' '}
          <CondDelivrComp info={infoView} />
        </>
      ) : (
        <Text style={stlTxt7}>Compras apenas no local</Text>
      )}
    </TouchableOpacity>
  );
};

const CondDelivrComp = ({ info }) => {
  // ----------- set Params
  const { condFreeDlvry, txtDelivery, txtGoalDelvry } = info;

  // ----------- set Comps
  const FreeDlvry = (
    <View style={[stlRow, { marginTop: 5 }]}>
      <Text style={stlTxt8}>· Entrega:</Text>
      <Text style={stlTxt9}>Grátis</Text>
    </View>
  );
  const GoalFreeDlvry = (
    <>
      <View style={[stlRow, { marginTop: 10 }]}>
        <Text style={stlTxt13}>Obs:</Text>
        <Text style={stlTxt14}>{txtDelivery}</Text>
      </View>
      <View style={stlBox4}>
        <Text style={stlTxt15}>Faltam apenas</Text>
        <Text style={stlTxt16}>{txtGoalDelvry}</Text>
        <Text style={stlTxt17}>para voce ter entrega grátis</Text>
      </View>
    </>
  );

  // ----------- set Return
  const condReturn = condFreeDlvry ? FreeDlvry : GoalFreeDlvry;
  return condReturn;
};

// #region :: STYLEs *********

const stlImg2 = {
  width: 50,
  height: 50,
};
const stlRow = [useStl.flexRow];

const stlBox2 = [useStl.card];
const stlBox4 = [useStl.flexRow, useStl.flex];

// # fonts *********
const stlTxt1 = [
  {
    fontSize: 15,
    fontWeight: 600,
    color: '#444',
  },
];
const stlTxt3 = {
  flex: 3,
  fontSize: 12,
  fontWeight: 400,
  color: '#808080',
};
const stlTxt4 = {
  flex: 1,
  fontSize: 16,
  fontWeight: 600,
  color: 'green',
};
const stlTxt5 = {
  fontSize: 12,
  fontWeight: 400,
  color: '#666',
};
const stlTxt6 = {
  textAlign: 'center',
  fontSize: 14,
  fontWeight: 700,
  color: '#DCDCDC',
  marginBottom: 20,
};
const stlTxt7 = {
  fontSize: 14,
  fontWeight: 600,
  color: '#333',
  marginBottom: 3,
};
const stlTxt8 = {
  fontSize: 12,
  fontWeight: 600,
  color: '#666',
};
const stlTxt9 = {
  fontSize: 12,
  fontWeight: 600,
  color: primaryColor,
  marginLeft: 5,
};
const stlTxt13 = {
  fontSize: 12,
  fontWeight: 600,
  color: '#444',
};
const stlTxt14 = {
  marginLeft: 5,
  fontSize: 10,
  fontWeight: 400,
  color: 'gray',
};
const stlTxt15 = {
  fontSize: 10,
  fontWeight: 400,
  color: 'gray',
  width: 70,
};
const stlTxt16 = {
  fontSize: 10,
  fontWeight: 600,
  color: 'green',
  width: 42,
};
const stlTxt17 = {
  fontSize: 10,
  fontWeight: 400,
  color: 'gray',
  width: 150,
};

// #endregion *********
