import React from 'react';
import { useDispatch } from 'react-redux';

export const fetchUrl = async urlBase => {
  // ---------- call Async Function
  const fetchGet = await fetch(urlBase);
  const dataSuccess = await fetchGet.json();

  return dataSuccess;
};

export const Address = () => {
  // ---------- set Hooks and Vars
  const [ready, setReady] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyB7wiLp3Nv_XTtimSk87TUupAM9lsyjI3I&libraries=places',
      initAutoComp,
    );
  }, []);
  React.useEffect(() => {
    if (!!ready) {
      geolocate();
    }
  }, [ready]);
  let autocomplete;

  // ---------- set Pattern
  const resPattern = [
    {
      gpField: 'street_number',
      saveAs: 'street_number',
    },
    {
      gpField: 'route',
      saveAs: 'street',
    },
    {
      gpField: 'sublocality_level_1',
      saveAs: 'neighborhood',
    },
    {
      gpField: 'administrative_area_level_2',
      saveAs: 'city',
    },
    {
      gpField: 'administrative_area_level_1',
      saveAs: 'state',
    },
    {
      gpField: 'country',
      saveAs: 'country',
    },
    {
      gpField: 'postal_code',
      saveAs: 'zip_code',
    },
  ];

  // ---------- set Other Functions
  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = function() {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null;
          callback();
          setReady(true);
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  const initAutoComp = () => {
    autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      { types: ['geocode'] },
    );
    autocomplete.setFields(['address_component', 'geometry']);

    autocomplete.addListener('place_changed', fillInAddress);
  };

  const fillInAddress = () => {
    var place = autocomplete.getPlace().address_components;
    var geometry = autocomplete.getPlace().geometry.location;
    var geolocation = { lat: geometry.lat(), lng: geometry.lng() };
    const formatedResult = convertResPattern(place);
    const res = {
      ...formatedResult,
      geolocation,
    };
    dispatch({ type: 'M1_AutoComplete', value: res });
  };

  const convertResPattern = result => {
    const formatedResult = {};
    resPattern.forEach(gpPtt => {
      const foundField = result.find(resultField =>
        resultField.types.some(type => type === gpPtt.gpField),
      );
      const condCountry = gpPtt.saveAs === 'country';
      const condField = condCountry ? 'short_name' : 'long_name';
      formatedResult[gpPtt.saveAs] = foundField ? foundField[condField] : '';
    });
    return formatedResult;
  };

  const geolocate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        var circle = new window.google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        autocomplete.setBounds(circle.getBounds());
      });
    }
  };
};
