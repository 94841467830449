// ----------- import Packs
import React from 'react';
import { View, Text } from 'react-native';

// ----------- import Internals
import { useStl } from '../../../../useMorfos';

// #region :: STYLEs *********

const stlPad = [
  {
    width: 180,
    height: 10,
    backgroundColor: '#eee',
    marginRight: 20,
    marginTop: 20,
  },
];

// #endregion *********

export default ({ children }) => {
  return (
    <>
      {/* <View style={[stlPad, { marginLeft: 10 }]} />
      <View style={[stlPad, { width: 60 }]} />
      <View style={stlPad} /> */}
    </>
  );
};
