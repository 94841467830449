/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import { primaryColor } from '../../../../src/config/styles';
import { useData, useStl } from '../../../useMorfos';
import { currencyMask, ezLog } from '../../../useMorfos/utils';
import defaultImg from '../../images/default.jpg';

export default ({ infoData }) => {
  // ---------- set Common Data
  const { itemId, reducer } = infoData;
  const currProd = useData(reducer, itemId) ?? <></>;
  const path = useData('baseRoute.path');
  const groupSc = useData('baseRoute.currScInfo.groupSc');
  const renderShop = useData('S1c.render');
  const name = currProd?.name;
  const typeVar = currProd?.varName ?? '...';
  const typeVal = currProd?.varValue ?? '.';
  const availableProd = currProd?.available;
  const condProd = availableProd ? stlBox : stlUnvaiProd;
  const isOrderList = path === 'orderList';
  const isPrint = path === 'printOrder';
  const isUnavailable = isOrderList && !availableProd;
  const condTypePrice = currProd?.shopPrice ?? currProd?.avgPrice;
  const avgPriceInt = condTypePrice && condTypePrice.toFixed(2);
  const condPrice = currencyMask(avgPriceInt, false);
  const condUnvailTxt = isUnavailable
    ? 'Preço'
    : 'Preço Médio dos Mercados da sua Região';
  const condTxtPrice = currProd?.shopPrice ? 'Preço' : condUnvailTxt;
  const unavailProd = isOrderList ? condProd : stlBox;
  const condButton = !isUnavailable;

  // ---------- set Hooks
  const [sttCount, setCount] = React.useState(0);
  const dispatch = useDispatch();

  // ----------- set Cond Render Comps
  const AppComp = () => {
    // ------- set Effects (Amount Prods)
    const fxAmount = () => {
      const currAmount = cartProds[itemId]?.amountProd;
      const condAmount = currAmount ?? 0;
      setCount(condAmount);
    };

    // ----------- set Hooks
    React.useEffect(fxAmount, [itemId]);
    const dispatch = useDispatch();

    // ----------- set Data
    const cartProds = useData('basePersist.cart.addedProds');

    // ----------- set Image
    const currImg = currProd.img;
    const condThumbnail = currImg ?? defaultImg;

    // ----------- set All Btns / Increment & Decrement
    const btnGoToProds = () =>
      dispatch({ type: 'comps_InitM7b', value: itemId });

    const btnIncrement = () => {
      const newStt = sttCount + 1;
      setCount(newStt);

      const infoProd = {
        docId: currProd.prodIdVar,
        amountProd: newStt,
      };
      dispatch({ type: 'M6_BtnControlCart', infoProd });
    };
    const btnDecrement = () => {
      const condZero = sttCount === 0;
      if (condZero) {
        return;
      }

      const newStt = sttCount - 1;
      setCount(newStt);

      const infoProd = {
        docId: currProd.prodIdVar,
        amountProd: newStt,
      };

      dispatch({ type: 'M6_BtnControlCart', infoProd });
    };

    // ----------- set Cond Render Counter
    const EditAmount = () => (
      <View style={stlBox2}>
        <TouchableOpacity onPress={btnIncrement}>
          <Text style={stlAdd}>+</Text>
        </TouchableOpacity>
        <Text style={stlAmount}>{sttCount}</Text>
        <TouchableOpacity onPress={btnDecrement}>
          <Text style={stlMin}>-</Text>
        </TouchableOpacity>
      </View>
    );
    const ViewAmount = () => (
      <View style={stlBox2}>
        <View>
          <Text style={stlTxtDisable}>+</Text>
        </View>
        <Text style={stlAmountDis}>{sttCount}</Text>
        <View>
          <Text style={stlTxtDisable}>-</Text>
        </View>
      </View>
    );
    const CondEditProds = () => {
      return isUnavailable ? <ViewAmount /> : <EditAmount />;
    };

    // ---------- set Return
    const condReturn = currProd?.renderItem;

    if (condReturn) {
      return (
        <TouchableOpacity
          style={unavailProd}
          disabled={condButton}
          onPress={btnGoToProds}
        >
          <Image source={condThumbnail} style={stlImg1} />

          <View style={stlBox1}>
            <Text style={stlTxt1}>{name}</Text>
            <View style={stlVarLabel}>
              <Text style={stlTxt1a}>{typeVar}</Text>
              <Text style={stlTxt1a}>{typeVal}</Text>
            </View>
            {!isUnavailable && (
              <>
                <Text style={stlTxt2}>{condTxtPrice}</Text>
                <Text style={stlTxt3}>{`R$ ${condPrice}`}</Text>
              </>
            )}
            {isUnavailable && (
              <Text style={stlTxtUnavai}>
                Produto indisponível - Clique para ver outros dessa categoria
              </Text>
            )}
          </View>

          <CondEditProds />
        </TouchableOpacity>
      );
    } else {
      return <></>;
    }
  };

  const ShopComp = () => {
    // ----------- set Data
    const cartProds = useData('S1c.itemsInfo');
    const editProds = useData('S1c.editProds');
    const path = useData('baseRoute.path');
    const isPrint = path === 'printOrder';

    // ------- set Effects (Amount Prods)
    const fxAmount = () => {
      const currAmount = cartProds[itemId]?.amountProd;
      const condAmount = currAmount ?? 0;
      setCount(condAmount);
    };

    // ----------- set Hooks
    React.useEffect(fxAmount, [itemId, sttCount]);

    // ----------- set Image
    const image = currProd?.currImg;
    const currImg = currProd[`imgUrl${image}`];
    const condThumbnail = currImg ?? defaultImg;

    // ----------- set Btns Increment & Decrement
    const btnIncrement = () => {
      const newStt = sttCount + 1;
      setCount(newStt);

      const infoProd = {
        docId: currProd.prodIdVar,
        amountProd: newStt,
      };
      dispatch({ type: 'S1c_BtnControlProds', infoProd });
    };

    const btnDecrement = () => {
      const condZero = sttCount === 0;
      if (condZero) {
        return;
      }

      const newStt = sttCount - 1;
      setCount(newStt);

      const infoProd = {
        docId: currProd.prodIdVar,
        amountProd: newStt,
      };

      dispatch({ type: 'S1c_BtnControlProds', infoProd });
    };

    // ----------- set Cond Render Counter
    const EditAmount = () => (
      <View style={stlBox2}>
        {!isPrint && (
          <TouchableOpacity onPress={btnIncrement}>
            <Text style={stlAdd}>+</Text>
          </TouchableOpacity>
        )}
        <Text style={stlAmount}>{sttCount}</Text>
        {!isPrint && (
          <TouchableOpacity onPress={btnDecrement}>
            <Text style={stlMin}>-</Text>
          </TouchableOpacity>
        )}
      </View>
    );

    const ViewAmount = () => (
      <View style={stlBox2}>
        {!isPrint && (
          <View>
            <Text style={stlTxtDisable}>+</Text>
          </View>
        )}
        <Text style={stlAmount}>{sttCount}</Text>
        {!isPrint && (
          <View>
            <Text style={stlTxtDisable}>-</Text>
          </View>
        )}
      </View>
    );

    const CondEditProds = () => {
      return editProds ? <EditAmount /> : <ViewAmount />;
    };

    // ---------- set Return
    const condReturn = currProd?.renderItem;

    if (condReturn) {
      return (
        <View style={[stlBox, { width: '100%' }]}>
          <Image source={condThumbnail} style={stlImg1} />

          <View style={stlBox1}>
            <Text style={stlTxt1}>{name}</Text>
            <View style={stlVarLabel}>
              <Text style={stlTxt1a}>{typeVar}</Text>
              <Text style={stlTxt1a}>{typeVal}</Text>
            </View>
            <Text style={stlTxt2}>{condTxtPrice}</Text>
            <Text style={stlTxt3}>{`R$ ${condPrice}`}</Text>
          </View>

          <CondEditProds />
        </View>
      );
    } else {
      return <></>;
    }
  };

  // ----------- set Return
  const isShop = groupSc === 'shop1' || path === 'printOrder';
  const condRender = renderShop || isShop;
  const condReturn = condRender ? <ShopComp /> : <AppComp />;

  return condReturn;
};

// #region :: STYLEs *********
// Boxs
const stlBox = [
  useStl.cardMask,
  useStl.flexRow,
  useStl.flexBetween,
  {
    width: '80%',
    backgroundColor: '#fff',
    borderRadius: 8,
    minHeight: 90,
    padding: 5,
  },
];
const stlTxtUnavai = [{ fontSize: 10, color: '#f51e1ed4' }, useStl.flexRow];
const stlUnvaiProd = [stlBox, { borderColor: '#f51e1ed4', borderWidth: 1 }];
const stlBox1 = [useStl.flex4, { paddingLeft: 10 }];
const stlBox2 = [
  useStl.flex1,
  useStl.flexCenter,
  useStl.border(0, 0, 0, 1, '#ddd'),
];
const stlVarLabel = [useStl.flexStart];

// Imgs
const stlImg1 = [
  {
    width: 80,
    height: 55,
  },
];

// Texts
const stlTxt1 = { fontSize: 14, color: '#444', marginBottom: 3 };
const stlTxt1a = {
  fontSize: 9,
  color: '#666',
  marginBottom: 20,
  minHeight: 14,
  backgroundColor: '#eee',
  borderRadius: 2,
  paddingHorizontal: 4,
  paddingVertical: 1,
  marginRight: 1.8,
};
const stlTxt2 = { fontSize: 9, color: '#333' };
const stlTxt3 = { fontSize: 14, fontWeight: 600 };

const stlAdd = { fontSize: 18, fontWeight: 600, color: primaryColor };
const stlTxtDisable = { fontSize: 18, fontWeight: 600, color: '#ddd' };
const stlAmount = { fontSize: 18, fontWeight: 600 };
const stlAmountDis = [stlAmount, { color: '#d0d0d0' }];
const stlMin = { fontSize: 18, fontWeight: 600, color: primaryColor };
// #endregion *********
