/* eslint-disable import/no-anonymous-default-export */
// ---------- set Initial State
export default {
  // ---------- set Route
  baseRoute: {
    path: 'signin',
    historyPath: {
      counter: 0,
    },
  },
  comps: {
    sidemenu: {
      sttMenu: false,
    },
  },
  refs: {
    weekDays: [
      { label: 'Dom.', id: '0', name: 'Domingo' },
      { label: 'Seg.', id: '1', name: 'Segunda' },
      { label: 'Ter.', id: '2', name: 'Terça' },
      { label: 'Qua.', id: '3', name: 'Quarta' },
      { label: 'Qui.', id: '4', name: 'Quinta' },
      { label: 'Sex.', id: '5', name: 'Sexta' },
      { label: 'Sab.', id: '6', name: 'Sábado' },
    ],
  },
  // B2: {},
  // baseRoute: { path: 'home', historyPath: 'home' },

  // ---------- set Persistence
  basePersist: {
    userId: null,
    cart: {
      addedProds: {},
      totalAmount: 0,
    },
    categs: {
      itemsInfo: [],
      itemsList: [],
    },
    subCategs: {
      itemsInfo: [],
      itemsList: [],
    },
  },
};
