/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, Text, TextInput } from 'react-native';
import { useData } from '../../../../useMorfos';
import { useDispatch } from 'react-redux';
import { primaryColor } from '../../../../config/styles';
import {
  CNPJMask,
  commaMask,
  currencyMask,
  hourMask,
  phoneMask,
  numIntMask,
} from '../../../../useMorfos/utils';

export default ({ infoData, infoStl = {}, type, fieldName, sc, variation }) => {
  // ----------- set Selectors
  const item = useData(infoData);
  const condNumber = item.condNumber;

  const { iptChange, itemId, label, pHolder, lines, editData } = item;

  // ---------- set Effects
  const fxValue = () => {
    const condEdit = editData ?? '';

    setValue(condEdit);
  };

  // ---------- set Hooks
  const dispatch = useDispatch();
  React.useEffect(fxValue, [editData]);

  const { colorBase = primaryColor } = infoStl;

  const [sttValue, setValue] = React.useState('');
  const [sttLabelUp, setLabelUp] = React.useState(false);
  const [sttFocus, setFocus] = React.useState(false);

  const condLabelUp = sttLabelUp || sttValue;
  const setLabelSize = num => (!condLabelUp ? num : num * 0.8);
  const myStlLabel = infoStl.stlLabel || { fontSize: 14 };
  const labelFontSize = setLabelSize(myStlLabel.fontSize);

  const iptLabelUp = !condLabelUp && labelFontSize;
  const iptLabelDown = infoStl.stlLabel ? infoStl.stlLabel.fontSize : 15;
  const iptFontSize = iptLabelUp || iptLabelDown;

  const valueColor = sttValue ? 'gray' : 'lightgray';
  const focusColor = sttFocus ? colorBase : valueColor;

  const condLines = lines > 1;

  const changeBlur = () => {
    setLabelUp(false);
    setFocus(false);
  };
  const changeFocus = () => {
    setLabelUp(true);
    setFocus(true);
  };

  // ----------- set Containers
  const currSc = useData('baseRoute.currScInfo.scKey');
  const condSc = sc ?? currSc;
  let path = `${condSc}.forms.iptsChanges.${fieldName}`;
  let valueField = useData(path) ?? editData;
  let condMask = sttValue;

  const condNumInt = type === 'numInt';
  const condCurrency = type === 'currency';
  const condHour = type === 'hour';
  const condPhone = type === 'phone';
  const condCNPJ = type === 'cnpj';
  const condComma = type === 'comma';

  // ---- TYPES
  if (condCurrency) {
    if (variation) {
      path = `${condSc}.forms.iptsChanges.${fieldName}.${item.key}.price`;
    }
    valueField = useData(path) ?? editData;
    const fixComma = Number(valueField).toFixed(2);
    condMask = `R$ ${currencyMask(fixComma, false)}`;
  }

  if (condHour) {
    condMask = hourMask(valueField);
  }

  if (condPhone) {
    condMask = phoneMask(valueField).mask;
  }

  if (condCNPJ) {
    condMask = CNPJMask(valueField).mask;
  }

  if (condComma) {
    condMask = commaMask(valueField).mask;
  }

  if (condNumInt) {
    condMask = numIntMask(valueField, 3).mask;
  }

  const changeValue = val => {
    let numInt;
    if (condCurrency) {
      numInt = currencyMask(val, true);
    }
    if (condPhone) {
      numInt = phoneMask(val).onlyNum;
    }
    if (condCNPJ) {
      numInt = CNPJMask(val).onlyNum;
    }
    if (condComma) {
      numInt = commaMask(val).onlyNum;
    }
    if (condNumInt) {
      numInt = numIntMask(val, 3).onlyNum;
    }

    const changeDots = val.replace(':', '.');
    const toNum = Number(changeDots);
    const delZero = String(toNum).replace('0', '');
    const newNum = Number(delZero);
    const fixNum = toNum > 24 ? newNum : toNum;

    dispatch({
      type: iptChange,
      value: val,
      field: itemId,
      key: item.key,
      toNum: fixNum,
      numInt,
    });
    setValue(val);
  };

  // #region :: info + STYLEs *********
  const {
    txtLabel = label,
    placeHolderLabel = pHolder,
    placeholderTextColor = 'gray',

    stlContainer = {
      width: '100%',
      marginBottom: 5,
    },
    stlLabelView = {
      height: 22,
      justifyContent: 'flex-end',
    },

    stlLabel = {
      color: colorBase,
      fontSize: labelFontSize,
      position: 'absolute',
    },
    stlIpt = {
      paddingLeft: 0,
      height: !condLines && 30,
      borderBottomWidth: 2,
      borderColor: focusColor,
      outline: 'none',

      backgroundColor: '#fff',
      color: 'black',
      fontSize: iptFontSize,
      fontStyle: sttLabelUp && !sttValue && 'italic',
    },
  } = infoStl;
  // #endregion *********

  return (
    <View style={stlContainer}>
      <View style={stlLabelView}>
        <Text style={stlLabel}>{condLabelUp && txtLabel}</Text>
      </View>
      <TextInput
        style={{ ...stlIpt }}
        onChangeText={changeValue}
        onBlur={changeBlur}
        onFocus={changeFocus}
        value={condMask}
        placeholderTextColor={
          !sttLabelUp ? stlLabel.color : placeholderTextColor
        }
        placeholder={sttLabelUp ? placeHolderLabel : txtLabel}
        multiline={condLines}
        numberOfLines={lines}
        keyboardType={condNumber && 'numeric'}
      />
    </View>
  );
};
