// ---------- import Internals
import {
  firebase,
  firestore,
  storage,
} from '../../../../../config/firebase/fbConfig';
import { asyncRefs } from '../../../../../useMorfos/utils';
import { getGeohashRange } from '../../shops/S4_SettingsADD/helpers';
import { processData } from './helpers';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Shops_
    C1_InitData: () => {
      const scContent = {
        title: 'Signin',
        subTitle: 'Oi Mundo!',
        description: 'Lorem Ipsum!',
        txtBtn: 'Ir para os TERMOS',
      };

      action.asyncDispatch({ type: 'C1_InitForm' });

      return {
        ...state,
        C1: {
          ...state.C1,
          loader: true,
          scContent,
        },
      };
    },
  };

  const forms = {
    C1_InitForm: () => {
      // ---------- set Edit
      const idToEdit = state?.C1?.idToEdit;
      const isEditable = idToEdit ? true : false;
      const editData = state?.B1?.itemsInfo[idToEdit];
      const N_ = editData?.address?.geolocation?.N_;
      const x_ = editData?.address?.geolocation?.x_;
      const condLat = x_ === undefined ? '' : x_;
      const condLong = N_ === undefined ? '' : N_;

      // ------- set Forms Data
      const condData = true;
      const iptsInfo = {
        // ---------- Inputs Base

        image: {
          // ----- set Dynamics
          itemId: 'image',
          // required: true,
          iptChange: 'C1_ImgChange',
          imgData: 'C1.forms.iptsChanges.image',

          // ----- set Edit
          editData: editData && editData.imgUrl,
          isEditable,
        },

        name: {
          // ----- set Dynamics
          itemId: 'name',
          required: true,
          iptChange: 'C1_IptValues',
          // condNumber: true,

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Nome',
          editData: editData && editData.name,
          isEditable,
        },

        phone: {
          itemId: 'phone',
          label: 'Telefone',
          required: true,

          pHolder: 'Escreva...',

          iptChange: 'C1_IptMasks',
          editData: editData && editData.phone,
          isEditable,
        },

        email: {
          itemId: 'email',
          label: 'Email',
          required: true,

          pHolder: 'Escreva...',

          iptChange: 'C1_IptValues',
          editData: editData && editData.email,
          isEditable,
        },

        lat: {
          // ----- set Dynamics
          itemId: 'lat',
          required: true,
          condNumber: true,
          iptChange: 'C1_IptValues',

          // ----- set Statics
          pHolder: 'Escreva apenas números',
          label: `Latitude: ${condLat}`,
          // editData: editData && condLat,
          editData: '',
          isEditable,
        },

        long: {
          // ----- set Dynamics
          itemId: 'long',
          required: true,
          condNumber: true,
          iptChange: 'C1_IptValues',

          // ----- set Statics
          pHolder: 'Escreva apenas números',
          label: `Longitude: ${condLong}`,
          // editData: editData && condLong,
          editData: '',
          isEditable,
        },

        // number: {
        //   // ----- set Dynamics
        //   itemId: 'number',
        //   required: true,
        //   condNumber: true,
        //   iptChange: 'C1_IptValues',

        //   // ----- set Statics
        //   pHolder: 'Escreva apenas números',
        //   label: 'Número',
        //   editData: editData && editData.number,
        //   isEditable,
        // },

        answerable: {
          itemId: 'answerable',
          label: 'Responsável',
          required: true,

          pHolder: 'Escreva...',

          iptChange: 'C1_IptValues',
          editData: editData && editData.answerable,
          isEditable,
        },

        cnpj: {
          itemId: 'cnpj',
          label: 'CNPJ',
          required: true,

          pHolder: 'Escreva...',

          iptChange: 'C1_IptMasks',
          editData: editData && editData.cnpj,
          isEditable,
        },

        idpagme: {
          itemId: 'idpagme',
          label: 'ID PAGAR.ME',
          required: true,

          pHolder: 'Escreva...',

          iptChange: 'C1_IptValues',
          editData: editData && editData.idpagme,
          isEditable,
        },

        details: {
          itemId: 'details',
          label: 'Detalhes',
          lines: 3,

          required: true,

          pHolder: 'Escreva...',

          iptChange: 'C1_IptValues',
          editData: editData && editData.details,
          isEditable,
        },

        accountStatus: {
          editData: editData && editData?.admStatus,
        },
        appBuy: {
          editData: editData && (editData?.appBuy ?? false),
        },
      };

      const msgs = {};
      for (const key in iptsInfo) {
        const item = iptsInfo[key];
        const condAdd = item.required;

        if (condAdd) {
          msgs[item.itemId] = true;
        }
      }

      return {
        ...state,
        C1: {
          ...state.C1,
          condData,
          loader: true,
          forms: {
            iptsInfo: { ...iptsInfo },
            iptsChanges: {},
            msgs,
          },
        },
        comps: {
          ...state.comps,
          sideRight: {
            ...state.comps.sideRight,
            options: isEditable,
          },
        },
      };
    },

    C1_ChangeAccountStatus: () => {
      // Checar se Tem Produtos desatualizados
      // Se sim, não mudar *** accountStatus *****
      const idToEdit = state?.C1?.idToEdit;
      const currShop = state?.B1?.itemsInfo[idToEdit];
      const itsOutdated = currShop?.prodsOutdated;
      const condValue = itsOutdated ? 'suspended' : 'active';

      return {
        ...state,
        C1: {
          ...state.C1,
          forms: {
            ...state?.C1?.forms,
            iptsChanges: {
              ...state?.C1?.forms?.iptsChanges,
              accountStatus: condValue, // esse cara q faz suspender produtos
              admStatus: action.value,
            },
          },
        },
      };
    },

    C1_ChangeAppBuy: () => {
      return {
        ...state,
        C1: {
          ...state.C1,
          forms: {
            ...state?.C1?.forms,
            iptsChanges: {
              ...state?.C1?.forms?.iptsChanges,
              appBuy: action.value,
            },
          },
        },
      };
    },

    // ---------- set Form Fields
    C1_IptValues: () => {
      const itemLabel = `${action.field}_label`;
      const condLabel = action.label ? { [itemLabel]: action.label } : {};

      // ---------- set Validation
      const value = action.value;
      const condEmpty = value === '';

      return {
        ...state,
        C1: {
          ...state.C1,

          forms: {
            ...state.C1.forms,
            iptsChanges: {
              ...state.C1.forms.iptsChanges,
              [action.field]: action.value,
              ...condLabel,
            },
            msgs: {
              ...state.C1.forms.msgs,
              [action.field]: condEmpty,
            },
          },
        },
      };
    },

    C1_IptMasks: () => {
      const itemLabel = `${action.field}_label`;
      const condLabel = action.label ? { [itemLabel]: action.label } : {};

      // ---------- set Validation
      const value = action.value;
      const condEmpty = value === '';

      return {
        ...state,
        C1: {
          ...state.C1,

          forms: {
            ...state.C1.forms,
            iptsChanges: {
              ...state.C1.forms.iptsChanges,
              [action.field]: action.value,
              [`${action.field}_num`]: action.numInt,
              ...condLabel,
            },
            msgs: {
              ...state.C1.forms.msgs,
              [action.field]: condEmpty,
            },
          },
        },
      };
    },

    C1_ImgChange: () => {
      // ---------- set Validation
      const value = action.value;
      const condEmpty = value === '';

      return {
        ...state,
        C1: {
          ...state.C1,

          forms: {
            ...state.C1.forms,
            iptsChanges: {
              ...state.C1.forms.iptsChanges,
              image: action.value,
            },
            msgs: {
              ...state.C1.forms.msgs,
              [action.field]: condEmpty,
            },
          },
        },
      };
    },
  };

  const adds = {
    // ---------- set ADD C1 _Shops
    C1_ADD_Shops: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Data to Add
        const idToEdit = state.C1.idToEdit;
        const dataToAdd = { ...state.C1.forms.iptsChanges };

        // ------ When Image Exist
        const infoImg = dataToAdd.image;
        const getUrl = async () => {
          await storage.ref(`images/${infoImg.name}`).put(infoImg);

          const url = await storage
            .ref('images')
            .child(infoImg.name)
            .getDownloadURL();

          return url;
        };

        if (infoImg) {
          dataToAdd.imgUrl = await getUrl();
          delete dataToAdd.image;
        }

        // ------ set Address
        const condGeo = await processData(dataToAdd, idToEdit);
        const newObj = condGeo.newObj;
        const geoError = condGeo.geoError;

        // ---------- set Cond Edit or Save
        let dataSuccess = null;

        // ---------- lat, long both need to be filled to proceed
        if (!geoError) {
          if (idToEdit) {
            const refDbEdit = firestore.collection('shops').doc(idToEdit);
            dataSuccess = await refDbEdit.update({ ...newObj });
          } else {
            const refDb = firestore.collection('shops').doc();
            newObj.docId = refDb.id;
            newObj.createdAt = firebase.firestore.Timestamp.now();

            const coords = {
              lat: Number(dataToAdd?.lat),
              long: Number(dataToAdd?.long),
            };

            const range = getGeohashRange(coords.lat, coords.long, 1);
            newObj.address = {
              ...newObj.address,
              range: {
                upper: range.upper,
                radius: 1,
                lower: range.lower,
              },
            };
            newObj.settings = { radiusLimit: 20 };

            dataSuccess = await refDb.set(newObj);
          }
        }

        // ------ return SUCCESS (return null when lat or long not filled)
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return {
        ...state,
        C1: {
          ...state.C1,
          loader: false,
        },
      };
    },

    C1_ADD_Shops_SUCCESS: () => {
      const error = action.value === null;
      let showMsgVal = false;

      // when C1_ADD_Shops return null set MsgError
      if (error) {
        showMsgVal = true;
      } else {
        action.asyncDispatch({ type: 'base_setRoute', value: null });
      }

      return {
        ...state,
        C1: {
          ...state.C1,
          loader: true,
          forms: {
            ...state.C1.forms,
            msgs: {
              ...state.C1.forms.msgs,
              showMsgVal,
            },
          },
        },
      };
    },
  };

  const btns = {
    C1_BtnCancel: () => {
      return {
        ...state,
        C1: {
          ...state.C1,
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: null,
        },
      };
    },
  };

  return {
    ...adds,
    ...inits,
    ...forms,
    ...btns,
  };
};

// ---------- set Exports
export default reducers;
