// ----------- import Packs
import React from 'react';

// ----------- import Internals
import { useRouter, useData, UseCondLoader } from '../../../useMorfos';
import { useDispatch } from 'react-redux';

// ----------- set Group
export default ({ children }) => {
  // ----------- set Data
  const baseAuthUser = useData(`baseAuthUser`);
  const authUser = useData(`basePersist.userId`);

  // ----------- set Effects
  const fxGetUser = () => {
    const condCall = authUser && !baseAuthUser;
    condCall && dispatch({ type: 'allgps_GET_User', value: authUser });
  };

  // ----------- set Hooks
  const { callRedirect } = useRouter();
  const dispatch = useDispatch();
  React.useEffect(fxGetUser, []);

  const condChild = (
    <UseCondLoader data={'baseAuthUser'}>{children}</UseCondLoader>
  );
  const condReturn = authUser ? condChild : callRedirect('signin');

  return condReturn;
};
