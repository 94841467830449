// ----------- import Packs
import React from 'react';
import { View, Text } from 'react-native';

// ----------- import Internals
import { useStl } from '../../../../useMorfos';

// #region :: STYLEs *********

const stlPad = [{ width: '100%', paddingHorizontal: 30 }];
const stlCard = [
  useStl.cardItemList,
  useStl.flexRow,
  useStl.border(1, 1, 1, 1, '#ddd'),
  { backgroundColor: '#f6f6f6', shadowOpacity: 0 },
];

// #endregion *********

export default ({ children }) => {
  return (
    <View style={stlPad}>
      <View style={stlCard}>
        <Text style={{ color: '#666', fontSize: 12 }}>
          Nenhum item encontrado
        </Text>
      </View>
    </View>
  );
};
