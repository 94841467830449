// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import {
  useRouter,
  UseInitData,
  useData,
} from '../../../../../../src/useMorfos';

// ----------- set Info Screen
export const infoSc = {
  path: 'salePf',
  groupSc: 'pubApp',
  condBigSc: true,
  scCode: 'D3',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('D3.scContent');

  // ----------- set Hooks
  const { callRouter } = useRouter();

  // ----------- set Routes
  const btnGoto = () => callRouter('signin');

  // ----------- set Return
  const infoView = { content, btnGoto };

  return (
    <UseInitData reducer={'D3_InitData'}>
      <ViewDF info={infoView} />
    </UseInitData>
  );
};
