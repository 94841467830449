// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { useDispatch } from 'react-redux';

// import { useDispatch } from 'react-redux';

// ----------- import Internals
import { useData, UseIcoMoon, useStl } from '../../../useMorfos';
import defaultImg from '../../images/default.jpg';

export default ({ infoData }) => {
  // ----------- set infoData
  const { itemsInfo, itemId } = infoData;
  const dispatch = useDispatch();

  // ----------- set Data
  const item = useData(itemsInfo, itemId);
  const currShop = useData('M11.shopsInReach.itemsInfo', item.shopId);
  const shopImg = currShop?.imgUrl ?? defaultImg;
  const shopName = currShop?.name ?? '...';
  const expDate = item?.expiDate ?? '__/__/__';

  const currImg = item?.currImg;
  const numImgs = item?.numImgs;
  const condSrc = item[`imgUrl${currImg}`] ?? item.imgUrl;

  // ----------- set BTNs
  const btnPrev = () =>
    dispatch({ type: 'M11_PrevImg', currImg, docId: item.docId, numImgs });
  const btnNext = () =>
    dispatch({ type: 'M11_NextImg', currImg, docId: item.docId, numImgs });

  return (
    <View>
      {/* Header Title */}
      <View style={stlHeader}>
        <View style={stlRow}>
          <Image source={shopImg} style={stlShopImg} />
          <Text style={{ fontWeight: '600', fontSize: 18, marginLeft: 14 }}>
            {shopName}
          </Text>
        </View>

        <Text style={{ fontWeight: '600' }}>{expDate}</Text>
      </View>

      {/* Gallery */}
      <View style={stlGallery}>
        <View style={stlControl}>
          <TouchableOpacity style={stlBtnArr} onPress={btnPrev}>
            <UseIcoMoon name="left" size={16} color="#444" />
          </TouchableOpacity>
          <Text>{`${currImg}/${numImgs}`}</Text>
          <TouchableOpacity style={stlBtnArr} onPress={btnNext}>
            <UseIcoMoon name="right" size={16} color="#444" />
          </TouchableOpacity>
        </View>
        <Image source={condSrc} style={stlBookImg} resizeMode="stretch" />
      </View>
    </View>
  );
};

// #region :: STYLEs *********

const stlRow = [useStl.flexRow];
const stlHeader = [
  stlRow,
  useStl.flexBetween,
  { backgroundColor: '#fff', paddingHorizontal: 20, paddingVertical: 4 },
];

const stlGallery = [{}];
const stlShopImg = [{ width: 50, height: 50, borderRadius: 50 }];
const stlBookImg = [
  {
    width: '100%',
    aspectRatio: 2 / 2.6,
  },
];
const stlControl = [
  stlRow,
  useStl.flexBetween,
  {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 10,
    backgroundColor: '#fff',
    borderRadius: 20,
    width: 80,
    height: 30,
  },
];
const stlBtnArr = [useStl.flexCenter, { width: 30, height: 30 }];

// #endregion *********
