// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';

// ----------- import Internals
import src1 from '../../../images/logo.svg';
import { useStl, UseIcoMoon, useData, useRouter } from '../../../../useMorfos';
import { useDispatch } from 'react-redux';
import SearchBar from '../../Prj/SearchBar';

// #region :: STYLEs *********

const stlRow = [useStl.flexRow, { flex: 1, justifyContent: 'start' }];
const stlNAV1 = [{ zIndex: 2 }];
const stlNAV1a = [useStl.navbarView, { backgroundColor: '#fff' }];
const stlNAV1c = [{ marginRight: 140 }];
const stlNAV2 = [useStl.titlePageLeft];
const stlLogo = [{ width: 80, height: 24, marginLeft: 20, marginBottom: -3 }];

const stlUserTag = [useStl.flexRow];
const stlAvatar = [useStl.avatar, { marginRight: 2 }];
const stlNameUser = [
  {
    fontWeight: '600',
    width: 120,
    height: 20,
    overflow: 'hidden',
    paddingHorizontal: 5,
  },
];
const stlMsgSuspension = [
  useStl.flexRow,
  useStl.flexBetween,
  { backgroundColor: '#233c21', height: 40, paddingHorizontal: 30 },
];
const stlTxtMsgSuspn = [{ color: '#fff', fontSize: 12 }];
const stlBtnMsgSuspn = [useStl.btn, useStl.btnXSmall, useStl.btnPrimary];

// #endregion *********

export default () => {
  // ----------- set UseData
  const { imgUrl, name, typeAccount } = useData('baseAuthUser');
  const condShop = typeAccount === 'shop1';

  // ----------- set Router
  const { callRouter } = useRouter();

  const goToProfile = () => {
    callRouter('myPf');
  };

  // ----------- set Return
  return (
    <>
      {condShop && <MsgSuspension />}
      <View style={stlNAV1}>
        <View style={[stlNAV1a]}>
          <TouchableOpacity onPress={'info.condGoTo'}>
            <UseIcoMoon name={'icon'} size={20} color={'#fff'} />
          </TouchableOpacity>
          <View style={[stlRow]}>
            <View style={stlNAV1c}>
              <Text style={stlNAV2}>
                <Image source={src1} style={stlLogo} />
              </Text>
            </View>
            <SearchBar />
          </View>

          <TouchableOpacity onPress={goToProfile}>
            <View style={stlUserTag}>
              <Image source={imgUrl} style={stlAvatar} />
              <Text style={stlNameUser}>{name}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

const MsgSuspension = () => {
  // ---------- set Data
  const accountStatus = useData('S1.accountStatus');
  const alert = accountStatus === 'alert';
  const suspended = accountStatus === 'suspended';

  // ---------- set Return
  if (alert) {
    return <Alert />;
  }
  if (suspended) {
    return <Suspended />;
  }
  return <></>;
};

const Alert = () => {
  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Btns
  const updateProds = () => dispatch({ type: 'comps_UpdateShop' });

  // ---------- set Return
  return (
    <View style={stlMsgSuspension}>
      <Text style={stlTxtMsgSuspn}>
        Por favor atualizar o preço de seus produtos. Caso não seja atualizado
        seu mercado ficara suspenso
      </Text>
      <TouchableOpacity style={stlBtnMsgSuspn} onPress={updateProds}>
        <Text style={stlTxtMsgSuspn}>Produtos Atualizados</Text>
      </TouchableOpacity>
    </View>
  );
};

const Suspended = () => {
  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Btns
  const updateProds = () => dispatch({ type: 'comps_UpdateShop' });

  // ---------- set Return
  return (
    <View style={stlMsgSuspension}>
      <Text style={stlTxtMsgSuspn}>
        Seu Mercado está suspenso, por falta de atualização nos preços! Para
        voltar as atividades, deverá atualizar os preços novamente.
      </Text>
      <TouchableOpacity style={stlBtnMsgSuspn} onPress={updateProds}>
        <Text style={stlTxtMsgSuspn}>Produtos Atualizados</Text>
      </TouchableOpacity>
    </View>
  );
};
