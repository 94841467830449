// ----------- import Packs
import React from 'react';
import { View, Text } from 'react-native';

// ----------- import Internals
import { useStl } from '../../../../../useMorfos';

// #region :: STYLEs *********
const stlBodyView = [
  useStl.flex1,
  useStl.pad20,
  useStl.bgSc,
  useStl.flexTopCenter,
];
const stlTitleTxt = [useStl.txtTitleScreen, { marginBottom: 20 }];

// #endregion *********

export default ({ info, children }) => {
  return (
    <View style={stlBodyView}>
      {/* ----------- PARTs */}
      <Text style={stlTitleTxt}>{info.content.title}</Text>
      {children}
    </View>
  );
};
