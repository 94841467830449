/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';

// ----------- import Internals
import { useData, useStl } from '../../../../../useMorfos';
import { IptPicker, IptTxt, OrderInfoCard } from '../../../../comps';

export default ({ info, children }) => {
  const itemId = useData('S1b.edit');
  const editProds = useData('S1c.editProds');
  const condPicks = useData('S1c.condPicks');
  const currOrder = useData('S1b.itemsInfo', itemId);
  const condBtn = useData('S1c.msgs.condBtn');
  const { imgUrl, name, statusOrder } = currOrder;
  const { btnConfirm, btnPaid, btnCancel } = info;
  const condTxt = editProds ? 'Cancelar' : 'Editar';

  return (
    <View style={stlBodyView}>
      {/* Buyer Infos */}
      <View style={stlHeader}>
        <Image source={imgUrl} style={stlHeaderImg} />

        <View style={stlHeader01}>
          <Text style={stlSubTitle}>{name}</Text>
          <Text style={stl03}>Informação adicional</Text>
        </View>
      </View>

      {/* Order Infos */}
      <OrderInfoCard info={{ ...currOrder, btnConfirm, btnPaid, btnCancel }} />

      {/* List Prods + Edit */}
      <View style={stlEditHeader}>
        <Text style={stl01}>Produtos Solicitados</Text>
        {statusOrder === 'Confirmar' && (
          <TouchableOpacity style={stl02} onPress={info.btnEdit}>
            <Text style={stlTxtBtn}>{condTxt}</Text>
          </TouchableOpacity>
        )}

        {editProds && (
          <TouchableOpacity style={stl02} onPress={info.btnSave}>
            <Text style={stlTxtBtn}>Salvar</Text>
          </TouchableOpacity>
        )}
      </View>
      {editProds && (
        <View style={stlAddProd}>
          <View style={stlInclude}>
            <View style={stlRectangleLine}>
              <Text style={fontSmall}>Incluir novo produto</Text>
              <TouchableOpacity style={stlBtnList} onPress={info.btnInclude}>
                <Text style={stlTxtInverse}>Ver Lista</Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                backgroundColor: '#fff',
                // paddingVertical: condPicks ? 6 : 0,
                width: '100%',
              }}
            >
              {condPicks && (
                <View style={{ width: '100%' }}>
                  <View style={stlCtnPicker}>
                    <IptPicker infoData={'S1c.forms.iptsInfo.prodId'} />
                  </View>
                  <View style={stlCtnPicker}>
                    <IptPicker infoData={'S1c.forms.iptsInfo.varId'} />
                  </View>
                  <View style={stlPicker01}>
                    <IptTxt
                      infoData={'S1c.forms.iptsInfo.amountProd'}
                      style={stlTxtIpt}
                    />
                    <TouchableOpacity
                      style={stlBtnInclude}
                      onPress={info.btnAdd}
                    >
                      <Text style={stlTxtInverse}>Adicionar</Text>
                    </TouchableOpacity>
                  </View>
                  {condBtn && (
                    <Text style={stlAlertMsg}>Preencha todos os campos!</Text>
                  )}
                </View>
              )}
            </View>
          </View>
        </View>
      )}

      {children}
    </View>
  );
};

// #region :: STYLEs *********

// ------------- set Views
const stlRow = [useStl.flexRow];
const stlBodyView = [useStl.flex1, { padding: 10 }];
const stl01 = {
  flex: 2,
  color: '#666666',
  marginHorizontal: 'auto',
};
const stlEditHeader = [stlRow, { marginBottom: 10 }];
const stl02 = [useStl.btn, useStl.btnXSmall, { height: 17 }];
const stlTxtBtn = { fontSize: 12 };
const stl03 = { color: '#666666', fontSize: 13, fontWeight: 'bold' };
const stlSubTitle = [{ fontWeight: 600, fontSize: 13, color: '#444' }];

// ---------- HEADER STYLE
const stlHeaderImg = [useStl.profileImg, { height: 55, width: 55 }];
const stlHeader = [useStl.flexRow, { margin: 'auto' }];
const stlHeader01 = [{ marginBottom: 20, marginLeft: 10 }];

// ---------- STATUS STYLE
const stlTxSmall = { fontSize: 10 };
const fontSmall = [stlTxSmall, { fontWeight: 600 }];

// ---------- INCLUDE STYLE
const stlBtnXSmall = [useStl.btn, useStl.btnXSmall];
const stlBtnMedium = [useStl.btn, useStl.btnMedium];
const stlRectangleLine = [
  stlRow,
  useStl.flexBetween,
  { padding: 6, width: '100%' },
];

const stlTxtIpt = [useStl.flexRow];
const stlTxtInverse = [
  useStl.txtPrimaryInverse,
  { fontSize: 10, fontWeight: 600 },
];
const stlInclude = [
  useStl.flexBetween,
  useStl.border(1, 1, 1, 1, '#cfcfcf'),
  {
    backgroundColor: '#fff',
    borderRadius: 4,
    marginBottom: 22,
    padding: 5,
  },
];
const stlAddProd = [{}];
const stlCtnPicker = [{ flex: 1 }];
const stlPicker01 = [useStl.flexRow, { width: 100, marginBottom: 0 }];

const stlBtnInclude = [stlBtnMedium, useStl.btnPrimary];
const stlBtnList = [stlBtnXSmall, useStl.btnPrimary];

const stlAlertMsg = [{ color: 'red', paddingVertical: 9 }];

// #endregion *********
