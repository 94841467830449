/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, ScrollView, Text, TouchableOpacity } from 'react-native';

// ----------- import Internals
import { useStl } from '../../../../useMorfos';
import { AlertBox, ItemCateg, NavBar } from '../../../comps';

export default ({ children, info }) => {
  return (
    <>
      <NavBar />
      <View style={stlBodyView}>
        <View style={stlScrollCateg}>
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            <View style={stlRow}>
              <info.ListCategs />
            </View>
          </ScrollView>
        </View>
        <View style={stlScrollSub}>
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            <info.ListSubCategs />
          </ScrollView>
        </View>
        <View style={stlScroll}>
          <ScrollView>
            {/* ----------- PARTs */}

            <View style={[useStl.flexCenter]}>{children}</View>
          </ScrollView>
        </View>
        <AlertBox info={{ ...info }} />
      </View>
    </>
  );
};

// #region :: STYLEs *********

const stlBodyView = [
  useStl.flex1,
  { backgroundColor: '#f6f6f6', overflow: 'hidden', marginTop: 60 },
];
const stlRow = [useStl.flexRow];
const stlScrollCateg = [{ height: 80 }];
const stlScrollSub = [{ height: 30 }];
const stlScroll = [{ flex: 1 }];

// #endregion *********
