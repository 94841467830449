/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF, { Purchase } from './Views';
import {
  useData,
  UseInitData,
  UseList,
  UseCondLoader,
} from '../../../../useMorfos';
import { ItemList, NoItemView } from '../../../comps';
import { ezLog } from '../../../../useMorfos/utils';
import { useDispatch } from 'react-redux';

// ----------- set Info Screen
export const infoSc = {
  path: 'purchasedList',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: 'Minhas Compras',
  },

  scCode: 'M7d',
};

// ----------- set Default Component
export default () => {
  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set BTNs
  const btnPay = itemId => dispatch({ type: 'M7d_BtnPay', value: itemId });

  // ----------- set Return
  const infoItem = {
    itemContent: 'M7d.scContent',
    itemsInfo: 'M7d.itemsInfo',
    pressItem: 'M7d_EditShopProds',
  };

  return (
    <UseInitData reducer={'M7d_InitData'}>
      <ViewDF>
        <UseCondLoader data={'M7d.condList'}>
          <UseList data={'M7d.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <Purchase infoData={{ ...infoItem, itemId, btnPay }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
