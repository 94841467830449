// ---------- import Internals
import { firestore } from '../../../../../config/firebase/fbConfig';
import { asyncRefs, ezLog } from '../../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    S1c_InitData: () => {
      const ids = state.S1b.edit;
      const currOrder = state.S1b.itemsInfo[ids];
      const withdraw = currOrder?.deliveryData?.withdraw;

      action.asyncDispatch({ type: 'S1_GET_ShopProds' });
      action.asyncDispatch({ type: 'S1c_GET_Infos' });

      const condList = false;
      return {
        ...state,
        S1c: {
          condList,
          editProds: false,
          withdraw,
        },
      };
    },

    S1c_InitPicks: () => {
      // ---------- set Data
      const prods = state?.S1?.itemsInfo;
      const allProdVars = state.S1c?.allProdVars;
      const edit = state?.S1c?.forms?.iptsChanges?.prodId;
      const cleanIpt = edit && null;
      const condEnabled = edit ? true : false;
      // --- set Pickers
      const picksVar = [];
      const picksProd = [];

      // ---------- set Loops
      for (const key in prods) {
        const prod = prods[key];
        const variations = [];
        const name = prod.name;
        const docId = prod.docId;
        variations.push(prod.variations);

        picksProd.push({ labels: name, id: docId });
      }

      for (let i = 0; i < 4; i++) {
        for (const item in allProdVars) {
          const prod = `${edit}_${i}`;
          let variation = allProdVars[item].varName;
          item === prod && picksVar.push({ labels: variation, id: String(i) });
        }
      }

      const iptsInfo = {
        // ---------- Inputs Base
        prodId: {
          // ----- set Dynamics
          itemId: 'prodId',
          required: true,
          iptChange: 'S1c_IptPicker',

          // ----- set Statics
          dataPath: 'S1c.forms.iptsChanges.prodId',
          pHolder: 'Produto',
          // title: 'Escolha o Produto',
          label: 'Produtos',
          pickerList: picksProd,
        },

        varId: {
          // ----- set Dynamics
          itemId: 'varId',
          required: true,
          iptChange: 'S1c_IptPicker',
          enabled: condEnabled,

          // ----- set Statics
          dataPath: 'S1c.forms.iptsChanges.varId',
          pHolder: 'Variação',
          // title: 'Escolha a Variação',
          label: 'Variação',
          pickerList: picksVar,
        },

        amountProd: {
          // ----- set Dynamics
          itemId: 'amountProd',
          dataPath: 'S1c.forms.iptsChanges.amountProd',

          required: true,
          iptChange: 'S1c_IptTxt',
          // condNumber: true,

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Quantidade',
          editData: cleanIpt,
          // isEditable,
        },
      };

      return {
        ...state,
        S1c: {
          ...state.S1c,
          forms: { ...state?.S1c?.forms, iptsInfo },
          condPicks: true,
          picksVar,
        },
      };
    },
  };

  const gets = {
    // ---------- set GET SHOP PRODS
    S1c_GET_ShopProds: () => {
      // ---------- set Async Function2
      const asyncProd = async () => {
        // ---------- set Type List
        const currShop = state?.baseAuthUser?.shopId;

        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('shops')
          .doc(currShop)
          .collection('prods')
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncProd);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S1c_GET_ShopProds_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      const prodId = [];

      // ----------- set Prods Ids
      const currShopInfo = {};
      dataDb.forEach(doc => {
        const dataDb = doc.data();
        currShopInfo[doc.id] = dataDb;
        prodId.push(doc.data().prodId);
      });
      action.asyncDispatch({ type: 'S1c_GET_Prods' });
      return {
        ...state,
        S1c: {
          ...state.S1c,
          currShopInfo,
          // condList: true,
        },
      };
    },

    S1c_GET_Prods: () => {
      // ---------- set Data
      const currShopInfo = state?.S1c?.currShopInfo;
      const prods = [];

      for (const key in currShopInfo) {
        const item = currShopInfo[key];
        prods.push(item.prodId);
      }

      const asyncFn = async () => {
        const itemRefs = prods.map(id => {
          return firestore.collection('prods').doc(id).get();
        });
        const finalRes = Promise.all(itemRefs);
        return finalRes;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S1c_GET_Prods_SUCCESS: () => {
      const dataDb = action.value;
      const itemsInfo = {};
      const itemsList = [];

      dataDb.forEach(doc => {
        itemsInfo[doc.id] = doc.data();
        itemsList.push(doc.id);

        // theDate = new Date(lastDate.seconds * 1000);
      });

      action.asyncDispatch({ type: 'S1c_GET_Infos' });

      return {
        ...state,
        S1: {
          ...state?.S1,
          itemsInfo,
          itemsList,
          deleteInfo: true,
        },
      };
    },

    S1c_GET_Infos: () => {
      // ---------- set Data
      const ids = state.S1b.edit;
      const currOrder = state.S1b.itemsInfo[ids];
      const arrProds = currOrder.arrProds;
      const prodsBase = state.S1.itemsInfo;

      // ---------- set Containers
      const itemsList = [];
      const newItems = {};
      const allProdVars = {};

      // ---------- set Loops
      for (const key in arrProds) {
        const item = arrProds[key];
        const varId = item.id;

        for (const key2 in prodsBase) {
          const item2 = prodsBase[key2];
          const cutKey = varId.slice(0, -2);
          const takeVar = varId.slice(-1);
          const condSnap = key2 === cutKey;

          const variations = prodsBase[cutKey]?.variations[takeVar];
          const currImg = item2.currImg;
          const img = `imgUrl${currImg}`;
          if (condSnap) {
            newItems[varId] = {
              renderItem: true,
              prodIdVar: varId,
              shopPrice: item.price,
              amountProd: item.amount,
              varName: variations.typeName_label,
              varValue: variations.typeValue,
              name: item2.name,
              [img]: item2[img],
              currImg,
            };
          }
        }

        itemsList.push(varId);
      }

      // ---------- set AllVariations
      for (const key in prodsBase) {
        const item = prodsBase[key];
        const variations = item.variations;
        const currImg = item.currImg;
        const img = `imgUrl${currImg}`;

        for (const key2 in variations) {
          const varItem = variations[key2];
          const varId = `${key}_${key2}`;

          allProdVars[varId] = {
            renderItem: true,
            prodIdVar: varId,
            varName: varItem.typeName_label,
            varValue: varItem.typeValue,
            name: item.name,
            [img]: item[img],
            currImg,
          };
        }
      }

      return {
        ...state,
        S1c: {
          ...state.S1c,
          allProdVars,
          itemsInfo: { ...newItems },
          itemsList,
          condList: true,
        },
      };
    },
  };

  const edits = {
    S1c_BtnEditProd: () => {
      const editProds = state?.S1c?.editProds;

      return {
        ...state,
        S1c: {
          ...state.S1c,
          editProds: !editProds,
        },
      };
    },

    S1c_BtnControlProds: () => {
      // ---------- set Data
      const { docId, amountProd } = action.infoProd;
      const itemsList = state?.S1c?.itemsList;
      let render = false;

      // ---------- set Prod To Add
      const historyAddPrds = state?.S1c?.itemsInfo[docId] ?? {};
      const addedProds = {
        ...state.S1c.itemsInfo,
        [docId]: {
          ...historyAddPrds,
          amountProd,
        },
      };

      // ---------- set Cond Delete
      const item = addedProds[docId];
      const condRemove = item.amountProd === 0;
      if (condRemove) {
        delete addedProds[docId];
        render = true;

        item.renderItem = false;

        const idxToDel = itemsList.indexOf(docId);
        if (idxToDel > -1) {
          itemsList.splice(idxToDel, 1);
        }
      }

      // ---------- set Calc Total Amount
      const arrPrices = [];
      for (const key in addedProds) {
        const item = addedProds[key];
        const itemAmount = addedProds[key].amountProd;
        const multiplyAmount = item.shopPrice * itemAmount;

        arrPrices.push(multiplyAmount);
      }
      const total = arrPrices.reduce((prev, crr) => prev + crr, 0);

      return {
        ...state,
        S1c: {
          ...state.S1c,
          render,
          totalPrice: total,
          itemsInfo: addedProds,
          itemsList,
        },
      };
    },

    S1c_IncludeProds: () => {
      // ---------- set Data
      const iptsChanges = state?.S1c?.forms?.iptsChanges;
      const { prodId, amountProd, varId = 0 } = iptsChanges;
      const currShopInfo = state?.S1?.currShopInfo;
      const prodIdVar = `${prodId}_${varId}`;
      const currProd = state?.S1?.itemsInfo[prodId];
      const currVarName = state?.S1c?.allProdVars[prodIdVar].varName;
      const currVarValue = state?.S1c?.allProdVars[prodIdVar].varValue;

      const { currImg, imgUrl1, name } = currProd;
      const varName = currVarName;
      const varValue = currVarValue;
      let shopPrice;
      const renderItem = true;

      const itemsList = [];
      let render = false;

      for (const prod in currShopInfo) {
        const shop = currShopInfo[prod];
        if (shop.prodId == prodId) {
          shopPrice = shop.variations[varId].price;
        }
      }

      // ---------- set Prod To Add
      const addedProds = {
        ...state.S1c.itemsInfo,
        [prodIdVar]: {
          // iptsChanges
          amountProd: Number(amountProd),

          // currProd - selecionado a partir do prodId (vem do picker)
          currImg,
          imgUrl1,
          name,
          prodIdVar,
          renderItem,
          shopPrice,

          // selecionando de s1c.allProdVars[prodIdVar]
          varName,
          varValue,
        },
      };

      for (const key in addedProds) {
        itemsList.push(key);
      }

      // ---------- set Calc Total Amount
      const arrPrices = [];
      for (const key in addedProds) {
        const item = addedProds[key];
        const itemAmount = addedProds[key].amountProd;
        const multiplyAmount = item.shopPrice * itemAmount;

        arrPrices.push(multiplyAmount);
      }
      const total = arrPrices.reduce((prev, crr) => prev + crr, 0);

      action.asyncDispatch({ type: 'S1c_InitPicks' });

      return {
        ...state,
        S1c: {
          ...state.S1c,
          render,
          totalPrice: total,
          itemsInfo: addedProds,
          itemsList,
          forms: {
            ...state.S1c.forms,
            iptsChanges: '',
          },
        },
      };
    },

    S1c_BtnSaveProds: () => {
      // ---------- set Data
      const editProds = state?.S1c?.editProds;
      const itemsInfo = state.S1c.itemsInfo;
      const itemsList = state.S1c.itemsList;
      const orderId = state.S1b.edit;

      // ---------- set Containers
      const newArrProds = [];
      let totalPrice = 0;

      // ---------- set Loops
      itemsList.forEach(item => {
        const prod = itemsInfo[item];
        const varNumber = item.slice(-1);
        const price = prod.shopPrice * prod.amountProd;
        totalPrice = totalPrice + price;

        newArrProds.push({
          id: prod.prodIdVar,
          amount: prod.amountProd,
          price: prod.shopPrice,
          var: varNumber,
        });
      });

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('orders').doc(orderId);
        const dataSuccess = await dbRef.update({
          arrProds: newArrProds,
          totalAmount: newArrProds.length,
          totalPrice: totalPrice,
        });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return { ...state, S1c: { ...state.S1c, editProds: !editProds } };
    },

    S1c_StatusConfirm: () => {
      const itemId = state?.S1b.edit;
      const currOrder = state?.S1b?.itemsInfo[itemId];
      // const currOrder = state?.M7e?.currOrder;

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = await firestore
          .collection('orders')
          .doc(currOrder.docId)
          .get();

        const condStatus = dbRef.data().statusOrder === 'Cancelado';
        if (!condStatus) {
          const dbRef = firestore.collection('orders').doc(currOrder.docId);
          const dataSuccess = await dbRef.update({
            statusOrder: 'Aguardando Pagamento',
          });
          // ------ return SUCCESS
          return dataSuccess;
        } else {
          const dataSuccess = false;
          return dataSuccess;
        }
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        S1c: {
          ...state.S1c,
          token: action.value,
        },
      };
    },

    S1c_StatusConfirm_SUCCESS: () => {
      const edit = state?.S1b?.edit;
      const token = state?.S1b?.itemsInfo[edit]?.token;
      const value = action.value;

      const sendMsg = () => {
        const toToken = token;
        const data = {
          notification: {
            title: 'Poupi',
            body: 'Seu pedido foi confirmado!',
            click_action: 'https://modelopoupi.web.app/signin2',
            icon: 'https://modelopoupi.web.app/pwa/logo512.png',
          },
          to: toToken,
        };

        fetch('https://fcm.googleapis.com/fcm/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'key=AAAALRYh1uQ:APA91bFUu0xBLL3P7qPl2jRs7MxyOcqgSq0yCPITDGd3-31jdbi3fFSYz2IKR0TKOw6jxSrBVVFUYanUeWSzETydhsLDZEMCIVP--XO--cA4je3GyBiPj9kwnd8ZUolDKGLTX9RfhD8j',
          },
          body: JSON.stringify(data),
        })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };
      sendMsg();

      const itemsInfo = state?.S1b?.itemsInfo;
      const itemId = state?.S1b.edit;
      const currOrder = state?.S1b?.itemsInfo[itemId];
      if (value === false) {
        return {
          ...state,
          S1c: {
            ...state.S1c,
            statusCancel: true,
          },
        };
      } else {
        itemsInfo[currOrder.docId] = {
          ...currOrder,
          statusOrder: 'Aguardando Pagamento',
        };

        return state;
      }
    },

    S1c_StatusShipped: () => {
      const itemId = state?.S1b.edit;
      const currOrder = state?.S1b?.itemsInfo[itemId];
      // const currOrder = state?.M7e?.currOrder;

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('orders').doc(currOrder.docId);
        const dataSuccess = await dbRef.update({
          statusOrder: 'Pedido Entregue',
        });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S1c_StatusShipped_SUCCESS: () => {
      const itemsInfo = state?.S1b?.itemsInfo;
      const itemId = state?.S1b.edit;
      const currOrder = state?.S1b?.itemsInfo[itemId];

      itemsInfo[currOrder.docId] = {
        ...currOrder,
        statusOrder: 'Pedido Entregue',
      };
      return {
        ...state,
        S1b: {
          ...state.S1b,
        },
      };
    },

    S1c_BtnCancel: () => {
      const itemId = state?.S1b.edit;
      const currOrder = state?.S1b?.itemsInfo[itemId];
      // const currOrder = state?.M7e?.currOrder;

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('orders').doc(currOrder.docId);
        const dataSuccess = await dbRef.update({
          statusOrder: 'Cancelado',
        });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S1c_BtnCancel_SUCCESS: () => {
      const itemsInfo = state?.S1b?.itemsInfo;
      const itemId = state?.S1b.edit;
      const currOrder = state?.S1b?.itemsInfo[itemId];

      itemsInfo[currOrder.docId] = {
        ...currOrder,
        statusOrder: 'Cancelado',
      };
      return {
        ...state,
        S1b: {
          ...state.S1b,
        },
      };
    },
  };

  const forms = {
    S1c_IptPicker: () => {
      const edit = action.value;
      action.asyncDispatch({ type: 'S1c_InitPicks' });
      return {
        ...state,
        S1c: {
          ...state.S1c,
          forms: {
            ...state.S1c.forms,
            iptsChanges: {
              ...state.S1c.forms.iptsChanges,
              [action.itemId]: action.value,
            },
          },
        },
      };
    },

    S1c_IptTxt: () => {
      action.asyncDispatch({ type: 'S1c_InitPicks' });

      return {
        ...state,
        S1c: {
          ...state.S1c,
          forms: {
            ...state.S1c.forms,
            iptsChanges: {
              ...state.S1c.forms.iptsChanges,
              [action.field]: action.value,
            },
          },
        },
      };
    },

    S1c_BtnIncludeProds: () => {
      const iptsChanges = state?.S1c?.forms?.iptsChanges;

      if (iptsChanges) {
        const { prodId, amountProd, varId = 0 } = iptsChanges;
        if (prodId && amountProd && varId) {
          action.asyncDispatch({ type: 'S1c_IncludeProds' });
          return state;
        } else {
          return {
            ...state,
            S1c: {
              ...state?.S1c,
              msgs: {
                ...state?.S1c?.msgs,
                condBtn: true,
              },
            },
          };
        }
      } else {
        return {
          ...state,
          S1c: {
            ...state?.S1c,
            msgs: {
              ...state?.S1c?.msgs,
              condBtn: true,
            },
          },
        };
      }
    },
  };

  return {
    ...inits,
    ...edits,
    ...gets,
    ...forms,
  };
};

// ---------- set Exports
export default reducers;
