// ----------- import Packs
import React from 'react';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF from './Views';
import { useRouter, UseInitData, useData } from '../../../../useMorfos';
import imgDefault from '../../../images/default.jpg';

// ----------- set Info Screen
export const infoSc = {
  path: 'myprofile',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: 'Meu Perfil',
  },

  scCode: 'M5',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('M5.scContent');
  const { name, imgUrl, email } = useData('baseAuthUser');

  // ----------- set Hooks
  const { callRouter } = useRouter();

  // ----------- set Routes
  const btnGoto = () => callRouter('signin');
  const condThumbnail = imgUrl ?? imgDefault;

  // ----------- set Return
  const infoView = {
    content,
    btnGoto,
    name,
    condThumbnail,
    email,
  };

  return (
    <UseInitData reducer={'M5_InitData'}>
      <ViewDF info={infoView} />
    </UseInitData>
  );
};
