// ---------- import Internals
import { firebase, firestore } from '../../../../config/firebase/fbConfig';

import { asyncRefs } from '../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    M7e_InitData: () => {
      const condGetProdsBase = state?.M7e?.prodsBase?.itemsInfo;
      !condGetProdsBase && action.asyncDispatch({ type: 'M7e_GET_ProdsBase' });
      action.asyncDispatch({ type: 'M7e_GET_ShopInfo' });

      return {
        ...state,
      };
    },
  };

  const changes = {
    M7e_StatusUserOk: () => {
      const currOrder = state?.M7e?.currOrder;

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('orders').doc(currOrder.docId);
        const dataSuccess = await dbRef.update({
          statusOrder: 'Pedido Entregue',
        });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M7e_StatusUserOk_SUCCESS: () => {
      return state;
    },

    M7e_StatusShipped: () => {
      const currOrder = state?.M7e?.currOrder;

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('orders').doc(currOrder.docId);
        const dataSuccess = await dbRef.update({
          statusOrder: 'Pedido Entregue',
        });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M7e_StatusShipped_SUCCESS: () => {
      return state;
    },
  };

  const gets = {
    // ---------- set GET _ProdsBase
    M7e_GET_ProdsBase: () => {
      const orderProds = state?.M7e?.currOrder?.arrProds;
      const newArr = [];
      orderProds &&
        orderProds.map(item => {
          const cutKey = item.id.slice(0, item.id.length - 2);
          newArr.push(cutKey);
        });

      function getContentById(ids, path) {
        return new Promise(res => {
          // don't run if there aren't any ids or a path for the collection
          if (!ids || !ids.length || !path) return res([]);

          const collectionPath = firestore.collection(path);
          let batches = [];

          while (ids.length) {
            // firestore limits batches to 10
            const batch = ids.splice(0, 10);

            // add the batch request to to a queue
            batches.push(
              new Promise(response => {
                collectionPath
                  .where(firebase.firestore.FieldPath.documentId(), 'in', [
                    ...batch,
                  ])
                  .get()
                  .then(results =>
                    response(
                      results.docs.map(result => ({ ...result.data() })),
                    ),
                  );
              }),
            );
          }

          // after all of the data is fetched, return it
          Promise.all(batches).then(content => {
            res(content.flat());
          });
        });
      }

      const testeFn = async () => await getContentById(newArr, 'prods');

      // ---------- set Async References
      const ref = asyncRefs(action, testeFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
      };
    },

    M7e_GET_ProdsBase_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      const orderProds = state?.M7e?.currOrder?.arrProds;
      const prodsBaseInfo = {};
      const itemsInfo = {};
      const itemsList = [];

      dataDb &&
        dataDb.forEach(item => {
          prodsBaseInfo[item.docId] = item;
        });

      for (const key in orderProds) {
        const item = orderProds[key];

        for (const key2 in prodsBaseInfo) {
          const item2 = prodsBaseInfo[key2];
          const cutKey1 = item.id.slice(0, item.id.length - 2);
          const snapshot = cutKey1 === key2;

          if (snapshot) {
            itemsList.push(item.id);

            itemsInfo[item.id] = {
              prodId: item.id,
              price: item.price,
              amount: item.amount,
              name: item2.name,
              imgUrl: item2[`imgUrl${item2.currImg}`],
            };
          }
        }
      }

      return {
        ...state,

        M7e: {
          ...state.M7e,
          itemsOrder: {
            condList: true,
            itemsInfo,
            itemsList,
          },
        },
      };
    },

    // ---------- set GET _ShopInfo
    M7e_GET_ShopInfo: () => {
      const shopId = state?.M7e?.currOrder?.shopId;

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('shops').doc(shopId);
        const dataSuccess = await dbRef.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M7e_GET_ShopInfo_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action?.value?.data();
      const condPatch = state?.baseRoute?.currScInfo?.scKey === 'M7d';
      condPatch && action.asyncDispatch({ type: 'M7e_BTN_Payment' });

      return {
        ...state,
        M7e: {
          ...state.M7e,
          shopInfo: {
            name: dataDb?.name,
            imgUrl: dataDb?.imgUrl,
            details: dataDb?.details,
            phone: dataDb?.phone,
            recipientId: dataDb?.idpagme,
            settings: dataDb?.settings,
          },
        },
      };
    },
  };

  const btns = {
    M7e_BTN_Payment: () => {
      return { ...state, baseRoute: { ...state.baseRoute, path: 'schedule' } };
    },

    M7e_BTN_Delivered: () => {
      const currOrder = state?.M7e?.currOrder;

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('orders').doc(currOrder.docId);
        const dataSuccess = await dbRef.update({ statusOrder: 'Recebido' });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M7e_BTN_Delivered_SUCCESS: () => {
      return {
        ...state,
        M7e: {
          ...state.M7e,
          currOrder: {
            ...state.M7e.currOrder,
            statusOrder: 'Recebido',
          },
        },
      };
    },

    M7e_BTN_Adjustment: () => {
      const currOrder = state?.M7e?.currOrder;

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('orders').doc(currOrder.docId);
        const dataSuccess = await dbRef.update({
          statusOrder: 'Confirmar',
        });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M7e_BTN_Adjustment_SUCCESS: () => {
      return {
        ...state,
        M7e: {
          ...state.M7e,
          currOrder: { ...state.M7e.currOrder, adjustment: true },
        },
      };
    },

    M7e_BTN_Cancel: () => {
      const currOrder = state?.M7e?.currOrder;

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('orders').doc(currOrder.docId);
        const dataSuccess = await dbRef.update({
          statusOrder: 'Cancelado',
        });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M7e_BTN_Cancel_SUCCESS: () => {
      return {
        ...state,
        M7e: {
          ...state.M7e,
          currOrder: { ...state.M7e.currOrder, statusOrder: 'Cancelado' },
        },
      };
    },
  };

  return {
    ...inits,
    ...changes,
    ...gets,
    ...btns,
  };
};

// ---------- set Exports
export default reducers;
