// ---------- import Internals
import { firebase, firestore } from '../../../../config/firebase/fbConfig';

import { asyncRefs, ezLog } from '../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  // ---------- set Init Data _Name_
  const inits = {
    M8_InitData: () => {
      action.asyncDispatch({ type: 'S4_GET_AdmPar' });

      // ---------- set Data + Consts
      const scContent = {
        title: 'Signin',
        subTitle: 'Oi Mundo!',
        description: 'Lorem Ipsum!',
        txtBtn: 'Ir para os TERMOS',
      };

      const objDays = state?.refs?.weekDays;

      const currShop = state.M7e.shopInfo;
      const deliveryPriceLimit = state.S4.admPar.deliveryPriceLimit;
      const totalPrice = state.M7e.currOrder.totalPrice;

      // ------ set TEMP MOCK
      // const currShop = {
      //   allInfos: [
      //     { prodId: 'fnPnzIfVuvs4hnbsU2qk_0', shopPrice: 70 },
      //     { prodId: 'dTGBJGZDgBa5FsjIJSFr_0', shopPrice: 68 },
      //     { prodId: '9HOtKvydZEhYPqvCxV7p_0', shopPrice: 18 },
      //   ],
      //   availableProds: [
      //     'fnPnzIfVuvs4hnbsU2qk_0',
      //     'dTGBJGZDgBa5FsjIJSFr_0',
      //     '9HOtKvydZEhYPqvCxV7p_0',
      //   ],
      //   condFreeDelivery: true,
      //   goalToDelvry: -56,
      //   name: 'Pão de Açúcar',
      //   rating: 0,
      //   settings: {
      //     radiusLimit: '20',
      //     days: {
      //       0: {
      //         minHour: '07:00',
      //         minHour_num: 7,
      //         maxHour: '22:22',
      //         maxHour_num: 22.22,
      //       },
      //       1: {
      //         minHour: '09:30',
      //         minHour_num: 9.3,
      //         maxHour: '12:30',
      //         maxHour_num: 12.3,
      //       },
      //       // 4: { minHour_num: 7, maxHour_num: 18 },
      //     },
      //   },
      //   shopId: 'RJdZWYNKAnZTQRuBUGOb',
      //   totalPrice: [70, 68, 18],
      //   totalPriceLabel: 156,
      // };
      // const deliveryPriceLimit = 100;
      // const totalPrice = 156;
      // ------ END MOCK

      const allDays = currShop.settings.days;
      const itemsInfo = {};
      const itemsList = [];

      // ---------- set Data Processing
      const deliveryPrice = totalPrice >= Number(deliveryPriceLimit);
      const condData = true;

      function getNextDay(dayOfWeek) {
        const date = new Date();
        const resultDate = new Date(date.getTime());
        resultDate.setDate(
          date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7),
        );
        const day = resultDate.getDate();
        const month = resultDate.toLocaleString('default', { month: 'long' });
        const data = resultDate;
        return { day, month, data };
      }

      for (const key in allDays) {
        const minHour = allDays[key].minHour_num;
        const maxHour = allDays[key].maxHour_num;

        // ----- set CountHours
        const arrHours = [];
        const objHours = {};

        const countHours = (min, max, initVal) => {
          const minNum = Number(min);
          const currDay = new Date().getDay();
          const currHour = new Date().getHours();

          if (minNum < max) {
            const condSum = initVal ? minNum : minNum + 1;
            const roundNum = condSum && Number(condSum?.toFixed(2));
            const condFormat = String(roundNum).length > 2;
            let condZ = 0;
            let hourLabel = '';

            if (condFormat) {
              const sumRound = condSum + 1;
              const maxRange = Number(sumRound?.toFixed(2));
              const strgMin = String(roundNum).replace('.', 'h');
              const strgMax = String(maxRange).replace('.', 'h');

              // --- contar numeros decimais
              const countDec = pnum => {
                if (Math.floor(pnum.valueOf()) === pnum.valueOf()) return 0;
                return pnum.toString().split('.')[1].length || 0;
              };
              const numDec = countDec(condSum);
              condZ = numDec < 2 ? '0' : '';
              hourLabel = `${strgMin}${condZ} - ${strgMax}${condZ}`;
            } else {
              condZ = 'h00';
              hourLabel = `${roundNum}${condZ} - ${roundNum + 1}${condZ}`;
            }

            // cond set hours of curr day
            if (String(currDay) === key) {
              roundNum > currHour + 3 && arrHours.push(String(roundNum));
            } else {
              arrHours.push(String(roundNum));
            }

            objHours[roundNum] = hourLabel;
            return countHours(roundNum, maxHour);
          }
        };
        countHours(minHour, maxHour, true);

        // ----- set Get Day/Month
        const varDay = getNextDay(key).day;
        const varMonth = getNextDay(key).month;
        const data = getNextDay(key).data;

        itemsInfo[key] = {
          key,
          data,
          ref: varDay,
          dayLabel: `${objDays[key].name}, ${varDay} de ${varMonth}`,
          itemsList: arrHours,
          itemsInfo: objHours,
        };
      }

      // ------ set Order By Date (dec)
      const newArr = Object.values(itemsInfo);
      newArr.sort((a, b) => a.data - b.data);
      const newInfoOrder = {};
      for (const key in newArr) {
        const item = newArr[key];
        const arrHours = item.itemsInfo;

        newInfoOrder[item.key] = item;
        const condArrHours = arrHours.length !== 0;
        condArrHours && itemsList.push(item.key);
      }

      return {
        ...state,
        M8: {
          ...state.M8,
          scContent,
          condData,
          deliveryPrice,
          // withdraw: false,
          freeDelivery: deliveryPrice,
          itemsInfo: newInfoOrder,
          itemsList,
        },
      };
    },
  };

  const btns = {
    M8_BtnConclude: () => {
      const deliveryData = state?.M8?.deliveryData;
      const withdraw = state?.M8?.withdraw;

      if (deliveryData || withdraw) {
        action.asyncDispatch({ type: 'base_setRoute', value: 'payment' });
        return state;
      } else {
        return { ...state, M8: { ...state.M8, msgError: true } };
      }
    },

    M8_ChangeDelivery: () => {
      // const delivery = state.M8.withdraw;
      // const condDelivery = delivery === false;
      const withdraw = action.value;
      return {
        ...state,
        M8: {
          ...state.M8,
          withdraw,
        },
      };
    },

    M8_ChangeHour: () => {
      const { dayId, hour, currHour } = action.value;
      const dayLabel = state?.M8?.itemsInfo[dayId].dayLabel;

      return {
        ...state,
        M8: {
          ...state.M8,
          currOpt: hour,
          deliveryData: {
            hour,
            dayLabel,
            currHour,
          },
        },
      };
    },
  };

  return {
    ...inits,
    ...btns,
  };
};

// ---------- set Exports
export default reducers;
