// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import { UseInitData, useData } from '../../../../../useMorfos';
import { IptsValidation } from '../../../../comps';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

// ----------- set Info Screen
export const infoSc = {
  path: 'shopADD',
  groupSc: 'adm1',
  sideRight: {
    title: 'Supermercados',
  },
  scCode: 'C1',
};

// ----------- set Default Component
export default () => {
  // ----------- set Effects
  const fxInitAcStatus = () => {
    setAccStatus(condAccStatusVal);
  };
  const fxInitBuyStatus = () => {
    setAppBuy(appBuyInfo);
  };

  // ----------- set Data
  const content = useData('C1.scContent');
  const accStatusInfo = useData('C1.forms.iptsInfo.accountStatus.editData');
  const appBuyInfo = useData('C1.forms.iptsInfo.appBuy.editData');
  const condAccStatusVal = accStatusInfo === 'active';

  // ----------- set Hooks
  const [accStatus, setAccStatus] = useState(condAccStatusVal);
  const [appBuy, setAppBuy] = useState(appBuyInfo);
  const dispatch = useDispatch();
  React.useEffect(fxInitAcStatus, [condAccStatusVal]);
  React.useEffect(fxInitBuyStatus, [appBuyInfo]);

  // ---------- set Function
  const fnAccStatus = val => {
    setAccStatus(!accStatus);
    const condAccStatus = val ? 'active' : 'suspended';

    dispatch({
      type: 'C1_ChangeAccountStatus',
      value: condAccStatus,
    });
  };

  const fnAppBuy = () => {
    setAppBuy(previousState => !previousState);
    dispatch({
      type: 'C1_ChangeAppBuy',
      value: !appBuy,
    });
  };

  // ----------- set Return
  const infoView = {
    content,
    fnAppBuy,
    fnAccStatus,
    accStatus,
    appBuy,
  };

  return (
    <UseInitData reducer={'C1_InitData'}>
      <ViewDF info={infoView}>
        <IptsValidation
          infoData={{
            rdLoader: 'C1.loader',
            rdEdit: 'C1.idToEdit',
            rdMsgs: 'C1.forms.msgs',
            rdSave: 'C1_ADD_Shops',
            rdCancel: 'C1_BtnCancel',
          }}
        />
      </ViewDF>
    </UseInitData>
  );
};
