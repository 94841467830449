/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, TextInput, Text } from 'react-native';
import { useData, useStl } from '../../../../useMorfos';
import { useDispatch } from 'react-redux';
import {
  currencyMask,
  phoneMask,
  CNPJMask,
  commaMask,
  hourMask,
  dateMask,
} from '../../../../useMorfos/utils';

// ---------- set Default Component
export default ({
  sc,
  editPath,
  styleParam,
  fieldName,
  pHolder,
  type,
  title,
}) => {
  // ----------- set Data
  const editData = useData(editPath);
  const currSc = useData('baseRoute.currScInfo.scKey');
  const condSc = sc ?? currSc;

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Containers
  let path = `${condSc}.forms.iptsChanges.${fieldName}`;
  // let valueField = useData(path) ?? editData;
  let valueField = editData ?? useData(path);

  let condType = valueField;
  let multiline = false;
  let numberOfLines = 1;

  // ----------- set Events / Btns
  const condCurrency = type === 'currency';
  const condPhone = type === 'phone';
  const condCNPJ = type === 'cnpj';
  const condHour = type === 'hour';
  const condComma = type === 'comma';
  const condLines = type === 'multiline';
  const condDate = type === 'date';
  const changeValue = value => {
    const numInt = condCurrency && currencyMask(value, true);

    // kill remnant char
    if (condDate) {
      if (value.length > 10) {
        // substring elimina a sobra
        value = value.substring(0, 10);
      }
    }

    dispatch({
      type: 'comps_changeIptTxt',
      fieldName,
      value,
      numInt,
      sc,
    });
  };

  // ---- when is currency
  if (condCurrency) {
    path = `${condSc}.forms.iptsChanges.${fieldName}.value`;
    const valueField = useData(path) ?? editData;
    condType = `R$ ${currencyMask(valueField, false)}`;
  }

  if (condPhone) {
    condType = phoneMask(condType).mask;
  }

  if (condCNPJ) {
    condType = CNPJMask(condType);
  }

  if (condHour) {
    condType = hourMask(condType);
  }

  if (condComma) {
    condType = commaMask(condType);
  }

  if (condLines) {
    multiline = true;
    numberOfLines = 3;
  }

  if (condDate) {
    condType = dateMask(condType);
  }

  return (
    <View>
      <View style={stlLabelView}>
        <Text style={stlTXT01}>{title ?? ''}</Text>
      </View>
      <View style={[stlContainer, styleParam]}>
        <TextInput
          style={stlTrick}
          value={condType}
          onChangeText={changeValue}
          placeholder={pHolder}
          numberOfLines={numberOfLines}
          multiline={multiline}
        />
      </View>
    </View>
  );
};

// #region :: STYLEs *********
// ------------------------------
const stlContainer = [useStl.input];
const stlTrick = [{ outline: 'none' }];

const stlLabelView = {
  height: 22,
  justifyContent: 'flex-end',
};

const stlTXT01 = [{ fontSize: 12, color: '#666' }];

// ------------------------------
// #endregion *********
