// ---------- import Internals
import { firestore } from '../../../../config/firebase/fbConfig';
import { asyncRefs, ezLog } from '../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    M4_InitData: () => {
      const scContent = {};

      const condData = true;
      action.asyncDispatch({ type: 'M4_InitForm' });

      // ---------- set Init Categs
      const catList = [
        { labels: 'Alimentos', id: '0' },
        { labels: 'Bebidas', id: '1' },
        { labels: 'Limpeza', id: '2' },
        { labels: 'Pets', id: '3' },
      ];
      const arrCatList = [];
      for (const item of catList) {
        arrCatList.push(item.id);
      }

      return {
        ...state,
        M4: {
          ...state.M4,
          arrCatList,
          catList,
          scContent,
          condData,
          search: {
            itemsInfo: {},
            itemsList: [],
          },
        },
      };
    },
  };

  /* 
  
  // const gets = {
  //   // ---------- set GET _ProdsBase
  //   M4_GET_ProdsBase: () => {
  //     // ---------- set Async Function
  //     const asyncFn = async () => {
  //       // ---------- set Async Call
  //       const dbRef = firestore.collection('prods');
  //       const dataSuccess = await dbRef
  //         .orderBy('createdAt', 'desc')
  //         .limit(10)
  //         .get();

  //       // ------ return SUCCESS
  //       return dataSuccess;
  //     };

  //     // ---------- set Async References
  //     const ref = asyncRefs(action, asyncFn);

  //     // ---------- call Async / Mock
  //     ref.callAsync();

  //     return {
  //       ...state,
  //       M4: { ...state.M4, prodsBase: { firstCall: true } },
  //     };
  //   },

  //   M4_GET_ProdsBase_SUCCESS: () => {
  //     // ---------- set Data to Show
  //     const dataDb = action.value;
  //     const itemsInfo = {};
  //     const itemsList = [];
  //     const itemsList2 = [];
  //     dataDb.forEach(doc => {
  //       itemsInfo[doc.id] = doc.data();
  //       itemsList.length < 101 && itemsList.push(doc.id);
  //       itemsList.length === 101 && itemsList2.push(doc.id);
  //     });

  //     const condList2 = itemsList2.length > 0 && true;

  //     return {
  //       ...state,

  //       M4: {
  //         ...state.M4,

  //         prodsBase: {
  //           ...state.M4.prodsBase,
  //           itemsList,
  //           itemsList2,
  //           itemsInfo,
  //           condList: true,
  //           condList2,
  //         },
  //       },
  //     };
  //   },

  //   // ---------- set GET _Range
  //   M4_GET_Range: () => {
  //     // ---------- set Async Function
  //     const asyncFn = async () => {
  //       // ---------- set Data
  //       const geohash = state.baseAuthUser.address.geohash;

  //       // ---------- set Async Call
  //       const dbRef = firestore.collection('shops');
  //       const dataSuccess = await dbRef
  //         .where('address.range.upper', '>=', geohash)
  //         .get();

  //       // ------ return SUCCESS
  //       return dataSuccess;
  //     };

  //     // ---------- set Async References
  //     const ref = asyncRefs(action, asyncFn);

  //     // ---------- call Async / Mock
  //     ref.callAsync();

  //     return {
  //       ...state,
  //       M4: { ...state.M4 },
  //     };
  //   },

  //   M4_GET_Range_SUCCESS: () => {
  //     action.asyncDispatch({ type: 'M4_MAKE_VarsGroup' });

  //     // ---------- set Data to Show
  //     const dataDb = action.value;
  //     const itemsList = [];
  //     dataDb.forEach(doc => {
  //       itemsList.push(doc.id);
  //     });

  //     return {
  //       ...state,
  //       M4: {
  //         ...state.M4,
  //         shopsInReach: {
  //           condList: true,
  //           itemsList,
  //         },
  //       },
  //     };
  //   },

  //   // ---------- set MAKE _VarsGroup
  //   M4_MAKE_VarsGroup: () => {
  //     const rangeShops = state?.M4?.shopsInReach.itemsList;

  //     // ---------- set Async Function
  //     const asyncFn = async () => {
  //       const itemRefs = rangeShops.map(id => {
  //         return firestore
  //           .collection('shops')
  //           .doc(id)
  //           .collection('prods')
  //           .get();
  //       });
  //       const finalRes = Promise.all(itemRefs);

  //       return finalRes;
  //     };

  //     // ---------- set Async References
  //     const ref = asyncRefs(action, asyncFn);

  //     // ---------- call Async / Mock
  //     ref.callAsync();

  //     return {
  //       ...state,
  //       M4: { ...state.M4 },
  //     };
  //   },

  //   M4_MAKE_VarsGroup_SUCCESS: () => {
  //     action.asyncDispatch({ type: 'M4_InitForm' });

  //     // ---------- set Data to Show
  //     const dataDb = action.value;
  //     const itemsInfo = {};

  //     // ---------- set Merge ProdsBase with ShopProd Data
  //     dataDb.forEach((docShop, idx) => {
  //       for (const item of docShop.docs) {
  //         // ---------- laço do produto
  //         const prodShop = item.data();
  //         const prodShopVars = prodShop.variations;

  //         for (const key in prodShopVars) {
  //           // ---------- laço das vars do produto
  //           const currVar = prodShopVars[key];
  //           const uniqueIdProds = `${prodShop.prodId}_${currVar.typeId}_${idx}`;

  //           const currProdBase =
  //             state?.M4?.prodsBase?.itemsInfo[prodShop?.prodId];
  //           const prodImg = currProdBase[`imgUrl${prodShop.imgSelected}`];
  //           const currVarBase = currProdBase.variations[currVar.typeId];

  //           // montar obj separando produtos por var e shop
  //           itemsInfo[uniqueIdProds] = {
  //             dataShops: {
  //               [`${prodShop.shopId}`]: {
  //                 shopId: prodShop.shopId,
  //                 prodId: prodShop.prodId,
  //                 prodShopId: prodShop.docId,
  //                 idVariation: currVar.typeId,
  //                 shopPrice: Number(currVar.price),
  //               },
  //             },
  //             dataProd: {
  //               renderItem: true,
  //               varName: currVarBase.typeName_label,
  //               varValue: currVarBase.typeValue,
  //               idVar: currVar.typeId,
  //               prodId: prodShop.prodId,
  //               img: prodImg,
  //               avgPrice: 0,
  //               name: currProdBase.name,
  //               categ: currProdBase.categ,
  //               subCateg: currProdBase.subCateg,
  //             },
  //           };
  //         }
  //       }
  //     });

  //     const itemsList = [...Object.keys(itemsInfo)];

  //     // ---------- set Average Prices
  //     let avgPrice = {};
  //     for (const key1 in itemsInfo) {
  //       const item1 = itemsInfo[key1];
  //       const dataShops = item1.dataShops;
  //       const dataProd = item1.dataProd;

  //       for (const keyShop in dataShops) {
  //         const itemShop = dataShops[keyShop];

  //         // ---------- alimentar avgPrice organizando arrays por prod e var
  //         const idVariation = itemShop.idVariation;
  //         const prevPrices =
  //           avgPrice[dataProd.prodId] && avgPrice[dataProd.prodId][idVariation];
  //         const condPrev = prevPrices !== undefined ? prevPrices : [];

  //         avgPrice[dataProd.prodId] = {
  //           ...avgPrice[dataProd.prodId],
  //           [idVariation]: [...condPrev, itemShop.shopPrice],
  //         };
  //       }
  //     }

  //     // ---------- set Merge avgPrice with respective dataProd.avgPrice
  //     for (const key1 in itemsInfo) {
  //       const item1 = itemsInfo[key1];
  //       const dataShops = item1.dataShops;
  //       const dataProd = item1.dataProd;

  //       for (const keyShop in dataShops) {
  //         const itemShop = dataShops[keyShop];
  //         const currAvgPrc = avgPrice[dataProd.prodId][itemShop.idVariation];

  //         const priceSum = currAvgPrc.reduce((prev, crr) => prev + crr, 0);
  //         dataProd.avgPrice = priceSum / currAvgPrc.length;
  //       }
  //     }

  //     return {
  //       ...state,
  //       M4: {
  //         ...state.M4,
  //         mergedVars: {
  //           condList: true,
  //           itemsList,
  //           itemsInfo,
  //         },
  //       },
  //     };
  //   },
  // };*/

  const forms = {
    M4_InitForm: () => {
      const mergedVars = state?.M2?.mergedVars;
      const itemsInfo = {};
      const itemsList = [];

      const resObj = {};
      const resList = [];
      for (const key in mergedVars.itemsInfo) {
        const item = mergedVars.itemsInfo[key];
        const dataProd = item.dataProd;

        // const condCateg = dataProd.categ === action.value;
        // if (condCateg) {
        resList.push(String(key));
        resObj[key] = dataProd;
        // }
      }

      // ---------- set Filter Vars
      for (const key in resObj) {
        const item = resObj[key];

        const prodId = item.prodId;
        const idVar = item.idVar;

        itemsInfo[`${prodId}_${idVar}`] = item;
      }

      for (const key in itemsInfo) {
        itemsList.push(key);
      }

      const iptsInfo = {
        search: {
          // ----- set Dynamics
          itemId: 'search',
          required: true,
          iptChange: 'M4_IptTxt',

          // ----- set Statics
          pHolder: 'Buscar',
          label: 'Escreva p/ buscar',
        },
      };

      return {
        ...state,
        M4: {
          ...state.M4,
          search: {
            itemsInfo,
            itemsList,
          },
          forms: {
            iptsInfo: { ...iptsInfo },
            iptsChanges: {
              search: '',
            },
          },
        },
      };
    },

    // ---------- set Form Changes
    M4_IptChange: () => {
      // ---------- set Data
      const txtIpt = action.value;
      const itemsList = [];

      const search = state?.M4?.forms?.iptsChanges?.search;
      const dataToMap = state?.M4?.search?.itemsInfo;

      const newArr = [];

      for (const key in dataToMap) {
        const item = dataToMap[key];
        item.prodIdVar = key;

        newArr.push(item);
      }

      newArr
        .filter(({ name }) => name.indexOf(search) !== -1)
        // .filter(({ name }) => name.indexOf(search.toLowerCase()) > -1)
        .map(item => {
          itemsList.push(item.prodIdVar);
        });

      return {
        ...state,
        M4: {
          ...state.M4,
          currCateg: null,
          search: {
            ...state.M4.search,
            itemsList,
          },
          forms: {
            ...state.M4.forms,
            iptsChanges: {
              ...state.M4.forms.iptsChanges,
              [action.field]: txtIpt,
            },
          },
        },
      };
    },
  };

  const edits = {
    M4_ChangeCateg: () => {
      const mergedVars = state?.M2?.mergedVars;
      const itemsInfo = {};
      const itemsList = [];

      const resObj = {};
      const resList = [];
      for (const key in mergedVars.itemsInfo) {
        const item = mergedVars.itemsInfo[key];
        const dataProd = item.dataProd;

        const shops = state?.M2?.shopsInReach?.itemsList;
        let shopId;
        for (const shop in item.dataShops) {
          shopId = item.dataShops[shop].shopId;
        }

        const haveProds = shops.includes(shopId);

        const condCateg = dataProd.categ === action.value && haveProds;
        if (condCateg) {
          resList.push(String(key));
          resObj[key] = dataProd;
        }
      }

      // ---------- set Filter Vars
      for (const key in resObj) {
        const item = resObj[key];

        const prodId = item.prodId;
        const idVar = item.idVar;

        itemsInfo[`${prodId}_${idVar}`] = item;
      }

      for (const key in itemsInfo) {
        itemsList.push(key);
      }

      return {
        ...state,
        M4: {
          ...state.M4,
          currCateg: action.value,
          filterProds: {
            itemsInfo,
            itemsList,
          },
        },
      };
    },
  };

  return {
    ...inits,
    ...forms,
    ...edits,
  };
};

// ---------- set Exports
export default reducers;
