// ----------- import Packs
import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import {
  useData,
  UseIcoMoon,
  useResize,
  useRouter,
  useStl,
} from '../../../useMorfos';

import { IptTxt } from '..';
import { useDispatch } from 'react-redux';

export default ({ info }) => {
  const confirmClear = useData('M6.confirmClear');
  const resizeBar = useResize({
    mob: { width: '100%' },
    desk: { maxWidth: 500 },
  });
  const path = useData('baseRoute.path');

  if (path === 'orderList') {
    return <MsgOrder1 info={info} />;
  }
  if (path === 'orderEdit') {
    return <MsgOrder2 info={info} />;
  }
  if (path === 'orderFinal') {
    return (
      <View style={[stlAlertBox, resizeBar, { height: 300 }]}>
        <MsgOrder3 info={info} />
      </View>
    );
  }
  if (path === 'cart') {
    return (
      <View style={[stlAlertBox, resizeBar]}>
        {!confirmClear && <Msg1 />}
        {confirmClear && <Msg2 info={{ ...info }} />}
      </View>
    );
  }
  if (path === 'schedule') {
    return (
      <View style={[resizeBar]}>
        <MsgOrder4 info={info} />
      </View>
    );
  }
  if (path === 'payment') {
    return (
      <View style={[resizeBar]}>
        <MsgOrder5 info={info} />
      </View>
    );
  }
  if (path === 'requestOrderList') {
    return (
      <View>
        <Msg3 />
      </View>
    );
  }
  if (path === 'shopsInReach') {
    const msgCart = useData('M10.msgCart');

    return msgCart ? (
      <View style={[stlAlertBox, resizeBar, { height: 200 }]}>
        <MsgOrder6 />
      </View>
    ) : (
      <></>
    );
  }
};

// ---------- set Temp Comps Msgs
const Msg1 = () => {
  // ---------- set Data
  const objAddress = useData('baseAuthUser.address');
  const street = objAddress?.street ?? '...';
  const number = objAddress?.street_number ?? '...';
  const neighborhood = objAddress?.neighborhood ?? '...';
  const address = `${street},${number} - ${neighborhood}`;

  // ---------- set Hooks
  const { callRouter } = useRouter();
  const dispatch = useDispatch();

  const goToSelShops = () => {
    dispatch({ type: 'comps_HistoryPath' });
    callRouter('selshop');
  };

  // ---------- set Return
  return (
    <>
      <View style={stlTitle}>
        <UseIcoMoon name="alert2" size={18} color="#444" />
        <Text style={stlTxt4}>Observações</Text>
      </View>
      <View style={stlMsg}>
        <Text style={stlTxtMsg}>
          Ao confirmar, não será mais possível mudar o endereço de entrega
          selecionado:
        </Text>
      </View>
      <View style={stlAddress}>
        <UseIcoMoon name="pin" size={12} color="#ccc" />
        <Text style={stlTxt3}>{address}</Text>
      </View>
      <TouchableOpacity style={stlBtnNext} onPress={goToSelShops}>
        <Text style={stlTxt2}>Ver Orçamentos</Text>
      </TouchableOpacity>
    </>
  );
};

const Msg2 = ({ info }) => {
  return (
    <>
      <View style={stlTitle}>
        <UseIcoMoon name="alert2" size={18} color="#444" />
        <Text style={stlTxt4}>Esvaziar Carrinho</Text>
      </View>
      <View style={stlMsg}>
        <Text style={stlTxtMsg}>
          Ao confirmar, os produtos serão removidos do carrinho:
        </Text>
      </View>
      <View style={stlBtnsInline}>
        <TouchableOpacity style={stlBtn1} onPress={info.btnCancel}>
          <Text style={stlTxt2}>Cancelar</Text>
        </TouchableOpacity>
        <TouchableOpacity style={stlBtn1} onPress={info.btnClearCart}>
          <Text style={stlTxt2}>Esvaziar</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

const Msg3 = () => {
  return (
    <>
      <View style={val3}>
        <View style={stlRow}>
          <Text style={val2}>O cliente cancelou o pedido</Text>
        </View>
      </View>
    </>
  );
};

const MsgOrder1 = ({ info }) => {
  // ---------- set Data
  const allProds = useData('M7a.allProds.itemsList');
  const appBuy = useData('M7a.appBuy');
  const availProds = useData('M7a.availProds.itemsList');
  const totalPrice = useData('M7a.totalPrice');
  const condBuy = appBuy ?? false;
  const allProdsArr = [...allProds];
  const availProdsArr = [...availProds];

  const totalPriceView = `Total:R$ ${totalPrice.toFixed(2)}`;

  const resizeBar = useResize({
    mob: { width: '100%' },
    desk: { maxWidth: 500 },
  });

  // ---------- set Conds
  const condMsg = allProdsArr.length === availProdsArr.length;
  const stlMsg = !condMsg ? [stlAlertBox, resizeBar] : '';

  return (
    <>
      <View style={stlMsg}>
        {!condMsg && (
          <View style={[useStl.flexCenter, { paddingBottom: 30 }]}>
            <View style={stlTitle}>
              <UseIcoMoon name="alert2" size={18} color="#444" />
              <Text style={stlTxt4}>Observações</Text>
            </View>
            <View style={stlMsg}>
              <Text style={[stlTxtMsg, { width: '80%' }]}>
                Você deseja continuar com a compra mesmo com alguns produtos
                indisponíveis?
              </Text>
            </View>
          </View>
        )}
        <View style={[stlBtnsInline, { marginTop: 0, bottom: 1 }]}>
          <Text style={{ color: '#fff' }}>
            {totalPriceView.replace('.', ',')}
          </Text>
        </View>
        <View style={[stlBtnsInline, { marginTop: 0 }]}>
          <TouchableOpacity style={stlBtn1} onPress={info.btnAllProds}>
            <Text style={stlTxt2}>Produtos do Mercado</Text>
          </TouchableOpacity>
          <Text style={stlDiv}>|</Text>
          {condBuy ? (
            <TouchableOpacity style={stlBtn1} onPress={info.btnConfirm}>
              <Text style={stlTxt2}>Continuar Compra</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity style={stlBtn1} onPress={info.btnGoHome}>
              <Text style={stlTxt2}>Home</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </>
  );
};

const MsgOrder2 = ({ info }) => {
  const totalAmount = useData('basePersist.cart.totalAmount');
  const cartIsFilled = totalAmount > 0;

  return (
    <>
      {cartIsFilled && (
        <TouchableOpacity
          style={[stlBtnNext, { marginTop: 0 }]}
          onPress={info.btnOrderFinal}
        >
          <Text style={stlTxt2}>Concluir</Text>
        </TouchableOpacity>
      )}
    </>
  );
};

const MsgOrder3 = ({ info }) => {
  const now = new Date();
  const today = now.getDay();
  const thisHour = now.getHours();
  const thisMinute = now.getMinutes();
  const thisTime = Number(thisHour + '.' + thisMinute);

  let block = false;
  if (today === 5) {
    // Sexta
    if (thisTime > 17) {
      block = true;
    }
  }
  if (today === 6) {
    // Sábado
    if (thisTime < 23.59) {
      block = true;
    }
  }
  const condStl = block ? stlOffTxt : stlTxt2;

  // ---------- set Return
  return (
    <>
      <View style={stlTitle}>
        <UseIcoMoon name="alert2" size={18} color="#444" />
        <Text style={stlTxt4}>Observações</Text>
      </View>
      <View style={stlMsg}>
        {block ? (
          <Text style={stlTxtMsg}>
            Compras pelo Aplicativo:
            <br /> De Domingo às 06h00 <br /> à Sexta-feira às 17h00.
          </Text>
        ) : (
          <Text style={stlTxtMsg}>
            Para continuar, o mercado precisará do seu número para fazer a
            confirmação do estoque antes de você fazer o pagamento. agora são.
          </Text>
        )}
      </View>
      <View style={stlPhone}>
        <Text style={stlCodPhone}>+55</Text>
        <IptTxt
          sc="M7c"
          fieldName="phone"
          type={'phone'}
          infoData={'M7c.forms.iptsInfo.phone'}
        />
      </View>
      <View style={stlBtnsInline}>
        <View style={stlBtn1}>
          <info.TotalPriceComp />
        </View>
        <TouchableOpacity
          style={stlBtn1}
          onPress={info.btnOrderFinal}
          disabled={block}
        >
          <Text style={condStl}>Continuar</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

const MsgOrder4 = ({ info }) => {
  return (
    <>
      <TouchableOpacity
        style={[stlBtnNext, { marginTop: 0 }]}
        onPress={info.btnDelive}
      >
        <Text style={stlTxt2}>CONCLUIR</Text>
      </TouchableOpacity>
    </>
  );
};

const MsgOrder5 = ({ info }) => {
  return (
    <View style={stlBtnsInline}>
      <TouchableOpacity style={stlBtn1} onPress={info.btnCancel}>
        <Text style={stlTxt2}>Cancelar</Text>
      </TouchableOpacity>
      <Text style={stlDiv}>|</Text>
      <TouchableOpacity style={stlBtn1} onPress={info.btnValidCard}>
        <Text style={stlTxt2}>Confirmar</Text>
      </TouchableOpacity>
    </View>
  );
};

const MsgOrder6 = ({ info }) => {
  const dispatch = useDispatch();
  const { callRouter } = useRouter();

  const btnCancel = () => callRouter('home');
  const btnClearCart = () => dispatch({ type: 'M6_BtnClearCart' });
  return (
    <>
      <View style={stlTitle}>
        <UseIcoMoon name="alert2" size={18} color="#444" />
        <Text style={stlTxt4}>Observação</Text>
      </View>
      <View style={stlMsg}>
        <Text style={stlTxtMsg}>Para continuar iremos limpar o carrinho.</Text>
      </View>
      <View style={stlBtnsInline}>
        <TouchableOpacity style={stlBtn1} onPress={btnCancel}>
          <Text style={stlTxt2}>Voltar</Text>
        </TouchableOpacity>
        <Text style={stlDiv}>|</Text>
        <TouchableOpacity style={stlBtn1} onPress={btnClearCart}>
          <Text style={stlTxt2}>Confirmar</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

// #region :: STYLEs *********

const stlAlertBox = [
  useStl.txtCenter,
  useStl.flexCenter,
  {
    paddingTop: 30,
    // width: '100%',
    // height: 220,
    // position: 'fixed',
    backgroundColor: '#fff',
    bottom: 0,
  },
];
const stlMsg = [useStl.centerBox, { width: '60%' }];
const stlPhone = [useStl.flexStart, useStl.flexRow, { width: '60%' }];
const stlCodPhone = {
  color: 'lightgray',
  marginTop: 15,
  paddingRight: 7,
  fontSize: 15,
};

const stlTxt3 = [{ fontSize: 12, color: '#666', marginLeft: 5 }];
const stlTxt4 = [
  {
    fontSize: 16,
    fontWeight: 600,
    color: '#444',
    marginLeft: 5,
    lineHeight: 0,
  },
];
const stlTxtMsg = [
  {
    fontSize: 13,
    color: '#444',
  },
];
const stlAddress = [
  useStl.flexRow,
  useStl.border(1, 1, 1, 1, '#ccc'),
  { borderRadius: 4, padding: 4 },
];

const stlTitle = [useStl.flexRow];

const stlBtnNext = [
  useStl.flexCenter,
  {
    marginTop: 30,
    backgroundColor: '#363636',
    height: 56,
    width: '100%',
    bottom: 0,
  },
];
const stlBtnsInline = [
  stlBtnNext,
  useStl.flexRow,
  useStl.flexAround,
  {
    paddingHorizontal: 25,
  },
];
const stlTxt2 = [{ fontWeight: 600, fontSize: 13, color: '#fff' }];
const stlOffTxt = [{ color: '#a1a1a1' }];
const stlBtn1 = [
  {
    width: 140,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
];
const stlDiv = [{ color: '#666', fontSize: 14 }];
const stlCard = [
  useStl.card,
  { width: '80%', borderRadius: 10, marginHorizontal: 'auto' },
];
const val2 = [
  {
    fontSize: 12,
    marginHorizontal: 15,
    color: '#a2844d',
    fontWeight: '400',
  },
];
const val3 = [[stlCard, { borderLeftWidth: 8, borderLeftColor: '#e55403' }]];
const stlRow = [useStl.flexRow];
// #endregion *********
