// ----------- import Packs
import React from 'react';
import { View } from 'react-native';
import { shadowLess } from '../../../../config/styles';
import { useResize } from '../../../../useMorfos';

export default ({ children }) => {
  const stlWidth = useResize({
    mob: stlMobile,
    desk: stlDesk,
  });

  return <View style={stlWidth}>{children}</View>;
};

const stlDesk = {
  width: 500,
  flex: 1,
  alignSelf: 'center',
  ...shadowLess,
};
const stlMobile = { flex: 1 };

// const stlWidth = [isDesktop ? stlDesk : stlMobile];
