/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import { useData, useStl } from '../../../../../useMorfos';
import { IptTxt } from '../../../../comps';

// #region :: STYLEs *********

//------------set Viewa
const stlBodyView = [useStl.flex1, { padding: 10 }];
const stlRow = [{ flexDirection: 'row' }];
const stlWrap = [{ flexDirection: 'row', flexWrap: 'wrap' }];
const stlDateBox = [
  useStl.border(1, 1, 1, 1, '#ddd'),
  useStl.pad(10),
  { marginTop: 20, borderRadius: 8 },
];
const stlSpaceCol = [{ flex: 1, marginRight: 10, width: '50%' }];
const stlDayTxt = [{ fontSize: 11, color: '#555' }];
const stlDayTxtAct = [{ fontSize: 11, color: 'darkgreen' }];

//-----------set Btns
const stlCenter = [useStl.flexCenter, { marginVertical: 40 }];
const stlBtn = [useStl.btn, useStl.btnMedium, { width: 110, marginBottom: 5 }];
const stlBtnPrimary = [stlBtn, useStl.btnPrimary];
const stltxtInverse = [useStl.txtPrimaryInverse];

const stlBtnDayAct = [
  useStl.border(2, 2, 2, 2, '#50A106'),
  useStl.pad(3, 6),
  { borderRadius: 4, marginRight: 4, marginBottom: 3 },
];
const stlBtnDay = [
  useStl.border(2, 2, 2, 2, '#ccc'),
  useStl.pad(3, 6),
  { borderRadius: 4, marginRight: 4, marginBottom: 3 },
];

// #endregion *********

//-------set Region Export

export const DayBox = ({ itemId }) => {
  // ------ set Hooks
  const dispatch = useDispatch();

  const data = useData('E1.forms.btnsInfos');
  const currDay = useData('E1.currDay');
  const btnChangeDay = () => dispatch({ type: 'E1_BtnChangeDay', itemId });
  const day = data[itemId].label;

  // ------- set Styles
  const condStl = currDay === itemId;
  const stlBtnActive = condStl ? stlBtnDayAct : stlBtnDay;
  const stlTxtActive = condStl ? stlDayTxtAct : stlDayTxt;

  // ------- set Return
  return (
    <TouchableOpacity style={stlBtnActive} onPress={btnChangeDay}>
      <Text style={stlTxtActive}>{day}</Text>
    </TouchableOpacity>
  );
};

// #endregion *********

export default ({ info, children }) => {
  return (
    <View style={stlBodyView}>
      <Form children={children} />

      {/* ----------- set BTNs SAVE & CANCEL */}
      <View style={stlCenter}>
        <TouchableOpacity style={stlBtnPrimary} onPress={info.btnSave}>
          <Text style={stltxtInverse}>Salvar</Text>
        </TouchableOpacity>
        <TouchableOpacity style={stlBtn} onPress={info.btnCancel}>
          <Text>Cancelar</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const Form = ({ children }) => {
  return (
    <>
      <IptTxt
        fieldName="radiusLimit"
        infoData={'E1.forms.iptsInfo.radiusLimit'}
        type="numInt"
        sc="E1"
      />
      <IptTxt
        sc="E1"
        fieldName="deliveryPriceLimit"
        type={'currency'}
        infoData={'E1.forms.iptsInfo.deliveryPriceLimit'}
      />

      <View style={stlDateBox}>
        <View style={stlWrap}>{children}</View>

        <HourFields />
      </View>
    </>
  );
};

const HourFields = () => {
  const currDay = useData('E1.currDay');
  const pathMin = `E1.forms.iptsInfo.days.${currDay}.minHour`;
  const pathMax = `E1.forms.iptsInfo.days.${currDay}.maxHour`;

  return (
    <View style={stlRow}>
      <View style={stlSpaceCol}>
        <IptTxt fieldName="minHour" infoData={pathMin} type="hour" sc="E1" />
      </View>
      <View style={stlSpaceCol}>
        <IptTxt fieldName="maxHour" infoData={pathMax} type="hour" sc="E1" />
      </View>
    </View>
  );
};
