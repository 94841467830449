// ---------- import Internals
// import { firestore } from '../../../../config/firebase/fbConfig';

// ---------- set Reducers
const reducers = (state, action) => ({
  // ---------- set Init Data _Name_
  M3_InitData: () => {
    const scContent = {};

    const condData = true;

    return {
      ...state,
      M3: { ...state.M3, scContent, condData },
    };
  },
});

// ---------- set Exports
export default reducers;
