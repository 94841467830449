// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';

// ----------- import Internals
import { useData, useStl } from '../../../../useMorfos';
import step1 from '../../../images/step1.png';
import step2 from '../../../images/step2.png';
import step3 from '../../../images/step3.png';
import step4 from '../../../images/step4.png';

import src2 from '../../../images/btn_block.png';

// #region :: STYLEs *********

const stlBodyView = [useStl.flex1, useStl.flexCenter, { zIndex: 0 }];

const stlTitle = [useStl.flexCenter];
const stlTxtContent = [useStl.flex1, useStl.flexCenter];
const stlTxt3 = [
  {
    color: '#444',
    fontSize: 18,
    width: '80%',
    textAlign: 'center',
    marginBottom: -30,
  },
];
const stlCenter = [useStl.flexCenter];

const stlTitleTxt = [useStl.txtTitleScreen, useStl.txtCenter];
const stlImg1 = {
  width: '100%',
  height: 350,
  resizeMode: 'center',
};
const stlImg2 = {
  width: '100%',
  height: 93,
  position: 'absolute',
  left: 0,
  top: 0,
};
const stlBtn = [
  {
    width: '100%',
    height: 60,
    padding: 20,

    overflow: 'hidden',
  },
];

const stlTxtBt = [useStl.txtPrimaryInverse, useStl.txtCenter, { zIndex: 2 }];

const stlRow = [{ flexDirection: 'row' }];
const stlPoints = [stlCenter, { padding: 40 }];
const stlDotInactive = [
  {
    width: 10,
    height: 10,
    backgroundColor: '#ddd',
    borderRadius: 50,
    marginRight: 3,
  },
];
const stlDotActive = [stlDotInactive, useStl.bgPrimary];

// #endregion *********

// #region :: EXPORTs *********
export const Dots = ({ infoData }) => {
  const { itemId, btnGoToStep } = infoData;
  const currStep = useData('M0.currStep');
  const condActive = String(currStep) === itemId;
  const condStl = condActive ? stlDotActive : stlDotInactive;
  const passIdToGo = () => btnGoToStep(itemId);

  return (
    <TouchableOpacity onPress={passIdToGo}>
      <View style={condStl} />
    </TouchableOpacity>
  );
};
// #endregion *********

export default ({ info, children }) => {
  const currStep = useData('M0.currStep');
  const { content } = info;
  const objImg = {
    1: step1,
    2: step2,
    3: step3,
    4: step4,
  };

  return (
    <View style={{ flex: 1 }}>
      {/* <View style={stlBodyView} {...panResponder.panHandlers}> */}
      <View style={stlBodyView}>
        <View style={[useStl.flexCenter, { flex: 4, width: '100%' }]}>
          <Image source={objImg[currStep]} style={stlImg1} />
        </View>
        <View style={stlTxtContent}>
          <View style={stlTitle}>
            <Text style={stlTitleTxt} selectable={false}>
              {content[currStep].title}
            </Text>
          </View>
          <Text style={stlTxt3} selectable={false}>
            {content[currStep].desc}
          </Text>
        </View>

        {/* Set Dots */}
        <View style={stlPoints}>
          <View style={stlRow}>{children}</View>
        </View>

        <TouchableOpacity style={stlBtn} onPress={info.btnNextStep}>
          <Text style={stlTxtBt} selectable={false}>
            Próximo
          </Text>
          <Image source={src2} style={stlImg2} />
        </TouchableOpacity>
      </View>
    </View>
  );
};
