// ---------- import Internals

import { ezLog } from '../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    M7b_InitData: () => {
      const condM2 = state?.M2;
      action.asyncDispatch({ type: 'M7b_InitList' });
      action.asyncDispatch({ type: 'M7b_InitCategs' });

      if (!condM2) {
        action.asyncDispatch({ type: 'M2_InitData' });
      }

      return {
        ...state,
        M7b: {
          ...state.M7b,
          condList: false,
        },
      };
    },

    M7b_InitCategs: () => {
      // ---------- set Data
      const shopProds = state?.M7b?.itemsInfo;
      // const dataDb1 = Object.values(shopProds);
      const catInfo = {};
      const subCatInfo = {};

      for (const key in shopProds) {
        const currProd = shopProds[key];
        const currCateg = currProd?.categ;
        const currSubCateg = currProd?.subCateg;

        catInfo[currCateg] = currCateg;
        subCatInfo[currSubCateg] = currSubCateg;
      }

      const newCatArr = Object.keys(catInfo);
      const newSubCatArr = Object.keys(subCatInfo);
      const condKey = newCatArr[0];

      return {
        ...state,
        M2: {
          ...state.M2,
          currCateg: condKey,
        },
        basePersist: {
          ...state.basePersist,
          categs: {
            ...state.basePersist.categs,
            itemsList: newCatArr,
          },
          subCategs: {
            ...state.basePersist.subCategs,
            itemsList: newSubCatArr,
          },
        },
      };
    },

    M7b_InitList: () => {
      const itemsInfo = {};
      const itemsList = [];

      // ---------- set Data
      const currShop = state.M7?.currShop;
      const shopId = state.M10?.shopId;
      const mergedVars = state.M2?.mergedVars?.itemsInfo;

      // ---------- set Merge Info Prods / Show List
      for (const keyVar in mergedVars) {
        const item = mergedVars[keyVar];
        const prodVarId = item.dataProd.prodIdVar;
        // const prodId = prodVarId.slice(0, prodVarId.length - 2);
        const prodId = prodVarId;
        ezLog({ prodVarId, prodId });

        const dataShops = item?.dataShops;
        const dataProd = item?.dataProd;

        for (const key in dataShops) {
          const condData = currShop ?? shopId;
          const condShop = condData === key;
          const item2 = dataShops[key];
          if (condShop) {
            itemsInfo[prodId] = {
              renderItem: true,
              shopPrice: item2?.shopPrice,
              prodIdVar: dataProd?.prodIdVar,
              img: dataProd?.img,
              name: dataProd?.name,
              varName: dataProd?.varName,
              varValue: dataProd?.varValue,
              categ: dataProd?.categ,
              subCateg: dataProd?.subCateg,
            };

            itemsList.push(prodId);
          }
        }
      }

      const idToSearch = state?.M7b?.idToSearch;
      if (idToSearch) {
        action.asyncDispatch({ type: 'comps_SimilarProds', value: idToSearch });
      }

      return {
        ...state,
        M7b: {
          ...state.M7b,
          condList: true,
          itemsInfo,
          itemsList,
        },
      };
    },
  };

  const changes = {
    M7b_ChangeSubCat: () => {
      const shopProds = state?.M7b?.itemsInfo;
      const itemsList = [];

      for (const key in shopProds) {
        const item = shopProds[key];
        const condFilter = item.subCateg === action.value;

        condFilter && itemsList.push(key);
      }

      return {
        ...state,
        M2: {
          ...state.M2,
          filterProds: {
            ...state.M2.filterProds,
            subCategActive: action.value,
          },
        },
        M7b: {
          ...state.M7b,
          itemsList,
        },
      };
    },
  };

  const btns = {
    M7b_UpdateReferProds: () => {
      // ---------- set Data
      const prodsCart = state.basePersist?.cart?.addedProds;
      const mergedVars = state.M2?.mergedVars?.itemsInfo;
      const currShop = state.M7?.currShop ?? state?.M10?.shopId;
      const availableProds = state.M7?.itemsInfo[currShop]?.availableProds;

      // ---------- set AllProds
      const itemsInfo = {};

      for (const keyCart in prodsCart) {
        for (const keyMerged in mergedVars) {
          const item = mergedVars[keyMerged];

          // ---------- set Product in Shop
          const condShop = item.dataShops[currShop];
          const condShopCart = condShop?.prodId === keyCart;

          // ---------- set Product in Cart
          const dataProd = item.dataProd;
          const condProdCart = dataProd?.prodIdVar === keyCart;

          // ---------- set Cond Price
          const shopPrice = condShop?.shopPrice;
          const condPrice = shopPrice ?? 0;
          const condCartInfo = condProdCart && !itemsInfo[keyCart];
          const condItem = condShopCart || condCartInfo;

          // ---------- set itemsInfo
          if (condItem) {
            itemsInfo[keyCart] = {
              ...dataProd,
              shopPrice: condPrice,
            };
          }
        }
      }

      // ---------- set Only Availabe Prods
      const availableInfo = {};
      const availableList = [];

      for (const key in availableProds) {
        const prodId = availableProds[key];

        itemsInfo[prodId] = { ...itemsInfo[prodId], available: true };
        availableInfo[prodId] = { ...itemsInfo[prodId] };

        availableList.push(prodId);
      }

      action.asyncDispatch({ type: 'M7b_ConcludePurcharse' });

      // set UpDate itemList render
      const cartList = state?.basePersist?.cart?.addedProds;
      const newList = Object.keys(cartList);

      return {
        ...state,
        M7a: {
          ...state.M7a,
          allProds: {
            condList: true,
            itemsList: newList,
            itemsInfo,
          },
          availProds: {
            itemsList: availableList,
            itemsInfo: availableInfo,
          },
        },
      };
    },

    M7b_ConcludePurcharse: () => {
      // ---------- set Data
      action.asyncDispatch({ type: 'base_setRoute', value: 'orderList' });

      return {
        ...state,
      };
    },
  };

  return {
    ...inits,
    ...changes,
    ...btns,
  };
};

// ---------- set Exports
export default reducers;
