// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import { UseInitData, UseList, UseCondLoader } from '../../../../useMorfos';
import { ItemBooklet, NoItemView } from '../../../comps';

// ----------- set Info Screen
export const infoSc = {
  path: 'bookletList',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: 'Encartes',
  },

  scCode: 'M11',
};

// ----------- set Default Component
export default () => {
  // ----------- set Hooks
  // const dispatch = useDispatch();

  // ----------- set BTNs
  // const btnPay = itemId => dispatch({ type: '' });

  // ----------- set Return
  const infoItem = {
    itemContent: 'M11.scContent',
    itemsInfo: 'M11.itemsInfo',
    pressItem: 'M11_EditShopProds',
  };

  return (
    <UseInitData reducer={'M11_InitData'}>
      <ViewDF>
        <UseCondLoader data={'M11.condList'}>
          <UseList data={'M11.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemBooklet infoData={{ ...infoItem, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
