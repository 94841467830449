// ----------- import Packs
import React from 'react';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF from './Views';
import {
  UseInitData,
  useData,
  UseCondLoader,
  UseList,
  useStl,
} from '../../../../../useMorfos';
import { NoItemView } from '../../../../comps';
import { Text, TouchableOpacity } from 'react-native';

// ----------- set Info Screen
export const infoSc = {
  path: 'tempUsers',
  groupSc: 'pub',
  condBigSc: true,

  scCode: 'A0b',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('A0b.scContent');

  // ----------- set Return
  const infoView = { content };

  return (
    <UseInitData reducer={'A0b_InitContentData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'A0b.condList'}>
          <UseList data={'A0b.usersList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemBtn infoItem={itemId} content={content} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};

// --------- Temp Item List
const ItemBtn = ({ infoItem }) => {
  // ----------- set Data
  const item = useData('A0b.usersInfo', infoItem);

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Temp Styles
  const stlBtn = [
    useStl.btn,
    useStl.btnMedium,
    useStl.btnPrimary,
    { width: 190, marginBottom: 10 },
  ];
  const stlBtnTxt = [useStl.txtPrimaryInverse];

  const btnLogin = () => dispatch({ type: 'A0b_SetLogin', value: infoItem });

  return (
    <TouchableOpacity style={stlBtn} onPress={btnLogin}>
      <Text style={stlBtnTxt}>{item.name}</Text>
    </TouchableOpacity>
  );
};
