/* eslint-disable no-loop-func */
const utils = {
  logRender: (name, info = '-') => {
    if (process.env.NODE_ENV === 'development') {
      return console.log(`RENDER => ${name}`, info);
    }
  },

  ezLog: (...info) => {
    const condLog = process.env.NODE_ENV !== 'development';
    if (condLog) {
      return;
    }
    const condType = typeof info[0] === 'object';
    const condMany = condType && utils.toArr(info[0]).length === 1;
    const condArr = Array.isArray(info[0]);
    const condLog1 =
      !condMany && ((condType && condArr) || condMany || info[0] !== null);
    const log1 = () => console.log('>>>>> EZLOG: => ', ...info);
    const log2 = () => {
      const name = Object.keys(info[0])[0];

      console.log(`>>>>> EZLOG: ${name} => `, info[0][name]);
    };
    const condReturn = condLog1 ? log1() : log2();
    return condReturn;
  },

  findItem: (item, arr) => arr.find(res => res.docId === item),

  findIndexId: (item, arr) => arr.findIndex(res => res.docId === item),

  filterFn: (arr, type) => arr.filter(res => res.type === type),

  timeFunc: (func, ms = 900) => setTimeout(func, ms),

  toArr(obj, setFields) {
    var newArr = [];
    for (const item in obj) {
      if (setFields) {
        for (const fieldName of setFields) {
          newArr.push({ [fieldName]: obj[item][fieldName] });
        }
      } else {
        newArr.push(obj[item]);
      }
    }
    return newArr;
  },

  /**
   * Select a property using a string path and returns false if undefined
   * @type {function}
   * @param {Object.<string, object>} obj - Ex: state
   * @param {string} path - Ex: "C1.forms.condData"
   * @param {string} notation - A dynamic notation. Ex: "itemId"
   * @returns {any | false}
   */
  setPath(obj, path = '', notation) {
    const value = path.split('.').reduce((p, c) => p && p[c], obj && obj);
    const selNotation = value && value[notation];
    const condReturn = notation ? selNotation : value;

    return condReturn;
  },

  mergeDeep(...objects) {
    const isObject = obj => obj && typeof obj === 'object';

    return objects.reduce((prev, obj) => {
      Object.keys(obj).forEach(key => {
        const pVal = prev[key];
        const oVal = obj[key];

        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = pVal.concat(...oVal);
        } else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = utils.mergeDeep(pVal, oVal);
        } else {
          prev[key] = oVal;
        }
      });

      return prev;
    }, {});
  },

  hasData(data) {
    const checkArr = [];

    const loop = info => {
      const isObj = typeof info === 'object';
      if (isObj) {
        for (var item in info) loop(info[item]);
        return;
      }
      checkArr.push(info && true);
    };

    loop(data);

    const findTrue = checkArr.find(item => item);
    const finalCheck = findTrue ? true : false;
    return finalCheck;
  },

  currencyMask(info, returnAsNumber) {
    if (returnAsNumber) {
      return Number(String(info).replace(/\D/g, '')) / 100;
    } else {
      const _info = String(info);
      const onlyNumbers = Number(_info.replace(/\D/g, ''));
      const stringWithMask = (onlyNumbers / 100)
        .toFixed(2)
        .replace('.', ',')
        .replace(/\d(?=(\d{3})+,)/g, '$&.');
      return stringWithMask;
    }
  },

  CNPJMask(info) {
    // ---------- set Limit Chars
    let limitChars = info;
    if (limitChars?.length > 19) limitChars = limitChars?.substring(0, 19);

    // ---------- set OnlyNumbers
    const onlyNum = limitChars?.replace(/\D/g, '');

    // ---------- set Format Numbers
    const mask = onlyNum
      ?.replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

    return { mask, onlyNum };
  },

  hourMask(info) {
    let val = info ?? '';

    if (val.length > 5) val = val.substring(0, 5);

    return val?.replace(/\D+/g, '').replace(/(\d{2})(\d)/, '$1:$2');
  },

  dateMask(info) {
    let date = info ?? '';
    date = date.replace('//', '/');
    var result = date.split('/');

    var length = result.length;

    // Append "/" after the last two charas, if more than 2 charas then remove it
    if (length <= 2 && result[length - 1] != '') {
      var last_two_digits = result[length - 1];
      if (last_two_digits.length >= 2) {
        date = date.slice(0, -last_two_digits.length);
        date = date + last_two_digits.slice(0, 2) + '/';
      }
    }

    if (typeof result[2] != 'undefined') {
      var year = result[2];
      if (year.length > 4) {
        date = date.slice(0, -year.length);
        year = year.slice(0, 4);
        date = date + year;
      }
    }

    return date;
  },

  commaMask(info) {
    const _info = String(info);
    const onlyNum = Number(_info.replace(/\D/g, ''));
    const mask = (onlyNum / 100)
      .toFixed(2)
      .replace('.', ',')
      .replace(/\d(?=(\d{3})+,)/g, '$&.');

    return { mask, onlyNum };
  },

  phoneMask(info) {
    // ---------- set Limit Chars
    let limitChars = info;
    if (limitChars?.length > 15) limitChars = limitChars?.substring(0, 15);

    // ---------- set OnlyNumbers
    const onlyNum = limitChars?.replace(/\D/g, '') ?? {};
    const arrChars = Object.values(onlyNum);
    const numChars = arrChars.length;

    // ---------- set Format Numbers
    const idx = num => arrChars[num] ?? '';
    const firstFour = `${idx(2)}${idx(3)}${idx(4)}${idx(5)}${idx(6)}`;
    const secFour = `${idx(7)}${idx(8)}${idx(9)}${idx(10)}`;
    const condHifen = numChars > 7 ? '-' : '';
    const condSpace = numChars > 2 ? ' ' : '';
    const condPar1 = numChars > 0 ? '(' : '';
    const condPar2 = numChars > 2 ? ')' : '';
    const condDDD = `${condPar1}${idx(0)}${idx(1)}${condPar2}`;
    const mask = `${condDDD}${condSpace}${firstFour}${condHifen}${secFour}`;

    return { mask, onlyNum };
  },

  numIntMask(info, limitChar) {
    // ---------- set Limit Chars
    let limitChars = String(info) ?? '';
    if (limitChars?.length > limitChar)
      limitChars = limitChars?.substring(0, limitChar);

    // ---------- set OnlyNumbers
    const mask = limitChars?.replace(/\D/g, '');
    const fixZero = mask === '0' ? '' : mask;
    const onlyNum = Number(mask);

    return { mask: fixZero, onlyNum };
  },

  // ---------- set Object Iteration

  findKey: (obj, val) => {
    let found;
    for (const key in obj) {
      const item = obj[key];
      if (val === item) {
        found = key;
      }
    }
    return found;
  },

  findVal: (obj, val) => {
    let found;
    for (const key in obj) {
      const item = obj[key];
      if (val === item) {
        found = item;
      }
    }
    return found;
  },

  filterObj: (obj, field, type, cond) => {
    let found;
    for (const key in obj) {
      const item = obj[key];
      const currField = item && item[field];

      const toSel = {
        contains() {
          if (Array.isArray([currField])) {
            const findFn = itemArr => itemArr === cond;
            const condFind = currField.find(findFn);
            if (condFind) {
              found = { ...found, [key]: item };
            }
          }
        },
        '==='() {
          const condFind = currField === cond;
          if (condFind) {
            found = { ...found, [key]: item };
          }
        },
        '!=='() {
          const condFind = currField !== cond;
          if (condFind) {
            found = { ...found, [key]: item };
          }
        },
      };
      const condSel = toSel[type] === undefined;
      const LEIA = 'Verifique alguma que possa ser usada no lugar';
      if (condSel) {
        throw new Error(`Tipo de condição '${type}' não foi definida`, LEIA);
      }

      currField && toSel[type]();
    }
    return found;
  },

  asyncRefs: (action, asyncFn) => {
    // ---------- set Reference Code
    const refCode = action.type.split('_')[0];

    // ---------- set Async Reference
    const ref = {
      // ---------- set Names
      startName: action.type + '_START',
      pendingName: action.type + '_PENDING',
      errorName: action.type + '_ERROR',
      mockName: action.type + '_MOCK',
      successCondData: action.type + '_CONDDATA',
      successMsgName: action.type + '_SUCCESS_MSG',
      successName: action.type + '_SUCCESS',

      // ---------- set START Message
      startMsg: () => {
        return action.asyncDispatch({
          type: 'base_ASYNC_MSGS',
          loggerName: ref.startName,
          code: refCode,
          msgs: { [ref.pendingName]: true, [ref.errorName]: null },
        });
      },

      // ---------- set ERROR Message
      errorMsg: err => {
        return action.asyncDispatch({
          type: 'base_ASYNC_MSGS',
          loggerName: ref.errorName,
          value: err,
          code: refCode,
          msgs: { [ref.pendingName]: false, [ref.errorName]: err.message },
        });
      },

      // ---------- set SUCCESS Message
      successMsg: res => {
        return action.asyncDispatch({
          type: 'base_ASYNC_MSGS',
          loggerName: ref.successMsgName,
          value: res,
          code: refCode,
          msgs: {
            [ref.pendingName]: false,
            [ref.errorName]: false,
            [ref.successCondData]: true,
          },
        });
      },

      // ---------- set Async Call
      callAsync: () => {
        // ---------- call START Msg
        ref.startMsg();

        const condArr = Array.isArray(asyncFn);
        const condCall = () => (condArr ? Promise.all(asyncFn) : asyncFn());

        // ---------- call Async Function
        return (
          condCall()
            // ---------- call ERROR Msg
            .catch(err => ref.errorMsg(err))
            // ---------- call SUCCESS Msg
            .then(res => {
              ref.successMsg(res);
              action.asyncDispatch({
                type: ref.successName,
                value: res,
              });
            })
        );
      },

      // ---------- set Mock Call
      callMock: () => {
        // ---------- set Mock Data
        const mockData = {
          item1: {
            id: 1,
            prop: 'mock1',
          },
          item2: {
            id: 2,
            prop: 'mock1',
          },
        };

        return action.asyncDispatch({
          type: ref.mockName,
          value: mockData,
        });
      },
    };

    return ref;
  },
};

export const {
  ezLog,
  logRender,
  timeFunc,
  toArr,
  setPath,
  mergeDeep,
  hasData,
  currencyMask,
  CNPJMask,
  hourMask,
  dateMask,
  phoneMask,
  commaMask,
  numIntMask,
  findKey,
  findVal,
  filterObj,
  asyncRefs,
} = utils;

export default utils;
