// ---------- import Internals

import { ezLog } from '../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    M7_InitData: () => {
      action.asyncDispatch({ type: 'S4_GET_AdmPar' });

      // ---------- set Data
      const allProdsInfo = state?.M2?.mergedVars?.itemsInfo;
      const cartProds = state?.basePersist?.cart?.addedProds;
      const infoShops = state?.M2?.shopsInReach?.itemsInfo;

      // ---------- set Containers
      const itemsInfo = {};
      let sortBy = {};
      let itemsList = [];

      // ---------- set Loop to Mount Data
      for (const key in cartProds) {
        // ---------- Laço do Carrinho
        const cartProdId = cartProds[key].id;
        const cartProdAmount = cartProds[key].amountProd;

        for (const key2 in allProdsInfo) {
          // ---------- Laço dos Produtos Filtrados
          const item = allProdsInfo[key2];
          const dataProd = item?.dataProd;
          const dataShops = item?.dataShops;
          const prodIdVar = dataProd?.prodIdVar;

          for (const key3 in dataShops) {
            // ---------- Laço dos Mercados
            const currShop = dataShops[key3];
            const shopId = currShop?.shopId;
            const prodId = currShop?.prodId;
            const shopPrice = currShop?.shopPrice * cartProdAmount;
            const dataShop = infoShops[shopId];
            const shopName = dataShop?.name ?? '...';
            const shopImg = dataShop?.imgUrl ?? '...';
            const settings = dataShop?.settings ?? '...';

            // ---------- set Prices
            const prevPrices =
              itemsInfo[shopId] && itemsInfo[shopId].totalPrice;
            const condPrevPrices = prevPrices !== undefined ? prevPrices : [];

            // ---------- set Prods
            const prevProdIds =
              itemsInfo[shopId] && itemsInfo[shopId].availableProds;
            const condPrevProds = prevProdIds !== undefined ? prevProdIds : [];

            // ---------- set Prods
            const prevAllProdsIds =
              itemsInfo[shopId] && itemsInfo[shopId].allInfos;
            const condPrevAllInfos =
              prevAllProdsIds !== undefined ? prevAllProdsIds : [];

            // ---------- set Mount Obj Info
            const condCapture = cartProdId === prodIdVar;
            if (condCapture) {
              sortBy[shopId] = {
                id: shopId,
                totalPrice: [...condPrevPrices, shopPrice].reduce(
                  (prev, crr) => prev + crr,
                  0,
                ),
              };

              itemsInfo[shopId] = {
                shopId,
                name: shopName,
                imgUrl: shopImg,
                rating: 0,
                allInfos: [...condPrevAllInfos, { prodId, shopPrice }],
                totalPrice: [...condPrevPrices, shopPrice],
                availableProds: [...condPrevProds, prodId],
                settings,
              };
            }
          }
        }
      }

      // ---------- set Sort Best Prices
      sortBy = [...Object.values(sortBy)];
      sortBy.sort((a, b) => {
        if (a.totalPrice > b.totalPrice) {
          return 1;
        }
        if (a.totalPrice < b.totalPrice) {
          return -1;
        }
        return 0;
      });

      // ---------- set Filter 3 Shops to Show
      for (const arr of sortBy.entries()) {
        const item = arr[1];

        itemsList.push(item.id);
      }

      const baseRoute = state?.baseRoute?.path;
      const condUpdate = baseRoute === 'orderEdit';
      condUpdate && action.asyncDispatch({ type: 'M7b_UpdateReferProds' });

      return {
        ...state,
        M7: {
          ...state.M7,
          condList: true,
          itemsInfo,
          itemsList,
          sortBy,
        },
      };
    },

    M7_CalcPrices: () => {
      const itemsM7 = state?.M7?.itemsInfo;
      const admPrice = state?.S4?.admPar?.deliveryPriceLimit;
      const itemsInfo = {};

      // ---------- set Calc Total Price
      for (const key in itemsM7) {
        const totalPrice = itemsM7[key].totalPrice;
        const sumArrPrices = totalPrice.reduce((prev, crr) => prev + crr, 0);
        const condFreeDelivery = sumArrPrices >= admPrice;
        const goalToDelvry = admPrice - sumArrPrices;

        itemsInfo[key] = {
          ...itemsM7[key],
          totalPriceLabel: sumArrPrices,
          condFreeDelivery,
          goalToDelvry,
        };
      }

      return {
        ...state,
        M7: {
          ...state.M7,
          itemsInfo,
        },
      };
    },
  };

  const adds = {
    M7_AddCurrShop: () => {
      action.asyncDispatch({ type: 'base_setRoute', value: 'orderList' });

      return {
        ...state,
        M7: {
          ...state.M7,
          currShop: action.value,
        },
      };
    },
  };

  const forms = {};

  return {
    ...inits,
    ...adds,
    ...forms,
  };
};

// ---------- set Exports
export default reducers;
