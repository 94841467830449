// ----------- import Packs
import React, { useRef } from 'react';
import { Animated } from 'react-native';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF from './Views';
import {
  useRouter,
  UseInitData,
  useData,
  UseList,
  UseCondLoader,
} from '../../../../useMorfos';
import { NoItemView, LongBar } from '../../../comps';
import ItemProd from '../../../comps/App/ItemProd';
import { useDispatch } from 'react-redux';

// ----------- set Info Screen
export const infoSc = {
  path: 'home',
  groupSc: 'pubApp',
  condBigSc: true,

  scCode: 'M2',
};

// ----------- set Default Component
export default () => {
  // ----------- set Effects
  const fxToken = () => {
    dispatch({ type: 'M2_TokenPermission' });
  };

  // ----------- set Data
  const content = useData('M2.scContent');

  // ----------- set Hooks
  const { callRouter } = useRouter();
  const dispatch = useDispatch();
  React.useEffect(fxToken, []);

  // useSnapDb(snapProdsBase);

  // ----------- set Routes
  const goSearch = () => callRouter('search');

  // ----------- set Animated Scroll Header
  const padBottom = useRef(new Animated.Value(0)).current;
  const heightAnim = useRef(new Animated.Value(300)).current;
  const fadeAnim = useRef(new Animated.Value(1)).current;

  const moveUp = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 35,
    }).start();

    Animated.timing(heightAnim, {
      toValue: 85,
      duration: 35,
    }).start();

    Animated.timing(padBottom, {
      toValue: 180,
      duration: 35,
    }).start();
  };

  const moveDown = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 35,
    }).start();

    Animated.timing(heightAnim, {
      toValue: 300,
      duration: 35,
    }).start();

    Animated.timing(padBottom, {
      toValue: 0,
      duration: 35,
    }).start();
  };

  const moveScroll = event => {
    const currentOffset = event.nativeEvent.contentOffset.y;
    let offset = 0;
    currentOffset > offset ? moveUp() : moveDown();
  };

  // ----- set Comp Return
  const LongBarView = () => (
    <LongBar
      infoData={{
        fadeAnim,
        heightAnim,
      }}
    />
  );

  // ----------- set Return
  const infoView = {
    moveScroll,
    content,
    goSearch,
    padBottom,

    LongBarView,
  };
  const infoList = {
    reducer: 'M2.filterProds.itemsInfo',
    handleString: true,
  };

  return (
    <UseInitData reducer={'M2_InitData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'M2.filterProds.condList'}>
          <UseList data={'M2.filterProds.itemsList'} loadNext={'M2.loadNext'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemProd infoData={{ ...infoList, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
