// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import { UseInitData, UseList, UseCondLoader } from '../../../../../useMorfos';
import { ItemList, NoItemView } from '../../../../comps';

// ----------- set Info Screen
export const infoSc = {
  path: 'shopsList',
  groupSc: 'adm1',
  condBigSc: true,
  layout: {
    icon: 'cart',
    title: 'Supermercados',
    desc: 'Lista de Supermercados',
    btn: 'Add. Supermercado',
    actionADD: 'B1_ShopsADD',
  },

  scCode: 'B1',
};

// ----------- set Default Component
export default () => {
  // ----------- set Return
  const infoList = {
    itemContent: 'B1.scContent',
    itemsInfo: 'B1.itemsInfo',
    pressItem: 'B1_EditMkt',
    actionName1: 'B1_ShopProds',
    actionName2: 'q',
  };

  return (
    <UseInitData reducer={'B1_InitData'}>
      <ViewDF>
        <UseCondLoader data={'B1.condList'}>
          <UseList data={'B1.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemList infoData={{ ...infoList, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
