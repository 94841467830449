// ----------- import Packs
import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';

// ----------- import Internals
import { useStl, UseIcoMoon } from '../../../../useMorfos';
import src2 from '../../../../../src/project/images/basket.png';
import src3 from '../../../../../src/project/images/cashier.png';
import NavBar from '../../../comps/App/NavBar';
import SideMenu from '../../../comps/App/SideMenu';

// #region :: STYLEs *********
const stlBodyView = [
  useStl.flex1,
  useStl.flexCenter,
  {
    backgroundColor: '#fcfcfc',
    overflow: 'hidden',
  },
];

const stlContainerImg = [useStl.profileImg];
const stlImg1 = [{ width: '100%', height: '100%' }];
const stlImg2 = {
  width: 160,
  height: 160,
  position: 'absolute',
  right: -80,
  top: 100,
  zIndex: 0,
};

const stlImg3 = {
  width: 160,
  height: 160,
  position: 'absolute',
  left: -70,
  bottom: 100,
  zIndex: 0,
};

// DADOS
const stlContent = [useStl.flexCenter, { zIndex: 10 }];
const stlName = [
  {
    fontSize: 30,
    fontWeight: 700,
    color: '#4a4a4a',
    textAlign: 'center',
    marginBottom: 5,
  },
];
const stlMail = [{ fontWeight: 600, color: 'gray' }];
const stlTel = [
  useStl.border(0, 0, 1, 0, '#4a4a4a'),
  useStl.flexCenter,
  { width: '50%', marginTop: 30 },
];
const stlTextel = [{ fontSize: 12, fontWeight: 700, color: 'gray' }];
const stlNuntel = [{ fontSize: 17, fontWeight: 700, color: 'gray' }];
const stltexEnd = [
  {
    textAlign: 'center',
    color: 'gray',
    marginTop: 15,
    fontSize: 13,
    fontWeight: 600,
    width: 250,
  },
];

// BTNs
const stlCenter1 = [useStl.flexCenter, { marginVertical: 40 }];
const stlBtn1 = [
  useStl.btn,
  useStl.btnMedium,
  {
    backgroundColor: '#293A0F',
    fontSize: 8,
    fontWeight: 700,
    width: 110,
    marginBottom: 5,
  },
];
const stlBtnPrimary1 = [stlBtn1, useStl.btnPrimary];
const stltxtInverse1 = [
  useStl.txtPrimaryInverse,
  { fontSize: 9, fontWeight: 700 },
];
// #endregion *********

export default ({ info }) => {
  return (
    <>
      <View style={stlBodyView}>
        <SideMenu />
        <NavBar />

        <Image source={src2} style={stlImg2} />
        <Image source={src3} style={stlImg3} />

        <View style={stlContent}>
          <View style={stlContainerImg}>
            <Image source={info.condThumbnail} style={stlImg1} />
          </View>
          <Text style={stlName}>{info.name}</Text>
          <Text style={stlMail}>{info.email}</Text>
          {/* 
          <View style={stlTel}>
            <Text style={stlTextel}>Telefone de Contato</Text>
            <Text style={stlNuntel}>11 9 9977-6655</Text>
          </View> */}

          {/* ----------- set BTNs SAVE & CANCEL */}
          {/* <View style={stlCenter1}>
            <TouchableOpacity style={stlBtnPrimary1} onPress={info.btnSave}>
              <Text style={stltxtInverse1}>SALVAR</Text>
            </TouchableOpacity>
            <TouchableOpacity style={stlBtn1} onPress={info.btnCancel}>
              <Text style={{ color: 'white', fontSize: 9, fontWeight: 700 }}>
                CANCELAR
              </Text>
            </TouchableOpacity>
          </View> */}
          <Text style={stltexEnd}>
            Qualquer dúvida favor contatar o email abaixo:
            contato@poupimais.com.br
          </Text>
        </View>
      </View>
    </>
  );
};
