// ----------- import Packs
import React from 'react';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF from './Views';
import {
  useRouter,
  UseInitData,
  useData,
  UseCondLoader,
  UseList,
} from '../../../../useMorfos';
import { NoItemView, ShopView } from '../../../comps';

// ----------- set Info Screen
export const infoSc = {
  path: 'selshop',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: 'Escolha um Mercado',
  },

  scCode: 'M7',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('M7.scContent');

  // ----------- set Hooks
  const { callRouter } = useRouter();

  // ----------- set Routes
  const btnGoto = () => callRouter('signin');

  // ----------- set Return
  const infoView = { content, btnGoto };
  const infoItem = {};

  return (
    <UseInitData reducer={'M7_InitData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'M7.condList'}>
          <UseList data={'M7.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ShopView infoData={{ ...infoItem, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
