// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { primaryColor } from '../../../../config/styles';

// ----------- import Internals
import { UseIcoMoon, useResize, useStl } from '../../../../useMorfos';
import src1 from '../../../images/logo.svg';

// #region :: STYLEs *********
const stlBodyView = [
  useStl.pad20,
  useStl.flexCenter,
  { backgroundColor: '#fefefe' },
];
const stlTitleTxt = [useStl.txtTitleScreen];
const stlDescTxt = [useStl.txtBase, useStl.mgB20, { color: '#444' }];
const stlTitleSmall = [
  useStl.txtTitleScreen,
  { fontSize: 14, fontWeight: 600, marginBottom: 12 },
];
const stlItalic = [{ fontStyle: 'italic' }];
const stlCard = [useStl.card, useStl.flexTopCenter, { minHeight: 400 }];
const changeBg = {
  desk: { width: '30%' },
  mob: { width: '60%' },
};
const stlLogo = [{ width: 120, height: 37, marginLeft: -70 }];
const stlRow = [
  useStl.flexRow,
  useStl.flexBetween,
  { marginBottom: 60, width: '100%' },
];
const stlBrandArea = [useStl.flex6, useStl.flexCenter];
const stlBtnBack = [useStl.flex2, useStl.flexRow];
const stlBtnTxtBack = [{ color: primaryColor }];

// #endregion *********

export default ({ info }) => {
  return (
    <View style={stlBodyView}>
      {/* ----------- PARTs */}
      <View style={stlRow}>
        <TouchableOpacity style={stlBtnBack} onPress={info.btnGoBack}>
          <UseIcoMoon name="arrow-left" size={16} color={primaryColor} />
          <Text style={stlBtnTxtBack}>Voltar</Text>
        </TouchableOpacity>
        <View style={stlBrandArea}>
          <Image source={src1} style={stlLogo} />
        </View>
      </View>
      <View>
        <Text style={stlTitleTxt}>TERMOS DE USO</Text>
        <Text style={stlDescTxt}>
          Por favor, leia com atenção os termos e condições. Ao se cadastrar no
          poupimais.com.br você ESTÁ DE ACORDO COM AS CONDIÇÕES E TERMOS do
          Website e Aplicativo. Note que a recusa destes Termos do Website
          impedirá que você faça pedidos de produtos do nosso Aplicativo e
          utilize nossa plataforma de gerenciamento de Supermercados e Atacados.
        </Text>
        <Text style={stlTitleSmall}>1. SERVIÇOS OFERECIDOS</Text>
        <Text style={stlDescTxt}>
          1.1 Este TERMO se aplica para regular o uso do serviço oferecido pela
          poupimais.com.br aos USUÁRIOS, qual seja, possibilitar a escolha,
          pelos USUÁRIOS, de ORÇAMENTOS DE SUPERMERCADOS E ATACADOS cadastrados
          e, via on-line, efetivar solicitações para aquisição (e entrega em
          domicílio e retirada no local) de gêneros alimentícios, higiene e
          limpeza fornecidos pelos SUPERMERCADOS E ATACADOS, de acordo com os
          itens disponibilizado, sendo possível, igualmente, aos USUÁRIOS, a
          efetivação do pagamento do preço dos produtos via on-line.
        </Text>
        <Text style={stlDescTxt}>
          1.2 O serviço da poupimais.com.br consiste, portanto, em aproximar,
          através do nosso site (aplicativo), os USUÁRIOS e os SUPERMERCADOS E
          ATACADOS cadastrados, possibilitando que os USUÁRIOS encaminhem, aos
          SUPERMERCADOS E ATACADOS, pedidos de entrega de gêneros alimentícios,
          higiene e limpeza, bem como, sendo essa a opção dos USUÁRIOS, receber
          on-line pagamento do preço dos produtos entregues aos USUÁRIOS pelos
          SUPERMERCADOS E ATACADOS.
        </Text>
        <Text style={stlDescTxt}>
          1.3 Desde logo fica esclarecido ao USUÁRIO - o qual se declara ciente
          - que o serviço oferecido pela poupimais.com.br se relaciona apenas à
          intermediação (com opção de pagamento on-line) para comercialização de
          produtos de gêneros alimentícios, higiene e limpeza, não abarcando
          embalagem, seleção dos produtos disponíveis, armazenagem de produtos
          perecíveis e entrega física (via motoboy ou outros meios) dos
          produtos, sendo esses quatro itens de responsabilidade integral dos
          SUPERMERCADOS E ATACADOS, a quem deverão ser direcionados quaisquer
          reclamos acerca de problemas decorrentes de separação de produtos,
          disponibilidade de produtos, armazenagem de produtos perecíveis,
          defeito, falta de produtos na entrega e entrega.
        </Text>
        <Text style={stlTitleSmall}>2. CADASTRO</Text>
        <Text style={stlDescTxt}>
          2.1 O USUÁRIO, para utilizar os serviços acima descritos, deverá ter
          capacidade jurídica para atos civis e deverá, necessariamente, prestar
          as informações exigidas no CADASTRO, assumindo integralmente a
          responsabilidade (inclusive cível e criminal) pela exatidão e
          veracidade das informações fornecidas no CADASTRO, que poderá ser
          verificado, a qualquer momento, pela poupimais.com.br.
        </Text>
        <Text style={stlDescTxt}>
          2.1.1 Em caso de informações incorretas, inverídicas ou não
          confirmadas, bem assim na hipótese da negativa em corrigi-las ou
          enviar documentação que comprove a correção, a Poupi se reserva o
          direito de não concluir o cadastramento em curso ou, ainda, de
          bloquear o cadastro já existente, impedindo o USUÁRIO de utilizar os
          serviços on-line até que, a critério da poupimais.com.br, a situação
          de anomalia esteja regularizada. A poupimais.com.br se reserva o
          direito de impedir, a seu critério, novos CADASTROS, ou cancelar os já
          efetuados, em caso de ser detectada anomalia que, em sua análise, seja
          revestida de gravidade ou demonstre tentativa deliberada de burlar as
          regras aqui descritas, obrigatórias para todos os USUÁRIOS,
          SUPERMERCADOS ou ATACADOS. Também agirá a poupimais.com.br de tal
          forma caso verifique descumprimento, pelo USUÁRIO, SUPERMERCADO ou
          ATACADO de qualquer obrigação prevista no presente TERMO.
        </Text>
        <Text style={stlDescTxt}>
          2.1.1 Toda a autenticação de USUÁRIOS cadastrados na poupimais.com.br
          (plataforma ou aplicativo) será feita via GOOGLE, assim qualquer
          problema com perda de senha ou usuário deverá ser feita diretamente
          pelo GOOGLE.
        </Text>
        <Text style={stlDescTxt}>
          2.2 Efetuado, com sucesso, o CADASTRO, o USUÁRIO terá acesso aos
          serviços por meio de login e senha, dados esses que se compromete a
          não divulgar a terceiros, ficando sob sua exclusiva responsabilidade
          qualquer solicitação de serviço que seja feita com o uso de login e
          senha de sua titularidade.
        </Text>
        <Text style={stlTitleSmall}>3. OBRIGAÇÕES DO USUÁRIO</Text>
        <Text style={stlDescTxt}>
          3.1 Efetuado com sucesso o CADASTRO do USUÁRIO, este se obriga a não
          divulgar a terceiros login e senha de acesso, nem permitir o uso de
          tais informações por terceiros, responsabilizando-se pelas
          consequências do uso de login e senha de sua titularidade.
        </Text>
        <Text style={stlDescTxt}>
          3.2 É obrigação do USUÁRIO fornecer informações cadastrais totalmente
          verídicas e exatas, responsabilizando-se exclusiva e integralmente (em
          todas as searas jurídicas) por todo o conteúdo por si informado no
          item CADASTRO, mantendo atualizado e confirmado o endereço para
          entrega dos produtos encomendados.
        </Text>
        <Text style={stlDescTxt}>
          3.3 O USUÁRIO se obriga, também, a pagar integralmente o preço dos
          produtos por si solicitados ou encomendados ao SUPERMERCADO ou ATACADO
          e efetivamente a si entregues, seja pela modalidade on-line, seja por
          qualquer outra forma, diretamente ao portador dos produtos
          encomendados por meio deste site (dinheiro, cartão de crédito, cheque,
          tickets, etc.).
        </Text>
        <Text style={stlDescTxt}>
          3.4 O USUÁRIO que seja menor de 18 anos de idade está ciente de que
          não poderá encomendar e adquirir, em qualquer hipótese, produtos
          alcoólicos, responsabilizando-se pela correta informação de sua idade
          no item CADASTRO.
        </Text>
        <Text style={stlDescTxt}>
          3.5 O USUÁRIO concorda com o uso das informações de avaliações e
          feedbacks dos serviços dos SUPERMERCADOS ou ATACADOS e da Poupi,
          conforme descrito nos TERMOS DE PRIVACIDADE da Poupi.
        </Text>
        <Text style={stlTitleSmall}>
          4. OBRIGAÇÕES DOS SUPERMERCADOS ou ATACADOS
        </Text>
        <Text style={stlDescTxt}>
          4.1 Os SUPERMERCADOS ou ATACADOS se comprometem em fornecer preços
          atualizados de cada produto disponibilizado em nossa plataforma
          (Aplicativo).{' '}
        </Text>
        <Text style={stlDescTxt}>
          4.2 Caso os produtos estiverem desatualizados o supermercado se
          responsabiliza por qualquer prejuízo causado aos Usuários e a Poupi.
        </Text>
        <Text style={stlDescTxt}>
          4.3 Os SUPERMERCADOS ou ATACADOS se comprometem em fornecer na
          plataforma, os mesmos preços de suas prateleiras que tem em seu
          ambiente físico.
        </Text>
        <Text style={stlDescTxt}>
          4.4 Os SUPERMERCADOS ou ATACADOS se comprometem com a entrega (se o
          mesmo tiver esse serviço disponibilizado) dos produtos e produtos
          perecíveis em ambiente refrigerado.
        </Text>
        <Text style={stlTitleSmall}>5. OBRIGAÇÕES DA poupimais.com.br </Text>
        <Text style={stlDescTxt}>
          5.1 Disponibilizar no aplicativo Poupi espaço virtual que permita ao
          USUÁRIO devidamente cadastrado efetivar pedidos de compra de gêneros
          alimentícios, higiene e limpeza, anunciados e comercializados pelos
          SUPERMERCADOS ou ATACADOS e, também, disponibilizar ao USUÁRIO meios
          de pagamento do preço dos produtos on-line.
        </Text>
        <Text style={stlDescTxt}>
          5.2 Proteger, por meio de armazenamento em servidores ou quaisquer
          outros meios magnéticos de alta segurança, a confidencialidade de
          todas as informações e cadastros relativos aos USUÁRIOS, assim como
          valores atinentes às operações financeiras advindas da
          operacionalização dos serviços previstos no presente TERMO. Contudo,
          não responderá pela reparação de prejuízos que possam ser derivados de
          apreensão e cooptação de dados por parte de terceiros que, rompendo os
          sistemas de segurança, consigam acessar essas informações.
        </Text>
        <Text style={stlTitleSmall}>6. MODIFICAÇÕES DESTE TERMO</Text>
        <Text style={stlDescTxt}>
          6.1 O presente TERMO DE USO poderá, a qualquer tempo, ter seu
          conteúdo, ou parte dele, modificados para adequações e inserções, tudo
          com vistas ao aprimoramento dos serviços disponibilizados.
        </Text>
        <Text style={stlDescTxt}>
          6.2 As novas condições entrarão em vigência assim que sua veiculada no
          site, sendo possível ao USUÁRIO manifestar oposição a quaisquer dos
          termos modificados, desde que o faça por escrito, através do site
          poupimais.com.br, o que gerará o cancelamento de seu CADASTRO.
        </Text>
        <Text style={stlTitleSmall}>7. CANAL DE COMUNICAÇÃO</Text>
        <Text style={stlDescTxt}>
          7.1 Para estabelecer contato entre poupimais.com.br e o USUÁRIO fica
          disponibilizado o endereço eletrônico deste link, sendo certo que o
          USUÁRIO se obriga, igualmente, a manter em seu cadastro endereço
          eletrônico atual por intermédio do qual se farão as comunicações a ele
          dirigidas pelo poupimais.com.br., desde logo emprestando-se validade
          jurídica e efetividade a esse meio eletrônico de troca de informações
          recíprocas.
        </Text>
        <Text style={stlTitleSmall}>8. ACEITAÇÃO DO TERMO DE USO</Text>
        <Text style={stlDescTxt}>
          8.1 O USUÁRIO declara ter lido, entendido e que aceita todas as
          regras, condições e obrigações estabelecidas no presente TERMO.
        </Text>
        <Text style={stlTitleSmall}>9. FORO DE ELEIÇÃO</Text>
        <Text style={stlDescTxt}>
          9.1 As partes elegem como competente para dirimir eventuais
          controvérsias que venham a surgir da interpretação e do cumprimento do
          presente TERMO{' '}
        </Text>
        <Text style={[stlDescTxt, stlItalic]}>
          Última atualização: 01 de Janeiro de 2021
        </Text>
      </View>
    </View>
  );
};
