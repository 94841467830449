// ---------- import Internals
import { firestore } from '../../../../../config/firebase/fbConfig';
import { checkAccount } from './helpers';
import { asyncRefs, ezLog } from '../../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    S1_InitData: () => {
      const condCateg = state?.basePersist?.categs.itemsInfo;
      const deleteInfo = state?.S1?.deleteInfo;
      const initProds = state?.S1?.itemsInfo ?? {};
      const condCategs = Object.keys(condCateg).length === 0;
      const condInitProds = Object.keys(initProds).length === 0 || deleteInfo;
      const scContent = {
        // --- ItemList

        status1: 'Adicionar',
        status2: 'Adicionado',
      };

      const condData = true;
      action.asyncDispatch({ type: 'S1_GET_ShopProds' }); // ver status account
      condCategs && action.asyncDispatch({ type: 'S1_GET_Categs' });
      condInitProds && action.asyncDispatch({ type: 'S1_GET_InitProds' }); // começa trazer prods base

      const condShow = condInitProds ? false : true;
      return {
        ...state,
        S1: {
          ...state.S1,
          scContent,
          condData,
          condAddedProd: false,
          condList: condShow,
        },
      };
    },
  };

  const gets = {
    // ---------- set GET BASE PRODS
    S1_GET_PriceProds: () => {
      const currShopInfo = state?.S1?.currShopInfo;
      const allShopProds = [];
      for (const key in currShopInfo) {
        const item = currShopInfo[key];
        allShopProds.push(item.prodId);
      }

      const asyncBase = async () => {
        const itemRefs = allShopProds.map(id => {
          return firestore.collection('prods').doc(id).get();
        });
        const finalRes = Promise.all(itemRefs);
        return finalRes;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        S1: {
          ...state.S1,
          condList: false,
        },
      };
    },

    S1_GET_PriceProds_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      const itemsInfo = {};
      const itemsList = [];

      dataDb.forEach(docProds => {
        const item = docProds.data();
        itemsInfo[item.docId] = item;
        itemsList.push(item.docId);
      });

      return {
        ...state,
        S1: {
          ...state.S1,
          itemsList,
          itemsInfo,
          lastDocId: undefined,
          lastDate: undefined,
          condList: true,
        },
      };
    },

    // ---------- set GET BASE PRODS
    S1_GET_InitProds: () => {
      const asyncBase = async () => {
        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('prods')
          // .where('categ', '==', '10')
          .orderBy('createdAt', 'desc')
          .orderBy('docId', 'desc')
          .limit(30)
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S1_GET_InitProds_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;

      const itemsInfo = {};
      const itemsList = [];
      let lastDocId;
      let lastDate;
      // let theDate;

      dataDb.forEach(doc => {
        const currShopInfo = state?.S1?.currShopInfo;
        const prodsPrice = Object.values(currShopInfo);
        const fillFirst = prodsPrice.some(i => i.prodId === doc.id);

        if (fillFirst) {
          itemsInfo[doc.id] = doc.data();
          itemsList.unshift(doc.id);
        } else {
          itemsInfo[doc.id] = doc.data();
          itemsList.push(doc.id);
        }

        lastDocId = doc.id;
        lastDate = doc.data().createdAt;
        // theDate = new Date(lastDate.seconds * 1000);
      });

      return {
        ...state,
        S1: {
          ...state.S1,
          itemsList,
          itemsInfo,
          lastDocId,
          lastDate,
          condList: true,
        },
      };
    },

    S1_GET_NextProds: () => {
      const lastDocId = state?.S1?.lastDocId;
      const lastDate = state?.S1?.lastDate;

      const asyncBase = async () => {
        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('prods')
          .orderBy('createdAt', 'desc')
          .orderBy('docId', 'desc')
          // .orderBy('name', 'desc')
          .startAfter(lastDate, lastDocId)
          .limit(30)
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        S1: {
          ...state.S1,
          loadNext: true,
        },
      };
    },

    S1_GET_NextProds_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;

      const itemsInfo = state?.S1?.itemsInfo;
      const itemsList = state?.S1?.itemsList;
      let lastDocId;
      let lastDate;
      // let theDate;

      dataDb.forEach(doc => {
        itemsInfo[doc.id] = doc.data();
        itemsList.push(doc.id);
        lastDocId = doc.id;
        lastDate = doc.data().createdAt;
        // theDate = new Date(lastDate.seconds * 1000);
      });
      // const dateString = theDate.toGMTString();
      // console.log('last date >>>>>>>>>>', dateString);

      return {
        ...state,
        S1: {
          ...state.S1,
          itemsList,
          itemsInfo,
          loadNext: false,
          lastDocId,
          lastDate,
        },
      };
    },

    // ---------- set GET SHOP PRODS
    S1_GET_ShopProds: () => {
      // ---------- set Async Function2
      const asyncProd = async () => {
        // ---------- set Type List
        const currShop = state?.baseAuthUser?.shopId;

        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('shops')
          .doc(currShop)
          .collection('prods')
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncProd);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S1_GET_ShopProds_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      // const prods = state?.S1?.itemsInfo;
      const prodId = [];
      // ꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜ NÃO FUNCIONA ou NÃO SERVE MAIS
      // ꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜ (VERIFICAR MELHOR)
      // const itemsInfo = {};
      // const picks = [];

      // ----------- set Prods Ids
      const currShopInfo = {};
      dataDb.forEach(doc => {
        const dataDb = doc.data();
        currShopInfo[doc.id] = dataDb;
        prodId.push(doc.data().prodId);

        // ꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜ NÃO FUNCIONA ou NÃO SERVE MAIS
        // ꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜ (VERIFICAR MELHOR)
        // const condContain = itemsInfo[dataDb.prodId];
        // if (condContain) {
        //   condContain.contain = true;
        // }
      });

      // ----------- set Prods Base
      // ꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜ NÃO FUNCIONA ou NÃO SERVE MAIS
      // ꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜꜜ (VERIFICAR MELHOR)
      // prodId.map(prod => picks.push(itemsInfo[prod]));

      // ----------- set Check Account Status
      const accountStatus = checkAccount(currShopInfo);

      // ----------- set Update Database / Account Status
      action.asyncDispatch({ type: 'S1_UptAccount', value: accountStatus });

      // ----------- set Dispatch Order PF
      // const path = state?.baseRoute?.path;
      // const condGetProds = path === 'requestOrderList' && !prods;
      // const condGetInfos = path === 'requestOrderList' && prods;
      // action.asyncDispatch({ type: 'S1c_GET_Prods' });
      // condGetProds && action.asyncDispatch({ type: 'S1c_GET_Prods' });
      // condGetInfos && action.asyncDispatch({ type: 'S1c_GET_Infos' });

      return {
        ...state,
        S1: {
          ...state.S1,
          accountStatus,
          currShopInfo,
          // condList: true,
        },
      };
    },

    // ---------- set GET CATEGS + SUBCATEGS
    S1_GET_Categs: () => {
      // ---------- set Async Function1
      const asyncBase = async () => {
        const dbRef = firestore.collection('settings');
        const dataSuccess = await dbRef.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S1_GET_Categs_SUCCESS: () => {
      // ---------- set Data
      const dataDb = action.value;
      let catInfo = [];
      let subCatInfo = [];

      dataDb.forEach(doc => {
        // --- Items Info
        const catListX = doc.data().catList;
        const subcatListX = doc.data().subcatList;
        catInfo.push(...catListX);
        subCatInfo.push(...Object.values(subcatListX));
      });

      return {
        ...state,
        basePersist: {
          ...state.basePersist,
          categs: {
            ...state.basePersist.categs,
            itemsInfo: catInfo,
          },
          subCategs: {
            ...state.basePersist.subCategs,
            itemsInfo: subCatInfo,
          },
        },
      };
    },
  };

  const btns = {
    S1_ProdADD: () => {
      return {
        ...state,
        C2: {
          ...state.C2,
          isEditable: false,
          idToEdit: null,
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: 'prodADD',
        },
      };
    },
  };

  const edits = {
    S1_EditShopProds: () => {
      action.asyncDispatch({ type: 'C2_InitForm' });

      return {
        ...state,
        C2: {
          ...state.C2,
          idToEdit: action.itemId,
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: 'prodADD',
        },
      };
    },

    S1_UptAccount: () => {
      const currShop = state.baseAuthUser.shopId;
      const accStatus = action.value;
      const condUpdt = accStatus === 'suspended';
      ezLog({ condUpdt });
      /// ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('shops').doc(currShop);
        const dataSuccess = await dbRef.update({
          accountStatus: accStatus,
          prodsOutdated: condUpdt,
        });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S1_UptAccount_SUCCESS: () => {
      return state;
    },
  };

  return {
    ...inits,
    ...gets,
    ...edits,
    ...btns,
  };
};

// ---------- set Exports
export default reducers;
