// ----------- import Packs
import React from 'react';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF from './Views';
import { useRouter, UseInitData, useData } from '../../../../../useMorfos';

// ----------- set Info Screen
export const infoSc = {
  path: 'myPf',
  groupSc: 'adm1',
  sideRight: {
    title: 'Meu Perfil',
  },
  scCode: 'D1',
};

// ----------- set Default Component

export default () => {
  // ----------- set Data
  const content = useData('D1.scContent');
  const { imgUrl, name, email } = useData('baseAuthUser');

  // ----------- set Hooks

  const { callRouter } = useRouter();
  const dispatch = useDispatch();
  // ----------- set Routes
  const btnGoto = () => dispatch({ type: 'comps_Logout' });

  // ----------- set Return
  const infoView = { content, btnGoto, imgUrl, name, email };

  return (
    <UseInitData reducer={'D1_InitContentData'}>
      <ViewDF info={infoView} />
    </UseInitData>
  );
};
