// ---------- import Internals
import pagarme from 'pagarme';
import { firestore } from '../../../../config/firebase/fbConfig';
import { asyncRefs, ezLog } from '../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    M9_InitData: () => {
      action.asyncDispatch({ type: 'M9_InitForm' });

      const scContent = {
        title: 'Signin',
        subTitle: 'Oi Mundo!',
        description: 'Lorem Ipsum!',
        txtBtn: 'Ir para os TERMOS',
      };

      const condData = true;
      // const totalPrice = state.M7e.currOrder.totalPrice;
      // const deliveryPriceLimit = state.S4.admPar.deliveryPriceLimit;
      // const condDelivery = totalPrice >= Number(deliveryPriceLimit);
      const condDelivery = state.M7e?.currOrder?.deliveryData?.withdraw;

      return {
        ...state,
        M9: { ...state.M9, scContent, condData, condDelivery },
      };
    },
  };

  const forms = {
    M9_InitForm: () => {
      const iptsInfo = {
        card_holder_name: {
          // ----- set Dynamics
          itemId: 'card_holder_name',
          required: true,
          iptChange: 'M9_IptValues',
          // condNumber: true,

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Nome',
          // editData: editData && editData.card_holder_name,
          editData: '',
          isEditable: false,
        },

        card_number: {
          // ----- set Dynamics
          itemId: 'card_number',
          required: true,
          iptChange: 'M9_IptValues',
          condNumber: true,

          // ----- set Statics
          pHolder: 'Apenas Números',
          label: 'Número do Cartão',
          // editData: editData && editData.card_number,
          editData: '',
          isEditable: false,
        },

        card_month: {
          // ----- set Dynamics
          itemId: 'card_month',
          required: true,
          iptChange: 'M9_IptValues',
          condNumber: true,

          // ----- set Statics
          pHolder: 'Apenas Números',
          label: 'Mês',
          // editData: editData && editData.card_month,
          editData: '',
          isEditable: false,
        },

        card_year: {
          // ----- set Dynamics
          itemId: 'card_year',
          required: true,
          iptChange: 'M9_IptValues',
          condNumber: true,

          // ----- set Statics
          pHolder: 'Apenas Números',
          label: 'Ano',
          // editData: editData && editData.card_year,
          editData: '',
          isEditable: false,
        },

        card_cvv: {
          // ----- set Dynamics
          itemId: 'card_cvv',
          required: true,
          iptChange: 'M9_IptValues',
          condNumber: true,

          // ----- set Statics
          pHolder: 'Apenas Números',
          label: 'CVV',
          // editData: editData && editData.card_cvv,
          editData: '',
          isEditable: false,
        },
      };

      return {
        ...state,
        M9: {
          ...state.M9,
          condList: true,
          forms: {
            iptsInfo,
            iptsChanges: {
              card_cvv: '',
              card_holder_name: '',
              card_month: '',
              card_number: '',
              card_year: '',
            },
          },
        },
      };
    },

    // --- Credit Card Validation
    M9_BtnFieldsFilled: () => {
      const iptsChanges = state?.M9?.forms?.iptsChanges;
      let cardFilled = false;

      for (const key in iptsChanges) {
        const item = iptsChanges[key];
        const condSnap = item === '';

        if (condSnap) {
          cardFilled = false;
        } else {
          cardFilled = true;
        }
      }

      if (cardFilled) {
        action.asyncDispatch({ type: 'M9_BtnValidate' });
      }

      return {
        ...state,
        M9: {
          ...state.M9,
          cardFilled,
        },
      };
    },

    M9_BtnValidate: () => {
      const asyncFn = async () => {
        // let dataSuccess;
        const card = state?.M9?.forms?.iptsChanges;
        let cardValidations = pagarme.validate({ card });

        return cardValidations;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return { ...state, M9: { ...state.M9, condList: false } };
    },

    M9_BtnValidate_SUCCESS: () => {
      const cardValids = action.value.card;
      let cardError = false;
      let condList = false;

      for (const key in cardValids) {
        const item = cardValids[key];
        const condSnap = item === false;

        if (condSnap) {
          cardError = true;
        }
      }

      if (cardError) {
        // console.log('Oops, número de cartão incorreto');
        condList = true;
      } else {
        action.asyncDispatch({ type: 'M9_SetTransaction' });
      }

      return {
        ...state,
        M9: {
          ...state.M9,
          cardValids,
          condList,
        },
      };
    },

    // --- set Transaction when Credit Card is Valid
    M9_SetTransaction: () => {
      const asyncFn = async () => {
        const APIKEY = process.env.REACT_APP_APIKEY;
        const POUPI_RECEIVER = process.env.REACT_APP_POUPI_RECEIVER;
        const iptsChanges = state?.M9?.forms?.iptsChanges;
        const totalPrice = state?.M7e?.currOrder?.totalPrice;
        const arrProds = state?.M7e?.currOrder?.arrProds;
        const phone = `+${state?.M7e?.currOrder?.clientPhone}`;
        const recipient_id = state?.M7e?.shopInfo?.recipientId;
        const itemsInfo = state?.M7e?.itemsOrder?.itemsInfo;
        const baseAuthUser = state?.baseAuthUser;
        const userAddress = state?.baseAuthUser?.address;
        const zip_code = userAddress.zip_code.replace('-', '');
        const toCents = totalPrice.toFixed(2).replace('.', '');
        const amount = Number(toCents);
        const card_number = iptsChanges?.card_number;
        const card_cvv = iptsChanges?.card_cvv;
        const card_expiration_date =
          iptsChanges.card_month + iptsChanges.card_year;
        const card_holder_name = iptsChanges.card_holder_name;

        const objItem = [];

        arrProds.map(item => {
          const ids = item.id;
          const price = item.price.toFixed(2).replace('.', '');

          objItem.push({
            id: ids,
            title: itemsInfo[ids].name,
            unit_price: price,
            quantity: item.amount,
            tangible: true,
          });
        });

        const connectApi = pagarme.client
          .connect({ api_key: APIKEY })
          .then(client =>
            client.transactions.create({
              amount,
              card_number,
              card_cvv,
              card_expiration_date,
              card_holder_name,
              // configSplit: {
              // },
              customer: {
                name: baseAuthUser.name,
                email: baseAuthUser.email,
                type: 'individual',
                phone_numbers: [phone],
                country: userAddress.country.toLowerCase(),
                external_id: baseAuthUser.docId,
                documents: [
                  {
                    type: 'cpf',
                    number: '91073065049',
                  },
                ],
              },
              billing: {
                name: baseAuthUser.name,
                address: {
                  country: userAddress.country.toLowerCase(),
                  state: userAddress.state,
                  city: userAddress.city,
                  neighborhood: userAddress.neighborhood,
                  street: userAddress.street,
                  street_number: userAddress.street_number,
                  zipcode: zip_code,
                },
              },
              items: objItem,
              split_rules: [
                {
                  recipient_id: POUPI_RECEIVER, // id do ADM
                  percentage: 8,
                  liable: true, // chargeback
                  charge_processing_fee: true, // taxas
                },
                {
                  recipient_id, // id do Mercado
                  percentage: 92,
                  liable: true, // chargeback
                  charge_processing_fee: false, // taxas
                },
              ],
            }),
          )
          .then(transaction =>
            action.asyncDispatch({
              type: 'M9_TransData',
              value: transaction,
            }),
          )
          .catch(error =>
            action.asyncDispatch({ type: 'M9_TransError', value: error }),
          );

        return connectApi;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    // --- set Transaction SUCCESS
    M9_SetTransaction_SUCCESS: () => {
      return state;
    },

    M9_TransData: () => {
      const transactionData = action.value;
      const condPayError = transactionData === undefined;
      const status = transactionData?.status;

      if (status === 'refused') {
        action.asyncDispatch({ type: 'M9_BillingToOrder_Error', status });
      } else action.asyncDispatch({ type: 'M9_BillingToOrder' });

      return {
        ...state,
        M9: {
          ...state.M9,
          transactionData,
          condPayError,
          condList: true,
        },
      };
    },

    // --- set Transaction ERROR
    M9_TransError: () => {
      const transactionData = action.value;
      const condPayError = transactionData.status !== 200;
      action.asyncDispatch({ type: 'M9_BillingToOrder_Error' });

      return {
        ...state,
        M9: {
          ...state.M9,
          transactionData,
          condPayError,
          condList: true,
        },
      };
    },

    // --- Ipt Changes
    M9_IptValues: () => {
      const itemLabel = `${action.field}_label`;
      const condLabel = action.label ? { [itemLabel]: action.label } : {};

      // ---------- set Validation
      const value = action.value;
      const condEmpty = value === '';

      return {
        ...state,
        M9: {
          ...state.M9,

          forms: {
            ...state.M9.forms,
            iptsChanges: {
              ...state.M9.forms.iptsChanges,
              [action.field]: action.value,
              ...condLabel,
            },
            msgs: {
              ...state.M9.forms.msgs,
              [action.field]: condEmpty,
            },
          },
        },
      };
    },
  };

  const updates = {
    M9_BillingToOrder: () => {
      const asyncFn = async () => {
        const orderId = state?.M7e?.currOrder?.docId;
        const transactionData = state?.M9?.transactionData;
        const deliveryData = state?.M8?.deliveryData;
        const withdraw = state?.M8?.withdraw;
        const deliveryType = withdraw ? 'Retirada no Local' : 'Delivery';

        const dataToAdd = {
          statusOrder: 'Pagamento Realizado',
          deliveryData: { ...deliveryData, type: deliveryType },
          billingData: {
            status: transactionData.status,
            transaction_id: transactionData.id,
            paid_amount: transactionData.paid_amount,
            card_brand: transactionData.card_brand,
            card_first_digits: transactionData.card_first_digits,
            card_last_digits: transactionData.card_last_digits,
            date_created: transactionData.date_created,
          },
        };

        // ---------- set Async Call
        let dataSuccess;
        const dbRef = firestore.collection('orders').doc(orderId);

        // ------ return SUCCESS
        dataSuccess = await dbRef.update(dataToAdd);
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M9_BillingToOrder_SUCCESS: () => {
      action.asyncDispatch({ type: 'base_setRoute', value: 'purchasedList' });

      return state;
    },

    M9_BillingToOrder_Error: () => {
      const asyncFn = async () => {
        const orderId = state?.M7e?.currOrder?.docId;
        const recipient_id = state?.M7e?.shopInfo?.recipientId;
        const transactionData = state?.M9?.transactionData;
        const status =
          action?.status === 'refused'
            ? 'Pagamento Recusado'
            : 'Erro no Pagamento';

        const dataToAdd = {
          statusOrder: status,
          billingData: {
            recipient_id,
            ...transactionData,
          },
        };

        // ---------- set Async Call
        let dataSuccess;
        const dbRef = firestore.collection('orders').doc(orderId);

        // ------ return SUCCESS
        dataSuccess = await dbRef.update(dataToAdd);
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M9_BillingToOrder_Error_SUCCESS: () => {
      return state;
    },
  };

  return {
    ...inits,
    ...forms,
    ...updates,
  };
};

// ---------- set Exports
export default reducers;
