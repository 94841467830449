/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, Image, Text, TouchableOpacity, ScrollView } from 'react-native';

// ----------- import Internals
import NavBar from '../../../comps/App/NavBar';
import { useStl, UseIcoMoon, useData } from '../../../../useMorfos';
import src3a from '../../../images/banner.svg';
import src3 from '../../../../../src/project/images/call-center.svg';
import src4 from '../../../../../src/project/images/fragile.svg';
import src6 from '../../../../../src/project/images/credit-card.svg';
import { mountCurrency } from './helpers';

export const ItemProdView = ({ infoData }) => {
  // ---------- set Params
  const { name, currency, condThumb, amount } = infoData;

  // ---------- set Return
  return (
    <View style={stlProdLine}>
      <View style={stlBoxImg}>
        <Image style={stlThumb} source={condThumb} />
      </View>
      <View style={stlFlex4}>
        <Text style={stlTxtTotal}>{currency}</Text>

        <Text style={stlTxtName}>{name}</Text>
      </View>
      <View style={stlFlex1}>
        <Text style={stlTxtAmnt}>{amount}</Text>
        <Text style={stlTxtName2}>Qtd.</Text>
      </View>
    </View>
  );
};

export const OrderStatusView = ({ infoData }) => {
  // ---------- set Params
  const {
    paid,
    shipping,
    delivered,
    refused,
    confirmed,
    waiting,
    cancel,
    totalPrice,
    condAdjust,
    btnPayment,
    btnDelivered,
    btnAdjust,
    btnCancel,
  } = infoData;

  const viewPrice = mountCurrency(totalPrice);
  const shopPhone = useData('M7e.shopInfo.phone');
  const goToUrl = () => {
    window.open(`http://wa.me/${shopPhone}`);
  };

  const Waiting = () => (
    <View style={[stlCardMask, { marginBottom: 25 }]}>
      <View style={{ padding: 20 }}>
        <View style={stlRow}>
          <Image source={src3} style={stlImg4} />
          <Text style={stlTxtStatus}>
            Aguarde até que o mercado confirme seu pedido
          </Text>
        </View>
      </View>
      <View style={stlStsBtns}>
        <TouchableOpacity style={stlBtnConfirm} onPress={btnCancel}>
          <Text style={{ fontSize: 11 }}>Quero Cancelar</Text>
        </TouchableOpacity>
      </View>
      {condAdjust && (
        <View style={[stlWhatsapp, { paddingTop: 25 }]}>
          <View style={stlBorderLine2}>
            <Text style={stltexCard7}>
              Caso queira voce pode ligar para o mercado no telefone:{shopPhone}
              .{' '}
              <TouchableOpacity onPress={goToUrl}>
                Clique aqui para ver se este número tem Whatsapp
              </TouchableOpacity>
            </Text>
          </View>
        </View>
      )}
    </View>
  );
  const Checkout = () => (
    <View style={stlCardMask}>
      <View style={[stlBorderLine, { height: 70 }]}>
        <Image source={src6} style={stlImg4} />
        <Text style={stlTxtStatus}>
          O mercado confirmou seu Pedido. Informe o pagamento.
        </Text>
      </View>
      <View style={stlBorderLine}>
        <View style={stlCard5}>
          <Text style={stlTxtName}>Valor Total</Text>
          <Text style={stlTxtTotal}>{viewPrice}</Text>
        </View>
        <View>
          <TouchableOpacity style={stlBtnPrimary1} onPress={btnPayment}>
            <Text style={stlTxtInverse}>PAGAR</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={stlNoLine}>
        <View style={stlBorderLine1}>
          <Text style={stltexCard5}>
            Gostaria de pedir um ajuste nos itens abaixo?
          </Text>
        </View>

        <TouchableOpacity onPress={btnAdjust}>
          <Text style={stltexCard6}>PEDIR AJUSTE</Text>
        </TouchableOpacity>
      </View>
      {condAdjust && (
        <View style={stlWhatsapp}>
          <View style={stlBorderLine2}>
            <Text style={stltexCard7}>
              Caso precise você pode entrar em contato com o Supermercado pelo
              telefone: {shopPhone}.
              <TouchableOpacity onPress={goToUrl}>
                Clique aqui para ver se este supermercado tem whatsapp
              </TouchableOpacity>
            </Text>
          </View>
        </View>
      )}
      <View style={stlStsBtns}>
        <TouchableOpacity style={stlBtnConfirm} onPress={btnCancel}>
          <Text style={{ fontSize: 11 }}>Quero Cancelar</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
  const Shipping = () => (
    <View style={[stlCard, useStl.flexCenter]}>
      <Text style={stlTxtName}>Última Compra</Text>
      <Text style={stlTxtTit}>{viewPrice}</Text>
      <TouchableOpacity style={stlBtn3} onPress={btnDelivered}>
        <Text style={stlTxtInverse}>COMPRA RECEBIDA</Text>
      </TouchableOpacity>
    </View>
  );
  const Cancel = () => (
    <View style={stlCard}>
      <View style={stlRow}>
        <Image source={src4} style={stlImg4} />
        <Text style={stlTxtStatus}>Seu pedido foi cancelado</Text>
      </View>
    </View>
  );
  const Refused = () => (
    <View style={stlCard}>
      <View style={stlRow}>
        <Image source={src4} style={stlImg4} />
        <Text style={stlTxtStatus}>O Pagamento foi Recusado.</Text>
      </View>
    </View>
  );
  const Delivered = () => <></>;
  const Paid = () => <></>;

  // ---------- set Return
  if (waiting) {
    return <Waiting />;
  }
  if (cancel) {
    return <Cancel />;
  }
  if (confirmed) {
    return <Checkout />;
  }
  if (paid) {
    return <Paid />;
  }
  if (shipping) {
    return <Shipping />;
  }
  if (delivered) {
    return <Delivered />;
  }
  if (refused) {
    return <Refused />;
  }
};

export const ListProdsView = ({ info, children }) => {
  return (
    <>
      <View style={[stlBetween, { marginHorizontal: 20 }]}>
        <Text style={stlTxtSmall}>Itens do Pedido:</Text>
        <Text style={stlTxtSmall}>{info.currency}</Text>
      </View>
      <View style={[stlCard]}>{children}</View>
    </>
  );
};

export default ({ info, children }) => {
  return (
    <>
      <NavBar />
      <ScrollView style={stlScreen}>
        <View style={stlBanner}>
          <Image source={src3a} style={stlBoxImga} />
        </View>
        <View style={stlBodyView}>
          <View style={[stlCard, stlCenter, stlTopUp]}>
            <Image source={info.condThumb} style={stlImg2} />
            <Text style={stlTitle}>{info.name}</Text>
            <Text style={stlTitle2}>{info.details}</Text>
            {/* <Text style={stlTitle3}>Total de Avaliações</Text> */}
            {/* <View style={stlRating}>
              <UseIcoMoon name="star" size={11} color="green" />
              <UseIcoMoon name="star" size={11} color="green" />
              <UseIcoMoon name="star" size={11} color="green" />
              <UseIcoMoon name="star" size={11} color="green" />
              <UseIcoMoon name="star" size={11} color="grey" />
              <Text style={stltexAva}>4,5</Text>
            </View> */}
            <View style={stlAddress}>
              <UseIcoMoon name="pin" size={11} color="gray" />
              <Text style={stlTxtAddress}>{info.address}</Text>
            </View>
          </View>
          {children}
        </View>
      </ScrollView>
    </>
  );
};

// #region :: STYLEs *********

// --- Ajustes
const stlScreen = { backgroundColor: '#f6f6f6' };
const stlBodyView = [useStl.pad20];
const stlCard = [useStl.card, { borderRadius: 10, marginHorizontal: 10 }];
const stlCardMask = [
  useStl.cardMask,
  {
    marginBottom: 10,
    marginHorizontal: 10,
  },
];
const stlFlex1 = [
  useStl.flexCenter,
  useStl.flex1,
  useStl.border(0, 0, 0, 1, '#ddd'),
];
const stlFlex4 = [useStl.flex4];
const stlBoxImga = {
  width: '100%',
  height: 350,
};
const stlCenter = [useStl.flexCenter];
const stlTopUp = { marginTop: -209 };
const stlBanner = [
  {
    overflow: 'hidden',
    width: '100%',
    height: 260,
  },
];
const stlBoxImg = [useStl.thumbnail];
const stlThumb = [useStl.imgFull];
const stlRow = [useStl.flexRow];
const stlBetween = [stlRow, useStl.flexBetween];
const stlProdLine = [stlRow, { marginBottom: 16 }];

const stlTxtTotal = {
  fontSize: 12,
  fontWeight: 600,
};
const stlTxtName = [
  {
    fontSize: 11,
    fontWeight: 400,
    color: 'gray',
  },
];
const stlTxtName2 = [
  {
    fontSize: 10,
    fontWeight: 400,
    color: '#999',
  },
];
const stlTxtAmnt = [
  {
    fontSize: 16,
    fontWeight: 600,
    color: '#444',
  },
];
const stlTxtSmall = [{ fontSize: 12, fontWeight: 600, marginBottom: 3 }];
const stlTxtStatus = {
  fontSize: 14,
  fontWeight: 600,
  color: '#444',
  marginLeft: 5,
};
const stlTxtTit = {
  fontSize: 18,
  fontWeight: 700,
  color: '#393c3a',
};
const stlTxtInverse = [
  useStl.txtPrimaryInverse,
  { fontSize: 9, fontWeight: 600 },
];
const stlTxtAddress = [
  {
    marginLeft: 5,
    fontSize: 11,
    fontWeight: 400,
    color: '#999',
  },
];
const stlTitle = [{ fontSize: 27, fontWeight: 700, textAlign: 'center' }];
const stlTitle2 = [
  {
    width: '80%',
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 600,
    color: 'gray',
  },
];
const stlTitle3 = [
  {
    marginTop: 15,
    textAlign: 'center',
    fontSize: 9,
    fontWeight: 500,
    color: '#ccc',
  },
];
const stltexAva = [
  { margintop: -10, marginLeft: 3, fontSize: 10, fontWeight: 700 },
];
const stltexCard5 = [{ fontSize: 10, fontWeight: 600, color: 'gray' }];
const stltexCard6 = [
  { marginRight: 20, fontSize: 10, fontWeight: 700, color: '#6DC211' },
];
const stltexCard7 = [{ fontSize: 11, fontWeight: 600, color: '#9fa5a2' }];

const stlBtnMedium = [useStl.btn, useStl.btnMedium];
const stlBtnLarge = [useStl.btn, useStl.btnLarge];
const stlBtn3 = [stlBtnLarge, useStl.btnPrimary, { marginTop: 10 }];
const stlAddress = [
  useStl.flexRow,
  useStl.border(1, 1, 1, 1, '#ddd'),
  {
    height: 20,
    marginVertical: 10,
    paddingHorizontal: 6,
    borderRadius: 4,
  },
];

const stlImg2 = {
  width: 160,
  height: 120,
  borderRadius: 8,
  marginBottom: 10,
};
const stlImg4 = [{ marginHorizontal: 10, width: 50, height: 50 }];

//CARD
const stlRating = [useStl.flexRow, useStl.flexBetween];
const stlCard5 = [useStl.flex];
const stlBorderLine = [
  useStl.flexRow,
  useStl.flexBetween,
  useStl.border(0, 0, 1, 0, '#ddd'),
  { height: 60, padding: 20 },
];
const stlNoLine = [
  useStl.flexRow,
  useStl.flexBetween,
  { height: 60, padding: 20 },
];
const stlBorderLine1 = [useStl.flex, { width: '60%' }];

const stlWhatsapp = [stlCenter];
const stlBorderLine2 = [
  {
    borderRadius: 5,
    width: '90%',
    backgroundColor: '#F3F5F4',
    textAlign: 'center',
    padding: 5,
    marginBottom: 20,
  },
];

// BTNs
const stlStsBtns = [
  useStl.flexEnd,
  useStl.border(1, 0, 0, 0, '#eee'),
  {
    backgroundColor: '#fcfcfc',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
];
const stlBtnPrimary1 = [stlBtnMedium, useStl.btnPrimary];
const stlBtnConfirm = [useStl.btn, useStl.btnXSmall, { height: 17 }];
const stl01Tmp = [
  stlCenter,
  useStl.mgB20,
  {
    position: 'fixed',
    bottom: 0,
    right: 10,
    zIndex: 1000,
  },
];
const fab = [useStl.btn, { marginBottom: 10, padding: 4, width: 100 }];
const stl02Tmp = [fab, useStl.btnSecondary];
const stl02aTmp = [fab, { backgroundColor: 'darkgreen' }];
const stl02bTmp = [fab, { backgroundColor: '#cc9834' }];
const stl03Tmp = [
  {
    color: '#777',
    width: 80,
    textAlign: 'right',
    textWeight: 700,
    fontSize: 11,
    fontStyle: 'italic',
    marginBottom: 3,
    position: 'fixed',
    bottom: 140,
    right: 10,
  },
];
// #endregion *********
