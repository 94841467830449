// ----------- import Internals
import { useRouter, useData } from '../../../useMorfos';

// ----------- set Group
export default ({ children }) => {
  const { callRedirect } = useRouter();
  const userPermission = useData(`baseAuthUser.typeAccount`);
  const condApp = userPermission === 'app1';

  const condReturn = condApp ? children : callRedirect('bookletList');

  return condReturn;
};
