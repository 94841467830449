// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import { UseInitData, UseCondLoader, UseList } from '../../../../../useMorfos';
import { ItemList, NoItemView } from '../../../../comps';

// ----------- set Info Screen
export const infoSc = {
  path: 'requestOrderList',
  groupSc: 'shop1',
  condBigSc: true,
  layout: {
    icon: 'cart',
    title: 'Pedidos',
    desc: 'Lista de Pedidos',
  },

  scCode: 'S1b',
};

// ----------- set Default Component
export default () => {
  // ----------- set Return
  const infoItem = {
    itemContent: 'S1b.scContent',
    itemsInfo: 'S1b.itemsInfo',
    pressItem: 'S1b_EditConfirm',
  };

  return (
    <UseInitData reducer={'S1b_InitData'}>
      <ViewDF>
        <UseCondLoader data={'S1b.condList'}>
          <UseList data={'S1b.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemList infoData={{ ...infoItem, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
