/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF, { DaysView, HoursView } from './Views';
import {
  useRouter,
  UseInitData,
  useData,
  UseList,
} from '../../../../useMorfos';
import { useDispatch } from 'react-redux';
import { NoItemView } from '../../../comps';
import { ezLog } from '../../../../useMorfos/utils';

// ----------- set Info Screen
export const infoSc = {
  path: 'schedule',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: 'Tipo de Entrega',
  },

  scCode: 'M8',
};

// ----------- set Default Component
export default () => {
  const scM7 = useData('M7');
  const { callRedirect } = useRouter();

  // const condReturn = !scM7 ? callRedirect('home') : <ScM8 />;
  const condReturn = <ScM8 />;

  return condReturn;
};

const ScM8 = () => {
  // ----------- set Data
  const content = useData('M8.scContent');

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Conds
  const condPrice = useData('M8.deliveryPrice');
  const condMsg = useData('M8.msgError');
  const condShow = useData('M8.withdraw');

  // ----------- set Routes
  const btnDelive = () => dispatch({ type: 'M8_BtnConclude' });

  // ----------- set Return
  const infoView = { content, btnDelive, condPrice, condMsg };

  return (
    <UseInitData reducer={'M8_InitData'}>
      <ViewDF info={infoView}>{condShow === false && <DaysComp />}</ViewDF>
    </UseInitData>
  );
};

const DaysComp = () => {
  const labelDate = useData('M8.itemsInfo');
  return (
    <UseList data={'M8.itemsList'}>
      {(itemId, noItem) =>
        noItem ? (
          <NoItemView />
        ) : (
          <DaysView
            infoData={{
              HoursComp,
              labelDate,
              itemId,
            }}
          />
        )
      }
    </UseList>
  );
};

const HoursComp = ({ infoData }) => {
  // ---------- set Data
  const { dayId } = infoData;
  const dataHour = useData('M8.itemsInfo', dayId);
  const currDay = useData('M8.deliveryData.dayLabel');

  return (
    <UseList data={`M8.itemsInfo.${dayId}.itemsList`}>
      {(itemId, noItem) =>
        noItem ? (
          <NoItemView />
        ) : (
          <HoursView
            infoData={{
              dayId,
              dataHour,
              itemId,
              currDay,
            }}
          />
        )
      }
    </UseList>
  );
};
