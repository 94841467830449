// ---------- import Internals

// ---------- set Reducers
export default (state, action) => {
  return {
    // ---------- set Init Content Data
    A2_InitData: () => {
      const scContent = {
        title: 'Termos de Uso',
        subTitle: 'Oi Mundo!',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum luctus metus ut dui bibendum lobortis. Curabitur odio nisi, laoreet et consectetur id, fringilla eu mi. Phasellus eget dolor porttitor, scelerisque arcu eget, finibus nulla. Donec pharetra odio vel ex efficitur vulputate. Aenean fringilla feugiat sapien, nec feugiat ligula faucibus eu. Donec quis condimentum eros. Nam aliquam massa sed ultricies dictum.',
        txtBtn: 'Ir para Example',
      };

      const condData = true;

      return {
        ...state,
        A2: { ...state.A2, scContent, condData },
      };
    },
  };
};
