// ---------- import Internals
// import { firestore } from '../../../../../config/firebase/fbConfig';

// ---------- set Reducers
const reducers = (state, action) => ({
  // ---------- set Init Data _Name_
  D1_InitContentData: () => {
    const scContent = {
      title: 'Signin',
      subTitle: 'Oi Mundo!',
      description: 'Lorem Ipsum!',
      txtBtn: 'Ir para os TERMOS',
    };

    const condData = true;

    return {
      ...state,
      D1: { ...state.D1, scContent, condData },
    };
  },
});

// ---------- set Exports
export default reducers;
