// ---------- import Internals
import { firestore } from '../../../../config/firebase/fbConfig';
import { asyncRefs, ezLog } from '../../../../useMorfos/utils';
const geofire = require('geofire-common');

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    M6_InitData: () => {
      // ---------- set SEARCH SHOPS IN REACH
      action.asyncDispatch({ type: 'M6_GET_Range' });

      return state;
    },
  };

  const gets = {
    // ---------- set GET SHOPS IN REACH
    M6_GET_Range: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Ref DB (GET ALL SHOPS IN DB)
        const refDb = firestore.collection('shops');
        let dataSuccess = refDb.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        M6: { ...state.M6 },
      };
    },

    M6_GET_Range_SUCCESS: () => {
      // ---------- set Data to Show
      const userCoords = state.baseAuthUser.address.geolocation;
      const dataDb = action.value;
      const itemsInfo = {};
      const itemsList = [];
      const matchingDocs = [];

      dataDb.forEach(doc => {
        const item = doc.data();
        const lat =
          item.address.geolocation._lat ?? item.address.geolocation.x_;
        const lng =
          item.address.geolocation._long ?? item.address.geolocation.N_;
        const radiuskm = item.address.range.radius;

        const user = [userCoords.latitude, userCoords.longitude];
        const radiusInM = radiuskm * 1000;

        // Filter out a few false positives
        const distanceInKm = geofire.distanceBetween([lat, lng], user);
        const distanceInM = distanceInKm * 1000;

        if (distanceInM <= radiusInM) {
          matchingDocs.push(doc.data());
        }
      });

      // ----- set Filter Account Suspended
      for (const key in matchingDocs) {
        const itemMatch = matchingDocs[key];
        const matchId = itemMatch?.docId;
        const accStatus = itemMatch?.accountStatus;
        const accAdmStatus = itemMatch?.admStatus;
        const condProdStatus = accStatus !== 'suspended';
        const condAdmStatus = accAdmStatus !== 'suspended';

        if (condProdStatus) {
          if (condAdmStatus) {
            itemsInfo[matchId] = {
              name: itemMatch?.name,
              imgUrl: itemMatch?.imgUrl,
              settings: itemMatch?.settings,
            };
            itemsList.push(matchId);
          }
        }
      }

      action.asyncDispatch({ type: 'M6_GET_ShopProds' });

      return {
        ...state,
        M6: {
          ...state.M6,
          shopsInReach: {
            condList: true,
            itemsInfo,
            itemsList,
          },
        },
      };
    },

    // ---------- set GET SHOP PRODS
    M6_GET_ShopProds: () => {
      const rangeShops = state?.M6?.shopsInReach.itemsList;

      const asyncFn = async () => {
        const itemRefs = rangeShops.map(id => {
          return firestore
            .collection('shops')
            .doc(id)
            .collection('prods')
            .get();
        });
        const finalRes = Promise.all(itemRefs);

        return finalRes;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        M6: { ...state.M6 },
      };
    },

    M6_GET_ShopProds_SUCCESS: () => {
      // ---------- set Data
      const dataDb = action.value;

      const itemsInfo = {};
      const arrIdsBaseProds = [];
      let itemsList;
      let idx = 0;

      dataDb.forEach(docShop => {
        for (const item of docShop.docs) {
          idx = idx + 1;
          const currProd = item.data();
          itemsInfo[`${currProd.prodId}_${idx}`] = currProd;
          arrIdsBaseProds.push(currProd.prodId);
        }
      });
      itemsList = [...Object.keys(itemsInfo)];

      action.asyncDispatch({ type: 'M6_MAKE_VarsGroup' });
      return {
        ...state,
        M6: {
          ...state.M6,
          arrIdsBaseProds,
          allShopProds: {
            itemsInfo,
            itemsList,
          },
        },
      };
    },

    // ---------- set MAKE _VarsGroup
    M6_MAKE_VarsGroup: () => {
      const allShopProds = state?.M6?.arrIdsBaseProds;

      // ---------- set GET Base Prods (Apenas Prods de ShopsInReach)
      const asyncFn = async () => {
        const itemRefs = allShopProds.map(id => {
          return firestore.collection('prods').doc(id).get();
        });
        const finalRes = Promise.all(itemRefs);
        return finalRes;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        M6: { ...state.M6 },
      };
    },

    M6_MAKE_VarsGroup_SUCCESS: () => {
      action.asyncDispatch({ type: 'M6_InitForm' });

      // ---------- set Data to Show
      const dataBaseProds = action.value;
      const dataShopsInfo = state?.M6?.allShopProds.itemsInfo;
      const itemsInfo = {};
      const baseProds = {};
      let initNum = 0;

      // ---------- set All ProdsBase
      dataBaseProds.forEach(docProds => {
        const item = docProds.data();
        baseProds[item.docId] = item;
      });

      // ---------- set Merge ProdsBase with ShopProd Data
      for (const keyShop in dataShopsInfo) {
        const item = dataShopsInfo[keyShop];
        const idx = initNum;
        initNum = initNum + 1;

        // ---------- laço do produto
        const prodShop = item;
        const prodShopVars = prodShop.variations;
        for (const key in prodShopVars) {
          // ---------- laço das vars do produto
          const currVar = prodShopVars[key];
          const uniqueIdProds = `${prodShop.prodId}_${currVar.typeId}_${idx}`;
          const currProdBase = baseProds[prodShop?.prodId];
          const prodImg = currProdBase[`imgUrl${prodShop.imgSelected}`];
          const currVarBase = currProdBase.variations[currVar.typeId];

          // montar obj separando produtos por var e shop
          if (currVar.price > 0) {
            itemsInfo[uniqueIdProds] = {
              dataShops: {
                [`${prodShop.shopId}`]: {
                  shopId: prodShop.shopId,
                  prodId: prodShop.prodId,
                  prodShopId: prodShop.docId,
                  idVariation: currVar.typeId,
                  shopPrice: Number(currVar.price),
                },
              },
              dataProd: {
                varName: currVarBase.typeName_label,
                varValue: currVarBase.typeValue,
                idVar: currVar.typeId,
                prodId: prodShop.prodId,
                img: prodImg,
                avgPrice: 0,
                name: currProdBase.name,
                categ: currProdBase.categ,
                subCateg: currProdBase.subCateg,
              },
            };
          }
        }
      }

      const itemsList = [...Object.keys(itemsInfo)];

      // ---------- set Average Prices
      let avgPrice = {};
      for (const key1 in itemsInfo) {
        const item1 = itemsInfo[key1];
        const dataShops = item1.dataShops;
        const dataProd = item1.dataProd;

        for (const keyShop in dataShops) {
          const itemShop = dataShops[keyShop];

          // ---------- alimentar avgPrice organizando arrays por prod e var
          const idVariation = itemShop.idVariation;
          const prevPrices =
            avgPrice[dataProd.prodId] && avgPrice[dataProd.prodId][idVariation];
          const condPrev = prevPrices !== undefined ? prevPrices : [];

          avgPrice[dataProd.prodId] = {
            ...avgPrice[dataProd.prodId],
            [idVariation]: [...condPrev, itemShop.shopPrice],
          };
        }
      }

      // ---------- set Merge avgPrice with respective dataProd.avgPrice
      for (const key1 in itemsInfo) {
        const item1 = itemsInfo[key1];
        const dataShops = item1.dataShops;
        const dataProd = item1.dataProd;

        for (const keyShop in dataShops) {
          const itemShop = dataShops[keyShop];
          const currAvgPrc = avgPrice[dataProd.prodId][itemShop.idVariation];

          const priceSum = currAvgPrc.reduce((prev, crr) => prev + crr, 0);
          dataProd.avgPrice = priceSum / currAvgPrc.length;
        }
      }

      return {
        ...state,
        M6: {
          ...state.M6,
          mergedVars: {
            condList: true,
            itemsList,
            itemsInfo,
          },
        },
      };
    },
  };

  const edits = {
    M6_BtnControlCart: () => {
      // const itemsList
      // ---------- set Data
      const { docId, amountProd } = action.infoProd;
      const currScreen = state?.baseRoute?.currScInfo?.scKey;
      ezLog({ currScreen });

      // ---------- set ADD Prod in Cart
      const addedProds = {
        ...state.basePersist.cart.addedProds,
        [docId]: { id: docId, amountProd },
      };

      // ---------- set DEL Prods in Cart
      const currProd = addedProds[docId];
      const currAmount = currProd.amountProd;
      const condRemove = currAmount === 0;
      for (const key in addedProds) {
        const item = addedProds[key];
        const condRemove2 = item.amountProd === 0;

        condRemove2 && delete addedProds[key];
      }

      // ---------- update Total Amount
      const totalAmount = Object.keys(addedProds).length;

      // ---------- update Render
      // Home (não some, não da erro)
      // Cart render: M6.itemsList (ao zerar não some) ********
      // OrderEdit (não some, não da erro)
      // OrderList render: M7a.allProds.itemsList ********
      // OrderFinal render: M7c.finalProds.itemsList ********
      const delItemList = condObj => {
        const newArr = [...condObj];

        for (const key in condObj) {
          const prodId = condObj[key];
          const condId = prodId === currProd?.id;
          const condSnap = condRemove && condId;
          condSnap && newArr.splice(key, 1);
        }

        return [...newArr];
      };

      if (currScreen === 'M6') {
        const oldList = state?.M6?.itemsList;
        const newList = delItemList(oldList);

        return {
          ...state,
          M6: {
            ...state?.M6,
            itemsList: newList,
          },
          basePersist: {
            ...state.basePersist,
            cart: {
              addedProds,
              totalAmount,
            },
          },
        };
      }
      if (currScreen === 'M7a') {
        const oldList = state?.M7a?.allProds?.itemsList;
        const newList = delItemList(oldList);

        return {
          ...state,
          M7a: {
            ...state?.M7a,
            allProds: {
              ...state?.M7a?.allProds,
              itemsList: newList,
            },
          },
          basePersist: {
            ...state.basePersist,
            cart: {
              addedProds,
              totalAmount,
            },
          },
        };
      }
      if (currScreen === 'M7c') {
        const oldList = state?.M7c?.finalProds?.itemsList;
        const newList = delItemList(oldList);

        return {
          ...state,
          M7c: {
            ...state?.M7c,
            itemsList: newList,
          },
          basePersist: {
            ...state.basePersist,
            cart: {
              addedProds,
              totalAmount,
            },
          },
        };
      }

      return {
        ...state,
        basePersist: {
          ...state.basePersist,
          cart: {
            addedProds,
            totalAmount,
          },
        },
      };
    },

    M6_ConfirmClear: () => {
      return {
        ...state,
        M6: {
          ...state.M6,
          confirmClear: true,
        },
      };
    },

    M6_BtnCancelCart: () => {
      return {
        ...state,
        M6: {
          ...state.M6,
          confirmClear: false,
        },
      };
    },

    M6_BtnClearCart: () => {
      return {
        ...state,
        M10: {
          ...state?.M10,
          msgCart: false,
        },
        M6: {
          ...state.M6,
          itemsList: [],
          confirmClear: false,
        },
        basePersist: {
          ...state.basePersist,
          cart: {
            addedProds: {},
            totalAmount: 0,
          },
        },
      };
    },
  };

  const forms = {
    // ---------- set Init Data _Name_
    M6_InitForm: () => {
      const addedProds = state?.basePersist?.cart?.addedProds;
      const mergedVars = state?.M6?.mergedVars?.itemsInfo;
      const itemsInfo = {};
      const itemsList = [];

      const resObj = {};
      const resList = [];
      for (const key in mergedVars) {
        const item = mergedVars[key];
        const dataProd = item.dataProd;

        resList.push(String(key));
        resObj[key] = dataProd;
      }

      // ---------- set Filter Vars
      for (const key in resObj) {
        const item = resObj[key];

        const prodId = item.prodId;
        const idVar = item.idVar;
        item.prodIdVar = `${prodId}_${idVar}`;

        itemsInfo[`${prodId}_${idVar}`] = { ...item, renderItem: true };
      }

      for (const key in addedProds) {
        itemsList.push(key);
      }

      return {
        ...state,
        M6: {
          ...state.M6,
          condList: true,
          itemsInfo,
          itemsList,
        },
      };
    },
  };

  return {
    ...inits,
    ...gets,
    ...edits,
    ...forms,
  };
};

// ---------- set Exports
export default reducers;
