// ---------- import Internals
import { firebase, firestore } from '../../../../../config/firebase/fbConfig';
import { asyncRefs } from '../../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    S1b_InitData: () => {
      const scContent = {
        // --- ItemList

        status1: 'Adicionar',
        status2: 'Adicionado',
      };

      action.asyncDispatch({ type: 'S1b_GET_Orders' });

      return {
        ...state,
        S1b: {
          ...state.S1b,
          scContent,
          condList: false,
          // itemsInfo: {},
          // itemsList: [],
        },
      };
    },
  };

  const gets = {
    S1b_GET_Orders: () => {
      // ---------- set Async Function1
      const asyncBase = async () => {
        const shopId = state.baseAuthUser.shopId;
        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('orders')
          .where('shopId', '==', shopId)
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S1b_GET_Orders_SUCCESS: () => {
      action.asyncDispatch({ type: 'S1b_GET_Users' });
      // ---------- set Data to Show
      const dataDb = action.value;

      const objBuyer = {};
      let buyArr = [];
      const itemsInfo = {};
      const itemsList = [];
      dataDb.forEach(doc => {
        const buyerId = doc.data().buyerId;
        itemsInfo[doc.id] = doc.data();
        itemsList.push(doc.id);
        objBuyer[buyerId] = buyerId;
      });

      buyArr = [...Object.values(objBuyer)];

      return {
        ...state,
        S1b: {
          ...state.S1b,
          buyArr,
          orders: {
            itemsInfo,
            itemsList,
          },
          condList: false,
        },
      };
    },

    S1b_GET_Users: () => {
      const buyArray = state.S1b.buyArr;

      function getContentById(ids, path) {
        return new Promise(res => {
          // don't run if there aren't any ids or a path for the collection
          if (!ids || !ids.length || !path) return res([]);

          const collectionPath = firestore.collection(path);
          let batches = [];

          while (ids.length) {
            // firestore limits batches to 10
            const batch = ids.splice(0, 10);

            // add the batch request to to a queue
            batches.push(
              new Promise(response => {
                collectionPath
                  .where(firebase.firestore.FieldPath.documentId(), 'in', [
                    ...batch,
                  ])
                  .get()
                  .then(results =>
                    response(
                      results.docs.map(result => ({ ...result.data() })),
                    ),
                  );
              }),
            );
          }

          // after all of the data is fetched, return it
          Promise.all(batches).then(content => {
            res(content.flat());
          });
        });
      }

      const testeFn = async () => await getContentById(buyArray, 'users');

      // ---------- set Async References
      const ref = asyncRefs(action, testeFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S1b_GET_Users_SUCCESS: () => {
      // ---------- set Data
      const dataDb = action.value;
      const orders = state.S1b.orders.itemsInfo;

      // ---------- set Containers
      const itemsInfo = {};
      const itemsList = [];
      const listaOrdenada = [];
      const prodsIds = [];

      // ---------- set Loops
      for (const key in orders) {
        const item = orders[key];
        const buyerId = item.buyerId;
        listaOrdenada.push(item);

        item.arrProds.map(prod => prodsIds.push(prod.id));

        dataDb.forEach(doc => {
          const docId = doc.docId;
          const createdAt = doc.createdAt;
          const imgUrl = doc.imgUrl;
          const email = doc.email;
          const name = doc.name;
          const token = doc.msgToken;
          const condSnap = buyerId === docId;
          if (condSnap) {
            itemsInfo[key] = { imgUrl, email, name, token, createdAt, ...item };
          }
        });
      }
      listaOrdenada.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
      listaOrdenada.map(order => itemsList.push(order.docId));

      return {
        ...state,
        S1b: {
          ...state.S1b,
          itemsInfo,
          itemsList,
          prodsIds,
          condList: true,
        },
      };
    },
  };

  const edits = {
    S1b_EditConfirm: () => {
      const itemId = action.itemId;
      const currShopInfo = state?.S1c?.currShopInfo;
      const itemsInfo = state?.S1c?.ItemInfo;
      const condGetInfo = currShopInfo && itemsInfo;
      action.asyncDispatch({ type: 'base_setRoute', value: 'orderPf' });
      !condGetInfo
        ? action.asyncDispatch({ type: 'S1c_GET_ShopProds' })
        : action.asyncDispatch({ type: 'S1c_GET_Infos' });

      return {
        ...state,
        S1c: {
          ...state.S1c,
          condList: false,
        },
        S1b: {
          ...state.S1b,
          edit: itemId,
        },
      };
    },
  };

  return {
    ...gets,
    ...inits,
    ...edits,
  };
};

// ---------- set Exports
export default reducers;
