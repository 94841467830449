// ----------- import Internals
import { useDispatch } from 'react-redux';
import { firestore } from '../../../config/firebase/fbConfig';
import { useData } from '../../../useMorfos';

// ----------- set Group
export default ({ children }) => {
  const dispatch = useDispatch();
  const shopId = useData(`baseAuthUser.shopId`);

  const checkShop = async () => {
    const refDb = await firestore.collection('shops').doc(shopId).get();

    let status;
    const shopData = await refDb.data();
    if (shopData) {
      status = shopData?.admStatus;
      const itsSuspended = status === 'suspended';

      itsSuspended && dispatch({ type: 'comps_BlockAccount' });
    }
  };
  checkShop();

  return children;
};
