// ----------- import Packs
import React from 'react';
import { View, ScrollView, Text } from 'react-native';

// ----------- import Internals
import { useStl } from '../../../../useMorfos';
import { AlertBox, NavBar } from '../../../comps';

export const TotalPriceView = ({ info }) => {
  return <Text style={{ color: '#fff', fontWeight: 700 }}>{info}</Text>;
};

const MsgError = () => (
  <View style={val3}>
    <View style={stlRow}>
      <Text style={val2}>Insira Número de telefone.</Text>
    </View>
  </View>
);

const MsgDDDError = () => (
  <View style={val3}>
    <View style={stlRow}>
      <Text style={val2}>Insira com DDD.</Text>
    </View>
  </View>
);

export default ({ children, info }) => {
  const { condMsg, condDDDMsg } = info;
  return (
    <>
      <NavBar />
      <View style={stlBodyView}>
        <ScrollView style={stlScroll}>
          {/* ----------- PARTs */}

          {condMsg && <MsgError />}
          {condDDDMsg && <MsgDDDError />}
          <View style={stlCenter}>{children}</View>
        </ScrollView>
        <AlertBox info={{ ...info }} />
      </View>
    </>
  );
};

// #region :: STYLEs *********
const stlBodyView = [
  useStl.flex1,
  { backgroundColor: '#f6f6f6', marginTop: 60 },
];
const stlCenter = [useStl.flexCenter];
const stlScroll = [{ paddingVertical: 20 }];
const stlCard = [
  useStl.card,
  { width: '80%', borderRadius: 10, marginHorizontal: 'auto' },
];

// ---------- STYLE MSG
const val2 = [
  {
    fontSize: 12,
    marginHorizontal: 15,
    color: '#a2844d',
    fontWeight: '400',
  },
];
const val3 = [[stlCard, { borderLeftWidth: 8, borderLeftColor: '#e55403' }]];
const stlRow = [useStl.flexRow];

// #endregion *********
