// ---------- import Internals

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    M10_InitData: () => {
      // Merged Vars já traz Apenas Produtos Possíveis
      // Produtos com preço 0 não aparecem aqui
      const allProds = state.M2.mergedVars.itemsInfo;
      const idsShops = {};

      for (const keyProd in allProds) {
        const currProd = allProds[keyProd];
        const dataShops = currProd.dataShops;

        for (const keyShop in dataShops) {
          idsShops[keyShop] = true;
        }
      }

      return {
        ...state,
        M10: {
          ...state.M10,
          condList: true,
          itemsList: Object.keys(idsShops),
        },
      };
    },
  };

  const btns = {
    M10_BtnProds: () => {
      const shopName = state?.M2?.shopsInReach?.itemsInfo[action.value].name;
      const shopId = action.value;
      const cart = state?.basePersist?.cart?.totalAmount;
      const isEmpty = cart === 0;

      if (isEmpty) {
        return {
          ...state,
          M10: { ...state?.M10, shopName, shopId },
          M7: { ...state?.M7, currShop: shopId },
          baseRoute: {
            ...state.baseRoute,
            path: 'orderEdit',
          },
        };
      } else {
        return {
          ...state,
          M10: {
            ...state?.M10,
            shopName,
            shopId,
            msgCart: true,
          },
        };
      }
    },
  };

  return {
    ...inits,
    ...btns,
  };
};

// ---------- set Exports
export default reducers;
