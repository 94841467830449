// ---------- import Internals
import {
  firestore,
  firebase,
  storage,
} from '../../../../../config/firebase/fbConfig';
import { asyncRefs, currencyMask, ezLog } from '../../../../../useMorfos/utils';
import { getSettings, processData } from './helpers';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _SettingsADD_
    E1_InitData: () => {
      // const editData = state?.E1?.forms.editsInfo;

      // const condData = true;

      // action.asyncDispatch({ type: 'E1_InitForm' });
      // !editData && action.asyncDispatch({ type: 'E1_GET_EditData' });
      action.asyncDispatch({ type: 'E1_GET_EditData' });
      return state;
      // return {
      //   ...state,
      //   E1: {
      //     ...state.E1,
      //     // condData,
      //     currDay: '0',
      //     btnsInfos,
      //     btnsList: ['0', '1', '2', '3', '4', '5', '6'],
      //   },
      // };
    },
  };

  const forms = {
    E1_InitForm: () => {
      // ---------- set Edit
      const isEditable = true;
      const itemsInfo = state?.E1?.itemsInfo;
      const daysEmpty = Object.keys(itemsInfo).length === 0;

      // ------- set Forms Data
      // const condData = true;

      const fillDays = () => {
        const minHour = {
          // ----- set Dynamics
          itemId: 'minHour',
          required: true,
          iptChange: 'E1_IptDays',

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Horário Mínimo',
          condNumber: true,
        };

        const maxHour = {
          // ----- set Dynamics
          itemId: 'maxHour',
          required: true,
          iptChange: 'E1_IptDays',

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Horario Máximo',
          condNumber: true,
        };

        const newObj = {};

        ['0', '1', '2', '3', '4', '5', '6'].map(item => {
          const setEdit = (item, field) => {
            const hasDay = !daysEmpty && itemsInfo.days[item] !== undefined;
            const selector = hasDay && itemsInfo?.days[item][field];

            return hasDay ? selector : '';
          };
          newObj[item] = {
            minHour: {
              ...minHour,
              // ----- set Edits
              editData: setEdit(item, 'minHour'),
              isEditable,
            },
            maxHour: {
              ...maxHour,
              // ----- set Edits
              editData: setEdit(item, 'maxHour'),
              isEditable,
            },
          };
        });

        return newObj;
      };

      const iptsInfo = {
        // ---------- Inputs Base

        radiusLimit: {
          // ----- set Dynamics
          itemId: 'radiusLimit',
          required: true,
          iptChange: 'E1_IptTxt',

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Raio de Entrega em Km',
          condNumber: true,

          // ----- set Edits
          editData: itemsInfo.radiusLimit,
          isEditable,
        },

        deliveryPriceLimit: {
          // ----- set Dynamics
          itemId: 'deliveryPriceLimit',
          required: true,
          iptChange: 'E1_IptDelvryPrice',

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Valor Frete Grátis (Ex: 50,00)',
          condNumber: true,
          // currency: true,

          // ----- set Edits
          editData: itemsInfo.deliveryPriceLimit,
          isEditable,
        },
        days: fillDays(),
      };

      const btnsInfos = state?.refs?.weekDays;

      return {
        ...state,
        E1: {
          ...state.E1,
          // condData,
          currDay: '0',
          condLoader: true,
          forms: {
            btnsList: ['0', '1', '2', '3', '4', '5', '6'],
            btnsInfos,
            iptsInfo: { ...iptsInfo },
            iptsChanges: {},
          },
        },
      };
    },

    // ---------- set GET _Name
    E1_GET_EditData: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('settings');
        const dataSuccess = await dbRef.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return state;
    },

    E1_GET_EditData_SUCCESS: () => {
      const dataDb = action.value;
      // const editData = state?.E1?.forms?.editsInfo;
      // const iptsInfo = state?.E1?.forms?.iptsInfo;

      // ---------- set Data to Show
      let itemsInfo = {};
      dataDb.forEach(doc => {
        itemsInfo = doc.data();
        // editsList.push(doc.id);
      });
      action.asyncDispatch({ type: 'E1_InitForm' });

      return {
        ...state,
        E1: {
          ...state.E1,
          // forms: {
          //   ...state.E1.forms,
          //   // editsList,
          // },
          itemsInfo,
        },
      };
    },

    // ---------- set Form Changes

    E1_IptTxt: () => {
      const numInt = action.numInt;

      return {
        ...state,
        E1: {
          ...state.E1,

          forms: {
            ...state.E1.forms,
            iptsChanges: {
              ...state.E1.forms.iptsChanges,
              [action.field]: numInt,
            },
          },
        },
      };
    },

    E1_IptDays: () => {
      const currDay = state?.E1?.currDay;
      const days = state?.E1?.forms?.iptsChanges?.days;
      const history = days ? { ...days[currDay] } : {};

      // E1.forms.editsInfo.days.${currDay}.maxHour
      const hour = Number(action.toNum);
      const valNumber = hour;

      return {
        ...state,
        E1: {
          ...state.E1,

          itemsInfo: {
            ...state.E1.itemsInfo,
            days: {
              ...state.E1.itemsInfo.days,
              [currDay]: {
                ...history,
                id: currDay,
                [action.field]: action.value,
              },
            },
          },
          forms: {
            ...state.E1.forms,

            iptsInfo: {
              ...state.E1.forms.iptsInfo,
              days: {
                ...state.E1.forms.iptsInfo.days,
                [currDay]: {
                  ...state.E1.forms.iptsInfo.days[currDay],
                  // ...history,
                  // id: currDay,
                  [action.field]: {
                    ...state.E1.forms.iptsInfo.days[currDay][action.field],
                    editData: action.value,
                  },
                },
              },
            },
            iptsChanges: {
              ...state.E1.forms.iptsChanges,
              days: {
                ...state.E1.forms.iptsChanges.days,
                [currDay]: {
                  ...history,
                  id: currDay,
                  [action.field]: action.value,
                  [`${action.field}_num`]: valNumber,
                },
              },
            },
          },
        },
      };
    },

    E1_IptDelvryPrice: () => {
      return {
        ...state,
        E1: {
          ...state.E1,
          forms: {
            ...state.E1.forms,
            iptsChanges: {
              ...state.E1.forms.iptsChanges,
              [action.field]: action.numInt,
            },
          },
        },
      };
    },
  };

  const adds = {
    // ---------- set ADD E1 _SettingsADD
    E1_ADD_Settings: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Data to Update
        const dataToAdd = { ...state.E1.forms.iptsChanges };

        // ---------- set Update Data
        const refDb = firestore.collection('settings');

        // ---------- set Edit
        const arrSettings = await getSettings();
        const isEdit = arrSettings.length !== 0;

        let dataSuccess;
        const newObj = processData(dataToAdd, isEdit);

        if (isEdit) {
          const idToUpdt = arrSettings[0].docId;
          dataSuccess = await refDb.doc(idToUpdt).update(newObj);
        } else {
          const refDbSet = refDb.doc();
          newObj.docId = refDbSet.id;
          dataSuccess = await refDbSet.set(newObj);
        }

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return state;
    },

    E1_ADD_Settings_SUCCESS: () => {
      const baseRoute = state?.baseRoute;

      return {
        ...state,
        E1: {
          ...state.E1,
          dataName: action.value,
        },
        baseRoute: {
          ...baseRoute,
          path: 'prodList',
          pathRight: null,
        },
      };
    },
  };

  const btns = {
    // ---------- set CLOSE RIGHT E1 _BtnCancel_
    E1_BtnCancel: () => {
      return {
        ...state,
        baseRoute: {
          ...state.baseRoute,
          pathRight: null,
        },
      };
    },

    E1_BtnChangeDay: () => {
      return {
        ...state,
        E1: {
          ...state.E1,
          currDay: action.itemId,
        },
      };
    },
  };

  return {
    ...inits,
    ...forms,
    ...btns,
    ...adds,
  };
};

// ---------- set Exports
export default reducers;
