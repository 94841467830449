/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF, { ListProdsView, ItemProdView, OrderStatusView } from './Views';
import { UseCondLoader, UseList } from '../../../../useMorfos';
import { UseInitData, useData } from '../../../../useMorfos';
import { currencyMask } from '../../../../useMorfos/utils';
import imgDefault from '../../../images/default.jpg';
import { NoItemView } from '../../../comps';
import { mountCurrency } from './helpers';
import { useDispatch } from 'react-redux';

// ----------- set Info Screen
export const infoSc = {
  path: 'purchasePf',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: 'Perfil de Compra',
  },

  scCode: 'M7e',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const shopInfo = useData('M7e.shopInfo');
  const currOrder = useData('M7e.currOrder') ?? {};
  const { name = '...', imgUrl = '...', details = '...' } = shopInfo ?? {};
  const { totalPrice = 0, buyerAddress } = currOrder;
  const {
    neighborhood = '...',
    street = '...',
    street_number = 0,
  } = buyerAddress ?? {};
  const address = `${street}, ${street_number} - ${neighborhood}`;
  const condThumb = imgUrl ?? imgDefault;

  // ----------- set Hooks
  const currency = mountCurrency(totalPrice);

  // ----------- set Return
  const infoView = {
    condThumb,
    address,
    details,
    name,
  };
  return (
    <UseInitData reducer={'M7e_InitData'}>
      <ViewDF info={infoView}>
        <OrderStatusComp />
        <ListProdsComp info={{ currency }} />
      </ViewDF>
    </UseInitData>
  );
};

// ----------- set Lists
const ListProdsComp = ({ info }) => {
  return (
    <ListProdsView info={info}>
      <UseCondLoader data={'M7e.itemsOrder.condList'}>
        <UseList data={'M7e.itemsOrder.itemsList'}>
          {(itemId, noItem) =>
            noItem ? <NoItemView /> : <ItemProdComp itemId={itemId} />
          }
        </UseList>
      </UseCondLoader>
    </ListProdsView>
  );
};

const ItemProdComp = ({ itemId }) => {
  // ---------- set Data
  const currProd = useData('M7e.itemsOrder.itemsInfo', itemId);
  const { name, imgUrl, price, amount } = currProd;
  const priceInt = price.toFixed(2);
  const currency = `R$${currencyMask(priceInt, false)}`;
  const condThumb = imgUrl ?? imgDefault;

  const infoData = {
    name,
    amount,
    condThumb,
    currency,
  };

  return <ItemProdView infoData={infoData} />;
};

const OrderStatusComp = () => {
  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Conds
  const condAdjust = useData('M7e.currOrder.adjustment');

  // ---------- set Btn
  const btnDelivered = () => dispatch({ type: 'M7e_BTN_Delivered' });
  const btnPayment = () => dispatch({ type: 'M7e_BTN_Payment' });
  const btnAdjust = () => dispatch({ type: 'M7e_BTN_Adjustment' });
  const btnCancel = () => dispatch({ type: 'M7e_BTN_Cancel' });

  // ---------- set Data
  const totalPrice = useData('M7e.currOrder.totalPrice');
  const status = useData('M7e.currOrder.statusOrder');
  const waiting = status === 'Confirmar';
  const confirmed = status === 'Aguardando Pagamento';
  const paid = status === 'Pagamento Realizado';
  const shipping = status === 'Pedido Entregue';
  const delivered = status === 'Recebido';
  const cancel = status === 'Cancelado';
  const refused = status === 'Pagamento Recusado';

  const infoData = {
    totalPrice,
    status,
    delivered,
    refused,
    confirmed,
    cancel,
    paid,
    waiting,
    shipping,
    condAdjust,
    btnAdjust,
    btnDelivered,
    btnPayment,
    btnCancel,
  };

  return <OrderStatusView infoData={infoData} />;
};
