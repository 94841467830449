// ----------- import Internals
import { storage } from '../../../../../config/firebase/fbConfig';
import { ezLog } from '../../../../../useMorfos/utils';
import defaultImg from '../../../../images/default.jpg';

export const getUrl = async infoImg => {
  await storage.ref(`images/${infoImg.name}`).put(infoImg);
  const url = await storage
    .ref('images')
    .child(infoImg.name)
    .getDownloadURL();
  return url;
};

export const loopImgs = async (imgsList, dataToAdd) => {
  for (const key of imgsList) {
    const infoImgs = {};
    infoImgs[key] = dataToAdd[`image${key}`];

    if (infoImgs[key]) {
      dataToAdd[`imgUrl${key}`] = await getUrl(infoImgs[key]);

      delete dataToAdd[`image${key}`];
    }
  }
};

export const admEditProd = dataToAdd => {
  let newObj = {};

  if (dataToAdd.variations) {
    dataToAdd.variations = [...Object.values(dataToAdd.variations)];
  }
  const variations = dataToAdd.variations;
  const condHasVars = variations !== undefined;

  if (condHasVars) {
    for (const key in variations) {
      const item = variations[key];
      const { typeId, typeName, typeName_label, typeValue } = item;
      const condName = typeName !== undefined;
      const condValue = typeValue !== undefined;

      if (condName) {
        newObj[`variations.${typeId}.typeName`] = typeName;
        newObj[`variations.${typeId}.typeName_label`] = typeName_label;
      }
      if (condValue) {
        newObj[`variations.${typeId}.typeValue`] = typeValue;
      }
    }
    delete dataToAdd.variations;
    newObj = { ...dataToAdd, ...newObj };
  } else {
    newObj = { ...dataToAdd };
  }

  return newObj;
};

export const mountImgs = ({ infoData }) => {
  const { imgsList, isEditable, editData = {} } = infoData;
  const newObj = {};

  imgsList &&
    imgsList.map(key => {
      const condThumbnail = editData[`imgUrl${key}`] ?? defaultImg;

      newObj[`image${key}`] = {
        // ----- set Dynamics
        itemId: `image${key}`,
        // required: true,
        iptChange: `C2_ImgChange${key}`,
        imgData: `C2.forms.iptsChanges.image${key}`,

        // ----- set Edit
        editData: condThumbnail,
        isEditable,
      };
    });

  return newObj;
};
