// ---------- import Internals
import { firestore } from '../../../../config/firebase/fbConfig';
import { asyncRefs } from '../../../../useMorfos/utils';
import { removeDiffer } from './helpers';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data
    M7a_InitData: () => {
      // ---------- set Data
      const prodsCart = state.basePersist?.cart?.addedProds;
      const mergedVars = state.M2?.mergedVars?.itemsInfo;
      const currShop = state.M7?.currShop ?? state?.M10?.shopId;
      const shopName = state?.M7?.itemsInfo[currShop]?.name;
      const appBuy = state?.M2?.shopsInReach?.itemsInfo[currShop]?.appBuy;
      const availableProds = state.M7?.itemsInfo[currShop]?.availableProds;
      const unavailProds = state?.M7a?.unavailProds;

      // ---------- set AllProds
      const itemsInfo = {};

      for (const keyCart in prodsCart) {
        for (const keyMerged in mergedVars) {
          const item = mergedVars[keyMerged];

          // ---------- set Product in Shop
          const condShop = item.dataShops[currShop];
          const condShopCart = condShop?.prodId === keyCart;

          // ---------- set Product in Cart
          const dataProd = item.dataProd;
          const condProdCart = dataProd?.prodIdVar === keyCart;

          // ---------- set Cond Price
          const shopPrice = condShop?.shopPrice;
          const condPrice = shopPrice ?? 0;
          const condCartInfo = condProdCart && !itemsInfo[keyCart];
          const condItem = condShopCart || condCartInfo;

          // ---------- set itemsInfo
          if (condItem) {
            itemsInfo[keyCart] = {
              ...dataProd,
              shopPrice: condPrice,
            };
          }
        }
      }

      // ---------- set Only Availabe Prods
      const availableInfo = {};
      const availableList = [];

      for (const key in availableProds) {
        const prodId = availableProds[key];

        itemsInfo[prodId] = { ...itemsInfo[prodId], available: true };
        availableInfo[prodId] = { ...itemsInfo[prodId] };

        itemsInfo[prodId].name && availableList.push(prodId);
      }

      if (unavailProds) {
        for (const prod in unavailProds) {
          itemsInfo[prod] = unavailProds[prod];
        }
      }

      const cartList = state?.basePersist?.cart?.addedProds;
      const newList = Object.keys(cartList);
      const arrPrices = [];

      for (const key in availableInfo) {
        const shopPrice = availableInfo[key].shopPrice;
        const amountProd = cartList[key].amountProd;
        arrPrices.push(amountProd * shopPrice);
      }

      const totalPrice = arrPrices.reduce((price, i) => price + i);

      return {
        ...state,
        M7a: {
          ...state.M7a,
          totalPrice,
          shopName,
          appBuy,
          allProds: {
            condList: true,
            itemsList: newList,
            itemsInfo,
          },
          availProds: {
            itemsList: availableList,
            itemsInfo: availableInfo,
          },
        },
      };
    },

    // ---------- set GET _OrderList
    M7a_GET_OrderList: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('collection');
        const dataSuccess = await dbRef.orderBy('createdAt', 'desc').get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        M7a: { ...state.M7a },
      };
    },

    M7a_GET_OrderList_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      const itemsInfo = {};
      const itemsList = [];
      dataDb.forEach(doc => {
        itemsInfo[doc.id] = doc.data();
        itemsList.push(doc.id);
      });

      return {
        ...state,
        M7a: {
          ...state.M7a,
          itemsList,
          itemsInfo,
          condList: true,
        },
      };
    },
  };

  const btns = {
    M7a_BtnConfirm: () => {
      // ---------- set Data
      const cart = state?.basePersist?.cart?.addedProds;
      const availProds = state?.M7a?.availProds?.itemsList;
      const allProds = state?.M7a?.allProds?.itemsInfo;
      const unavailProds = {};
      for (const prod in allProds) {
        const condUnavailProd = availProds.includes(prod);
        if (!condUnavailProd) {
          unavailProds[prod] = allProds[prod];
        }
      }

      action.asyncDispatch({
        type: 'base_setRoute',
        value: 'orderFinal',
      });

      return {
        ...state,
        M7a: { ...state.M7a, unavailProds },
        basePersist: {
          ...state.basePersist,
          cart: {
            ...state.basePersist.cart,
            addedProds: {
              ...removeDiffer(cart, availProds),
            },
            totalAmount: Object.keys(removeDiffer(cart, availProds)).length,
          },
        },
      };
    },

    M7a_BtnGoHome: () => {
      action.asyncDispatch({
        type: 'base_setRoute',
        value: 'home',
      });
      return state;
    },

    M7a_BtnCancel: () => {},

    M7a_BtnProds: () => {
      return {
        ...state,
        baseRoute: {
          ...state.baseRoute,
          path: 'orderEdit',
        },
      };
    },
  };

  return {
    ...inits,
    ...btns,
  };
};

// ---------- set Exports
export default reducers;
