import { firebase } from '../../../../../config/firebase/fbConfig';
import { ezLog } from '../../../../../useMorfos/utils';

export const checkAccount = currShopInfo => {
  ezLog({ currShopInfo });
  // ----------- set Current Date
  const dateFs = firebase.firestore.Timestamp.now();
  const currDate = dateFs.toDate();

  const objStatus = {};
  let accountStatus = 'active';

  for (const key in currShopInfo) {
    const item = currShopInfo[key];

    // get Last Update Date
    const stampLastUpdt = item.lastUpdate ?? dateFs;
    const dateUptJs = stampLastUpdt.toDate();

    // set Difference Of Two Dates (in Time)
    const diffInTime = dateUptJs.getTime() - currDate.getTime();

    // set Total Days Difference
    const diffInDays = diffInTime / (1000 * 3600 * 24); // res is negative number
    const totalDays = Math.abs(diffInDays); // convert to positive number

    // set Status Account
    const condAlert = totalDays > 5;
    const condSuspend = totalDays > 7;

    if (condAlert) {
      objStatus[item.prodId] = 'alert';
    }
    if (condSuspend) {
      objStatus[item.prodId] = 'suspended';
    }
  }

  for (const key in objStatus) {
    const item = objStatus[key];

    const condMsgAlert = item === 'alert';
    const condMsgSpnd = item === 'suspended';

    if (condMsgAlert) {
      accountStatus = 'alert';
    }
    if (condMsgSpnd) {
      accountStatus = 'suspended';
    }
  }

  return accountStatus;
};
