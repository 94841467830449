// ----------- import Packs
import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { useData, useStl } from '../../../useMorfos';
import { ezLog } from '../../../useMorfos/utils';

export default ({ infoData }) => {
  const dispatch = useDispatch();

  const { itemId, change, stl = {} } = infoData;

  // #region :: STYLEs *********
  const stlBox = [
    useStl.flexCenter,
    { height: 40, paddingHorizontal: 7, margin: 2, ...stl },
  ];
  const txtActive = [{ color: '#000', fontWeight: 600 }];
  const txtInactive = [{ color: '#888' }];

  // #endregion *********

  const currCateg = useData('M2.currCateg');
  const allSubCategs = useData('basePersist.subCategs.itemsInfo', currCateg);
  const currSubCateg = allSubCategs.find(item => item.id === itemId);

  const subCategActive = useData('M2.filterProds.subCategActive');
  const active = subCategActive === itemId;
  const stlCondActive = active ? txtActive : txtInactive;

  const getSubCateg = () => dispatch({ type: change, value: itemId });

  return (
    <TouchableOpacity style={stlBox} onPress={getSubCateg}>
      <Text style={stlCondActive}>{currSubCateg?.labels}</Text>
    </TouchableOpacity>
  );
};
