// ---------- import Internals
import {
  firebase,
  firestore,
  firebaseAuth,
} from '../../../../../config/firebase/fbConfig';

import { asyncRefs, ezLog } from '../../../../../useMorfos/utils';

// ---------- set Reducers
export default (state, action) => {
  const inits = {
    // ---------- set Init Content Data
    A1_InitData: () => {
      return {
        ...state,
        A1: { ...state.A1, condList: true },
      };
    },
  };

  const btns = {
    A1_GoogleSignIn: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Firebase Reference
        const refDb = firestore.collection('users');

        // ---------- Set Web Auth Permission
        const provider = new firebase.auth.GoogleAuthProvider();
        const asyncGetPermission = await firebaseAuth.signInWithPopup(provider);

        const dataUser = asyncGetPermission.user;

        // ---------- set Get User DB Info
        const searchUser = await refDb
          .where('email', '==', dataUser.email)
          .get();

        const arrUser = [];
        searchUser.forEach(doc => arrUser.push(doc.data()));
        const newUser = arrUser.length === 0;

        const userDbInfo = {
          name: dataUser.displayName,
          imgUrl: dataUser.photoURL,
          email: dataUser.email,
        };

        // ---------- set TypeAccount
        const admId1 = 'poupi.mais@gmail.com';
        const admId2 = 'leandro.noisecall@gmail.com';
        const admId3 = 'carlosvsk@gmail.com';
        const loginMail = dataUser.email;
        const isAdm = admId1 === loginMail || admId2 === loginMail || admId3 === loginMail;

        console.log({isAdm});

        // ---------- set Adm or Shop Data
        if (isAdm) {
          console.log("isAdm",{userDbInfo});
          userDbInfo.typeAccount = 'adm1';
          console.log("isAdm",{userDbInfo});
        } else {
          const arrShop = [];
          const refShop = firestore.collection('shops');
          const searchShop = await refShop
          .where('email', '==', loginMail)
          .get();
          
          searchShop.forEach(doc => arrShop.push(doc.data()));
          console.log("isSHOP",{arrShop});

          const shopId = arrShop[0].docId;
          const shopName = arrShop[0].name;
          const shopImg = arrShop[0].imgUrl;

          userDbInfo.answerable = dataUser.displayName;
          userDbInfo.name = shopName;
          userDbInfo.imgUrl = shopImg;
          userDbInfo.typeAccount = 'shop1';
          userDbInfo.shopId = shopId;
          userDbInfo.infos = {
            jumpOnboarding: false,
          };
        }

        // ---------- set New User
        const refDoc = refDb.doc();
        if (newUser) {
          console.log("NEW USER SHOP");
          userDbInfo.createdAt = firebase.firestore.Timestamp.now();
          userDbInfo.docId = refDoc.id;
          
          await refDoc.set({ ...userDbInfo });
        }
        
        // ---------- set Update User
        if (!newUser) {
          console.log("UPDATE USER SHOP");
          console.log({id: arrUser[0].docId});
          console.log({userDbInfo});
          await refDb.doc(arrUser[0].docId).update({ ...userDbInfo });
        }
        
        const dataSuccess = newUser ? userDbInfo : arrUser[0];
        console.log({dataSuccess});

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ---------- set Return
      return {
        ...state,
        A1: {
          ...state.A1,
          condList: false,
        },
      };
    },

    // ---------- set Data
    A1_GoogleSignIn_SUCCESS: () => {
      const dataDb = action.value;

      console.log({dataDb});
      const mailNotFound = dataDb === undefined;

      if (mailNotFound) {
        return {
          ...state,
          A1: {
            ...state.A1,
            condList: true,
            mailNotFound,
          },
        };
      } else {
        return {
          ...state,
          baseAuthUser: dataDb,
          basePersist: {
            ...state.basePersist,
            userId: dataDb.docId,
          },
        };
      }
    },
  };

  const gets = {
    A1_GET_UserData: () => {
      return state;
    },
    A1_GET_UserData_SUCCESS: () => {
      return state;
    },
  };

  return {
    ...inits,
    ...btns,
    ...gets,
  };
};
