// ----------- import Packs
import React from 'react';
import { View, Image, Text, ScrollView } from 'react-native';

// ----------- import Internals
import { useData, useStl } from '../../../../useMorfos';
import { AlertBox, IptTxt } from '../../../comps';
import src2 from '../../../../../src/project/images/Pag_1.png';
import src3a from '../../../images/banner.svg';
import src4 from '../../../images/refused.svg';
import src5 from '../../../images/cvv.svg';
import src6 from '../../../images/warning.svg';

import { NavBar } from '../../../comps';
import { ezLog } from '../../../../useMorfos/utils';

export const Delivery = ({ infoData }) => {
  const { freeDelivery } = infoData;

  const FreeDelvr = () => (
    <View style={stlAmount}>
      <View style={stlCenter}>
        <Text style={stltexCard3}>Valor do Frete</Text>
        <Text style={stltexItem2}>Grátis</Text>
      </View>
    </View>
  );
  const Buyer = () => (
    <View style={stlAmount}>
      <View style={stlCenter}>
        {/* <Text style={stltexCard3}>Frete</Text> */}
        <Text style={[stltexItem2, { fontSize: 14 }]}>Retirada no Local</Text>
      </View>
    </View>
  );
  const condReturn = !freeDelivery ? <FreeDelvr /> : <Buyer />;

  return condReturn;
};

export const MsgCard = () => (
  <View style={val3}>
    <View style={stlRow}>
      <Image source={src4} style={val1} />
      <Text style={val2}>Número de cartão inválido.</Text>
    </View>
  </View>
);

export const MsgName = () => (
  <View style={val3}>
    <View style={stlRow}>
      <Image source={src4} style={val1} />
      <Text style={val2}>Nome inválido.</Text>
    </View>
  </View>
);

export const MsgCvv = () => (
  <View style={val3}>
    <View style={stlRow}>
      <Image source={src5} style={val1} />
      <Text style={val2}>Código CVV inválido.</Text>
    </View>
  </View>
);

export const MsgFilled = () => (
  <View style={val3}>
    <View style={stlRow}>
      <Image source={src4} style={val1} />
      <Text style={val2}>Preencha todos os campos</Text>
    </View>
  </View>
);

export const MsgPayError = () => (
  <View style={val3}>
    <View style={stlRow}>
      <Image source={src6} style={val1} />
      <Text style={val2}>Ocorreu um erro.</Text>
    </View>
  </View>
);

export const MsgPayRefused = () => (
  <View style={val3}>
    <View style={stlRow}>
      <Image source={src4} style={val1} />
      <Text style={val2}>
        Seu cartão foi recusado. Entre em contato com o seu banco.
      </Text>
    </View>
  </View>
);

export default ({ infoData, children }) => {
  const { currency, freeDelivery, btnValidCard, btnCancel } = infoData;

  return (
    <>
      <NavBar />
      <View style={stlBodyView}>
        <ScrollView>
          <View style={stlBanner}>
            <Image source={src3a} style={stlBoxImga} />
          </View>
          <View style={stlCenter}>
            <View style={[stlCard, stlTopUp]}>
              <View style={stlBetween}>
                <View style={stlAmount}>
                  <View style={stlCenter}>
                    <Text style={stltexCard3}>Total de Compra</Text>
                    <Text style={stltexItem}>{currency}</Text>
                  </View>
                </View>
                <Delivery infoData={{ freeDelivery }} />
              </View>
            </View>
            <View style={stlCard}>
              <IptTxt infoData={'M9.forms.iptsInfo.card_holder_name'} />
              <View style={stlRow}>
                <View style={stlFlx3}>
                  <IptTxt infoData={'M9.forms.iptsInfo.card_number'} />
                </View>
                <View style={stlFlx1Mg}>
                  <IptTxt infoData={'M9.forms.iptsInfo.card_cvv'} />
                </View>
              </View>
              <View style={stlRow}>
                <View style={stlFlx1}>
                  <IptTxt infoData={'M9.forms.iptsInfo.card_month'} />
                </View>
                <View style={stlFlx1Mg}>
                  <IptTxt infoData={'M9.forms.iptsInfo.card_year'} />
                </View>
              </View>
              <View style={stlPag}>
                <View style={stlRow}>
                  <View style={stlImg3}>
                    <Image style={stlThumb} source={src2} />
                  </View>
                  <Text style={stltexCard7}>Ambiente Seguro</Text>
                </View>
              </View>
            </View>
            {children}
          </View>
        </ScrollView>

        <AlertBox info={{ btnValidCard, btnCancel }} />
      </View>
    </>
  );
};

// #region :: STYLEs *********
// --- Utils
const stlCenter = [useStl.flexCenter];
const stlRow = [useStl.flexRow];
const stlFlx3 = [{ flex: 3 }];
const stlFlx1 = [{ flex: 1 }];
const stlFlx1Mg = [{ flex: 1, marginLeft: 20 }];

// --- Views, Containers
const stlBodyView = [useStl.flex1, { backgroundColor: '#f6f6f6' }];
const stlBanner = [
  {
    overflow: 'hidden',
    width: '100%',
    height: 320,
  },
];
const stlBoxImga = {
  width: '100%',
  height: 350,
};
const stlCard = [
  useStl.card,
  { width: '80%', borderRadius: 10, marginHorizontal: 10 },
];
const stlBetween = [useStl.flexRow, useStl.flexBetween];
const stlAmount = [useStl.flexRow, { marginVertical: 5 }];
const stlPag = [useStl.flexRow, useStl.flexWrap, { width: '100%', height: 50 }];
const stlImg3 = [{ width: 74, height: 20 }];
const stlThumb = [useStl.imgFull];
const val1 = [{ width: 36, height: 36 }];
const val2 = [
  {
    fontSize: 12,
    marginLeft: 15,
    color: '#a2844d',
    fontWeight: '400',
  },
];
const val3 = [[stlCard, { borderLeftWidth: 8, borderLeftColor: '#e55403' }]];

// --- Texts
const stltexCard3 = [
  {
    fontSize: 11,
    fontWeight: 600,
    color: 'gray',
    marginBottom: -5,
  },
];
const stltexItem = {
  fontWeight: 700,
  fontSize: 19,
};
const stltexItem2 = {
  fontWeight: 700,
  color: '#888d8b',
  fontSize: 19,
};
const stltexItem2b = {
  fontWeight: 700,
  color: '#888d8b',
  fontSize: 19,
  textDecoration: 'line-through',
};
const stltexCard7 = [
  { fontSize: 9, fontWeight: 600, color: '#9fa5a2', marginLeft: 5 },
];

// --- Aligns, Displacements
const stlTopUp = { marginTop: -258 };
// #endregion *********
