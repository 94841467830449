// ---------- import Internals
import { firestore } from '../../../../../config/firebase/fbConfig';

import { asyncRefs, ezLog } from '../../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    B3_InitData: () => {
      const currShop = state?.baseRoute?.currShop?.docId;
      if (currShop) {
        const scContent = {
          // --- ItemList
          act1: 'Ver Produtos',
          act2: 'Ver Vendas',
        };

        const condData = true;
        action.asyncDispatch({ type: 'B3_GET_ShopBaseProds' });

        return {
          ...state,
          B3: { ...state.B3, scContent, condData },
        };
      } else {
        action.asyncDispatch({ type: 'base_setRoute', value: 'shopsList' });
        return state;
      }
    },

    // ---------- set GET _BaseProds
    B3_GET_ShopBaseProds: () => {
      // ---------- set Async Function1
      const asyncBase = async () => {
        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('prods')
          .orderBy('createdAt', 'desc')
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };
      // ---------- set Async Function2
      const asyncProd = async () => {
        // ---------- set Type List
        const currShop = state?.baseRoute?.currShop?.docId;
        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('shops')
          .doc(currShop)
          .collection('prods')
          .orderBy('createdAt', 'desc')
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, [asyncBase(), asyncProd()]);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    B3_GET_ShopBaseProds_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;

      const itemsInfo = {};
      const itemsList = [];
      dataDb[0].forEach(doc => {
        itemsInfo[doc.id] = doc.data();
        itemsList.push(doc.id);
      });

      const currShopInfo = {};
      dataDb[1].forEach(doc => {
        const dataDb = doc.data();
        currShopInfo[doc.id] = dataDb;

        const condContain = itemsInfo[dataDb.prodId];
        if (condContain) {
          condContain.contain = true;
        }
      });

      return {
        ...state,
        B3: {
          ...state.B3,
          itemsList,
          itemsInfo,
          currShopInfo,
          condList: true,
        },
        // B2: {
        //   ...state.B2,
        //   itemsList,
        //   itemsInfo,
        //   condList: true,
        // },
      };
    },
  };

  const btns = {
    B3_ProdADD: () => {
      return {
        ...state,
        C2: {
          ...state.C2,
          isEditable: false,
          idToEdit: null,
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: 'prodADD',
        },
      };
    },
  };

  const edits = {
    B3_EditShopProds: () => {
      action.asyncDispatch({ type: 'C2_InitForm' });

      return {
        ...state,
        C2: {
          ...state.C2,
          idToEdit: action.itemId,
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: 'prodADD',
        },
      };
    },
  };

  return {
    ...inits,
    ...edits,
    ...btns,
  };
};

// ---------- set Exports
export default reducers;
