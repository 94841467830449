// ----------- import Packs
import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';

// ----------- import Internals
import { UseIcoMoon, useStl } from '../../../../useMorfos';
import { primaryColor } from '../../../../config/styles';
import src1 from '../../../images/bgAddress1.png';
import src2 from '../../../images/bgAddress2.png';
import { Address } from './helpers';
import './styles.css';

// #region :: EXPORTs *********
export const TxtInputView = ({ info }) => {
  return (
    <View style={stlInput}>
      <input
        id="autocomplete"
        placeholder="Buscar Endereço"
        type="text"
        style={{ border: 'none', outline: 'none', width: '90%' }}
      />
    </View>
  );
};

export const BtnSaveView = ({ info }) => {
  const { address, btnSave } = info;
  const condStl = address ? stlBtnSaveAct : stlBtnSave;
  const condDisable = address ? false : true;

  return (
    <TouchableOpacity style={condStl} onPress={btnSave} disabled={condDisable}>
      <UseIcoMoon name="arrow-right" size={18} color="#fff" />
    </TouchableOpacity>
  );
};

export const ItemView = ({ info }) => {
  return <Text>{info}</Text>;
};

export const MsgValidation = ({ info }) => {
  const { condVal, noShops } = info;
  const condShow = condVal === '';

  return condShow ? (
    <Text style={stlMsgVal}>
      Informe um número válido ao lado da rua e selecione uma opção para
      avançar.
    </Text>
  ) : (
    noShops && (
      <Text style={stlMsgVal}>
        Agradecemos por usar nosso app! Mas no momento não temos supermercados
        disponíveis em sua região. Estamos trabalhando para que logo possamos
        lhe atender.
      </Text>
    )
  );
};

// #endregion *********

export default ({ info, children }) => {
  const { goHome, isEdit, condVal, BtnSave, noShops } = info;

  // ----- set AutoComplete
  Address();

  // ----- set Return
  return (
    <>
      <View style={stlTopBar}>
        {isEdit && (
          <TouchableOpacity style={stlLeft} onPress={goHome}>
            <UseIcoMoon name="arrow-left" size={20} color="green" />
            <View style={stlTxtBar}>
              <Text style={stlTxtTitle}>VOLTAR</Text>
            </View>
          </TouchableOpacity>
        )}
      </View>

      <View style={stlBodyView}>
        <Image source={src1} style={stlImg1} />
        <Image source={src2} style={stlImg2} />
        <Text style={stlDescView}>
          Coloque o endereço de entrega para pesquisarmos o melhor preço da sua
          região
        </Text>
        <View style={stlRow}>
          <View style={stlInputView}>
            {children}
            <View style={stlIcon}>
              <UseIcoMoon name="search" size={18} color="#999" />
            </View>
          </View>
          <BtnSave />
        </View>
        <MsgValidation info={{ condVal, noShops }} />
      </View>
    </>
  );
};

// #region :: STYLEs *********

//-------Set Views
const stlBodyView = [
  useStl.flex1,
  useStl.flexCenter,
  { backgroundColor: '#f6f6f6', overflow: 'hidden' },
];
const stlDescView = [
  useStl.txtCenter,
  { marginBottom: 20, fontSize: 12, width: '70%', color: '#666', zIndex: 11 },
];

// ------ set Back
const stlLeft = [useStl.flexRow, { marginRigth: 10 }];
const stlTopBar = [
  useStl.flexRow,
  useStl.pad20,
  { backgroundColor: '#f6f6f6' },
];
const stlTxtBar = [useStl.flex1, { marginLeft: 10, alignItems: 'flex-start' }];
const stlTxtTitle = [
  {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 600,
    color: '#808080',
  },
];

//------set Image
const stlImg1 = {
  width: 100,
  height: 160,
  position: 'absolute',
  left: 0,
  top: 60,
  zindex: 0,
};
const stlImg2 = {
  width: 140,
  height: 120,
  position: 'absolute',
  right: -5,
  bottom: 100,
  zindex: 0,
};

//------set Address
const stlInput = [
  {
    backgroundColor: '#fff',
    borderRadius: 8,
    paddingHorizontal: 15,
    paddingVertical: 10,
    width: 250,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 20,
    shadowOffset: { width: 0, height: 0 },
  },
];

const stlRow = [useStl.flexRow];
const stlInputView = [{ marginRight: 5 }];
const stlIcon = [{ position: 'absolute', top: 9, right: 10 }];
const stlBtnSave = [
  useStl.btn,
  useStl.btnSmall,
  { borderRadius: 8, width: 42, height: 38, backgroundColor: '#ccc' },
];
const stlBtnSaveAct = [...stlBtnSave, { backgroundColor: primaryColor }];

const stlMsgVal = [
  {
    width: 290,
    marginTop: 2,
    fontSize: 12,
    fontWeight: 600,
    color: '#de5252',
    zIndex: 10,
  },
];

// #endregion *********
