/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF from './Views';
import {
  UseInitData,
  UseCondLoader,
  UseList,
  useData,
  useRouter,
} from '../../../../useMorfos';
import { ItemProd, NoItemView } from '../../../comps';
import { useDispatch } from 'react-redux';
import { ezLog } from '../../../../useMorfos/utils';

// ----------- set Info Screen
export const infoSc = {
  path: 'orderList',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: <ShopName />,
    subTitle: 'Lista de Compras',
  },

  scCode: 'M7a',
};

function ShopName() {
  const shopName = useData('M7a.shopName');
  return shopName;
}

// ----------- set Default Component
export default () => {
  // ----------- set Return
  const infoList = {
    reducer: 'M7a.allProds.itemsInfo',
  };

  // ---------- set Hooks
  const dispatch = useDispatch();
  // const { callRouter } = useRouter();

  const btnConfirm = () => {
    dispatch({ type: 'comps_HistoryPath' });
    dispatch({ type: 'M7a_BtnConfirm' });
  };
  const btnAllProds = () => {
    dispatch({ type: 'comps_HistoryPath' });
    dispatch({ type: 'M7a_BtnProds' });
  };
  const btnGoHome = () => {
    dispatch({ type: 'comps_HistoryPath' });
    dispatch({ type: 'M7a_BtnGoHome' });
  };

  const content = useData('M7a.scContent');

  const infoView = {
    content,
    btnAllProds,
    btnConfirm,
    btnGoHome,
  };

  return (
    <UseInitData reducer={'M7a_InitData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'M7a.allProds.condList'}>
          <UseList data={'M7a.allProds.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemProd infoData={{ ...infoList, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
