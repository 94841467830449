/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { useDispatch } from 'react-redux';
import { View, Text, Picker } from 'react-native';

// ----------- import Internals
import { useData, useStl } from '../../../../useMorfos';
import { ezLog } from '../../../../useMorfos/utils';

// ----------- set Default Component
export default ({ infoData }) => {
  // ----------- set Selectors
  const item = useData(infoData);
  const listData = item.pickerList;
  // ----------- set Hooks
  const dispatch = useDispatch();

  // ---------- set infoView
  const title = item.title;
  const pHolder = item.pHolder;

  // ---------- set Data True
  const DataTrue = () => {
    // ---------- set savedData
    const savedData = useData(item.dataPath);
    const selectedValue = savedData ?? 'pHolder';

    // ---------- set compItem
    const CompItem = () => {
      const titleList = [
        { labels: pHolder, id: 'pHolder' },
        ...Object.values(listData),
      ];

      return titleList.map((item2, idx) => {
        const infoReturn = { label: item2.labels, value: item2.id };
        return <PickerItem key={idx} info={infoReturn} />;
      });
    };

    // ---------- set inputChange
    const iptChange = val => {
      const found = listData.find(item2 => item2.id === val);
      // console.log('item.key', item.key);
      return (
        val !== 'pHolder' &&
        dispatch({
          key: item.key,
          type: item.iptChange,
          value: val,
          itemId: item.itemId,
          label: found.labels,
        })
      );
    };

    // ---------- set condDefault
    let condEnabled = item.enabled ?? true;

    // ---------- set infoReturn
    const condStlSel = selectedValue !== 'pHolder' && condEnabled;
    const infoReturn = {
      iptChange,
      condEnabled,
      title,
      selectedValue,
      condStlSel,
    };

    return (
      <IptPickList info={infoReturn}>
        <CompItem />
      </IptPickList>
    );
  };

  // ---------- set Return
  return <DataTrue />;
};

// ***************************************
// #region :: STYLEs
// ---------------
const stlTXT01 = [{ fontSize: 12, color: '#666' }];
const stlBODY2a = [useStl.pickerLine, useStl.mgB20];
const stlDisable = [{ backgroundColor: '#efefef' }];
const stlPicker = [
  {
    borderWidth: 0,
    marginVertical: 7,
    // marginHorizontal: 10,
    fontSize: 14,
    color: '#666',
  },
];
const stlPickerSel = [...stlPicker, { color: '#222', fontWeight: 600 }];

// ---------------
// #endregion
// ***************************************

const PickerItem = ({ info }) => {
  return <Picker.Item label={info.label} value={info.value} />;
};

const IptPickList = ({ info, children }) => {
  return (
    <>
      <Text style={stlTXT01}>{info.title}</Text>
      <View style={[stlBODY2a, !info.condEnabled && stlDisable]}>
        <Picker
          style={[
            stlPicker,
            info.condStlSel && stlPickerSel,
            !info.condEnabled && stlDisable,
          ]}
          enabled={info.condEnabled}
          onValueChange={info.iptChange}
          selectedValue={info.selectedValue}
        >
          {children}
        </Picker>
      </View>
    </>
  );
};
