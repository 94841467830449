// ---------- import Internals
import { firestore } from '../../../../../config/firebase/fbConfig';

import { asyncRefs } from '../../../../../useMorfos/utils';
import { testBooklets } from './helpers';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    S5_InitData: () => {
      const initProds = state?.S5?.itemsInfo ?? {};
      const condInitProds = Object.keys(initProds).length === 0;
      const condShow = condInitProds ? false : true;
      const scContent = {
        // --- ItemList
        act1: 'Excluir',
      };

      action.asyncDispatch({ type: 'S5_GET_Booklet' });

      return {
        ...state,
        S5: {
          ...state.S5,
          scContent,
          // condData,
          condList: condShow,
        },
        S6: {
          ...state?.S6,
          forms: { ...state?.S6?.forms, iptsChanges: {} },
        },
      };
    },
  };

  const btns = {
    S5_BookletADD: () => {
      const baseRoute = state.baseRoute;
      const S6 = state.S6;

      return {
        ...state,
        S6: {
          ...S6,
          isEditable: false,
          idToEdit: null,
        },
        baseRoute: {
          ...baseRoute,
          pathRight: 'bookletADD',
        },
      };
    },

    S5_DELETE_Booklet: () => {
      const itemId = action.itemId;

      const asyncFn = async () => {
        // ---------- set Data to Add

        const dbRef = firestore.collection('booklets').doc(itemId);

        const dataSuccess = await dbRef.delete();
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return {
        ...state,
        S5: {
          ...state.S5,
          idToDel: itemId,
          condList: false,
        },
      };
    },

    S5_DELETE_Booklet_SUCCESS: () => {
      const itemsList = state?.S5?.itemsList;
      const itemsInfo = state?.S5?.itemsInfo;
      const id = state.S5?.idToDel;

      const idx = itemsList.indexOf(id);
      itemsList.splice(idx, 1);

      return {
        ...state,
        S5: {
          ...state.S5,
          itemsList,
          condList: true,
          itemsInfo,
        },
      };
    },
  };

  const gets = {
    // ---------- set GET CATEGS + SUBCATEGS
    S5_GET_Booklet: () => {
      const shopId = state.baseAuthUser?.shopId;
      // ---------- set Async Function1
      const asyncBase = async () => {
        const dbRef = firestore
          .collection('booklets')
          .where('shopId', '==', shopId);
        const dataSuccess = await dbRef.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    S5_GET_Booklet_SUCCESS: () => {
      // ---------- set Data
      const dataDb = action.value;
      let itemsInfo = {};
      let itemsList = [];

      dataDb.forEach(doc => {
        // --- Items Info
        const dataDb = doc.data();
        itemsInfo[doc.id] = dataDb;
        itemsList.push(doc.id);
      });

      // ------ Testar os Encartes e Deletar caso passar 1d
      // ------------------- da data de expiração informada
      const fnToDel = key =>
        action.asyncDispatch({ type: 'S5_DELETE_Booklet', itemId: key });
      testBooklets({ itemsInfo, fnToDel });

      return {
        ...state,
        S5: {
          ...state?.S5,
          itemsInfo,
          itemsList,
          condList: true,
        },
      };
    },
  };

  const edits = {
    S5_EditProd: () => {
      action.asyncDispatch({ type: 'S6_InitData' });

      return {
        ...state,
        S6: {
          ...state.S6,
          idToEdit: action.itemId,
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: 'bookletADD',
        },
      };
    },
  };

  return {
    ...inits,
    ...btns,
    ...gets,
    ...edits,
  };
};

// ---------- set Exports
export default reducers;
