// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { primaryColor } from '../../../config/styles';

// ----------- import Internals
import { UseCondLoader, useData, useStl } from '../../../useMorfos';
import { ezLog } from '../../../useMorfos/utils';

export default ({ infoData }) => {
  // ----------- set Data
  const { rdEdit, rdMsgs, rdSave, rdLoader, rdCancel } = infoData;
  const msgs = useData(rdMsgs);
  const condAddedProd = useData('S1.condAddedProd');

  // ----------- set Hooks
  const dispatch = useDispatch();
  const [sttMsg, setMsg] = React.useState(false);

  let condSave = false;
  for (const key in msgs) {
    const item = msgs[key];

    const condTrue = item === true;
    if (condTrue) {
      condSave = true;
    }
  }

  const idToEdit = useData(rdEdit);
  const noEdit = idToEdit === null;

  // ----------- set Force Error
  const showMsgVal = msgs?.showMsgVal;

  const fxForceError = () => {
    if (showMsgVal) {
      setMsg(true);
    } else {
      setMsg(false);
    }
  };
  React.useEffect(fxForceError, [showMsgVal]);

  // ----------- set BTNs
  const btnSave = () => {
    if (condSave && noEdit) {
      setMsg(true);
    } else {
      // setMsg(false);
      dispatch({ type: rdSave });
    }
  };
  const btnCancel = () => dispatch({ type: rdCancel });

  // ----------- set Return
  return (
    <>
      {sttMsg && <Text style={stlMsg}>Preencha todos os campos</Text>}
      <UseCondLoader data={rdLoader}>
        <View style={stlCenter}>
          {condAddedProd && <Text style={stlVarTxt}>Produto Salvo !</Text>}
          <TouchableOpacity style={stlBtnPrimary} onPress={btnSave}>
            <Text style={stltxtInverse}>Salvar</Text>
          </TouchableOpacity>
          <TouchableOpacity style={stlBtn} onPress={btnCancel}>
            <Text>Cancelar</Text>
          </TouchableOpacity>
        </View>
      </UseCondLoader>
    </>
  );
};

// #region :: STYLEs *********

const stlMsg = [
  useStl.border(1, 1, 1, 1, '#d9caca'),
  {
    borderRadius: 6,
    color: '#ef4444',
    fontSize: 12,
    marginTop: 10,
    backgroundColor: '#f9f0ed',
    padding: 5,
  },
];
const stlCenter = [useStl.flexCenter, { marginVertical: 40 }];
const stlBtn = [useStl.btn, useStl.btnMedium, { width: 110, marginBottom: 5 }];
const stlBtnPrimary = [stlBtn, useStl.btnPrimary];
const stltxtInverse = [useStl.txtPrimaryInverse];
const stlVarTxt = [{ color: primaryColor, paddingBottom: 20 }];
// #endregion *********
