// ----------- import Packs
import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';

// ----------- import Internals
import { useStl, useData } from '../../../../../useMorfos';

// #region :: STYLEs *********
const stlBodyView = [useStl.flex1, useStl.pad20, useStl.flexTopCenter];
const stlImg = [useStl.profileImg, { marginTop: 20 }];
const stlTitle = [useStl.txtTitleCard];
const stlMail = [
  useStl.txtTitleCard,
  { fontSize: 13, margin: 0, marginBottom: 10, color: '#444' },
];
const stlBtn = [
  useStl.btn,
  useStl.btnMedium,
  useStl.btnPrimary,
  { marginTop: 20 },
];
const stlTxtInverse = [useStl.txtPrimaryInverse];

// #endregion *********

export default ({ info }) => {
  const { btnGoto, imgUrl, name, email } = info;

  return (
    <View style={stlBodyView}>
      <Image source={imgUrl} style={stlImg} />
      <Text style={stlTitle}>{name}</Text>
      <Text style={stlMail}>{email}</Text>
      <TouchableOpacity style={stlBtn} onPress={btnGoto}>
        <Text style={stlTxtInverse}>Sair</Text>
      </TouchableOpacity>
    </View>
  );
};
