// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF, { DayBox } from './Views';
import {
  useRouter,
  UseInitData,
  useData,
  UseList,
  UseCondLoader,
} from '../../../../../useMorfos';
import { useDispatch } from 'react-redux';
import { NoItemView } from '../../../../comps';

// ----------- set Info Screen
export const infoSc = {
  path: 'admSettings',
  groupSc: 'adm1',
  layout: {
    icon: 'gear',
    title: 'Configurações',
    desc: 'Lorem Ipsum',
  },
  sideRight: {
    title: 'Configurações',
    options: false,
  },

  scCode: 'E1',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('E1.scContent');

  // ----------- set Hooks
  const { callRouter } = useRouter();
  const dispatch = useDispatch();

  // ----------- set Btns
  const btnSave = () => dispatch({ type: 'E1_ADD_Settings' });
  const btnCancel = () => dispatch({ type: 'E1_BtnCancel' });

  // ----------- set Return
  const infoView = { content, btnSave, btnCancel };

  return (
    <UseInitData reducer={'E1_InitData'}>
      <UseCondLoader data={'E1.condLoader'}>
        <ViewDF info={infoView}>
          <UseList data={'E1.forms.btnsList'}>
            {(itemId, noItem) =>
              noItem ? <NoItemView /> : <DayBox itemId={itemId} />
            }
          </UseList>
        </ViewDF>
      </UseCondLoader>
    </UseInitData>
  );
};
