import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useData, useStl } from '../../../useMorfos';

export default ({ info }) => {
  const { lastItem, btnNextProds, condBtn } = info;

  const currSc = useData('baseRoute.currScInfo.scKey');
  const currList = useData(`${currSc}.itemsList`);
  const isEmptyList = currList.length === 0;
  const lastItemId = useData(lastItem);
  const loadNext = useData(condBtn);
  const condShowMsg = lastItemId === undefined;

  if (isEmptyList) {
    return <></>;
  } else {
    return condShowMsg ? (
      <MsgFinished />
    ) : (
      <Button info={{ btnNextProds, lastItemId, loadNext }} />
    );
  }
};

const MsgFinished = () => (
  <View style={stlBox}>
    <Text style={stlTxt}>Fim dos resultados!</Text>
  </View>
);

const Button = ({ info }) => {
  const { btnNextProds, loadNext } = info;
  return (
    !loadNext && (
      <View style={stlBox}>
        <TouchableOpacity style={stlBtn} onPress={btnNextProds}>
          <Text style={stlTxt}>Carregar Mais...</Text>
        </TouchableOpacity>
      </View>
    )
  );
};

// #region :: STYLEs *********
const stlBox = [
  useStl.border(1, 1, 1, 1, '#e4e4e4'),
  useStl.flexCenter,
  { borderRadius: 8, backgroundColor: '#f5f4f4', height: 60, marginBottom: 48 },
];
const stlBtn = [
  useStl.btn,
  {
    borderRadius: 10,
    backgroundColor: '#fbfbfb',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
];
const stlTxt = [{ fontWeight: 400, color: '#555', fontSize: 16 }];
// #endregion *********
