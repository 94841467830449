// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import { useRouter, UseInitData, useData } from '../../../../../useMorfos';

// ----------- set Info Screen
export const infoSc = {
  path: 'terms',
  groupSc: 'pub',
  condBigSc: true,
  scCode: 'A2',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('A2.scContent');

  // ----------- set Hooks
  const { callRouter } = useRouter();

  // ----------- set Routes
  const btnGoBack = () => callRouter('signin');

  // ----------- set Return
  const infoView = { content, btnGoBack };

  return (
    <UseInitData reducer={'A2_InitData'}>
      <ViewDF info={infoView} />
    </UseInitData>
  );
};
