// ---------- import Internals
import {
  firestore,
  firebase,
  storage,
} from '../../../../../config/firebase/fbConfig';
import { asyncRefs } from '../../../../../useMorfos/utils';
import { mountImgs } from './helpers';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    S6_InitData: () => {
      const scContent = {};
      const condData = true;
      action.asyncDispatch({ type: 'S6_InitForm' });

      return {
        ...state,
        S6: {
          ...state.S6,
          scContent,
          condData,
          loader: true,
          imgsList: ['1', '2', '3', '4', '5'],
          condList: true,
        },
      };
    },

    S6_InitForm: () => {
      const idToEdit = state?.S6?.idToEdit;

      const isEditable = idToEdit ? true : false;
      const imgsList = state?.S6?.imgsList ?? ['1', '2', '3', '4', '5'];
      const editData = state?.S5?.itemsInfo && state?.S5?.itemsInfo[idToEdit];
      const iptsChanges = editData?.docId
        ? editData
        : { currImg: '1', imgSelected: '1' };

      const infoData = {
        imgsList,
        editData,
        isEditable,
      };
      const iptsInfo = {
        ...mountImgs({ infoData }),
      };

      return {
        ...state,
        S6: {
          ...state?.S6,
          forms: {
            iptsInfo: { ...iptsInfo },
            iptsChanges,
          },
        },
      };
    },
  };

  const forms = {
    S6_IptSelectImg: () => {
      return {
        ...state,
        S6: {
          ...state.S6,
          forms: {
            ...state.S6.forms,
            iptsChanges: {
              ...state.S6.forms.iptsChanges,
              currImg: action.value,
            },
          },
        },
      };
    },

    // ---------- set Dynamic Imgs Changes
    S6_ImgChange1: () => {
      return {
        ...state,
        S6: {
          ...state.S6,

          forms: {
            ...state.S6.forms,
            iptsChanges: {
              ...state.S6.forms.iptsChanges,
              image1: action.value,
            },
          },
        },
      };
    },

    S6_ImgChange2: () => {
      return {
        ...state,
        S6: {
          ...state.S6,

          forms: {
            ...state.S6.forms,
            iptsChanges: {
              ...state.S6.forms.iptsChanges,
              image2: action.value,
            },
          },
        },
      };
    },

    S6_ImgChange3: () => {
      return {
        ...state,
        S6: {
          ...state.S6,

          forms: {
            ...state.S6.forms,
            iptsChanges: {
              ...state.S6.forms.iptsChanges,
              image3: action.value,
            },
          },
        },
      };
    },

    S6_ImgChange4: () => {
      return {
        ...state,
        S6: {
          ...state.S6,

          forms: {
            ...state.S6.forms,
            iptsChanges: {
              ...state.S6.forms.iptsChanges,
              image4: action.value,
            },
          },
        },
      };
    },

    S6_ImgChange5: () => {
      return {
        ...state,
        S6: {
          ...state.S6,

          forms: {
            ...state.S6.forms,
            iptsChanges: {
              ...state.S6.forms.iptsChanges,
              image5: action.value,
            },
          },
        },
      };
    },
  };

  const adds = {
    S6_ADD_Booklet: () => {
      const idToEdit = state.S6?.idToEdit;
      const imgsList = state?.S6?.imgsList;
      const shopId = state.baseAuthUser?.shopId;
      const dataToAdd = { ...state.S6.forms.iptsChanges };

      const asyncFn = async () => {
        // ------ When Image Exist
        for (const item in imgsList) {
          const infoImg = dataToAdd[`image${Number(item) + 1}`];

          if (infoImg) {
            const getUrl = async () => {
              await storage.ref(`images/${infoImg.name}`).put(infoImg);

              const url = await storage
                .ref('images')
                .child(infoImg.name)
                .getDownloadURL();

              return url;
            };
            dataToAdd[`imgUrl${Number(item) + 1}`] = await getUrl();
            delete dataToAdd[`image${Number(item) + 1}`];
          }
        }

        let dataSuccess;

        dataToAdd.createdAt = firebase.firestore.Timestamp.now();
        dataToAdd.shopId = shopId;

        if (idToEdit) {
          const dbRef = firestore.collection('booklets').doc(idToEdit);
          dataSuccess = await dbRef.set(dataToAdd);
        } else {
          const dbRef = firestore.collection('booklets').doc();
          dataToAdd.docId = dbRef.id;
          dataSuccess = await dbRef.set(dataToAdd);
        }

        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return {
        ...state,
        S5: {
          ...state.S5,
          condList: false,
        },
      };
    },

    S6_ADD_Booklet_SUCCESS: () => {
      return {
        ...state,
        S5: {
          ...state.S5,
          condList: true,
        },
        S6: {
          ...state.S6,
          idToEdit: undefined,
        },
        baseRoute: {
          ...state.baseRoute,
          path: 'shopBookletList',
          pathRight: null,
        },
      };
    },

    S6_Edit_Booklet: () => {
      const asyncFn = async () => {
        // ---------- set Data to Add
        const imgsList = state?.S6?.imgsList;
        const idToEdit = state.S6?.idToEdit;
        const shopId = state.baseAuthUser?.shopId;
        const dataToAdd = { ...state.S6.forms.iptsChanges };

        // ------ When Image Exist
        for (const item in imgsList) {
          const infoImg = dataToAdd[`image${Number(item) + 1}`];

          if (infoImg) {
            const getUrl = async () => {
              await storage.ref(`images/${infoImg.name}`).put(infoImg);

              const url = await storage
                .ref('images')
                .child(infoImg.name)
                .getDownloadURL();

              return url;
            };
            dataToAdd[`imgUrl${Number(item) + 1}`] = await getUrl();
            delete dataToAdd[`image${Number(item) + 1}`];
          }
        }

        const dbRef = firestore.collection('booklets').doc(idToEdit);
        dataToAdd.docId = dbRef.id;
        dataToAdd.createdAt = firebase.firestore.Timestamp.now();
        dataToAdd.shopId = shopId;

        const dataSuccess = await dbRef.update(dataToAdd);
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();
    },

    S6_Edit_Booklet_SUCCESS: () => {},
  };

  const btns = {
    S6_BtnSave: () => {
      const itemsList = state.S5.itemsList;
      const iptsChanges = state.S6.forms.iptsChanges;
      const fieldsLength = Object.keys(iptsChanges).length;
      const allFilleds = fieldsLength > 4;
      const year = iptsChanges?.expiDate;
      const condYear = year && year.length === 10;

      // Não deixa salvar quando tiver 3 Encartes ou mais
      if (itemsList.length < 3) {
        if (allFilleds) {
          if (condYear) {
            action.asyncDispatch({ type: 'S6_ADD_Booklet' });
            return {
              ...state,
              S6: {
                ...state.S6,
                condList: true,
                msgForm: undefined,
              },
            };
          } else {
            return {
              ...state,
              S6: {
                ...state.S6,
                condList: true,
                msgForm: "Ano deve ser formato 'aaaa'",
              },
            };
          }
        } else {
          return {
            ...state,
            S6: {
              ...state.S6,
              condList: true,
              msgForm: 'Preencha todos os campos',
            },
          };
        }
      }
      return state;
    },

    S6_BtnCancel: () => {
      return {
        ...state,
        S6: {
          ...state.S6,
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: null,
        },
      };
    },
  };

  return {
    ...inits,
    ...forms,
    ...adds,
    ...btns,
  };
};

// ---------- set Exports
export default reducers;
