// ---------- import Internals
// import { firestore } from '../../../config/firebase/fbConfig';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    M0c_InitData: () => {
      const scContent = {
        title: 'Termos de Uso',
        subTitle: 'Oi Mundo!',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum luctus metus ut dui bibendum lobortis. Curabitur odio nisi, laoreet et consectetur id, fringilla eu mi. Phasellus eget dolor porttitor, scelerisque arcu eget, finibus nulla. Donec pharetra odio vel ex efficitur vulputate. Aenean fringilla feugiat sapien, nec feugiat ligula faucibus eu. Donec quis condimentum eros. Nam aliquam massa sed ultricies dictum.',
        txtBtn: 'Ir para Example',
      };

      const condData = true;

      return {
        ...state,
        M0c: { ...state.M0c, scContent, condData },
      };
    },
  };

  return {
    ...inits,
  };
};

// ---------- set Exports
export default reducers;
