export default {
  CLEAR: require('../../config/clearComp'),

  A0b: require('./dash/adm/A0b_TempUsers'),
  A1: require('./dash/adm/A1_Signin'),
  A2: require('./dash/adm/A2_Terms'),

  B1: require('./dash/adm/B1_ShopsLIST'),
  B2: require('./dash/adm/B2_ProdLIST'),
  B3: require('./dash/adm/B3_ShopProdsLIST'),
  C1: require('./dash/adm/C1_ShopADD'),
  C2: require('./dash/adm/C2_ProdADD'),
  D1: require('./dash/adm/D1_MyPF'),
  D2: require('./dash/adm/D2_RequestsPF'),
  D3: require('./dash/adm/D3_SalePF'),
  E1: require('./dash/adm/E1_SettingsADD'),

  S1: require('./dash/shops/S1_ProdsLIST'),
  S1b: require('./dash/shops/S1b_OrderLIST'),
  S1c: require('./dash/shops/S1c_OrderPf'),
  S1d: require('./dash/shops/S1d_PrintOrder'),
  S4: require('./dash/shops/S4_SettingsADD'),
  S5: require('./dash/shops/S5_BookletList'),
  S6: require('./dash/shops/S6_BookletAdd'),

  M0: require('./app/M0_SignIn'),
  M0a: require('./app/M0a_TempUsers'),
  M0b: require('./app/M0b_Onboarding'),
  M0c: require('./app/M0c_Terms'),
  M1: require('./app/M1_Address'),
  M2: require('./app/M2_Home'),
  M3: require('./app/M3_Invite'),
  M4: require('./app/M4_Search'),
  M5: require('./app/M5_MyPF'),
  M6: require('./app/M6_Cart'),
  M7: require('./app/M7_SelShop'),
  M7a: require('./app/M7a_OrderLIST'),
  M7b: require('./app/M7b_OrderEDIT'),
  M7c: require('./app/M7c_OrderFINAL'),
  M7d: require('./app/M7d_PurchasedLIST'),
  M7e: require('./app/M7e_PurchasedPF'),
  M8: require('./app/M8_Schedule'),
  M9: require('./app/M9_Payment'),
  M10: require('./app/M10_ShopsInReach'),
  M11: require('./app/M11_BookletList'),

  404: require('./X_404'),
};
export const reducers = {
  A0b: require('./dash/adm/A0b_TempUsers/reducers'),
  A1: require('./dash/adm/A1_Signin/reducers'),
  A2: require('./dash/adm/A2_Terms/reducers'),

  B1: require('./dash/adm/B1_ShopsLIST/reducers'),
  B2: require('./dash/adm/B2_ProdLIST/reducers'),
  B3: require('./dash/adm/B3_ShopProdsLIST/reducers'),
  C1: require('./dash/adm/C1_ShopADD/reducers'),
  C2: require('./dash/adm/C2_ProdADD/reducers'),
  D1: require('./dash/adm/D1_MyPF/reducers'),
  D2: require('./dash/adm/D2_RequestsPF/reducers'),
  D3: require('./dash/adm/D3_SalePF/reducers'),
  E1: require('./dash/adm/E1_SettingsADD/reducers'),

  S1: require('./dash/shops/S1_ProdsLIST/reducers'),
  S1b: require('./dash/shops/S1b_OrderLIST/reducers'),
  S1c: require('./dash/shops/S1c_OrderPf/reducers'),
  S1d: require('./dash/shops/S1d_PrintOrder/reducers'),
  S4: require('./dash/shops/S4_SettingsADD/reducers'),
  S5: require('./dash/shops/S5_BookletList/reducers'),
  S6: require('./dash/shops/S6_BookletAdd/reducers'),

  M0: require('./app/M0_SignIn/reducers'),
  M0a: require('./app/M0a_TempUsers/reducers'),
  M0b: require('./app/M0b_Onboarding/reducers'),
  M0c: require('./app/M0c_Terms/reducers'),
  M1: require('./app/M1_Address/reducers'),
  M2: require('./app/M2_Home/reducers'),
  M3: require('./app/M3_Invite/reducers'),
  M4: require('./app/M4_Search/reducers'),
  M5: require('./app/M5_MyPF/reducers'),
  M6: require('./app/M6_Cart/reducers'),
  M7: require('./app/M7_SelShop/reducers'),
  M7a: require('./app/M7a_OrderLIST/reducers'),
  M7b: require('./app/M7b_OrderEDIT/reducers'),
  M7c: require('./app/M7c_OrderFINAL/reducers'),

  M7d: require('./app/M7d_PurchasedLIST/reducers'),
  M7e: require('./app/M7e_PurchasedPF/reducers'),
  M8: require('./app/M8_Schedule/reducers'),
  M9: require('./app/M9_Payment/reducers'),
  M10: require('./app/M10_ShopsInReach/reducers'),
  M11: require('./app/M11_BookletList/reducers'),

  404: require('./X_404/reducers'),
};
