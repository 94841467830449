import React from 'react';
import { View, TouchableOpacity, TextInput } from 'react-native';
import { useDispatch } from 'react-redux';
import { shadowLess } from '../../../config/styles';
import { useData, UseIcoMoon, useStl } from '../../../useMorfos';

export default ({ infoData }) => {
  // ---------- set Hooks
  const dispatch = useDispatch();
  const typeAccount = useData('baseAuthUser.typeAccount');
  const isAdm = typeAccount === 'adm1';
  const currSc = isAdm ? 'B2' : 'S1';

  // ---------- Change Values
  const onChangeTerms = value =>
    dispatch({
      type: 'comps_changeIptTxt',
      value,
      fieldName: 'terms',
      sc: currSc,
    });

  const initTerms = () => dispatch({ type: 'comps_initTerms' });

  // ---------- set Return
  return (
    <View style={stlRow}>
      <TextInput
        onChangeText={onChangeTerms}
        placeholder="Buscar"
        style={stlInput}
      />
      <TouchableOpacity style={stlBtn} onPress={initTerms}>
        <UseIcoMoon name="search" size={16} color="#fff" />
      </TouchableOpacity>
    </View>
  );
};

// #region :: STYLEs *********

const stlRow = [useStl.flexRow];
const stlInput = [
  useStl.input,
  shadowLess,
  { width: 220, borderRadius: 50, outline: 'none', borderWidth: 0 },
];
const stlBtn = [
  useStl.btn,
  useStl.btnPrimary,
  { width: 30, height: 30, marginLeft: -34 },
];

// #endregion *********
