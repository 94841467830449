// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { primaryColor } from '../../../../config/styles';

// ----------- import Internals
import { useStl, UseIcoMoon, useData } from '../../../../useMorfos';

// #region :: STYLEs *********

const stlDialogBox = [
  useStl.dialogueBox,
  {
    minWidth: 110,
    padding: 5,
    borderRadius: 5,
    zIndex: 5,

    // -- Align Position
    position: 'absolute',
    top: 22,
    right: 5,
  },
];

const stlBtList = [
  {
    width: '100%',
    padding: 4,
  },
];

// #endregion *********

export default () => {
  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Show, Hide Box
  const condShowOpt = useData('comps.sideRight.condShowOpt');
  const toggleBox = () => {
    dispatch({ type: 'comps_ToggleOpt' });
  };

  return (
    <>
      <TouchableOpacity onPress={toggleBox}>
        <UseIcoMoon name={'options'} size={22} color={primaryColor} />
      </TouchableOpacity>

      {/* SHOW or HIDE */}
      <ListOptions condShow={condShowOpt} />
    </>
  );
};

const ListOptions = ({ condShow }) => {
  // ---------- set Hooks
  const dispatch = useDispatch();

  const deleteItem = () => dispatch({ type: 'comps_ConfirmDel' });

  return (
    condShow && (
      <View style={stlDialogBox}>
        <TouchableOpacity style={stlBtList} onPress={deleteItem}>
          <Text>Excluir</Text>
        </TouchableOpacity>
      </View>
    )
  );
};
