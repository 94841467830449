// ----------- import Packs
import React from 'react';
import {
  View,
  Image,
  ScrollView,
  Animated,
  TouchableOpacity,
} from 'react-native';

// ----------- import Internals
import { UseList, useRouter, useStl } from '../../../useMorfos';

import src3 from '../../images/banner.svg';
import src4 from '../../images/imgbn1.jpg';
import { ItemCateg, ItemSubCateg, NavBar, NoItemCatg, NoItemSubCatg } from '..';

// #region :: STYLEs *********
const stlFlexCenter = [useStl.flexCenter];
const stlImg3 = {
  width: '100%',
  height: 350,
  position: 'absolute',
  backgroundColor: '#f6f6f6',
};
const stlImg4 = {
  display: 'flex',
  flex: 1,
  backgroundColor: '#f6f6f6',
  width: 350,
  height: 200,
  borderRadius: 15,
  marginTop: 60,
  // resizeMode: 'center',
};
const stlScrollSub = [{ height: 50, marginTop: -10 }];
const stlScrollCateg = [{ height: 65, marginTop: -30, paddingBottom: 10 }];
const stlRow = [{ flexDirection: 'row' }];
// #endregion *********

export default ({ infoData }) => {
  const { fadeAnim, heightAnim } = infoData;
  const { callRouter } = useRouter();

  const goToUrl = () => callRouter('bookletList');

  return (
    <View>
      <NavBar />
      <Animated.View
        style={[
          {
            overflow: 'hidden',
            height: heightAnim,
          },
        ]}
      >
        <Image source={src3} style={stlImg3} />

        <View style={stlFlexCenter}>
          <TouchableOpacity onPress={goToUrl}>
            <Animated.Image
              source={src4}
              style={[
                stlImg4,
                {
                  opacity: fadeAnim,
                },
              ]}
            />
          </TouchableOpacity>
        </View>
      </Animated.View>

      {/* Categs */}
      <ScrollView
        style={stlScrollCateg}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      >
        <View style={stlRow}>
          <ListCategs />
        </View>
      </ScrollView>

      {/* SubCategs */}
      <ScrollView
        style={stlScrollSub}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      >
        <View style={stlRow}>
          <ListSubCategs />
        </View>
      </ScrollView>
    </View>
  );
};

// ----------- set Lists
const ListCategs = () => {
  return (
    <UseList data={'basePersist.categs.itemsList'}>
      {(itemId, noItem) =>
        noItem ? (
          <NoItemCatg />
        ) : (
          <ItemCateg
            infoData={{
              list: 'basePersist.categs.itemsInfo',
              change: 'M2_ChangeCateg',
              itemId,
            }}
          />
        )
      }
    </UseList>
  );
};

const ListSubCategs = () => {
  return (
    <UseList data={'basePersist.subCategs.itemsList'}>
      {(itemId, noItem) =>
        noItem ? (
          <NoItemSubCatg />
        ) : (
          <ItemSubCateg
            infoData={{
              list: 'basePersist.subCategs.itemsInfo',
              change: 'M2_FILTER_Prods',
              itemId,
            }}
          />
        )
      }
    </UseList>
  );
};
