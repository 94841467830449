// ---------- import Internals
import { firestore } from '../../../../../config/firebase/fbConfig';
import { asyncRefs } from '../../../../../useMorfos/utils';
import {
  getSettings,
  processDays,
  getCoords,
  getGeohashRange,
} from './helpers';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _SettingsADD_
    S4_InitData: () => {
      action.asyncDispatch({ type: 'S4_GET_AdmPar' });
      action.asyncDispatch({ type: 'S4_GET_EditData' });
      return state;
    },
  };

  const forms = {
    S4_InitForm: () => {
      // ---------- set Edit
      const isEditable = true;
      const settings = state?.S4?.itemsInfo.settings;
      const hasDays = settings?.days;
      const admPar = state?.S4?.admPar;
      const objDays = admPar?.days;
      const days = Object.keys(objDays);

      // ------- set Forms Data
      const fillDays = () => {
        const minHour = {
          // ----- set Dynamics
          itemId: 'minHour',
          required: true,
          iptChange: 'S4_IptMinHour',

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Horário Mínimo',
          condNumber: true,
        };

        const maxHour = {
          // ----- set Dynamics
          itemId: 'maxHour',
          required: true,
          iptChange: 'S4_IptMaxHour',

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Horario Máximo',
          condNumber: true,
        };

        const newObj = {};

        days.map(item => {
          const setEdit = (item, field) => {
            const dayFilled = hasDays && settings.days[item] !== undefined;
            const selector = dayFilled ? settings?.days[item][field] : '';

            return selector;
          };

          newObj[item] = {
            minHour: {
              ...minHour,
              // ----- set Edits
              editData: setEdit(item, 'minHour'),
              isEditable,
            },
            maxHour: {
              ...maxHour,
              // ----- set Edits
              editData: setEdit(item, 'maxHour'),
              isEditable,
            },
          };
        });

        return newObj;
      };

      const iptsInfo = {
        // ---------- Inputs Base

        radiusLimit: {
          // ----- set Dynamics
          itemId: 'radiusLimit',
          required: true,
          iptChange: 'S4_IptTxt',

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Raio de Entrega em Km',
          condNumber: true,

          // ----- set Edits
          editData: settings.radius,
          isEditable,
        },

        deliveryPriceLimit: {
          // ----- set Dynamics
          itemId: 'deliveryPriceLimit',
          required: true,
          iptChange: 'S4_IptTxt',

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Valor p/ Frete Grátis',
          condNumber: true,

          // ----- set Edits
          editData: settings.deliveryPriceLimit,
          isEditable,
        },

        days: fillDays(),
      };

      const btnsInfos = state?.refs?.weekDays;

      return {
        ...state,
        S4: {
          ...state.S4,
          currDay: '0',
          condLoader: true,
          forms: {
            msgs: {
              days: false,
              deliveryPriceLimit: false,
              radiusLimit: false,
            },
            btnsList: days,
            btnsInfos,
            iptsInfo: { ...iptsInfo },
            iptsChanges: {},
          },
        },
      };
    },

    // ---------- set GET _Adm Parameters
    S4_GET_AdmPar: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('settings');
        const dataSuccess = await dbRef.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return state;
    },

    S4_GET_AdmPar_SUCCESS: () => {
      const dataDb = action.value;
      const condRoute = state?.baseRoute?.path === 'selshop';
      condRoute && action.asyncDispatch({ type: 'M7_CalcPrices' });

      // ---------- set Data to Show
      let admPar = {};
      dataDb.forEach(doc => {
        admPar = doc.data();
      });

      return {
        ...state,
        S4: {
          ...state.S4,
          admPar,
        },
      };
    },

    // ---------- set GET _Edit Data
    S4_GET_EditData: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        const currShop = state?.baseAuthUser?.shopId;

        // ---------- set Async Call
        const dbRef = firestore.collection('shops').doc(currShop);
        const dataSuccess = await dbRef.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return state;
    },

    S4_GET_EditData_SUCCESS: () => {
      const dataDb = action.value;

      const radius = dataDb.data()?.address?.range?.radius;
      const settings = dataDb.data()?.settings;

      // ---------- set Data to Show
      const itemsInfo = {
        settings: {
          ...settings,
          radius,
        },
      };
      action.asyncDispatch({ type: 'S4_InitForm' });

      return {
        ...state,
        S4: {
          ...state.S4,
          itemsInfo,
        },
      };
    },

    // ---------- set Form Changes

    S4_IptTxt: () => {
      // ---------- set Validation
      const limitVal = state?.S4?.admPar[action.field];
      const error = Number(action.value) > Number(limitVal);

      return {
        ...state,
        S4: {
          ...state.S4,

          forms: {
            ...state.S4.forms,
            msgs: {
              ...state.S4.forms.msgs,
              [action.field]: error,
            },
            iptsChanges: {
              ...state.S4.forms.iptsChanges,
              [action.field]: action.numInt,
            },
          },
        },
      };
    },

    S4_IptMinHour: () => {
      const currDay = state?.S4?.currDay;
      const days = state?.S4?.forms?.iptsChanges?.days;
      const history = days ? { ...days[currDay] } : {};

      const days2 = state.S4.forms.condDays;
      const history2 = days2 ? { ...days2[currDay] } : {};

      const daysAdm = state?.S4?.admPar?.days;
      const limitValMinH = daysAdm[currDay]?.minHour_num;
      const hour = Number(action.toNum);
      const hourLimit = Number(limitValMinH);

      let error = hour < hourLimit;

      const valNumber = hour;

      return {
        ...state,
        S4: {
          ...state.S4,

          itemsInfo: {
            ...state.S4.itemsInfo,
            days: {
              ...state.S4.itemsInfo.days,
              [currDay]: {
                ...history,
                id: currDay,
                [action.field]: valNumber,
              },
            },
          },
          forms: {
            ...state.S4.forms,
            condDays: {
              ...state.S4.forms.condDays,
              [currDay]: {
                ...history2,
                [action.field]: error,
              },
            },
            msgs: {
              ...state.S4.forms.msgs,
            },
            iptsInfo: {
              ...state.S4.forms.iptsInfo,
              days: {
                ...state.S4.forms.iptsInfo.days,
                [currDay]: {
                  ...state.S4.forms.iptsInfo.days[currDay],
                  [action.field]: {
                    ...state.S4.forms.iptsInfo.days[currDay][action.field],
                    editData: action.value,
                  },
                },
              },
            },
            iptsChanges: {
              ...state.S4.forms.iptsChanges,
              days: {
                ...state.S4.forms.iptsChanges.days,
                [currDay]: {
                  ...history,
                  id: currDay,
                  [action.field]: action.value,
                  [`${action.field}_num`]: valNumber,
                },
              },
            },
          },
        },
      };
    },

    S4_IptMaxHour: () => {
      const currDay = state?.S4?.currDay;
      const days = state?.S4?.forms?.iptsChanges?.days;
      const history = days ? { ...days[currDay] } : {};

      const days2 = state.S4.forms.condDays;
      const history2 = days2 ? { ...days2[currDay] } : {};

      const daysAdm = state?.S4?.admPar?.days;
      const limitValMaxH = daysAdm[currDay]?.maxHour_num;
      const hour = Number(action.toNum);
      const hourLimit = Number(limitValMaxH);
      let error = hour > hourLimit;
      const valNumber = hour;

      return {
        ...state,
        S4: {
          ...state.S4,

          itemsInfo: {
            ...state.S4.itemsInfo,
            days: {
              ...state.S4.itemsInfo.days,
              [currDay]: {
                ...history,
                id: currDay,
                [action.field]: action.value,
              },
            },
          },
          forms: {
            ...state.S4.forms,
            condDays: {
              ...state.S4.forms.condDays,
              [currDay]: {
                ...history2,
                [action.field]: error,
              },
            },
            msgs: {
              ...state.S4.forms.msgs,
            },
            iptsInfo: {
              ...state.S4.forms.iptsInfo,
              days: {
                ...state.S4.forms.iptsInfo.days,
                [currDay]: {
                  ...state.S4.forms.iptsInfo.days[currDay],
                  [action.field]: {
                    ...state.S4.forms.iptsInfo.days[currDay][action.field],
                    editData: action.value,
                  },
                },
              },
            },
            iptsChanges: {
              ...state.S4.forms.iptsChanges,
              days: {
                ...state.S4.forms.iptsChanges.days,
                [currDay]: {
                  ...history,
                  id: currDay,
                  [action.field]: action.value,
                  [`${action.field}_num`]: valNumber,
                },
              },
            },
          },
        },
      };
    },
  };

  const adds = {
    // ---------- set ADD S4 _SettingsADD
    S4_ADD_Settings: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Data to Update
        const currShop = state?.baseAuthUser?.shopId;
        const dataToAdd = { ...state.S4.forms.iptsChanges };

        // ---------- set Update Data
        const refDb = firestore.collection('shops').doc(currShop);

        // ------ set New Write Batch
        const batch = firestore.batch();

        // ---------- set Edit
        const arrSettings = await getSettings(currShop);
        const isEdit = Object.keys(arrSettings.settings).length !== 0;

        let dataSuccess;
        const newObj = processDays(dataToAdd, isEdit);

        const radius = newObj.radiusLimit;
        const coords = await getCoords(currShop);

        // const coords = arrSettings?.geoLocation; // já traz geolocation
        // const lat = coords?.ff;
        // const long = coords?.df;
        // const range = getGeohashRange(lat, long, radius);
        const range = getGeohashRange(coords.lat, coords.long, radius);

        if (isEdit) {
          // ----- set Updates
          await batch.update(refDb, newObj); // horas

          if (radius) {
            const objUpdt = {
              [`address.range.radius`]: radius,
              [`address.range.upper`]: range.upper,
              [`address.range.lower`]: range.lower,
            };
            await batch.update(refDb, objUpdt);

            const obj = {
              [`settings.radiusLimit`]: radius,
            };

            await batch.update(refDb, obj);
          }

          // ----- set Commit
          dataSuccess = await batch.commit();
        } else {
          const settings = {
            ...newObj,
          };
          // ----- set Updates
          await batch.update(refDb, { settings });
          // ----- set Commit
          dataSuccess = await batch.commit();
        }

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return state;
    },

    S4_ADD_Settings_SUCCESS: () => {
      const baseRoute = state?.baseRoute;

      return {
        ...state,
        S4: {
          ...state.S4,
          dataName: action.value,
        },
        baseRoute: {
          ...baseRoute,
          path: 'prodList',
          pathRight: null,
        },
      };
    },
  };

  const btns = {
    // ---------- set CLOSE RIGHT S4 _BtnCancel_
    S4_BtnCancel: () => {
      return {
        ...state,
        baseRoute: {
          ...state.baseRoute,
          pathRight: null,
        },
      };
    },

    S4_BtnChangeDay: () => {
      return {
        ...state,
        S4: {
          ...state.S4,
          currDay: action.itemId,
        },
      };
    },
  };

  return {
    ...inits,
    ...forms,
    ...btns,
    ...adds,
  };
};

// ---------- set Exports
export default reducers;
