// ----------- import Packs
import React from 'react';
import { View, ScrollView, TouchableOpacity, Text } from 'react-native';

// ----------- import Internals
import { UseIcoMoon, useResize, useStl } from '../../../../useMorfos';
import { AlertBox, NavBar } from '../../../comps';

export default ({ children, info }) => {
  return (
    <>
      <NavBar />
      <View style={stlBodyView}>
        <ScrollView style={stlScroll}>
          {/* ----------- PARTs */}

          <View style={stlCenter}>{children}</View>
        </ScrollView>
        <AlertBox info={{ ...info }} />
      </View>
    </>
  );
};

// #region :: STYLEs *********
const stlBodyView = [
  useStl.flex1,
  { backgroundColor: '#f6f6f6', marginTop: 60 },
];
const stlCenter = [useStl.flexCenter];
const stlScroll = [{ paddingVertical: 20 }];

// #endregion *********
