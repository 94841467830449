// ----------- import Packs
import { AppRegistry } from 'react-native';

// ----------- import Internals
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Root from './Root'; // javascript
import appInfo from './appInfo';
const { name } = appInfo;

const rootElement = document.getElementById('root'); // html

// ----------- set React Native Web
AppRegistry.registerComponent(name, () => Root);
AppRegistry.runApplication(name, {
  rootTag: rootElement,
});

// ----------- set PWA
serviceWorkerRegistration.register();
