// ---------- import Packs
import geohash from 'ngeohash';

// ---------- import Internals
import { firebase, firestore } from '../../../../config/firebase/fbConfig';

import { asyncRefs } from '../../../../useMorfos/utils';
const geofire = require('geofire-common');

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Range_
    M1_InitData: () => {
      const scContent = {
        title: 'Signin',
        subTitle: 'Oi Mundo!',
        description: 'Lorem Ipsum!',
        txtBtn: 'Ir para os TERMOS',
      };

      const condData = true;

      return {
        ...state,
        M1: {
          ...state.M1,
          condBtn: true,
          scContent,
          condData,
          noShops: false,
        },
      };
    },
  };

  const forms = {
    M1_AutoComplete: () => {
      const iptsChanges = state?.M1?.forms?.iptsChanges;
      const condHistory = iptsChanges ? { ...state.M1.forms.iptsChanges } : {};

      action.asyncDispatch({ type: 'M1_GET_Shop' });

      return {
        ...state,
        M1: {
          ...state.M1,
          forms: {
            ...state.M1.forms,
            iptsChanges: {
              ...condHistory,
              address: {
                ...action.value,
              },
            },
          },
        },
      };
    },

    M1_GET_Shop: () => {
      const condGetShop = state?.M1?.forms?.iptsChanges?.address?.street_number;

      condGetShop && action.asyncDispatch({ type: 'M1_GET_Range' });
      return state;
    },

    // ---------- set _Ipt Change
    M1_IptChange: () => {
      // ---------- set Data
      const txtIpt = action.value;

      return {
        ...state,
        M1: {
          ...state.M1,
          forms: {
            ...state.M1.forms,
            iptsChanges: {
              [action.field]: txtIpt,
            },
          },
        },
      };
    },

    // M1_SaveAddress: () => {},
  };

  const gets = {
    M1_GET_Range: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Ref DB (GET ALL SHOPS IN DB)
        const refDb = firestore.collection('shops');
        let dataSuccess = refDb.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        M2: { ...state.M2 },
      };
    },

    M1_GET_Range_SUCCESS: () => {
      // ---------- set Data to Show
      const userCoords = state?.M1?.forms?.iptsChanges?.address;
      const dataDb = action.value;
      const latitude = userCoords.geolocation.lat;
      const longitude = userCoords.geolocation.lng;
      const itemsInfo = {};

      const shopIds = [];
      const matchingDocs = [];

      dataDb.forEach(doc => {
        const item = doc.data();
        const lat = item.address.geolocation.x_;
        const lng = item.address.geolocation.N_;
        const radiuskm = item.address.range.radius;

        const user = [latitude, longitude];

        const radiusInM = radiuskm * 1000;

        // Filter out a few false positives
        const distanceInKm = geofire.distanceBetween([lat, lng], user);
        const distanceInM = distanceInKm * 1000;

        if (distanceInM <= radiusInM) {
          matchingDocs.push(doc.data());
        }
      });

      // ----- set Filter Account Suspended
      for (const key in matchingDocs) {
        const itemMatch = matchingDocs[key];
        const matchId = itemMatch.docId;
        const accStatus = itemMatch.accountStatus;
        const condPush = accStatus !== 'suspended';

        if (condPush) {
          itemsInfo[matchId] = {
            name: itemMatch?.name,
            imgUrl: itemMatch?.imgUrl,
            settings: itemMatch?.settings,
          };
          shopIds.push(matchId);
        }
      }

      action.asyncDispatch({ type: 'M1_GET_ShopProds', shopIds });
      return {
        ...state,
      };
    },

    M1_GET_ShopProds: () => {
      const shopIds = action.shopIds;

      const asyncFn = async () => {
        const itemRefs = shopIds.map(id => {
          return firestore
            .collection('shops')
            .doc(id)
            .collection('prods')
            .get();
        });
        const finalRes = Promise.all(itemRefs);

        return finalRes;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M1_GET_ShopProds_SUCCESS: () => {
      // ---------- set Data
      const dataDb = action.value;
      let itemsInfo = {};

      dataDb.forEach(docShop => {
        for (const item of docShop.docs) {
          const currProd = item.data();
          const currShopId = currProd?.shopId;
          const variations = currProd?.variations;

          for (const keyVar in variations) {
            const currVar = variations[keyVar];
            const priceVar = currVar?.price;

            if (priceVar > 0) {
              itemsInfo[currShopId] = currShopId;
            }
          }
        }
      });

      const itemsList = Object.keys(itemsInfo);
      const noShops = itemsList.length === 0;

      return {
        ...state,
        M1: { ...state.M1, noShops },
      };
    },
  };

  const adds = {
    // ---------- set ADD M1 _Address
    M1_ADD_Address: () => {
      // ---------- set Data to Add
      const idToEdit = state?.baseAuthUser?.docId;
      const dataToAdd = { ...state.M1.forms.iptsChanges };

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Cond Edit or Save
        let dataSuccess = null;
        const latitude = dataToAdd.address.geolocation.lat;
        const longitude = dataToAdd.address.geolocation.lng;
        dataToAdd.address.geohash = geohash.encode(latitude, longitude);
        dataToAdd.address.geolocation = new firebase.firestore.GeoPoint(
          latitude,
          longitude,
        );
        const refDbEdit = firestore.collection('users').doc(idToEdit);
        dataSuccess = await refDbEdit.update({
          address: { ...dataToAdd.address },
        });

        // ------ return SUCCESS
        return action.asyncDispatch({
          type: ref.successName,
          value: dataSuccess,
        });
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return {
        ...state,
        M1: {
          ...state.M1,
          condBtn: false,
        },
      };
    },

    M1_ADD_Address_SUCCESS: () => {
      const dataToAdd = { ...state.M1.forms.iptsChanges };
      action.asyncDispatch({ type: 'base_setRoute', value: 'bookletList' });

      return {
        ...state,
        M1: {
          ...state.M1,
          condBtn: true,
        },
        baseAuthUser: {
          ...state.baseAuthUser,
          address: { ...dataToAdd.address },
        },
        basePersist: {
          ...state.basePersist,
          cart: {
            addedProds: {},
            totalAmount: 0,
          },
        },
      };
    },
  };

  return {
    ...inits,
    ...forms,
    ...gets,
    ...adds,
  };
};

// ---------- set Exports
export default reducers;
