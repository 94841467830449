// ----------- import Packs
import React from 'react';
import { View, Animated } from 'react-native';
import BottomBar from '../../../comps/App/BottomBar';

// ----------- import Internals
import { useStl } from '../../../../useMorfos';
import SideMenu from '../../../comps/App/SideMenu';

// #region :: STYLEs *********
const stlBg = [{ flex: 1, backgroundColor: '#f6f6f6', overflow: 'hidden' }];
const stlList = [useStl.flexCenter, { paddingBottom: 60 }];
// #endregion *********

export default ({ info, children }) => {
  return (
    <View style={stlBg}>
      <SideMenu />
      <info.LongBarView info={info} />
      <BottomBar />
      <Animated.ScrollView
        onScroll={info.moveScroll}
        scrollEventThrottle={5}
        style={{ zIndex: 2 }}
      >
        {/* List Prods */}
        <View style={stlList}>{children}</View>
        <Animated.View
          style={{
            width: '100%',
            height: info.padBottom,
          }}
        />
      </Animated.ScrollView>
    </View>
  );
};
