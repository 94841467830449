// ----------- import Packs
import React from 'react';
import { View } from 'react-native';

// ----------- import Internals
import { useStl } from '../../../../../useMorfos';
import { TitleList } from '../../../../comps';

// #region :: STYLEs *********
const stlBodyView = [useStl.flex1];
const stlPadContent = [useStl.pad(30, 100)];

// #endregion *********

export default ({ info, children }) => {
  const labels = { label1: 'Usuário', label2: 'Email' };

  return (
    <>
      <View style={stlBodyView}>
        {/* ----------- PARTs */}

        <View style={stlPadContent}>
          <TitleList infoData={labels} />
          {children}
        </View>
      </View>
    </>
  );
};
