/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import { useData, useStl } from '../../../../../useMorfos';
import { currencyMask } from '../../../../../useMorfos/utils';
import { IptTxt } from '../../../../comps';

// #region :: STYLEs *********

//--------------set Views
const stlBodyView = [useStl.flex1, { padding: 10 }];
const stlRow = [{ flexDirection: 'row' }];
const stlWrap = [{ flexDirection: 'row', flexWrap: 'wrap' }];
const stlDateBox = [
  useStl.border(1, 1, 1, 1, '#ddd'),
  useStl.pad(10),
  { marginTop: 20, borderRadius: 8 },
];
const stlSpaceCol = [{ flex: 1, marginRight: 10, width: '50%' }];
const stlMsg = [
  useStl.border(1, 1, 1, 1, '#d9caca'),
  {
    borderRadius: 6,
    color: '#ef4444',
    fontSize: 12,
    marginTop: 10,
    backgroundColor: '#f9f0ed',
    padding: 5,
  },
];
const stlDayTxt = [{ fontSize: 11, color: '#555' }];
const stlDayTxtAct = [{ fontSize: 11, color: 'darkgreen' }];

//--------------set Btns
const stlCenter = [useStl.flexCenter, { marginVertical: 40 }];
const stlBtn = [useStl.btn, useStl.btnMedium, { width: 110, marginBottom: 5 }];
const stlBtnPrimary = [stlBtn, useStl.btnPrimary];
const stlBtnDisable = [
  stlBtn,
  useStl.btnPrimary,
  { opacity: 0.4, backgroundColor: '#758268' },
];
const stltxtInverse = [useStl.txtPrimaryInverse];
const stlTxtSmall = [{ fontSize: 10, color: '#666', marginBottom: 3 }];
const stlBtnDayAct = [
  useStl.border(2, 2, 2, 2, '#50A106'),
  useStl.pad(3, 6),
  { borderRadius: 4, marginRight: 4, marginBottom: 3 },
];
const stlBtnDay = [
  useStl.border(2, 2, 2, 2, '#ccc'),
  useStl.pad(3, 6),
  { borderRadius: 4, marginRight: 4, marginBottom: 3 },
];

//--------------set Txts
const stlTxtDlvry1 = [{ fontSize: 11, color: '#444', marginTop: 20 }];

// #endregion *********

//-----set Region Export's

export const DayBox = ({ itemId }) => {
  // ------ set Hooks
  const dispatch = useDispatch();

  const data = useData('S4.forms.btnsInfos');
  const currDay = useData('S4.currDay');
  const btnChangeDay = () => dispatch({ type: 'S4_BtnChangeDay', itemId });
  const day = data[itemId].label;

  // ------- set Styles
  const condStl = currDay === itemId;
  const stlBtnActive = condStl ? stlBtnDayAct : stlBtnDay;
  const stlTxtActive = condStl ? stlDayTxtAct : stlDayTxt;

  // ------- set Return
  return (
    <TouchableOpacity style={stlBtnActive} onPress={btnChangeDay}>
      <Text style={stlTxtActive}>{day}</Text>
    </TouchableOpacity>
  );
};

// #endregion *********

export default ({ info, children }) => {
  const { condMsg, btnSave, btnCancel } = info;
  const condStlBtn = condMsg ? stlBtnDisable : stlBtnPrimary;

  return (
    <View style={stlBodyView}>
      <Form children={children} />

      {/* ----------- set BTNs SAVE & CANCEL */}
      {condMsg && (
        <Text style={stlMsg}>Valor ultrapassou o limite definido pelo adm</Text>
      )}
      <View style={stlCenter}>
        <TouchableOpacity
          disabled={condMsg}
          style={condStlBtn}
          onPress={btnSave}
        >
          <Text style={stltxtInverse}>Salvar</Text>
        </TouchableOpacity>
        <TouchableOpacity style={stlBtn} onPress={btnCancel}>
          <Text>Cancelar</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const Form = ({ children }) => {
  const deliveryVal = useData('S4.admPar.deliveryPriceLimit');
  const fixComma = Number(deliveryVal).toFixed(2);
  const condDlvryVal = `R$ ${currencyMask(fixComma, false)}` ?? '---';

  return (
    <>
      <IptTxt
        fieldName="radiusLimit"
        infoData="S4.forms.iptsInfo.radiusLimit"
        type="numInt"
        sc="S4"
      />

      <Text style={stlTxtDlvry1}>Valor p/ Frete Grátis</Text>
      <Text>{condDlvryVal}</Text>

      <View style={stlDateBox}>
        <Text style={stlTxtSmall}>Dias Disponíveis:</Text>
        <View style={stlWrap}>{children}</View>

        <HourFields />
      </View>
    </>
  );
};

const HourFields = () => {
  const currDay = useData('S4.currDay');
  const pathMin = `S4.forms.iptsInfo.days.${currDay}.minHour`;
  const pathMax = `S4.forms.iptsInfo.days.${currDay}.maxHour`;

  return (
    <View style={stlRow}>
      <View style={stlSpaceCol}>
        <IptTxt infoData={pathMin} type="hour" sc="S4" />
      </View>
      <View style={stlSpaceCol}>
        <IptTxt infoData={pathMax} type="hour" sc="S4" />
      </View>
    </View>
  );
};
