/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import NavBar from '../../../comps/App/NavBar';
import { View, Image, Text, TouchableOpacity, ScrollView } from 'react-native';

// ----------- import Internals
import { useStl, UseIcoMoon, UseList, useData } from '../../../../useMorfos';
import src3a from '../../../images/banner.svg';
import { AlertBox } from '../../../comps';
import { useDispatch } from 'react-redux';
import { ezLog } from '../../../../useMorfos/utils';

// ----------- Export
export const HoursView = ({ infoData }) => {
  // ---------- set Data
  const { dataHour, itemId, dayId, currDay } = infoData;
  const currOpt = useData('M8.currOpt');
  const label = dataHour.itemsInfo[itemId];
  const dayLabel = dataHour.dayLabel;

  const condOpt = currOpt === itemId && dayLabel === currDay;
  const condStl = condOpt ? stlHora2 : stlHora1;

  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Btn
  const objDelvry = {
    currHour: label,
    hour: itemId,
    dayId,
  };
  const btnSelect = () => dispatch({ type: 'M8_ChangeHour', value: objDelvry });

  return (
    <>
      <TouchableOpacity style={condStl} onPress={btnSelect}>
        <Text style={stlTxtHour}>{label}</Text>
      </TouchableOpacity>
    </>
  );
};

export const DaysView = ({ infoData }) => {
  const { HoursComp, labelDate, itemId } = infoData;
  const label = labelDate[itemId]?.dayLabel ?? '...';
  const dayId = itemId;

  return (
    <>
      <View style={stlCard}>
        <Text style={stlHora}>{label}</Text>
        <View style={stlHoracard}>
          <HoursComp infoData={{ dayId }} />
        </View>
      </View>
    </>
  );
};

const MsgError = () => (
  <View style={val3}>
    <View style={stlRow}>
      <Text style={val2}>Escolha uma data e horário.</Text>
    </View>
  </View>
);

export default ({ info, children }) => {
  const { condDelive, condPrice, condMsg } = info;

  const dispatch = useDispatch();

  const deliveryText =
    'Escolha um dia e horario abaixo p/ receber suas compras';
  const withdrawText =
    'retire no local de segunda a sexta das 9h às 11h30 ou 15h às 18h';

  const condFreight = condPrice || condDelive;
  const condBtn = useData('M8.freeDelivery');
  const withdraw = useData('M8.withdraw');
  const withdrawData = withdraw === undefined;
  const condText = withdrawData ? '' : withdraw ? withdrawText : deliveryText;

  const setFreeDelivery = () =>
    dispatch({ type: 'M8_ChangeDelivery', value: false });
  const setWithdraw = () =>
    dispatch({ type: 'M8_ChangeDelivery', value: true });

  return (
    <>
      <NavBar />
      <View style={stlBodyView}>
        <ScrollView>
          <View style={stlBanner}>
            <Image source={src3a} style={stlBoxImga} />
          </View>
          <View style={stlCenter}>
            <View style={[stlCard, stlTopUp]}>
              <View style={stlCenter}>
                <Text style={stltexCard}>Escolha o tipo de Entrega</Text>
              </View>
              <View style={stlRetirar}>
                <TouchableOpacity
                  style={withdraw === true ? stlBtnPrimary2 : stlBtnDisabled}
                  onPress={setWithdraw}
                >
                  <Text style={withdraw === true ? stltxtInverse1 : stltxtDark}>
                    Retirada no Local
                  </Text>
                </TouchableOpacity>
                {condBtn && (
                  <TouchableOpacity
                    style={withdraw === false ? stlBtnPrimary2 : stlBtnDisabled}
                    onPress={setFreeDelivery}
                  >
                    <Text
                      style={withdraw === false ? stltxtInverse1 : stltxtDark}
                    >
                      Frete Grátis
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
              <View style={stlCenter1}>
                {condFreight ? (
                  <>
                    {withdraw === false && (
                      <Text style={stltexCard3}>Frete Grátis</Text>
                    )}
                    <Text
                      style={[
                        stltexCard2,
                        { paddingHorizontal: 50, textAlign: 'center' },
                      ]}
                    >
                      {condText}
                    </Text>
                  </>
                ) : (
                  <Text style={stltexCard2b}>{condText}</Text>
                )}
              </View>
            </View>
            {children}
            {condMsg && <MsgError />}
          </View>
        </ScrollView>
        <AlertBox info={{ ...info }} />
      </View>
    </>
  );
};

// #region :: STYLEs *********
const stlBodyView = [
  // useStl.pad20,
  useStl.flex1,
  { backgroundColor: '#f6f6f6' },
];
const stlTopUp = { marginTop: -190 };
const stlBoxImga = {
  width: '100%',
  height: 350,
};
const stlBanner = [
  {
    overflow: 'hidden',
    width: '100%',
    height: 260,
  },
];
const stlCard = [
  useStl.card,
  { width: '80%', borderRadius: 10, marginHorizontal: 10 },
];
const stltexCard = [{ fontSize: 12, fontWeight: 700, color: 'gray' }];
const stltexCard2 = [{ fontSize: 10, fontWeight: 600, color: 'gray' }];
const stltexCard2b = [{ fontSize: 12, fontWeight: 600, color: 'gray' }];
const stltexCard3 = [{ fontSize: 23, fontWeight: 600, color: 'gray' }];
const stlRetirar = [
  useStl.flexWrap,
  useStl.flexCenter,
  useStl.flexRow,
  useStl.border(0.3, 0, '#bfbfbfa1'),
  { width: '100%', height: 70 },
];
const stlBtn1 = [
  useStl.btn,
  useStl.btnMedium,
  {
    backgroundColor: '#293A0F',
    fontSize: 8,
    fontWeight: 700,
  },
];
const stlBtnPrimary2 = [stlBtn1, useStl.btnPrimary, { marginRight: 5 }];
const stlBtnDisabled = [
  stlBtn1,
  useStl.btnPrimary,
  { backgroundColor: '#C0C0C0', marginRight: 5 },
];
const stltxtInverse1 = [
  useStl.txtPrimaryInverse,
  { fontSize: 10, fontWeight: 700 },
];
const stltxtDark = [{ fontSize: 10, fontWeight: 700, color: '#444' }];

const stlHora = { fontSize: 14, fontWeight: 600, paddingBottom: 20 };
const stlHora1 = [
  useStl.flexCenter,
  {
    paddingVertical: 2,
    width: 95,
    margin: 5,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
  },
];
const stlHora2 = [
  useStl.flexCenter,
  {
    paddingVertical: 2,
    width: 95,
    margin: 5,
    borderColor: 'green',
    borderWidth: 2,
    borderRadius: 5,
  },
];
const stlTxtHour = [
  { fontSize: 13, fontWeight: 600, textAlign: 'center', color: 'gray' },
];
const stlHoracard = [
  useStl.flexRow,
  useStl.flexWrap,
  {
    width: '100%',
    justifyContent: 'center',
  },
];
const stlCenter = [useStl.flexCenter, { marginBottom: 20 }];
const stlCenter1 = [stlCenter, { marginTop: 10, width: '100%' }];

// ---------- MSG STYLE
const val2 = [
  {
    fontSize: 12,
    marginLeft: 15,
    color: '#a2844d',
    fontWeight: '400',
  },
];
const val3 = [[stlCard, { borderLeftWidth: 8, borderLeftColor: '#e55403' }]];
const stlRow = [useStl.flexRow];

// #endregion *********
