// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import {
  UseInitData,
  UseCondLoader,
  UseList,
  useData,
} from '../../../../../useMorfos';
import {
  FilterBar,
  ItemList,
  LoadMoreItems,
  NoItemView,
  TitleList,
} from '../../../../comps';
import { useDispatch } from 'react-redux';

// ----------- set Info Screen
export const infoSc = {
  path: 'prodList',
  groupSc: 'adm1',
  condBigSc: true,
  layout: {
    icon: 'carrot',
    title: 'Produtos',
    desc: 'Lista de Produtos Base',
    btn: 'Add. Produto',
    actionADD: 'B2_ProdADD',
  },

  scCode: 'B2',
};

// ----------- set Default Component
export default () => {
  // ----------- set Hooks
  const dispatch = useDispatch();
  const isSearch = useData('B2.forms.iptsChanges.terms');

  // ----------- set Btns
  const btnNextProds = () => {
    isSearch
      ? dispatch({ type: 'comps_nextQuery' })
      : dispatch({ type: 'B2_GET_NextProds' });
  };

  // ----------- set Return
  const labels = { label1: 'Produto' };
  const infoItem = {
    itemContent: 'B2.scContent',
    itemsInfo: 'B2.itemsInfo',
    pressItem: 'B2_EditProd',
  };

  return (
    <UseInitData reducer={'B2_InitData'}>
      <ViewDF>
        <FilterBar />
        <TitleList infoData={labels} />

        <UseCondLoader data={'B2.condList'}>
          <UseList data={'B2.itemsList'} loadNext={'B2.loadNext'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemList infoData={{ ...infoItem, itemId }} />
              )
            }
          </UseList>
          <LoadMoreItems
            info={{
              btnNextProds,
              lastItem: 'B2.lastDocId',
              condBtn: 'B2.loadNext',
            }}
          />
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
