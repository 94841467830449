/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import { UseInitData, useData, UseList } from '../../../../../useMorfos';
import { UseCondLoader, useRouter } from '../../../../../useMorfos';
import { messaging } from '../../../../../config/firebase/fbConfig';
import { ItemProd, NoItemView } from '../../../../comps';
import ViewDF from './Views';

// ----------- set Info Screen
export const infoSc = {
  path: 'printOrder',
  groupSc: 'print',
  condBigSc: true,
  scCode: 'S1d',
};

// ----------- set Default Component
export default () => {
  // ----------- set Effects
  const fxToken = () =>
    messaging
      .getToken()
      .then(token => {
        console.log('token', token);
        setToken(token);
      })
      .catch(err => console.log('err', err));

  const [sttToken, setToken] = React.useState('');
  const [sttJsx, setJsx] = React.useState('');
  React.useEffect(fxToken, []);

  // ----------- set Data
  const content = useData('S1c.scContent');

  // ----------- set Hooks
  const dispatch = useDispatch();
  const { callRouter } = useRouter();

  // const VIWT = () => <Text>Ola</Text>;

  // ----------- set Btns
  const btnPrint = () => {
    dispatch({ type: 'S1d_InitPrint', value: sttJsx });
  };
  const btnBack = () => callRouter('requestOrderList');
  const btnEdit = () => dispatch({ type: 'S1c_BtnEditProd' });
  const btnConfirm = () =>
    dispatch({ type: 'S1c_StatusConfirm', value: sttToken });
  const btnPaid = () => dispatch({ type: 'S1c_StatusShipped' });
  const btnSave = () => dispatch({ type: 'S1c_BtnSaveProds' });
  const btnInclude = () => dispatch({ type: 'S1c_InitPicks' });

  // ----------- set Return
  const infoView = {
    content,
    btnEdit,
    btnConfirm,
    btnSave,
    btnPaid,
    btnBack,
    btnInclude,
    setJsx,
  };

  const infoItem = { reducer: 'S1c.itemsInfo' };

  return (
    <UseInitData reducer={'S1d_InitData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'S1c.condList'}>
          <UseList data={'S1c.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemProd infoData={{ ...infoItem, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
