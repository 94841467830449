/* eslint-disable import/no-anonymous-default-export */
// ---------- import Packs
import {
  firebase,
  firestore,
  firebaseAuth,
} from '../../../../config/firebase/fbConfig';

// ---------- import Internals
import { asyncRefs, ezLog } from '../../../../useMorfos/utils';

// ---------- set Reducers
export default (state, action) => {
  const inits = {
    // ---------- set Init Content Data
    M0_InitData: () => {
      const scContent = {
        title: 'GYM ONE',
        subTitle: 'Oi Mundo!',
        txtBtn1: 'Google',
      };

      const condData = true;

      return {
        ...state,
        M0: { ...state.M0, scContent, condData },
      };
    },
  };

  const btns = {
    M0_BtnPubEnter: () => {
      action.asyncDispatch({ type: 'base_setRoute', value: 'address' });

      return {
        ...state,
        baseAuthUser: {},
      };
    },

    // ---------- set GET GoogleSignin
    M0_GoogleSignIn: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Firebase Reference
        const refDb = firestore.collection('users');

        // ---------- Set Web Auth Permission
        const provider = new firebase.auth.GoogleAuthProvider();
        const asyncGetPermission = await firebaseAuth.signInWithPopup(provider);

        const dataUser = asyncGetPermission.user;

        // ---------- set Get User DB Info
        const searchUser = await refDb
          .where('email', '==', dataUser.email)
          .get();

        const arrUser = [];
        searchUser.forEach(doc => arrUser.push(doc.data()));
        const newUser = arrUser.length === 0;
        const condJump = newUser ? false : true;

        const userDbInfo = {
          name: dataUser.displayName,
          imgUrl: dataUser.photoURL,
          email: dataUser.email,
        };

        // ---------- set User Data
        userDbInfo.typeAccount = 'app1';
        userDbInfo.infos = {
          jumpOnboarding: condJump,
        };

        // ---------- set New User
        const refDoc = refDb.doc();
        if (newUser) {
          userDbInfo.createdAt = firebase.firestore.Timestamp.now();
          userDbInfo.docId = refDoc.id;

          await refDoc.set({ ...userDbInfo });
        }

        // ---------- set Update User
        if (!newUser) {
          await refDb.doc(arrUser[0].docId).update({ ...userDbInfo });
        }

        const dataSuccess = newUser ? userDbInfo : arrUser[0];

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ---------- set Return
      return {
        ...state,
        M0: { ...state.M0, condLoader: true },
      };
    },

    M0_GoogleSignIn_SUCCESS: () => {
      // ---------- set Data
      const dataDb = action.value;

      // ---------- set Return
      return {
        ...state,
        M0: { ...state.M0, condLoader: false },
        baseAuthUser: dataDb,
        baseRoute: {
          ...state.baseRoute,
          path: 'onboarding',
        },
      };
    },
  };

  return {
    ...inits,
    ...btns,
  };
};
