// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import { Card, Thumbnail, ActionList } from '../';
import { useData, UseIcoMoon, useStl } from '../../../useMorfos';
import { currencyMask, ezLog } from '../../../useMorfos/utils';
import src1 from '../../images/div.png';

// #region :: STYLEs *********

const stlLabel = [
  {
    paddingRight: 10,
    width: 200,
    minWidth: 105,
    maxHeight: 40,
    overflow: 'hidden',
  },
];
const stlItem = [useStl.cardItemList, useStl.flexRow];
const stlDetail = [useStl.flex4];
const stlEmail = [useStl.flex4, { color: '#666666' }];
const stlDiv = [{ width: 1, height: 14, marginHorizontal: 10 }];

// ---------- ACCOUNT STATUS STYLE
const stlBadge = [
  {
    color: '#ff2603',
    fontWeight: '600',
    backgroundColor: 'rgba(253,227,209,.4)',
    paddingRight: 6,
    paddingLeft: 22,
    borderRadius: 5,
    marginLeft: 10,
    borderColor: '#f17241',
    borderWidth: 1,
    position: 'relative',
  },
];
const stlInactive = [{ color: '#f4a338', fontWeight: '600' }];
const stlActive = [
  {
    color: '#a7b58c',
    fontWeight: '600',
    backgroundColor: 'rgba(255,236,213,.4)',
    paddingHorizontal: 6,
    borderRadius: 5,
    marginLeft: 10,
    borderColor: '#d5e2c1',
    borderWidth: 1,
  },
];

// ---------- STATUS STYLE
const stlConfirm = [useStl.flex4, { color: 'green' }];
const stlWaiting = [useStl.flex4, { color: '#f4a338' }];
const stlReality = [useStl.flex4, { color: '#fd5935' }];

const stlRow = [useStl.flexRow, { marginRight: 10 }];
const stlMsg1 = [{ color: '#ff0036' }];
const stlMsg2 = [{ color: '#666' }];

// #endregion *********

export default ({ infoData }) => {
  // ----------- set infoData
  const {
    itemContent,
    itemsInfo,
    itemId,
    pressItem,
    actionName1,
    actionName2,
  } = infoData;

  // ----------- set Data
  const item = useData(itemsInfo, itemId);
  const content = useData(itemContent);
  const currShopInfo = useData('S1.currShopInfo');
  const accountStatus = item?.accountStatus;
  const itsOutdated = item?.prodsOutdated;
  const admStatus = item?.admStatus === 'suspended';
  const currImg = item?.currImg;
  const condSrc = item[`imgUrl${currImg}`] ?? item.imgUrl;
  const path = useData('baseRoute.path');
  const detailsView = [];
  for (const key in currShopInfo) {
    const prod = currShopInfo[key];
    const variations = prod.variations;
    const condPush = prod.prodId === item.docId;
    if (condPush) {
      for (const variation in variations) {
        const shopProdVar = item.variations;
        const detObj = {};
        const prodPrice = prod.variations[variation].price;
        detObj.price = `R$${currencyMask(prodPrice.toFixed(2))}`;
        detObj.typeLabel = shopProdVar[variation].typeName_label;
        detObj.typeValue = shopProdVar[variation].typeValue;

        const condAddPrice = prodPrice !== 0;
        if (condAddPrice) {
          detailsView.push(detObj);
        }
      }
    }
  }

  // ----------- set Conds
  const condRequestOrder = path === 'requestOrderList';
  const condShopList = path === 'shopsList';
  const condprodsShopList = path === 'prodsShopList';
  const condAwait = item.statusOrder === 'Confirmar';

  const condConfirm = item.statusOrder === 'Aguardando Pagamento';
  const condAccActive = accountStatus === 'active';
  const condAlert = accountStatus === 'alert';

  const condActive = condAccActive ? '' : 'Suspenso';
  const accStatusText = condAlert ? 'Alerta' : condActive;
  const admStatusLabel = admStatus ? 'Inativo' : '';
  const StlAccStatus = itsOutdated ? stlActive : {};
  const stlCondBdge = admStatus ? stlBadge : {};
  const condStatus = condConfirm ? stlConfirm : stlReality;
  const stlStatus = condAwait ? stlWaiting : condStatus;
  const condDiv = item.details || item.email || detailsView.length !== 0;
  const condDiv2 = admStatus || itsOutdated;

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- stBtns
  const goToEdit = () => dispatch({ type: pressItem, itemId });
  const btnAct1 = () => dispatch({ type: actionName1, itemId });

  return (
    <TouchableOpacity onPress={goToEdit}>
      <Card style={stlItem}>
        <Thumbnail src={condSrc} />
        <CondDivider>
          <View style={stlLabel}>
            <Text style={{ fontWeight: '600' }}>{item.name}</Text>
          </View>

          {condDiv && <Image source={src1} style={stlDiv} />}

          {condRequestOrder && <Text style={stlEmail}>{item.email}</Text>}
          {!condprodsShopList && <Text style={stlDetail}>{item.details}</Text>}

          {item.statusOrder && <Image source={src1} style={stlDiv} />}
          {condRequestOrder && (
            <Text style={stlStatus}>{item.statusOrder.toUpperCase()}</Text>
          )}

          {condShopList && (
            <View
              style={{
                flex: 3,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              {condDiv2 && <Image source={src1} style={stlDiv} />}
              {itsOutdated && (
                <>
                  <Text style={StlAccStatus}>{accStatusText}</Text>
                </>
              )}
              <Text style={stlCondBdge}>
                {admStatus && (
                  <>
                    <View style={{ position: 'absolute', top: 2, left: 4 }}>
                      <UseIcoMoon name="alert2" size={14} color="#ff2603" />
                    </View>
                  </>
                )}
                {admStatusLabel}
              </Text>
            </View>
          )}

          {detailsView.map(details => {
            const detailText = `${details.typeLabel} / ${details.typeValue} / ${details.price}`;
            return (
              <>
                <Text style={useStl.leftBar}>{detailText}</Text>
                <Image source={src1} style={stlDiv} />
              </>
            );
          })}

          {actionName1 && (
            <>
              <Image source={src1} style={stlDiv} />
              <ActionList info={content.act1} action={btnAct1} />
            </>
          )}

          {/* <Image source={src1} style={stlDiv} /> */}
        </CondDivider>
      </Card>
    </TouchableOpacity>
  );
};

const CompMsg = ({ contain }) => {
  const path = useData('baseRoute.path');
  const condShow = path === 'shopProdsList';
  const condMsg = !contain ? (
    <View style={stlRow}>
      <Text style={stlMsg1}>Adicionar</Text>
      <UseIcoMoon name={'rd-right'} size={14} color={'#ff0036'} />
    </View>
  ) : (
    <View style={stlRow}>
      <Text style={stlMsg2}>Adicionado</Text>
      <UseIcoMoon name={'rd-right'} size={14} color={'#666'} />
    </View>
  );
  const returnDiv = condShow ? condMsg : null;

  return returnDiv;
};

const CondDivider = ({ children }) => {
  const condInsertDiv = children.length > 1;
  const withDiv = '';

  const withoutDiv = children;
  const returnDiv = condInsertDiv ? withoutDiv : withDiv;

  return returnDiv;
};
