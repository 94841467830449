// ----------- import Internals
import { useRouter, useData } from '../../../useMorfos';

// ----------- set Group
export default ({ children }) => {
  const { callRedirect } = useRouter();
  const userPermission = useData(`baseAuthUser.typeAccount`);

  const condShop = userPermission === 'shop1';
  const condReturn = condShop ? children : callRedirect('shopsList');

  return condReturn;
};
