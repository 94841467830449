// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import { UseInitData, UseList, UseCondLoader } from '../../../../useMorfos';
import { NoItemView } from '../../../comps';
import ShopsInReach from '../../../comps/App/ShopsInReach';

// ----------- set Info Screen
export const infoSc = {
  path: 'shopsInReach',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: 'Supermercados',
  },

  scCode: 'M10',
};

// ----------- set Default Component
export default () => {
  // ----------- set Return
  const infoItem = {
    itemContent: 'M10.scContent',
    itemsInfo: 'M2.shopsInReach.itemsInfo',
    pressItem: 'M10_BtnProds',
  };

  return (
    <UseInitData reducer={'M10_InitData'}>
      <ViewDF>
        <UseCondLoader data={'M10.condList'}>
          <UseList data={'M10.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ShopsInReach infoData={{ ...infoItem, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
