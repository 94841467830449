/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, Text, ScrollView } from 'react-native';

// ----------- import Internals
import { useStl } from '../../../../useMorfos';
import { NavBar } from '../../../comps';
const scrolltrick = require('./scrolltrick.css');

export default ({ info, children }) => {
  return (
    <>
      <NavBar />
      <View style={stlBodyView}>
        <ScrollView>
          <Text style={stlTxt}>
            A Poupi Selecionou os mercados com o melhor preço pra você
          </Text>
          {children}
        </ScrollView>
      </View>
    </>
  );
};

// #region :: STYLEs *********
const stlBodyView = [useStl.bodyViewApp];

// # fonts *********
const stlTxt = [
  {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 600,
    color: '#444',
    marginBottom: 22,
  },
];
// #endregion *********
