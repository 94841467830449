// ----------- import Packs
import React from 'react';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF from './Views';
import { useRouter, UseInitData, useData } from '../../../../useMorfos';

// ----------- set Info Screen
export const infoSc = {
  path: 'invite',
  groupSc: 'pubApp',
  condBigSc: true,

  scCode: 'M3',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('M3.scContent');

  // ----------- set Hooks
  const { callRouter } = useRouter();

  // ----------- set Routes
  const btnGoto = () => callRouter('signin');

  // ----------- set Return
  const infoView = { content, btnGoto };

  return (
    <UseInitData reducer={'M3_InitData'}>
      <ViewDF info={infoView} />
    </UseInitData>
  );
};
