/* eslint-disable import/no-anonymous-default-export */

// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

// ----------- import Internals
import { useData, useStl } from '../../../useMorfos';
import { currencyMask, phoneMask } from '../../../useMorfos/utils';
import AlertBox from '../App/AlertBox';

export default ({ info }) => {
  // ---------- set Params
  const {
    statusOrder,
    totalPrice,
    clientPhone,
    buyerAddress,
    btnConfirm,
    btnCancel,
    btnPaid,
    createdAt,
    deliveryData,
  } = info;

  // ---------- set Data
  const updatePrice = useData('S1c.totalPrice');
  const statusCancel = useData('S1c.statusCancel');

  // ---------- set Conds Status
  const date = new Date(createdAt.seconds * 1000);
  const condAwait = statusOrder === 'Confirmar';
  const condPay = statusOrder === 'Pagamento Realizado';
  const condReceived = statusOrder === 'Recebido';
  const condWaitPay = statusOrder === 'Aguardando Pagamento';
  const condDelivered = statusOrder === 'Pedido Entregue';
  const condCancel = statusOrder === 'Cancelado';
  const deliveryType = deliveryData?.type ?? 'Pendente...';
  const isDelivery = deliveryData?.type === 'Delivery';
  const delvryDay = deliveryData?.dayLabel ?? '';
  const delvryHour = deliveryData?.currHour ?? '';
  const deliveryDate = `${delvryDay} ${delvryHour}` ?? '';
  const condShowBtns = condWaitPay || condCancel;
  const condBtns = condDelivered || condReceived;
  const phoneFormated = clientPhone.substring(2);

  // ---------- set Cond Stl Status Colors
  let stlStatus;
  if (condAwait || condReceived) {
    stlStatus = stlConfirm;
  }
  if (condWaitPay || condPay || condDelivered) {
    stlStatus = stlWaiting;
  }
  if (condCancel) {
    stlStatus = stlReality;
  }

  // ---------- set Format Labels
  const fixComma1 = Number(updatePrice)?.toFixed(2);
  const fixComma2 = Number(totalPrice)?.toFixed(2);
  const formatUpdatePrc = `R$ ${currencyMask(fixComma1, false)}`;
  const formatTotalPrc = `R$ ${currencyMask(fixComma2, false)}`;
  const formatPrice = updatePrice ? formatUpdatePrc : formatTotalPrc;
  const formatStatus = statusOrder.toUpperCase();
  const formatDate = date.toLocaleDateString();
  const formatPhone = phoneMask(phoneFormated).mask;

  // ---------- set Return
  const infoView = {
    condAwait,
    condWaitPay,
    condCancel,
    condBtns,
    condPay,
    condShowBtns,
    btnPaid,
    btnConfirm,
    btnCancel,
  };
  return (
    <>
      <View style={stlHeaderStatus}>
        <View style={{ padding: 10 }}>
          <Text style={stlTitleStatus}>STATUS</Text>
          <Text style={stlStatus}>{formatStatus}</Text>
        </View>
        <CondBtns info={infoView} />
      </View>

      {/* O Que Esse AlertBox Faz? */}
      {statusCancel && <AlertBox />}

      <View style={stlCard}>
        <Text style={stlSubTitle}>Data do Pedido:</Text>
        <Text style={stlBotLine}>{formatDate}</Text>

        <Text style={stlSubTitle}>Telefone do Cliente</Text>
        <Text style={stlBotLine}>{formatPhone}</Text>

        <Text style={stlSubTitle}>Valor da Venda:</Text>
        <Text style={stlBotLine}>{formatPrice}</Text>

        <Text style={stlSubTitle}>Tipo de ENTREGA:</Text>
        <Text style={[stlBotLine, { fontWeight: 'bold', color: '#444' }]}>
          {deliveryType}
        </Text>
        {isDelivery && (
          <Text style={[stlBotLine, { marginTop: -14 }]}>{deliveryDate}</Text>
        )}

        <Text style={stlSubTitle}>Endereço do Cliente:</Text>
        <Text>
          {buyerAddress.street}, {buyerAddress.street_number},
          {buyerAddress.neighborhood}
        </Text>
      </View>
    </>
  );
};

const CondBtns = ({ info }) => {
  // ---------- set Params
  const { condAwait, condWaitPay, condPay, btnPaid, btnConfirm, btnCancel } =
    info;

  // ---------- set Renders
  const BtnShipped = () => (
    <TouchableOpacity style={stlBtnConfirm} onPress={btnPaid}>
      <Text style={stlTxtBtn1}>Enviar</Text>
    </TouchableOpacity>
  );
  const BtnConfirmOrdr = () => (
    <TouchableOpacity style={stlBtnConfirm} onPress={btnConfirm}>
      <Text style={stlTxtBtn1}>Confirmar</Text>
    </TouchableOpacity>
  );
  const BtnCancelOrdr = () => (
    <TouchableOpacity
      style={[stlBtnConfirm, { marginRight: 5 }]}
      onPress={btnCancel}
    >
      <Text style={stlTxtBtn1}>Cancelar</Text>
    </TouchableOpacity>
  );

  // ---------- set Return
  if (condAwait || condWaitPay) {
    return (
      <View style={stlStsBtns}>
        <BtnCancelOrdr />
        {!condWaitPay && <BtnConfirmOrdr />}
      </View>
    );
  }
  if (condPay) {
    return (
      <View style={stlStsBtns}>
        <BtnShipped />
      </View>
    );
  }

  return <></>;
};

// #region :: STYLEs *********

// ------------- set Views
const stlEnd = [useStl.flexEnd, { paddingHorizontal: 6, paddingVertical: 5 }];
const stlTxtBtn = { fontSize: 11 };
const stlCard = [useStl.cardItemList];
const stlSubTitle = [{ fontWeight: 600, fontSize: 13, color: '#444' }];

// ---------- HEADER STYLE
const stlBotLine = { marginBottom: 14 };

// ---------- STATUS STYLE
const stlStsBtns = [
  stlEnd,
  useStl.border(1, 0, 0, 0, '#eee'),
  { backgroundColor: '#fcfcfc' },
];
const stlTitleStatus = [stlSubTitle, { fontSize: 9 }];
const stlTxSmall = { fontSize: 10 };
const fontSmall = [stlTxSmall, { fontWeight: 600 }];
const stlConfirm = [fontSmall, useStl.flex4, { color: 'green' }];
const stlWaiting = [fontSmall, useStl.flex4, { color: '#f4a338' }];
const stlReality = [fontSmall, useStl.flex4, { color: '#fd5935' }];
const stlHeaderStatus = [useStl.cardMask, { borderRadius: 8, marginTop: 2 }];
const stlBtnConfirm = [useStl.btn, useStl.btnXSmall, { height: 17 }];
const stlTxtBtn1 = [stlTxtBtn];

// #endregion *********
