// ----------- import Packs
import React from 'react';
import { useDispatch } from 'react-redux';

// ----------- set Info Screen
export const infoSc = {
  path: 'clear',
  groupSc: 'clear',

  scCode: 'CLEAR',
};

// ----------- set Default Component
export default () => {
  // ----------- set Effects
  const fxClear = () => dispatch({ type: 'CLEAR_ALL' });
  React.useEffect(fxClear, []);

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Return

  return null;
};
