// ----------- import Packs
import { useData } from '.';
import { setPath } from './utils';

export default stl => {
  // ----------- set Selectors
  const condDesk = useData('baseRoute.condDeskSize');

  // ----------- set Return
  const condStl = condDesk ? setPath(stl, 'desk') : setPath(stl, 'mob');
  const condReturn = stl === undefined ? condDesk : condStl;
  return condReturn;
};
