// ---------- import Internals
import { firestore } from '../../../../config/firebase/fbConfig';

import { asyncRefs, ezLog } from '../../../../../src/useMorfos/utils';
import { testBooklets } from '../../dash/shops/S5_BookletList/helpers';
const geofire = require('geofire-common');

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    M11_InitData: () => {
      action.asyncDispatch({ type: 'M11_GET_Range' });

      return {
        ...state,
        M11: {
          ...state.M11,
          condList: false,
        },
      };
    },

    // ---------- set GET SHOPS IN REACH
    M11_GET_Range: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Ref DB (GET ALL SHOPS IN DB)
        const refDb = firestore.collection('shops');
        let dataSuccess = refDb.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        M11: { ...state.M11 },
      };
    },

    M11_GET_Range_SUCCESS: () => {
      // ---------- set Data to Show
      const userCoords = state.baseAuthUser.address.geolocation;
      const dataDb = action.value;
      const itemsInfo = {};
      const itemsList = [];
      const matchingDocs = [];

      dataDb.forEach(doc => {
        const item = doc.data();
        const lat =
          item.address.geolocation._lat ?? item.address.geolocation.x_;
        const lng =
          item.address.geolocation._long ?? item.address.geolocation.N_;

        const radiuskm = item.address.range.radius;

        const user = [userCoords.latitude, userCoords.longitude];
        const radiusInM = radiuskm * 1000;

        // Filter out a few false positives
        const distanceInKm = geofire.distanceBetween([lat, lng], user);
        const distanceInM = distanceInKm * 1000;

        if (distanceInM <= radiusInM) {
          matchingDocs.push(doc.data());
        }
      });

      // ----- set Filter Account Inactive
      for (const key in matchingDocs) {
        const itemMatch = matchingDocs[key];
        const matchId = itemMatch.docId;
        const accStatus = itemMatch.admStatus;
        const condPush = accStatus !== 'suspended';

        if (condPush) {
          itemsInfo[matchId] = {
            docId: itemMatch?.docId,
            name: itemMatch?.name,
            imgUrl: itemMatch?.imgUrl,
            settings: itemMatch?.settings,
            appBuy: itemMatch?.appBuy ?? false,
          };
          itemsList.push(matchId);
        }
      }

      action.asyncDispatch({ type: 'M11_GET_Booklets' });
      return {
        ...state,
        M11: {
          ...state.M11,
          shopsInReach: {
            itemsInfo,
            itemsList,
          },
        },
      };
    },
  };

  const btns = {
    M11_PrevImg: () => {
      const currImg = action.currImg;
      const docId = action.docId;
      let prevNum;
      const condCount = Number(currImg) > 1;

      if (condCount) {
        prevNum = Number(currImg) - 1;

        return {
          ...state,
          M11: {
            ...state.M11,
            itemsInfo: {
              ...state.M11.itemsInfo,
              [docId]: {
                ...state.M11.itemsInfo[docId],
                currImg: String(prevNum),
              },
            },
          },
        };
      }

      return state;
    },
    M11_NextImg: () => {
      const currImg = action.currImg;
      const numImgs = action.numImgs;
      const docId = action.docId;
      let prevNum;
      const condCount = Number(currImg) < numImgs;

      if (condCount) {
        prevNum = Number(currImg) + 1;

        return {
          ...state,
          M11: {
            ...state.M11,
            itemsInfo: {
              ...state.M11.itemsInfo,
              [docId]: {
                ...state.M11.itemsInfo[docId],
                currImg: String(prevNum),
              },
            },
          },
        };
      }

      return state;
    },
  };

  const gets = {
    M11_GET_Booklets: () => {
      const arrIds = state?.M11?.shopsInReach?.itemsList;

      const asyncFn = async () => {
        const itemRefs = arrIds.map(id => {
          return firestore
            .collection('booklets')
            .where('shopId', '==', id)
            .get();
        });
        const finalRes = Promise.all(itemRefs);
        return finalRes;
      };
      const ref = asyncRefs(action, asyncFn);
      ref.callAsync();
      return state;
    },

    M11_GET_Booklets_SUCCESS: () => {
      const itemsInfo = {};
      const itemsList = [];
      const dataDb = action?.value;

      dataDb.forEach(res => {
        for (const item of res.docs) {
          const currItem = item.data();
          let num = 0;

          for (const prop in currItem) {
            const condImg = prop.substr(0, 6);
            const condCount = condImg === 'imgUrl';
            if (condCount) {
              num = num + 1;
            }
          }

          itemsInfo[currItem.docId] = currItem;
          currItem.numImgs = num;
          itemsList.push(currItem.docId);
        }
      });

      // ------ Testar os Encartes e Deletar caso passar 1d
      // ------------------- da data de expiração informada
      const fnToDel = key =>
        action.asyncDispatch({ type: 'M11_DELETE_Booklet', itemId: key });
      // testBooklets tb retorna true e false que pode ser usado pra
      // ativar o Loader, enquanto deleta no FB e atualiza CT Data
      // ezLog({ testBk });
      testBooklets({ itemsInfo, fnToDel });

      return {
        ...state,
        M11: {
          ...state.M11,
          itemsInfo,
          itemsList,
          condList: true,
        },
      };
    },
  };

  const dels = {
    M11_DELETE_Booklet: () => {
      const itemId = action.itemId;

      const asyncFn = async () => {
        // ---------- set Data to Add

        const dbRef = firestore.collection('booklets').doc(itemId);

        const dataSuccess = await dbRef.delete();
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return {
        ...state,
        M11: {
          ...state.M11,
          idToDel: itemId,
          condList: false,
        },
      };
    },

    M11_DELETE_Booklet_SUCCESS: () => {
      const itemsList = state?.M11?.itemsList;
      const itemsInfo = state?.M11?.itemsInfo;
      const id = state.M11?.idToDel;

      const idx = itemsList.indexOf(id);
      itemsList.splice(idx, 1);
      delete itemsInfo[id];

      return {
        ...state,
        M11: {
          ...state.M11,
          condList: true,
          itemsInfo,
          itemsList,
        },
      };
    },
  };

  return {
    ...inits,
    ...btns,
    ...gets,
    ...dels,
  };
};

// ---------- set Exports
export default reducers;
