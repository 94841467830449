// ---------- import Internals
import { firebase, firestore } from '../../../../config/firebase/fbConfig';

import { asyncRefs } from '../../../../../src/useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    M7d_InitData: () => {
      action.asyncDispatch({ type: 'S4_GET_AdmPar' });
      action.asyncDispatch({ type: 'M7d_GET_Orders' });

      return {
        ...state,
        M7d: {
          ...state.M7d,
        },
        M6: {},
        M7: {},
        M7a: {},
        M7b: {},
        M7c: {},
        M8: { ...state.M8, withdraw: undefined },
        basePersist: {
          ...state.basePersist,
          cart: {
            addedProds: {},
            totalAmount: 0,
          },
        },
      };
    },
  };

  const gets = {
    // ---------- set Shops List
    M7d_GET_Orders: () => {
      // ---------- set Data
      const currUser = state?.baseAuthUser?.docId;

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('orders');
        const dataSuccess = await dbRef.where('buyerId', '==', currUser).get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M7d_GET_Orders_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      const itemsInfo = {};
      const itemsList = [];
      let dateDesc = [];

      dataDb.forEach(doc => {
        itemsInfo[doc.id] = doc.data();
        dateDesc.push({ id: doc.id, createdAt: doc.data().createdAt });
      });

      dateDesc.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        return 0;
      });

      for (const item of dateDesc) {
        itemsList.push(item.id);
      }

      action.asyncDispatch({ type: 'M7d_GET_ShopInfo' });

      return {
        ...state,
        M7d: {
          ...state.M7d,
          itemsList,
          itemsInfo,
          condList: true,
        },
      };
    },

    M7d_GET_ShopInfo: () => {
      const info = state.M7d.itemsInfo;
      const objShopId = {};

      for (const key in info) {
        const itemId = info[key].shopId;
        objShopId[itemId] = itemId;
      }
      const shopId = Object.keys(objShopId);

      function getContentById(ids, path) {
        return new Promise(res => {
          // don't run if there aren't any ids or a path for the collection
          if (!ids || !ids.length || !path) return res([]);

          const collectionPath = firestore.collection(path);
          let batches = [];

          while (ids.length) {
            // firestore limits batches to 10
            const batch = ids.splice(0, 10);

            // add the batch request to to a queue
            batches.push(
              new Promise(response => {
                collectionPath
                  .where(firebase.firestore.FieldPath.documentId(), 'in', [
                    ...batch,
                  ])
                  .get()
                  .then(results =>
                    response(
                      results.docs.map(result => ({ ...result.data() })),
                    ),
                  );
              }),
            );
          }

          // after all of the data is fetched, return it
          Promise.all(batches).then(content => {
            res(content.flat());
          });
        });
      }

      const testeFn = async () => await getContentById(shopId, 'shops');

      // ---------- set Async References
      const ref = asyncRefs(action, testeFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M7d_GET_ShopInfo_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action?.value;
      const itemsInfo = state.M7d.itemsInfo;

      for (const key in itemsInfo) {
        const order = itemsInfo[key];

        dataDb.forEach(item => {
          const shop = item;
          const condSnap = shop.docId === order.shopId;
          if (condSnap) {
            itemsInfo[key] = {
              ...itemsInfo[key],
              imgUrl: shop.imgUrl,
            };
          }
        });
      }

      return {
        ...state,
        M7d: {
          ...state.M7d,
          itemsInfo,
        },
      };
    },
  };

  const edits = {
    M7d_OrderPF: () => {
      const itemId = action.value;
      const currOrder = state?.M7d?.itemsInfo[itemId];
      action.asyncDispatch({ type: 'comps_HistoryPath' });
      action.asyncDispatch({ type: 'base_setRoute', value: 'purchasePf' });

      return {
        ...state,
        M7e: {
          currOrder,
        },
      };
    },

    M7d_BtnPay: () => {
      const itemId = action.value;
      const currOrder = state?.M7d?.itemsInfo[itemId];
      action.asyncDispatch({ type: 'comps_HistoryPath' });
      action.asyncDispatch({ type: 'M7e_InitData' });

      return {
        ...state,
        M7e: {
          currOrder,
        },
      };
    },
  };

  return {
    ...inits,
    ...gets,
    ...edits,
  };
};

// ---------- set Exports
export default reducers;
