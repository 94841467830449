// ---------- import Internals
import {
  firestore,
  firebase,
  // storage
} from '../../config/firebase/fbConfig';
import { asyncRefs, ezLog } from '../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    comps_InitMenu: () => {
      // ---------- set Data
      const typeAccount = state?.baseAuthUser?.typeAccount;
      const routesInfo = state?.baseRoute?.routesInfo;
      const arrAllSc = [...Object.values(routesInfo)];

      // ---------- set Adm or Shop
      const condArr = arrAllSc.filter(item => item.groupSc === typeAccount);

      // ---------- set Items Menu
      const arrOnlyLayout = condArr.filter(item => item.layout);
      const logout = {
        layout: { icon: 'exit', title: 'Sair' },
      };
      arrOnlyLayout.push(logout);

      return {
        ...state,
        comps: {
          ...state.comps,
          sideLeft: {
            items: arrOnlyLayout,
          },
        },
      };
    },

    comps_InitCart: () => {
      action.asyncDispatch({ type: 'comps_HistoryPath' });
      // ---------- set Data
      const path = state?.baseRoute?.path;
      const isCart = action.value ?? null;

      let addedProds = {};
      let totalAmount = 0;

      if (isCart) {
        const { actValProds, actValAmount } = action?.value;

        addedProds = actValProds;
        totalAmount = actValAmount;
      }

      return {
        ...state,
        basePersist: {
          ...state.basePersist,
          cart: {
            totalAmount: totalAmount,
            addedProds: { ...addedProds },
          },
        },
      };
    },

    comps_SideRight: () => {
      const pathRight = state?.baseRoute?.pathRight;
      const selectedRoute = state?.baseRoute?.routesInfo[pathRight];
      const sideRight = selectedRoute && selectedRoute?.sideRight;

      return {
        ...state,
        comps: {
          ...state.comps,
          sideRight: {
            ...state.comps.sideRight,
            title: sideRight.title,
            options: false,
            condShowOpt: false,
          },
        },
      };
    },
  };
  const changes = {
    comps_BlockAccount: () => {
      action.asyncDispatch({ type: 'comps_Logout' });
      return {
        ...state,
        A1: {
          ...state,
          msgSuspended: true,
        },
      };
    },
    comps_ToggMenu: () => {
      const sttMenu = state?.comps?.sidemenu?.sttMenu;

      return {
        ...state,
        comps: {
          ...state.comps,
          sidemenu: {
            ...state.comps.sidemenu,
            sttMenu: !sttMenu,
          },
        },
      };
    },
    // ---------- set Update All Prods
    comps_UpdateShop: () => {
      const currShop = state.baseAuthUser.shopId;

      /// ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('shops').doc(currShop);
        const dataSuccess = await dbRef.update({
          prodsOutdated: false,
        });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    comps_UpdateShop_SUCCESS: () => {
      action.asyncDispatch({ type: 'comps_UpdateProds' });
      return state;
    },

    comps_UpdateProds: () => {
      // ---------- set Data
      const currShopId = state?.baseAuthUser?.shopId;
      const currShopInfo = state?.S1?.currShopInfo;
      const arrProds = Object.keys(currShopInfo);

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const itemRefs = arrProds.map(id => {
          const fieldToUpdt = {
            lastUpdate: firebase.firestore.Timestamp.now(),
          };

          return firestore
            .collection('shops')
            .doc(currShopId)
            .collection('prods')
            .doc(id)
            .update(fieldToUpdt);
        });

        const dataSuccess = Promise.all(itemRefs);

        // // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    comps_UpdateProds_SUCCESS: () => {
      return {
        ...state,
        S1: {
          ...state.S1,
          accountStatus: 'active',
        },
      };
    },

    // ---------- set CHANGE Comps _Name_
    comps_ChangeName: () => {
      return {
        ...state,
        sttName: { ...action.value },
      };
    },

    // ---------- set CHANGE Comps _Logout_
    comps_LogoutApp: () => {
      // ---------- set Async Function
      const asyncFn = async () => firebase.auth().signOut();

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    comps_LogoutApp_SUCCESS: () => {
      return {
        ...state,
        baseAuthUser: {},
        baseRoute: {
          ...state.baseRoute,
          path: 'signin2',
          pathRight: null,
        },
      };
    },

    comps_Logout: () => {
      return {
        ...state,
        basePersist: {
          ...state.basePersist,
          userId: null,
        },
        baseRoute: {
          ...state.baseRoute,
          path: 'signin',
          pathRight: null,
        },
      };
    },

    // ---------- set CHANGE Comps _Close Menu Right_
    comps_CloseRight: () => {
      return {
        ...state,
        baseRoute: {
          ...state.baseRoute,
          pathRight: null,
        },
      };
    },
    // ---------- set CHANGE Comps _Toogle Options
    comps_ToggleOpt: () => {
      const toggle = !state.comps.sideRight.condShowOpt;

      return {
        ...state,
        comps: {
          ...state.comps,
          sideRight: {
            ...state.comps.sideRight,
            condShowOpt: toggle,
          },
        },
      };
    },

    comps_EditAddress: () => {
      action.asyncDispatch({ type: 'comps_HistoryPath' });

      return {
        ...state,
        baseAuthUser: {
          ...state.baseAuthUser,
          address: {
            ...state.baseAuthUser.address,
            edit: true,
          },
        },
      };
    },

    comps_goBack: () => {
      const path = state?.baseRoute?.path;
      const counter = state?.baseRoute?.historyPath?.counter;
      const paths = state?.baseRoute?.historyPath?.paths;
      const sub = counter - 1;
      action.asyncDispatch({ type: 'base_setRoute', value: paths[sub] });

      return {
        ...state,
        baseRoute: {
          ...state.baseRoute,
          historyPath: {
            ...state.baseRoute.historyPath,
            counter: sub,
          },
        },
      };
    },

    comps_HistoryPath: () => {
      const path = state?.baseRoute?.path;
      const counter = state?.baseRoute?.historyPath?.counter;
      const sum = counter + 1;
      return {
        ...state,
        baseRoute: {
          ...state.baseRoute,
          historyPath: {
            ...state.baseRoute.historyPath,
            counter: sum,
            paths: {
              ...state.baseRoute.historyPath.paths,
              [sum - 1]: path,
            },
          },
        },
      };
    },

    comps_Search: () => {
      const itemsList = [];
      const search = action.value;
      const allProds = state?.M7b?.itemsInfo;
      const newArr = Object.values(allProds);

      newArr
        .filter(({ name }) => name.indexOf(search) !== -1)
        .map(item => {
          itemsList.push(item.prodIdVar);
        });

      return {
        ...state,
        M7b: {
          ...state.M7b,
          itemsList,
        },
      };
    },

    comps_InitM7b: () => {
      action.asyncDispatch({ type: 'base_setRoute', value: 'orderEdit' });

      return {
        ...state,
        M7b: {
          ...state.M7b,
          idToSearch: action.value,
        },
      };
    },

    comps_SimilarProds: () => {
      const currProd = state?.M7a?.allProds?.itemsInfo[action.value];
      const subCatId = currProd?.subCateg;
      const shopProds = state?.M7b?.itemsInfo;
      const itemsList = [];

      for (const key in shopProds) {
        const item = shopProds[key];
        const condFilter = item.subCateg === subCatId;

        condFilter && itemsList.push(key);
      }

      return {
        ...state,
        M2: {
          ...state.M2,
          filterProds: {
            ...state.M2.filterProds,
            subCategActive: subCatId,
          },
        },
        M7b: {
          ...state.M7b,
          itemsList,
        },
      };
    },
  };
  const edits = {
    comps_changeIptTxt: () => {
      const currSc = state?.baseRoute?.currScInfo.scKey;
      const sc = action.sc;
      const condSc = sc ?? currSc;

      const key = action.fieldName;
      const value = action.value;
      const isNumInt = action.numInt;

      if (isNumInt) {
        const numInt = isNumInt;

        return {
          ...state,
          [condSc]: {
            ...state[condSc],
            forms: {
              ...state[condSc].forms,
              iptsChanges: {
                ...state[condSc]?.forms?.iptsChanges,
                [key]: { value, numInt },
              },
            },
          },
        };
      }

      return {
        ...state,
        [condSc]: {
          ...state[condSc],
          forms: {
            ...state[condSc].forms,
            iptsChanges: {
              ...state[condSc]?.forms?.iptsChanges,
              [key]: value,
            },
          },
        },
      };
    },

    // ---------- set FILTER CATEGS
    // ----------------------------

    comps_initFilter: () => {
      // ----- Categ Field
      const typeAccount = state?.baseAuthUser.typeAccount;
      const isAdm = typeAccount === 'adm1';
      const currSc = isAdm ? 'B2' : 'S1';
      const condCateg = state[currSc]?.forms?.iptsChanges?.categ ?? '';
      const currCateg = condCateg ?? '0';

      // ----- SubCateg Field
      const condSubCateg = state[currSc]?.forms?.iptsChanges?.subcateg ?? '';
      const currSubCateg = condSubCateg;

      // ----- Esse if apenas quebra a
      //------- função comps_initFilter
      const noCateg = condCateg?.length === 0;
      const noSubCateg = condSubCateg?.length === 0;
      const condDontFilter = noCateg && noSubCateg;
      if (condDontFilter) {
        return state;
      }

      // ----- Apenas Categ Selecionado
      let condPar1 = 'categ';
      let condPar2 = currCateg;

      // ----- Quando Tem SubCateg Selecionado
      if (!!condSubCateg) {
        condPar1 = 'subCateg';
        condPar2 = currSubCateg;
      }

      const asyncBase = async () => {
        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('prods')
          .where(condPar1, '==', condPar2)
          .orderBy('createdAt', 'desc')
          .orderBy('docId', 'desc')
          .limit(30)
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        [currSc]: {
          ...state[currSc],
          condList: false,
        },
      };
    },

    comps_initFilter_SUCCESS: () => {
      // ---------- set Data to Show
      const typeAccount = state?.baseAuthUser.typeAccount;
      const isAdm = typeAccount === 'adm1';
      const currSc = isAdm ? 'B2' : 'S1';
      const dataDb = action.value;

      const itemsInfo = {};
      const itemsList = [];
      let lastDocId;
      let lastDate;

      const currShopInfo = state?.S1?.currShopInfo;
      const prodsPrice = currShopInfo && Object.values(currShopInfo);

      dataDb.forEach(doc => {
        const fillFirst =
          currShopInfo && prodsPrice.some(i => i.prodId === doc.id);

        if (fillFirst) {
          itemsInfo[doc.id] = doc.data();
          itemsList.unshift(doc.id);
        } else {
          itemsInfo[doc.id] = doc.data();
          itemsList.push(doc.id);
        }

        lastDocId = doc.id;
        lastDate = doc.data().createdAt;
        // theDate = new Date(lastDate.seconds * 1000);
      });

      // dataDb.forEach(doc => {
      //   itemsInfo[doc.id] = doc.data();
      //   itemsList.push(doc.id);

      //   lastDocId = doc.id;
      //   lastDate = doc.data().createdAt;
      // });

      return {
        ...state,
        [currSc]: {
          ...state[currSc],
          forms: {
            ...state[currSc].forms,
            iptsChanges: {},
          },
          itemsList,
          itemsInfo,
          condList: true,
          lastDocId,
          lastDate,
        },
      };
    },

    // ---------- set SEARCH TERMS
    // ---------------------------

    comps_initTerms: () => {
      const typeAccount = state?.baseAuthUser?.typeAccount;
      const isAdm = typeAccount === 'adm1';
      const currSc = isAdm ? 'B2' : 'S1';
      const currTerm = state[currSc].forms?.iptsChanges?.terms ?? '';
      const condDontSearch = currTerm?.length === 0;
      const removeAcents = currTerm
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const lowrCsTerm = removeAcents.toLowerCase();
      const arrTerms = lowrCsTerm.split(/[\s,]+/);

      // ----- Esse if apenas quebra a
      //------- função comps_initTerms
      if (condDontSearch) {
        return state;
      }

      const asyncBase = async () => {
        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('prods')
          .where('search', 'array-contains-any', arrTerms)
          .orderBy('docId', 'desc')
          .limit(80)
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        [currSc]: {
          ...state[currSc],
          condList: false,
        },
      };
    },

    comps_initTerms_SUCCESS: () => {
      const typeAccount = state?.baseAuthUser?.typeAccount;
      const isAdm = typeAccount === 'adm1';
      const currSc = isAdm ? 'B2' : 'S1';
      const condPath = isAdm ? 'prodList' : 'prodsShopList';
      const termSearch = state[currSc].forms.iptsChanges.terms;
      const removeAcents = termSearch
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const arrSearch = removeAcents.toLowerCase().split(/[\s,]+/);
      const arrLength = arrSearch.length;
      // const initTerm = arrSearch[0];
      const dataDb = action.value;
      const itemsInfo = {};
      const objTemp = [];
      let tempArr = [];
      let itemsList = [];
      let lastDocId;
      // let lastDate;
      const currShopInfo = state?.S1?.currShopInfo;

      // ---------- ETAPA 1 SCORE
      dataDb.forEach(doc => {
        const frmtdProdName = doc
          .data()
          .name.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        const arrWordsName = frmtdProdName.split(/[\s,]+/);
        const initWordName = arrWordsName[0];

        let score = 0; // Zera a cada DOC / Produto
        for (const key in arrSearch) {
          const item = arrSearch[key];
          // verifica se a string contém o termo
          const isScorer = frmtdProdName.includes(item);

          if (isScorer) {
            // cada termo correspondente conta 1
            score = score + 1;
            // compara primeiro termo do name
            // do produto com cada keyword
            // quando true conta mais 1 ponto
            if (initWordName === item) {
              score = score + 1;
            }
            const scoreBase = arrLength - 1;

            // --- prods com preço aumentam score p/ ficar no topo
            const prodsPrice = currShopInfo && Object.values(currShopInfo);
            const fillFirst =
              currShopInfo && prodsPrice.some(i => i.prodId === doc.id);
            if (fillFirst) {
              score = score + 1;
            }
            // dependendo de quantos termos digitados
            // mudar referência de relevância
            const minScore = score > scoreBase;
            if (minScore) {
              objTemp[doc.id] = { docId: doc.id, score };
              itemsInfo[doc.id] = doc.data();
            }
          }
        }

        lastDocId = doc.id;
      });

      // ---------- ETAPA 2 ORDENAR POR
      // ---------- SCORE OU RELEVÂNCIA
      tempArr = Object.values(objTemp);
      tempArr.sort(function (a, b) {
        if (a.score > b.score) {
          return -1;
        }
        if (a.score < b.score) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });

      // ---------- ETAPA 3 ALIMENTAR
      // ------------------ ITEMSLIST
      for (const key in tempArr) {
        const item = tempArr[key];
        itemsList.push(item.docId);
      }

      return {
        ...state,
        [currSc]: {
          ...state[currSc],
          forms: {
            ...state[currSc].forms,
            iptsChanges: {
              terms: state[currSc].forms.iptsChanges.terms,
            },
          },
          itemsList,
          itemsInfo,
          condList: true,
          lastDocId,
          // lastDate,
        },
        baseRoute: {
          ...state.baseRoute,
          path: condPath,
        },
      };
    },

    comps_nextQuery: () => {
      const currSc = state?.baseRoute?.currScInfo?.scKey;
      const lastDocId = state[currSc].lastDocId;
      const currTerm = state[currSc].forms?.iptsChanges?.terms ?? '';
      const removeAcents = currTerm
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const lowrCsTerm = removeAcents.toLowerCase();
      const arrTerms = lowrCsTerm.split(/[\s,]+/);

      const asyncBase = async () => {
        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('prods')
          .where('search', 'array-contains-any', arrTerms)
          .orderBy('docId', 'desc')
          .startAfter(lastDocId)
          .limit(80)
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return { ...state };
    },
    comps_nextQuery_SUCCESS: () => {
      const dataDb = action.value;
      const currSc = state?.baseRoute?.currScInfo?.scKey;
      const termSearch = state[currSc].forms.iptsChanges.terms;
      const arrSearch = termSearch.toLowerCase().split(/[\s,]+/);
      const arrLength = arrSearch.length;
      const objTemp = [];
      let tempArr = [];
      let lastDocId;

      // const itemsInfo = {};
      // let itemsList = [];
      const itemsInfo = { ...state[currSc].itemsInfo };
      const itemsList = [...state[currSc].itemsList];

      // ---------- ETAPA 1 SCORE
      dataDb?.forEach(doc => {
        const frmtdProdName = doc
          .data()
          .name.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        let score = 0; // Zera a cada DOC / Produto
        for (const key in arrSearch) {
          const item = arrSearch[key];
          const isScorer = frmtdProdName.includes(item);
          if (isScorer) {
            score = score + 1;
            const scoreBase = arrLength - 1;
            const minScore = score > scoreBase;
            if (minScore) {
              objTemp[doc.id] = { docId: doc.id, score };
              itemsInfo[doc.id] = doc.data();
            }
          }
        }

        lastDocId = doc.id;
      });

      // ---------- ETAPA 2 ORDENAR POR
      // ---------- SCORE OU RELEVÂNCIA
      tempArr = Object.values(objTemp);
      tempArr.sort(function (a, b) {
        if (a.score > b.score) {
          return -1;
        }
        if (a.score < b.score) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });

      // ---------- ETAPA 3 ALIMENTAR
      // ------------------ ITEMSLIST
      for (const key in tempArr) {
        const item = tempArr[key];
        itemsList.push(item.docId);
      }

      return {
        ...state,
        [currSc]: {
          ...state[currSc],
          itemsList,
          itemsInfo,
          condList: true,
          lastDocId,
        },
      };
    },

    // ---------- set DELETE _Prod
    comps_ConfirmDel: () => {
      return {
        ...state,
        comps: {
          ...state.comps,
          sideRight: {
            ...state.comps.sideRight,
            msgConfirm: true,
            condShowOpt: false,
          },
        },
      };
    },

    comps_CancelDel: () => {
      return {
        ...state,
        comps: {
          ...state.comps,
          sideRight: {
            ...state.comps.sideRight,
            condShowOpt: false,
            msgConfirm: false,
          },
        },
      };
    },

    comps_DeleteItem: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Data to Delete
        const currPath = state?.baseRoute?.path;
        let selectColl = '';
        let selectId = '';
        let dataSuccess = null;
        const arrRefProds = [];

        // ---------- set Cond Delete
        switch (currPath) {
          case 'prodList':
            selectId = 'C2';
            selectColl = 'prods';
            break;
          case 'shopProdsList':
            selectId = 'C2';
            selectColl = 'prods';
            break;
          case 'shopsList':
            selectId = 'C1';
            selectColl = 'shops';
            break;
          default:
            console.log('');
        }

        // ---------- set Delete Shops Refers (SubCollections)
        const condProdDel = currPath === 'prodList';
        const idToEdit = state[selectId].idToEdit;

        if (condProdDel) {
          const refProds = firestore
            .collectionGroup('prods')
            .where('prodId', '==', idToEdit)
            .get();
          refProds.then(snapshot => {
            snapshot.forEach(doc => {
              doc.ref.delete();
            });
          });
        }

        // ---------- set Cond Delete (Shops or Prods)
        let refBaseProd = firestore.collection(selectColl).doc(idToEdit);
        dataSuccess = await refBaseProd.delete();

        // ---------- set Delete User Owner (When Shop Excluded)
        const condUserDel = currPath === 'shopsList';
        if (condUserDel) {
          const refProds = firestore
            .collection('users')
            .where('shopId', '==', idToEdit)
            .get();
          refProds.then(snapshot => {
            snapshot.forEach(doc => {
              doc.ref.delete();
            });
          });
        }

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return { ...state };
    },

    comps_DeleteItem_SUCCESS: () => {
      return {
        ...state,
        comps: {
          ...state.comps,
          sideRight: {
            ...state.comps.sideRight,
            condShowOpt: false,
            msgConfirm: false,
          },
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: null,
        },
      };
    },
  };

  // ---------- set Reducers Return
  return {
    ...inits,
    ...changes,
    ...edits,
  };
};

// ---------- set Exports
export default reducers;
