// ----------- import Packs
import React from 'react';
import { View } from 'react-native';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF, { TxtInputView } from './Views';
import {
  useRouter,
  UseInitData,
  useData,
  UseAutoComplete,
  UseList,
  useSnapDb,
} from '../../../../useMorfos';
import ItemProd from '../../../comps/App/ItemProd';
import { NoItemView, ItemCateg } from '../../../comps';
import { useDispatch } from 'react-redux';
import { ezLog } from '../../../../useMorfos/utils';

// ----------- set Info Screen
export const infoSc = {
  path: 'search',
  groupSc: 'pubApp',
  condBigSc: true,

  scCode: 'M4',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const search = useData('M4.forms.iptsChanges.search');
  const condCateg = useData('M4.currCateg');
  const condRes = search === '';
  const content = useData('M4.scContent');

  // ----------- set Hooks
  const { callRouter } = useRouter();
  const dispatch = useDispatch();

  // // ----------- set Snap Hooks
  // const snapProdsBase = {
  //   collectionName: 'prods',
  //   orderField: 'createdAt',
  //   orderType: 'desc',
  //   reducerGet: 'M4_GET_ProdsBase',
  //   reducerSuccess: 'M4_GET_ProdsBase_SUCCESS',
  //   firstCallData: 'M4.prodsBase.firstCall',
  //   itemsInfoData: 'M4.prodsBase.itemsInfo',
  //   condListData: 'M4.prodsBase.condList',
  // };

  // useSnapDb(snapProdsBase);

  // ----------- set Routes
  const historyPath = useData('baseRoute.historyPath');
  // const btnBack = () => callRouter(historyPath);
  const btnBack = () => dispatch({ type: 'comps_goBack' });

  // ----------- set Others Lists
  const ListCategs = () => {
    return (
      <UseList data={'M4.arrCatList'}>
        {(itemId, noItem) =>
          noItem ? (
            <NoItemView />
          ) : (
            <ItemCateg
              infoData={{
                list: 'M4.catList',
                change: 'M4_ChangeCateg',
                itemId,
              }}
            />
          )
        }
      </UseList>
    );
  };

  const ListResCatg = () => {
    return (
      <UseList data={'M4.filterProds.itemsList'}>
        {(itemId, noItem) =>
          noItem ? (
            <NoItemView />
          ) : (
            <ItemProd
              infoData={{ itemId, reducer: 'M4.filterProds.itemsInfo' }}
            />
          )
        }
      </UseList>
    );
  };

  const AutoComplete = () => {
    return (
      <UseList data={'M4.search.itemsList'}>
        {(itemId, noItem) =>
          noItem ? (
            <></>
          ) : (
            <ItemProd infoData={{ itemId, reducer: 'M4.search.itemsInfo' }} />
          )
        }
      </UseList>
    );
  };

  // ----------- set Return
  const infoView = {
    content,
    ListCategs,
    ListResCatg,
    AutoComplete,
    condRes,
    condCateg,
    TxtInput,
    btnBack,
  };

  return (
    <UseInitData reducer={'M4_InitData'}>
      <ViewDF info={infoView} />
    </UseInitData>
  );
};

const TxtInput = () => {
  // ----------- set Data
  const autocomplete = useData('M4.forms.iptsChanges.autocomplete');

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Forms
  const iptChange = txt => {
    dispatch({ type: 'M4_IptChange', value: txt, field: 'search' });
  };

  // ----------- set Return
  const infoReturn = {
    iptChange,
    autocomplete,
  };
  return <TxtInputView info={infoReturn} />;
};
