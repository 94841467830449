/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';

// ----------- import Internals
import { useStl } from '../../../../../../src/useMorfos/index';

// #region :: STYLEs *********

//----------- set Views
const stlBodyView = [useStl.flexCenter, { backgroundColor: '#f6f6f6' }];
const stlImg1 = [{ height: 60, width: 60, marginTop: 40, borderRadius: 65 }];
const stlImg2 = {
  width: 40,
  height: 40,
};
const stlNAV1 = [
  useStl.navbarView,
  useStl.flex1,
  useStl.border(0, 0, 1, 0, '#ddd'),
  { top: 10, shadowOpacity: 0, overflow: 'hidden' },
];
const stlTxtSubTitle = [
  useStl.txtPrimaryInverse,
  { textAlign: 'left', fontSize: 9, margin: 0, lineHeight: 10, color: '%999' },
];
const stlLeft = [useStl.leftBox];
const stlTxtBar = [useStl.flex1, { marginLeft: 9, alignItems: 'flex-start' }];
const stlTxtTitle = [
  useStl.txtPrimaryInverse,
  {
    textAlign: 'left',
    fontSize: 15,
    fontWeight: 700,
    margin: 0,
    color: '%999',
  },
];

//----------- set Perfil
const stlPerfil = [useStl.flexRow];
const stlTopper = [useStl.flex1, { width: '80%' }];
const stlPerfil2 = [useStl.flex1, { marginTop: 20, marginLeft: 10 }];
const stlName = [{ fontSize: 14, fontWeight: 700, color: '#4a4a4a' }];
const stlMail = [{ fontWeight: 600, color: 'gray' }];

//----------- set Cards
const stlCard = [useStl.card, { width: '90%', marginTop: 20 }];
const stlDados = [useStl.flex1, { marginBottom: 10 }];
const stlStatus = [{ fontSize: 13, fontWeight: 700, color: '#2b2a27' }];
const stlStatus2 = [{ fontSize: 13, fontWeight: 600, color: 'gray' }];
const stlTxpag = [{ fontSize: 13, fontWeight: 600, color: '#ffa20f' }];
const stlTxpag2 = [{ fontSize: 13, fontWeight: 600, color: 'red' }];
const stlTxpag3 = [{ fontSize: 13, fontWeight: 600, color: 'green' }];

//----------- set Btns
const stlCenter1 = [useStl.flexCenter, { marginVertical: 10 }];
const stlBtn1 = [
  useStl.btn,
  useStl.btnMedium,
  {
    backgroundColor: '#293A0F',
    fontSize: 10,
    fontWeight: 700,
    width: 140,
    marginBottom: 5,
  },
];
const stlBtnPrimary1 = [stlBtn1, useStl.btnPrimary];
const stltxtInverse1 = [
  useStl.txtPrimaryInverse,
  { fontSize: 9, fontWeight: 700 },
];

// #endregion *********

export default ({ info }) => {
  return <></>;
};
