import { name } from '../package.json';

export default {
  name: name,
  displayName: name,

  // --- DEV
  // fbWebConfig: {
  //   apiKey: 'AIzaSyD4ReTKa9fd2mzN4WBGsW78JLeniOQRwv8',
  //   authDomain: 'poupi-dev.firebaseapp.com',
  //   projectId: 'poupi-dev',
  //   storageBucket: 'poupi-dev.appspot.com',
  //   messagingSenderId: '29175705956',
  //   appId: '1:29175705956:web:48877302ab83762e400a71',
  //   persistence: true,
  // },

  // --- PRODUÇÃO
  fbWebConfig: {
    apiKey: 'AIzaSyBFUBTIBf0yaxbNoWJgbk8dqEbG4lu9Cco',
    authDomain: 'modelopoupi.firebaseapp.com',
    databaseURL: 'https://modelopoupi.firebaseio.com',
    projectId: 'modelopoupi',
    storageBucket: 'modelopoupi.appspot.com',
    messagingSenderId: '193644844772',
    appId: '1:193644844772:web:87d2f773739e29b553bef1',
    persistence: true,
  },
};
