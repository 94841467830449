// ---------- import Internals
import { firestore } from '../../../../config/firebase/fbConfig';
import { asyncRefs } from '../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data
    M0_InitData: () => {
      const scContent = {
        1: {
          title: '',
          desc: 'Bem-vindo à Poupi!',
        },
        2: {
          title: 'Orçamentos',
          desc: 'Sempre compre no Supermercado mais barato.',
        },
        3: {
          title: 'Encartes',
          desc: 'Fique por dentro das melhores promoções.',
        },
        4: {
          title: '',
          desc: 'Compre sem precisar sair de casa.',
        },
      };

      const condData = true;

      return {
        ...state,
        M0: {
          ...state.M0,
          scContent,
          dotsList: ['1', '2', '3', '4'],
          currStep: 1,
          condData,
        },
      };
    },
  };

  const edits = {
    M0_NextStep: () => {
      const currStep = state?.M0?.currStep;
      const nextStep = Number(currStep) + 1;

      return {
        ...state,
        M0: {
          ...state.M0,
          currStep: nextStep,
        },
      };
    },

    M0_PrevStep: () => {
      const currStep = state?.M0?.currStep;
      const prevStep = Number(currStep) - 1;

      return {
        ...state,
        M0: {
          ...state.M0,
          currStep: prevStep,
        },
      };
    },

    M0_GoToStep: () => {
      const itemId = action.value;

      return {
        ...state,
        M0: {
          ...state.M0,
          currStep: itemId,
        },
      };
    },

    // ---------- set Jump Onboarding
    M0_SetFinish: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        const currUser = state?.baseAuthUser?.docId;
        // ---------- set Async Call
        const dbRef = firestore.collection('users').doc(currUser);
        const dataSuccess = await dbRef.update({
          'infos.jumpOnboarding': true,
        });
        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return state;
    },

    M0_SetFinish_SUCCESS: () => {
      action.asyncDispatch({ type: 'base_setRoute', value: 'address' });
      return {
        ...state,
        aa: {},
        baseAuthUser: {
          ...state.baseAuthUser,
          infos: {
            jumpOnboarding: true,
          },
        },
        baseRoute: {
          ...state.baseRoute,
          path: 'address',
        },
      };
    },
  };

  return {
    ...inits,
    ...edits,
    // ...btns,
  };
};

// ---------- set Exports
export default reducers;
