/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';

// ----------- import Internals
import { primaryColor } from '../../../../../config/styles';
import { useData, useStl } from '../../../../../useMorfos';
import { ezLog } from '../../../../../useMorfos/utils';
import { IptPicker, IptText } from '../../../../comps';

export default ({ info, children }) => {
  const itemId = useData('S1b.edit');
  const editProds = useData('S1c.editProds');
  const condPicks = useData('S1c.condPicks');
  const currOrder = useData('S1b.itemsInfo', itemId);
  const { imgUrl, name, statusOrder } = currOrder;
  const { btnConfirm, btnPaid, btnPrint, btnBack, setJsx } = info;
  const condTxt = editProds ? 'Cancelar' : 'Editar';

  return (
    <View style={stlBodyView}>
      <View
        id="teste"
        style={[
          useStl.flexRow,
          useStl.flexBetween,
          {
            backgroundColor: '#eee',
            padding: 6,
            marginBottom: 12,
            width: '100%',
          },
        ]}
      >
        <TouchableOpacity style={stlBtnPrint1} onPress={btnBack}>
          <Text style={stlTxtSmall}>Voltar</Text>
        </TouchableOpacity>
        <TouchableOpacity style={stlBtnPrint2} onPress={window.print}>
          <Text style={stlTxtSmall}>Imprimir</Text>
        </TouchableOpacity>
      </View>
      <View style={{ width: 680 }}>
        <View style={stlHeader}>
          <Image source={imgUrl} style={stlHeaderImg} />

          <View style={stlHeader01}>
            <Text style={stlSubTitle}>{name}</Text>
            <Text style={stl03}>Informação adicional</Text>
          </View>
        </View>
        <InfoCard info={{ ...currOrder, btnConfirm, btnPaid }} />
        <View style={stlEditHeader}>
          <Text style={stl01}>Produtos Solicitados</Text>
        </View>
        {children}
      </View>
    </View>
  );
};

const InfoCard = ({ info }) => {
  const {
    statusOrder,
    totalPrice,
    clientPhone,
    buyerAddress,
    btnConfirm,
    btnPaid,
    createdAt,
  } = info;

  // set Date
  const date = new Date(createdAt.seconds * 1000);

  const condAwait = statusOrder === 'Confirmar';
  const condConfirm = statusOrder === 'Aguardando Pagamento';
  const condPay = statusOrder === 'Pagamento Realizado';
  const condBtns =
    statusOrder === 'Pedido Entregue' || statusOrder === 'Recebido';
  const condStatus = condConfirm ? stlConfirm : stlReality;

  const stlStatus = condAwait ? stlWaiting : condStatus;

  return (
    <>
      <View style={stlHeaderStatus}>
        <Text style={[stlSubTitle, { fontSize: 9 }]}>STATUS</Text>
        <View style={stlBetween}>
          <Text style={stlStatus}>{statusOrder.toUpperCase()}</Text>
        </View>
      </View>

      <View style={stlCard}>
        <Text style={stlSubTitle}>Data do Pedido:</Text>
        <Text style={stlBotLine}>{date.toLocaleDateString()}</Text>

        <Text style={stlSubTitle}>Telefone do Cliente</Text>
        <Text style={stlBotLine}>{clientPhone}</Text>

        <Text style={stlSubTitle}>Valor da Venda:</Text>
        <Text style={stlBotLine}>R${totalPrice}</Text>

        <Text style={stlSubTitle}>Tipo de ENTREGA:</Text>
        <Text style={stlBotLine}>Retirada do Local</Text>

        <Text style={stlSubTitle}>Endereço do Cliente:</Text>
        <Text>
          {buyerAddress.street}, {buyerAddress.street_number},{' '}
          {buyerAddress.neighborhood}
        </Text>
      </View>
    </>
  );
};

// #region :: STYLEs *********

// ------------- set Views
const stlBodyView = [useStl.flex1, { alignItems: 'center' }];
const flexRow = [useStl.flexRow];
const stl01 = {
  flex: 2,
  color: '#666666',
  marginHorizontal: 'auto',
};
const stlEditHeader = [flexRow, { marginBottom: 10 }];
const stl02 = [useStl.btn, useStl.btnXSmall, { height: 17 }];
const stlTxtBtn = { fontSize: 12 };
const stl03 = { color: '#666666', fontSize: 13, fontWeight: 'bold' };
const stlCard = [useStl.cardItemList];
const stlSubTitle = [{ fontWeight: 600, fontSize: 13, color: '#444' }];

// ---------- HEADER STYLE
const stlHeaderImg = [useStl.profileImg, { height: 55, width: 55 }];
const stlHeader = [useStl.flexRow, { margin: 'auto' }];
const stlHeader01 = [{ marginBottom: 20, marginLeft: 10 }];
const stlBotLine = { marginBottom: 14 };

// ---------- STATUS STYLE
const stlTxSmall = { fontSize: 10 };
const fontSmall = [stlTxSmall, { fontWeight: 600 }];
const stlConfirm = [fontSmall, useStl.flex4, { color: 'green' }];
const stlWaiting = [fontSmall, useStl.flex4, { color: '#f4a338' }];
const stlReality = [fontSmall, useStl.flex4, { color: '#fd5935' }];
const stlHeaderStatus = [useStl.card, { borderRadius: 8, marginTop: 2 }];
const stlBetween = [flexRow, useStl.flexBetween];
const stlBtnConfirm = [useStl.btn, useStl.btnXSmall, { height: 17 }];
const stlTxtBtn1 = [stlTxtBtn];
const stlTxtBtn2 = [stlTxtBtn, { color: 'orange' }];

// ---------- INCLUDE STYLE
const stlBtnMedium = [useStl.btn, useStl.btnMedium];
const stlBtnInclude = [stlBtnMedium, useStl.btnPrimary];
const stlTxtInverse = [
  useStl.txtPrimaryInverse,
  { fontSize: 10, fontWeight: 600 },
];
const stlInclude = [
  useStl.flexRow,
  useStl.flexBetween,
  useStl.border(1, 1, 1, 1, '#cfcfcf'),
  {
    backgroundColor: '#f3f3f3',
    borderRadius: 4,
    marginBottom: 16,
    padding: 8,
  },
];
const stlAddProd = [{}];
const stlCtnPicker = [{ flex: 1, marginRight: 10 }];
const stlTxtSmall = [{ fontSize: 10 }];
const stlBtnPrint1 = [stlBtnMedium, { backgroundColor: '#fff' }];
const stlBtnPrint2 = [stlBtnMedium, { backgroundColor: '#fff' }];

// #endregion *********
