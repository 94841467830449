// ----------- import Packs
import React from 'react';
import { View, Image, Text, TouchableOpacity, ScrollView } from 'react-native';

// ----------- import Internals
import { useStl, UseIcoMoon, useData } from '../../../../useMorfos';
import defaultImg from '../../../images/default.jpg';
import { NavBar } from '../../../comps';
import { currencyMask } from '../../../../useMorfos/utils';
import { useDispatch } from 'react-redux';
import SideMenu from '../../../comps/App/SideMenu';

export const Purchase = ({ infoData }) => {
  // ---------- set Data
  const { itemId, btnPay } = infoData;
  const itemsInfo = useData('M7.itemsInfo');
  const orderData = useData('M7d.itemsInfo', itemId);
  const { totalPrice, totalAmount, statusOrder, createdAt } = orderData;
  let currShop = {};

  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Curr Shop Data
  for (const key in itemsInfo) {
    const item = itemsInfo[key];
    const snapshot = item.shopId === orderData.shopId;

    if (snapshot) {
      currShop = { ...item };
    }
  }

  if (!itemsInfo) {
    currShop = orderData;
  }

  const objMonth = {
    0: 'JAN',
    1: 'FEV',
    2: 'MAR',
    3: 'ABR',
    4: 'MAI',
    5: 'JUN',
    6: 'JUL',
    7: 'AGO',
    8: 'SET',
    9: 'OUT',
    10: 'NOV',
    11: 'DEZ',
  };
  const { imgUrl } = currShop;
  const condThumb = imgUrl ?? defaultImg;
  const priceInt = totalPrice.toFixed(2);
  const currency = `R$${currencyMask(priceInt, false)}`;
  const amount = `${totalAmount} Produtos`;
  let timeDate = new Date(null);
  timeDate.setTime(createdAt.seconds * 1000);
  const day = timeDate.getDate();
  const month = objMonth[timeDate.getMonth()];
  const year = String(timeDate.getFullYear());
  const abrevYear = year.substr(year.length - 2);
  const txtDate = `${month} ${abrevYear}`;

  // ---------- set Conds Status
  const condStatus = statusOrder !== 'Pedido Entregue';
  const waiting = statusOrder === 'Confirmar';
  const condPay = statusOrder === 'Aguardando Pagamento';
  const condRefused = statusOrder === 'Pagamento Recusado';
  const condCancel = statusOrder === 'Cancelado';
  const condCheck = condRefused || condCancel ? 'slash' : 'check';
  const condIcon = waiting ? 'bag' : condCheck;
  const condColor = condRefused || condCancel ? 'red' : 'green';

  // ---------- set Btns
  const btnGoTo = () => dispatch({ type: 'M7d_OrderPF', value: itemId });

  // ---------- set Return
  return (
    <TouchableOpacity style={stlCard} onPress={btnGoTo}>
      <View style={stlRowDate}>
        <View style={stlImg1}>
          <Image style={stlThumb} source={condThumb} />
        </View>
        <View style={stlPrice}>
          <Text style={stlTxt7}>{currency}</Text>
          <Text style={stlTxt8}>{amount}</Text>
        </View>
        <View style={stlDate}>
          <Text style={stlTxt4}>{day}</Text>
          <Text style={stlTxt15}>{txtDate}</Text>
        </View>
      </View>

      {condStatus && (
        <View style={stlStatus}>
          <View style={stlRowStts}>
            <View style={stlRow}>
              <UseIcoMoon name={condIcon} size={15} color={condColor} />
              <Text style={stlTxt17}>{statusOrder}</Text>
            </View>
            {condPay && (
              <TouchableOpacity style={stlPay} onPress={() => btnPay(itemId)}>
                <Text style={stlPayTxt}>Pagar</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      )}
    </TouchableOpacity>
  );
};

export default ({ children }) => {
  return (
    <>
      <SideMenu />
      <NavBar />
      <ScrollView style={stlScrollView}>
        <View style={stlBodyView}>{children}</View>
      </ScrollView>
    </>
  );
};

// #region :: STYLEs *********
// --- Views
const stlBodyView = [useStl.bodyViewApp];
const stlScrollView = [useStl.bodyViewApp, { marginTop: 0, padding: 0 }];

const stlCard = [useStl.cardMask, { borderRadius: 8 }];
const stlRow = [useStl.flexRow];
const stlRowDate = [stlRow, { padding: 12 }];
const stlPrice = [useStl.flex4];
const stlDate = [useStl.flex1, useStl.flexCenter];
const stlStatus = [useStl.border(1, 0, 0, 0, '#ddd'), { padding: 10 }];
const stlRowStts = [stlRow, useStl.flexBetween];

// --- Btns
const stlPay = [
  useStl.border(2, 2, 2, 2, '#fd7627'),
  { paddingHorizontal: 12, paddingVertical: 3, borderRadius: 90 },
];
const stlPayTxt = [{ color: '#fd7627', fontSize: 11, fontWeight: 600 }];

// --- Fonts
const stlTxt4 = {
  fontSize: 20,
  fontWeight: 600,
  color: '#444',
};
const stlTxt7 = [
  {
    fontSize: 17,
    fontWeight: 600,
    color: '#444',
  },
];
const stlTxt8 = [
  {
    fontSize: 12,
    fontWeight: 600,
    color: 'gray',
  },
];
const stlTxt15 = [
  {
    fontSize: 9,
    fontWeight: 700,
    color: '#444',
  },
];
const stlTxt17 = [
  { marginLeft: 5, fontSize: 11, fontWeight: 600, color: '#333' },
];

// --- Imgs
const stlImg1 = [useStl.thumbnail];
const stlThumb = [useStl.imgFull];

// #endregion *********
