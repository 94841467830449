// ---------- import Internals
import { firestore } from '../../../../../config/firebase/fbConfig';
import { asyncRefs, ezLog } from '../../../../../useMorfos/utils';

// ---------- set Reducers
export default (state, action) => {
  const inits = {
    // ---------- set Init Content Data
    A0b_InitContentData: () => {
      const scContent = {
        title: 'Temp Users',
        subTitle: 'Oi Mundo!',
        description: 'Lorem Ipsum!',
        txtBtn: 'Ir para o Signin',
      };
      const condData = true;
      action.asyncDispatch({ type: 'A0b_GET_Users' });

      return {
        ...state,
        A0b: { ...state.A0b, scContent, condData },
      };
    },

    // ---------- set Shops List
    A0b_GET_Users: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('users');
        const dataSuccess = await dbRef
          .where('typeAccount', '==', 'shop1')
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    A0b_GET_Users_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      const usersInfo = {};
      const usersList = [];

      // where não aceita operador !=
      // evitar segunda consulta desnecessária == shop1 e == adm1
      usersInfo['xxx03'] = {
        docId: 'xxx03',
        email: 'joao@email.com',
        imgUrl:
          'https://images.unsplash.com/photo-1542909192-2f2241a99c9d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80',
        infos: { jumpOnboarding: true },
        name: 'João (ADM)',
        typeAccount: 'adm1',
      };
      usersList.push('xxx03');

      dataDb.forEach(doc => {
        usersInfo[doc.id] = doc.data();
        usersList.push(doc.id);
      });

      return {
        ...state,
        A0b: {
          ...state.A0b,
          usersList,
          usersInfo,
          condList: true,
        },
      };
    },
  };

  const others = {
    // ------------ set Login
    A0b_SetLogin: () => {
      const currUser = state?.A0b?.usersInfo[action.value];
      const condClient = currUser.typeAccount === 'app1';

      if (condClient) {
        return {
          ...state,

          baseRoute: {
            ...state.baseRoute,
            path: 'onboarding',
          },
          baseAuthUser: { ...currUser },
          // basePersist: {
          //   userId: action.value,
          // },
        };
      } else {
        return {
          ...state,

          baseRoute: {
            ...state.baseRoute,
            path: 'shopsList',
          },
          baseAuthUser: { ...currUser },
          basePersist: {
            ...state.basePersist,
            userId: action.value,
          },
        };
      }
    },
  };

  return {
    ...inits,
    ...others,
  };
};
