// ---------- import Internals
import { firestore } from '../../../../../config/firebase/fbConfig';
import { asyncRefs, ezLog } from '../../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    S1d_InitData: () => {
      return {
        ...state,
        S1c: {
          ...state.S1c,
          condList: true,
        },
      };
    },

    S1d_InitPrint: () => {
      // window.open('http://localhost:3000/printOrder', '_blank');

      return {
        ...state,

        baseRoute: {
          ...state.baseRoute,
          path: 'printOrder',
        },
      };
    },
  };

  return {
    ...inits,
  };
};

// ---------- set Exports
export default reducers;
