// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import { primaryColor } from '../../../../../config/styles';
import { useData, useStl } from '../../../../../useMorfos';
import { IptImg, IptTxt2 } from '../../../../comps';

// #region :: EXPORTs *********

export const VarTitle = () => <Text style={stlVarTxt}>VARIAÇÕES</Text>;

export const BtnImg = ({ infoData }) => {
  // ---------- set Data
  const { itemId } = infoData;
  const currImg = useData('S6.forms.iptsChanges.currImg');

  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Btns
  const btnSelectImg = () =>
    dispatch({ type: 'S6_IptSelectImg', value: itemId });

  // ---------- set Cond Stl Active
  const condId = currImg === itemId;
  const condStl1 = condId ? stlBtnImgAct : stlBtnImg;
  const condStl2 = condId ? stlBtnImgActTxt : stlBtnImgTxt;

  return (
    <TouchableOpacity style={condStl1} onPress={btnSelectImg}>
      <Text style={condStl2}>{itemId}</Text>
    </TouchableOpacity>
  );
};

export const CondImg = ({ info }) => {
  // ---------- set Data
  const { itemId } = info;
  const currImg = useData('S6.forms.iptsChanges.currImg');

  // ---------- set Cond Stl Active
  const condId = currImg === itemId;
  const condStl1 = condId ? stlShowView : stlHideView;

  return (
    <View style={condStl1}>
      <IptImg infoData={`S6.forms.iptsInfo.image${itemId}`} />
    </View>
  );
};

// #endregion *********

export default ({ info, children }) => {
  return (
    <View style={stlBodyView}>
      <Form info={info} />
      {children}
    </View>
  );
};

const Form = ({ info }) => {
  // ----- set View Return
  return (
    <>
      <View style={stlRowExpand}>
        <View style={stlBoxBtRow}>
          <info.ListBtnImg />
        </View>
      </View>
      <info.DynamicImage />

      <View style={stlBottomSpace}>
        <IptTxt2 sc={'S6'} fieldName={'name'} title={'Nome'} />
      </View>
      <IptTxt2
        sc={'S6'}
        fieldName={'expiDate'}
        title={'Data de expiração (dd/mm/aaaa)'}
        type="date"
      />

      <MsgErr />
      <View style={stlBoxBtn}>
        <TouchableOpacity style={stlBtnPrimary} onPress={info.btnSave}>
          <Text style={[stlBtnVarTxt, { color: '#fff' }]}>Salvar</Text>
        </TouchableOpacity>
        <TouchableOpacity style={stlBtn1} onPress={info.btnCancel}>
          <Text style={stlBtnVarTxt}>Cancelar</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

const MsgErr = () => {
  const msgErr = useData('S6.msgForm');

  return msgErr ? <Text style={{ color: 'red' }}>{msgErr}</Text> : <></>;
};

// #region :: STYLEs *********

// ------------- set Views
const stlBodyView = [useStl.flex1, { padding: 10 }];
const stlBottomSpace = [useStl.mgB20];
const stlBoxBtRow = [useStl.flexRow];
const stlShowView = [{}];
const stlHideView = [{ display: 'none' }];
const stlRowExpand = [useStl.flexRow, { marginBottom: 5 }];

// ------------- set Txts
const stlVarTxt = [
  { color: primaryColor, fontWeight: 600, fontSize: 10, textAlign: 'center' },
];

// ------------- set Btns
const stlBoxBtn = [useStl.flexCenter, { marginVertical: 40 }];

const stlBtnVarTxt = [{ fontSize: 12, color: '#444' }];
const stlBtnImgBase = [
  useStl.flexCenter,
  { borderRadius: 60, width: 20, height: 20, marginRight: 3 },
];
const stlBtnImg = [...stlBtnImgBase, useStl.border(2, 2, 2, 2, '#ccc')];
const stlBtnImgAct = [...stlBtnImgBase, useStl.border(2, 2, 2, 2, 'green')];
const stlBtnImgTxt = [{ fontSize: 12, color: '#666' }];
const stlBtnImgActTxt = [{ fontSize: 12, color: 'dargreen' }];
const stlBtn1 = [
  useStl.btn,
  useStl.btnMedium,
  {
    backgroundColor: '#fff',
    fontSize: 8,
    fontWeight: 700,
    width: 110,
    marginBottom: 5,
  },
];
const stlBtnPrimary = [stlBtn1, useStl.btnPrimary];
// #endregion *********
