// testCategs verifica se a Categoria ou
// SubCategoria contém produtos válidos ou
// seja Prods de Mercados da Região e com Preço
// Caso não tiver Prod. Válido não adiciona no

import { firebase } from '../../../../config/firebase/fbConfig';

// array que retorna e assim a lista é filtrada
export const testCategs = ({ arrCatOrSub, condKey, baseProds, prop }) => {
  const condParam = condKey ? arrCatOrSub[condKey] : arrCatOrSub;
  const tempObj = {};

  for (const key in condParam) {
    const item = condParam[key];

    for (const key2 in baseProds) {
      const currProd = baseProds[key2];
      const currBaseCateg = currProd?.dataProd[prop];

      const condAddId = currBaseCateg === item.id;

      if (condAddId) {
        tempObj[item.id] = item.id;
      }
    }
  }
  return Object.keys(tempObj);
};

export const checkShopsStatus = (currShopInfo, fnUpdate) => {
  // ----------- set Current Date
  const dateFs = firebase.firestore.Timestamp.now();
  const currDate = dateFs.toDate();

  // ---- Mount Obj Status
  const objShopsStatus = {};
  for (const key in currShopInfo) {
    const item = currShopInfo[key];
    const currShop = item?.shopId;

    // get Last Update Date
    const stampLastUpdt = item.lastUpdate ?? dateFs;
    const dateUptJs = stampLastUpdt.toDate();

    // set Difference Of Two Dates (in Time)
    const diffInTime = dateUptJs.getTime() - currDate.getTime();

    // set Total Days Difference
    const diffInDays = diffInTime / (1000 * 3600 * 24); // res is negative number
    const totalDays = Math.abs(diffInDays); // convert to positive number

    // set Status Account
    const condActive = totalDays < 5;
    const condAlert = totalDays > 5;
    const condSuspend = totalDays > 7;

    const condHist = objShopsStatus[currShop] ?? {};
    if (condActive) {
      objShopsStatus[currShop] = {
        ...condHist,
        [item.prodId]: 'active',
      };
    }
    if (condAlert) {
      objShopsStatus[currShop] = {
        ...condHist,
        [item.prodId]: 'alert',
      };
    }
    if (condSuspend) {
      objShopsStatus[currShop] = {
        ...condHist,
        [item.prodId]: 'suspended',
      };
    }
  }

  // ---- Update To Active
  for (const keyShop in objShopsStatus) {
    const currShop = objShopsStatus[keyShop];
    const arrStatus = Object.values(currShop);
    const isSuspendend = arrStatus.some(e => e === 'suspended');
    const isAlert = arrStatus.some(e => e === 'alert');

    if (isSuspendend) {
      fnUpdate('suspended', keyShop);
    } else {
      if (isAlert) {
        fnUpdate('alert', keyShop);
      } else {
        fnUpdate('active', keyShop);
      }
    }
  }
};
