// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import { useStl, UseIcoMoon, useRouter, useData } from '../../../../useMorfos';

// #region :: STYLEs *********

const stlSideMenu = [
  useStl.leftBar,
  useStl.pad(20, 0),
  {
    backgroundColor: '#fff',
    height: '100%',
  },
];
const stlItem = [useStl.flexRow, useStl.pad(10, 20)];
const stlLabel = [{ paddingLeft: 6 }];

// #endregion *********

export default () => {
  // ---------- set Data
  const path = useData('baseRoute.path');
  const typeAccount = useData('baseAuthUser.typeAccount');
  const arrMenu = useData('comps.sideLeft.items');

  // ---------- set Hooks
  const { callRouter } = useRouter();
  const dispatch = useDispatch();

  // ---------- set Itens Menu
  const ItemsList = arrMenu.map(item => {
    // ---------- set DATA + CONDs
    const itemMenu = item.layout;
    const goTo = item.path;
    const condActiveMenu = item.path === path;
    const condColor = condActiveMenu ? '#66cc66' : '#444';
    const condAccount = typeAccount === 'shop1';
    const condShop = itemMenu.title === 'Sair' && condAccount;

    // ---------- set BTNs
    const logout = !goTo;
    const condGoTo = () => {
      if (logout) {
        dispatch({ type: 'comps_Logout' });
      } else {
        callRouter(goTo);
      }
    };
    const goToLink = () =>
      window.open('https://www.gpay.com.br/poupimais', '_blank');

    // ---------- set RETURN
    return (
      <>
        {condShop && (
          <TouchableOpacity style={stlItem} onPress={goToLink}>
            <UseIcoMoon name="dollar-sign" size={20} color={condColor} />
            <Text style={[stlLabel, { color: condColor }]}>Mensalidade</Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity style={stlItem} onPress={() => condGoTo()}>
          <UseIcoMoon name={itemMenu.icon} size={20} color={condColor} />
          <Text style={[stlLabel, { color: condColor }]}>{itemMenu.title}</Text>
        </TouchableOpacity>
      </>
    );
  });

  return <View style={stlSideMenu}>{ItemsList}</View>;
};
