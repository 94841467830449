/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { Text } from 'react-native';

// ----------- import Internals
import ViewDF from './Views';
import {
  UseInitData,
  useData,
  UseList,
  UseCondLoader,
} from '../../../../../useMorfos';
import { useDispatch } from 'react-redux';
import { ItemProd, NoItemView } from '../../../../comps';
import { messaging } from '../../../../../config/firebase/fbConfig';

// ----------- set Info Screen
export const infoSc = {
  path: 'orderPf',
  groupSc: 'shop1',
  sideRight: {
    title: 'Confirmações',
  },
  scCode: 'S1c',
};

// ----------- set Default Component
export default () => {
  // ----------- set Effects
  const fxToken = () =>
    messaging
      .getToken()
      .then(token => {
        // console.log('token', token);
        setToken(token);
      })
      .catch(err => {
        // console.log('err', err)
      });

  const [sttToken, setToken] = React.useState('');
  React.useEffect(fxToken, []);

  // ----------- set Data
  const content = useData('S1c.scContent');

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Btns
  const btnEdit = () => dispatch({ type: 'S1c_BtnEditProd' });
  const btnConfirm = () =>
    dispatch({ type: 'S1c_StatusConfirm', value: sttToken });
  const btnPaid = () => dispatch({ type: 'S1c_StatusShipped' });
  const btnSave = () => dispatch({ type: 'S1c_BtnSaveProds' });
  const btnInclude = () => dispatch({ type: 'S1c_InitPicks' });
  const btnAdd = () => dispatch({ type: 'S1c_BtnIncludeProds' });
  const btnCancel = () => dispatch({ type: 'S1c_BtnCancel' });

  // ----------- set Return
  const infoView = {
    content,
    btnEdit,
    btnConfirm,
    btnSave,
    btnPaid,
    btnAdd,
    btnInclude,
    btnCancel,
  };

  const infoItem = { reducer: 'S1c.itemsInfo' };

  return (
    <UseInitData reducer={'S1c_InitData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'S1c.condList'}>
          <UseList data={'S1c.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemProd infoData={{ ...infoItem, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
