import React from 'react';
import { View, Text, Picker, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { useData, UseIcoMoon, useStl } from '../../../useMorfos';

export default ({ infoData }) => {
  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Data
  const typeAccount = useData('baseAuthUser.typeAccount');
  const isAdm = typeAccount === 'adm1';
  const currSc = isAdm ? 'B2' : 'S1';

  const currCateg = useData(`${currSc}.forms.iptsChanges.categ`);
  const categs = useData('basePersist.categs.itemsInfo');

  const subcategs = useData('basePersist.subCategs.itemsInfo', currCateg);
  const currSubCateg = useData(`${currSc}.forms.iptsChanges.subcateg`) ?? 0;
  const condStyle = !!currCateg ? '#fff' : '#fefefe';
  const condColor = { backgroundColor: condStyle };

  // ---------- Change Values
  const onChangeCatg = value =>
    value !== 'Selecione' &&
    dispatch({
      type: 'comps_changeIptTxt',
      value,
      fieldName: 'categ',
      sc: currSc,
    });
  const onChangeSubCatg = value =>
    value !== 'Selecione' &&
    dispatch({
      type: 'comps_changeIptTxt',
      value,
      fieldName: 'subcateg',
      sc: currSc,
    });

  const initSearch = () => dispatch({ type: 'comps_initFilter' });
  const btnGetMyProds = () => dispatch({ type: 'S1_GET_PriceProds' });

  // ---------- set Other Lists
  const ListCategs = () => {
    return categs.map(item => (
      <Picker.Item value={item.id} label={item.labels} />
    ));
  };
  const ListSubCategs = () => {
    const list = subcategs.map(item => (
      <Picker.Item value={item.id} label={item.labels} />
    ));

    // ---------- set Return
    return list;
  };
  // ---------- set Return
  return (
    <View style={stlRow}>
      <View>
        <Text style={stlLabel}>Categoria</Text>
        <View style={[stlPicker, condColor]}>
          {/* Set Categs */}
          <Picker
            style={stlInput}
            selectedValue={currCateg}
            onValueChange={onChangeCatg}
          >
            {!currCateg && (
              <Picker.Item value={'Selecione'} label={'Selecione...'} />
            )}
            <ListCategs />
          </Picker>
        </View>
      </View>
      <View>
        <Text style={stlLabel}>Subcategoria</Text>
        <View style={[stlPicker, condColor]}>
          {/* Set SubCategs */}
          <Picker
            style={stlInput}
            selectedValue={currSubCateg}
            onValueChange={onChangeSubCatg}
            enabled={!!currCateg}
          >
            {!currSubCateg && (
              <Picker.Item value={'Selecione'} label={'Selecione...'} />
            )}
            <ListSubCategs />
          </Picker>
        </View>
      </View>
      <TouchableOpacity style={stlBtn} onPress={initSearch}>
        <UseIcoMoon name="menu1" size={16} color="#fff" />
      </TouchableOpacity>

      {!isAdm && (
        <TouchableOpacity style={stlBtn2} onPress={btnGetMyProds}>
          <UseIcoMoon name="dollar-sign" size={12} color="#444" />
          <Text style={txtWhite}>Com Preço</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

// #region :: STYLEs *********

const stlRow = [useStl.flexRow];
const stlLabel = [{ fontSize: 12, color: '#444' }];
const stlPicker = [useStl.input, { width: 205, marginRight: 4, padding: 5 }];
const stlInput = [{ borderWidth: 0, fontSize: 14, fontWeight: '600' }];
const stlBtn = [
  useStl.btn,
  useStl.btnPrimary,
  { width: 30, height: 30, marginBottom: -15 },
];
const stlBtn2 = [
  useStl.btn,
  useStl.flexRow,
  {
    width: 80,
    height: 30,
    backgroundColor: '#fff',
    marginBottom: -15,
    right: 0,
    position: 'absolute',
  },
];
const txtWhite = { color: '#444', fontSize: 10 };

// #endregion *********
