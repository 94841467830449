// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF, { Dots } from './Views';
import {
  useRouter,
  UseInitData,
  useData,
  UseList,
} from '../../../../useMorfos';
import { useDispatch } from 'react-redux';
import { NoItemView } from '../../../comps';

// ----------- set Info Screen
export const infoSc = {
  path: 'onboarding',
  groupSc: 'pubApp',
  condBigSc: true,

  scCode: 'M0',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('M0.scContent');
  const condJump = useData('baseAuthUser.infos.jumpOnboarding');
  const currStep = useData('M0.currStep');

  // ----------- set Hooks
  const dispatch = useDispatch();
  const { callRedirect } = useRouter();

  const condNext = currStep < 4;
  const btnNextStep = () => {
    condNext
      ? dispatch({ type: 'M0_NextStep' })
      : dispatch({ type: 'M0_SetFinish' });
  };

  const condPrev = currStep < 1;
  const btnPrevStep = () => {
    condPrev && dispatch({ type: 'M0_PrevStep' });
  };

  const btnGoToStep = itemId =>
    dispatch({ type: 'M0_GoToStep', value: itemId });

  // ----------- set Return
  const infoView = {
    content,
    btnNextStep,
    btnPrevStep,
  };
  const infoList = {
    btnGoToStep,
  };
  const returnView = (
    <UseInitData reducer={'M0_InitData'}>
      <ViewDF info={infoView}>
        <UseList data={'M0.dotsList'}>
          {(itemId, noItem) =>
            noItem ? (
              <NoItemView />
            ) : (
              <Dots infoData={{ ...infoList, itemId }} />
            )
          }
        </UseList>
      </ViewDF>
    </UseInitData>
  );

  const condReturn = condJump ? callRedirect('address') : returnView;
  return condReturn;
};
