// ----------- import Packs
import React from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { AlertBox, NavBar } from '../../../comps';

// ----------- import Internals
import { useStl } from '../../../../useMorfos';

export const ClearCart = ({ info }) => {
  return (
    info.condBtnCancel && (
      <TouchableOpacity style={stlClearCart} onPress={info.btnConfirm}>
        <Text style={stlTxt1}>Esvaziar Carrinho</Text>
      </TouchableOpacity>
    )
  );
};

export default ({ children, info }) => {
  return (
    <>
      <NavBar />
      <View style={stlBodyView}>
        <ScrollView style={stlScroll}>
          <View style={stlCenter}>{children}</View>
        </ScrollView>
        <AlertBox info={{ ...info }} />
      </View>
    </>
  );
};

// #region :: STYLEs *********
const stlBodyView = [
  useStl.flex1,
  { backgroundColor: '#f6f6f6', marginTop: 60 },
];

const stlClearCart = [
  useStl.flexCenter,
  {
    width: '80%',
    height: 40,
    borderWidth: 2,
    borderColor: '#999',
    borderRadius: 8,
    marginBottom: 250,
  },
];

const stlAlertBox = [
  useStl.txtCenter,
  useStl.flexCenter,
  {
    paddingVertical: 30,
    width: '100%',
    height: 160,
    position: 'fixed',
    backgroundColor: '#fff',
    bottom: 70,
  },
];
const stlBtnNext = [
  useStl.flexCenter,
  {
    height: 70,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#363636',
  },
];
const stlMsg = [useStl.centerBox, { width: '80%' }];

const stlTxt1 = [{ fontSize: 12, fontWeight: 600, color: '#666' }];
const stlTxt2 = [{ fontWeight: 700, fontSize: 13, color: '#fff' }];
const stlTxt3 = [{ fontSize: 12, color: '#666', marginLeft: 5 }];
const stlTxt4 = [
  {
    fontSize: 16,
    fontWeight: 600,
    color: '#444',
    marginLeft: 5,
    lineHeight: 0,
  },
];
const stlTxtMsg = [
  {
    fontSize: 13,
    color: '#444',
  },
];

const stlAddress = [
  useStl.flexRow,
  useStl.border(1, 1, 1, 1, '#ccc'),
  { borderRadius: 4, padding: 4 },
];

const stlTitle = [useStl.flexRow];
const stlScroll = [{ paddingVertical: 20 }];
const stlCenter = [useStl.flexCenter];

// #endregion *********
