// ------ Testar os Encartes e Deletar caso passar 1d
// ------------------- da data de expiração informada
export const testBooklets = ({ itemsInfo, fnToDel }) => {
  const todayToMil = new Date().getTime();
  // Para testar Exclusão troque todayToMil
  // const testNow = new Date();
  // const testDate = testNow.setDate(testNow.getDate() + 1);
  // ezLog({ testDate: testDate > limitToMil });

  for (const key in itemsInfo) {
    // ---------- set Data
    const item = itemsInfo[key];
    const expiDate = item.expiDate;

    const expiDay = `${expiDate[0]}${expiDate[1]}`;
    const expiMonth = `${expiDate[3]}${expiDate[4]}`;
    const expiYear = `${expiDate[6]}${expiDate[7]}${expiDate[8]}${expiDate[9]}`;
    const expDateStamp = new Date(`${expiYear}/${expiMonth}/${expiDay}`);

    // Acrescenta + 1 dia a Data Limite ou seja
    // Excluir no dia Seguinte ao limite
    const limitToMil = expDateStamp.setDate(expDateStamp.getDate() + 1);

    // ---------- set Cond Exclude Booklet
    // ---- Compare Miliseconds to determine Exclude Booklet
    if (todayToMil > limitToMil) {
      fnToDel(key);
    }
  }
};
