/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, Text, Image, TouchableOpacity, TextInput } from 'react-native';

// ----------- import Internals
import { useStl, useResize } from '../../../../../useMorfos';
import { Card } from '../../../../comps';

// ----------- import Images
import src1 from '../../../../images/logo.svg';
import src2 from '../../../../images/bgshop.jpg';
import { ezLog, phoneMask } from '../../../../../useMorfos/utils';

export const MailErrorView = ({ info }) => {
  return (
    <View style={stlMsgError}>
      <Text style={stlMsgTxt}>
        Email não encontrado. Entre em contato com o Administrador.
      </Text>
    </View>
  );
};
export const SuspensionView = ({ info }) => {
  return (
    <View style={stlMsgError}>
      <Text style={stlMsgTxt}>
        Sua conta foi suspensa! Entre em contato com o Administrador.
      </Text>
    </View>
  );
};

export const BtnGoogleView = ({ info }) => {
  const { btnSetLogin } = info;

  return (
    <>
      <Text>Entre usando uma conta</Text>
      <TouchableOpacity
        style={[stlBtCallAct1, useResize(btChangewidth)]}
        onPress={btnSetLogin}
      >
        <Text style={stlBtTxtWhite}>Google</Text>
      </TouchableOpacity>
    </>
  );
};

export default ({ info, children }) => {
  const {
    btnGoToTerms,
    // initLoop
  } = info;

  // ----------- set Return
  return (
    <View style={stlBodyView}>
      <Image source={src2} style={useResize(stlBg)} />
      <Card style={[stlCard, useResize(changeBg)]}>
        <Image source={src1} style={useResize(stlLogo)} />
        {children}
      </Card>
      <TouchableOpacity onPress={btnGoToTerms}>
        <Text style={{ zIndex: 3 }}>Antes de usar veja os Termos de Uso</Text>
      </TouchableOpacity>
      {/* *** ** Não ** *** Apagar esse Botão comentado */}
      {/* Usado p/ atualizar produtos no banco */}
      {/* <TouchableOpacity onPress={initLoop}>
        <Text>Iniciar Loop</Text>
      </TouchableOpacity> */}
    </View>
  );
};

// #region :: STYLEs *********
const stlBodyView = [useStl.flex1, useStl.flexCenter, useStl.bgSc];
const stlCard = [
  useStl.card,
  useStl.flexCenter,
  { paddingVertical: 80, minHeight: 400 },
];
const changeBg = {
  desk: { width: '30%' },
  mob: { width: '60%' },
};
const stlLogo = {
  desk: {
    width: '54%',
    height: '40%',
    marginVertical: 40,
    resizeMode: 'contain',
  },
  mob: {
    width: '100%',
    height: '40%',
    marginVertical: 40,
    resizeMode: 'contain',
  },
};
const stlBg = {
  desk: {
    width: '60%',
    height: 430,
    position: 'absolute',
    resizeMode: 'center',
  },
  mob: {
    width: '100%',
    height: 430,
    position: 'absolute',
    resizeMode: 'center',
  },
};

// --------- set Buttons
const stlBtCallAct1 = [useStl.btn, useStl.btnLarge, useStl.btnPrimary];
const btChangewidth = {
  desk: { width: '60%' },
  mob: { width: '60%' },
};
const stlBtTxtWhite = [useStl.txtPrimaryInverse, { zIndex: 10 }];

// ---------- set Msgs
const stlMsgError = [{ width: '70%', textAlign: 'center' }];
const stlMsgTxt = [{ color: '#a2844d', fontWeight: 600 }];

// #endregion *********
