// ----------- import Packs
import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';

// ----------- import Internals
import { useStl, UseIcoMoon } from '../../../../useMorfos';
import src1 from '../../../images/invite.png';
import src2 from '../../../images/btn_block.png';

// #region :: STYLEs *********
const stlBodyView = [useStl.flex1, useStl.flexTopCenter];
const stlTitleTxt = [useStl.txtTitleScreen, useStl.txtCenter, useStl.mgB20];
const stlSubTitleTxt = [useStl.txtSubTitleCard];
const stlDescView = [useStl.txtCenter, { paddingHorizontal: 20 }];
const stlDescTxt = [useStl.txtBase, useStl.txtCenter];
const stlImg1 = { width: '100%', height: 300 };
const stlImg2 = {
  width: '100%',
  height: 93,
  position: 'absolute',
  left: 0,
  top: 0,
};
const stlBtn = [
  {
    width: '100%',
    height: 60,
    padding: 20,

    overflow: 'hidden',
  },
];
const stlTxtBt = [useStl.txtPrimaryInverse, useStl.txtCenter, { zIndex: 2 }];
const stlTxtBox = [useStl.flexCenter, useStl.flex1];
const stlIcon = [{ top: 388, right: 305, position: 'absolute' }];

// #endregion *********

export default ({ info }) => {
  return (
    <View style={stlBodyView}>
      <Image source={src1} style={stlImg1} />

      <View style={stlIcon}>
        <UseIcoMoon name="unlock" size={18} color="#99" />
      </View>

      <View style={stlTxtBox}>
        <Text style={stlTitleTxt}>Entre agora e acesse as funcionalidades</Text>
        <Text style={stlDescView}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </Text>
      </View>

      <TouchableOpacity style={stlBtn}>
        <Text style={stlTxtBt}>Google</Text>
        <Image source={src2} style={stlImg2} />
      </TouchableOpacity>
    </View>
  );
};
