// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF, { BtnImg, CondImg, VarTitle } from './Views';
import {
  UseInitData,
  useData,
  UseCondLoader,
  UseList,
} from '../../../../../useMorfos';
import { useDispatch } from 'react-redux';
import { IptsValidation, VarFields } from '../../../../comps';

// ----------- set Info Screen
export const infoSc = {
  path: 'prodADD',
  groupSc: 'adm1',
  sideRight: {
    title: 'Produtos',
  },

  scCode: 'C2',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('C2.scContent');

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Other Lists
  const ListBtnImg = () => {
    return (
      <UseCondLoader data={'C2.infoAdmPF.condList'}>
        <UseList data={'C2.infoAdmPF.imgsList'}>
          {(itemId, noItem) =>
            noItem ? <VarTitle /> : <BtnImg infoData={{ itemId }} />
          }
        </UseList>
      </UseCondLoader>
    );
  };

  const DynamicImage = () => {
    return (
      <UseCondLoader data={'C2.infoAdmPF.condList'}>
        <UseList data={'C2.infoAdmPF.imgsList'}>
          {(itemId, noItem) =>
            noItem ? <VarTitle /> : <CondImg info={{ itemId }} />
          }
        </UseList>
      </UseCondLoader>
    );
  };

  const DynamicPicker = () => {
    return (
      <UseCondLoader data={'C2.infoAdmPF.condList'}>
        <UseList data={'C2.infoAdmPF.infoList'}>
          {(itemId, noItem) =>
            noItem ? <VarTitle /> : <VarFields itemId={itemId} />
          }
        </UseList>
      </UseCondLoader>
    );
  };

  // ----------- set Btns
  const btnAddVar = () => dispatch({ type: 'C2_BtnAddVar' });
  const btnUseImg = () => dispatch({ type: 'C2_BtnUseImg' });

  // ----------- set Return
  const infoView = {
    content,
    btnAddVar,
    btnUseImg,

    ListBtnImg,
    DynamicImage,
    DynamicPicker,
  };

  return (
    <UseInitData reducer={'C2_InitData'}>
      <ViewDF info={infoView}>
        <IptsValidation
          infoData={{
            rdLoader: 'C2.loader',
            rdEdit: 'C2.idToEdit',
            rdMsgs: 'C2.forms.msgs',
            rdSave: 'C2_ADD_Prods',
            rdCancel: 'C2_BtnCancel',
          }}
        />
      </ViewDF>
    </UseInitData>
  );
};
