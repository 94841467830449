// ---------- import Internals
import { firebase, firestore } from '../../../../config/firebase/fbConfig';

import { asyncRefs } from '../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    M7c_InitData: () => {
      action.asyncDispatch({ type: 'M7c_InitList' });
      action.asyncDispatch({ type: 'M7c_InitForm' });
      const visited = true;
      return {
        ...state,
        M7c: { ...state.M7c, condList: false, visited },
      };
    },

    M7c_InitList: () => {
      // ---------- set Data
      const prodsCart = state.basePersist?.cart?.addedProds;
      const mergedVars = state.M2?.mergedVars?.itemsInfo;
      const currShop = state.M7?.currShop ?? state.M10?.shopId;

      // ---------- set AllProds
      const itemsInfo = {};
      const itemsList = [];

      for (const keyCart in prodsCart) {
        for (const keyMerged in mergedVars) {
          const item = mergedVars[keyMerged];

          // ---------- set Product in Shop
          const condShop = item.dataShops[currShop];
          const condShopCart = condShop?.prodId === keyCart;

          // ---------- set Product in Cart
          const dataProd = item.dataProd;
          const condProdCart = dataProd?.prodIdVar === keyCart;

          // ---------- set Cond Price
          const shopPrice = condShop?.shopPrice;
          const condPrice = shopPrice ?? 0;
          const condCartInfo = condProdCart && !itemsInfo[keyCart];
          const condItem = condShopCart || condCartInfo;

          // ---------- set itemsInfo
          if (condItem) {
            itemsInfo[keyCart] = {
              ...dataProd,
              shopPrice: condPrice,
            };
          }
        }
        itemsList.push(keyCart);
      }

      // ---------- set Recalculate Total Price ---- DESCONTINUAR
      const arrPrices = [];
      for (const key3 in itemsInfo) {
        const item = itemsInfo[key3];

        arrPrices.push(item.shopPrice);
      }
      const priceSum = arrPrices.reduce((prev, crr) => prev + crr, 0);

      return {
        ...state,
        M7c: {
          ...state.M7c,
          finalProds: {
            condList: true,
            totalPrice: priceSum,
            itemsInfo,
            itemsList,
          },
        },
      };
    },
  };

  const forms = {
    M7c_IptValues: () => {
      const itemLabel = `${action.field}_label`;
      const condLabel = action.label ? { [itemLabel]: action.label } : {};

      // ---------- set Validation
      const value = action.value;
      const condEmpty = value === '';

      return {
        ...state,
        M7c: {
          ...state.M7c,

          forms: {
            ...state.M7c.forms,
            iptsChanges: {
              ...state.M7c.forms.iptsChanges,
              [action.field]: action.value,
              [`${action.field}_num`]: action.numInt,
              ...condLabel,
            },
            msgs: {
              ...state.M7c.forms.msgs,
              [action.field]: condEmpty,
            },
          },
        },
      };
    },

    M7c_InitForm: () => {
      // ---------- set Edit
      const idToEdit = state?.M7c?.idToEdit;
      const isEditable = idToEdit ? true : false;
      const editData = state?.B1?.shopsInfo[idToEdit];

      // ------- set Forms Data
      const iptsInfo = {
        // ---------- Inputs Base
        phone: {
          // ----- set Dynamics
          itemId: 'phone',
          required: true,
          iptChange: 'M7c_IptValues',
          // condNumber: true,
          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Telefone',
          editData: editData && editData.phone,
          isEditable,
        },
      };

      return {
        ...state,
        M7c: {
          ...state.M7c,
          forms: { ...state.M7c.forms, iptsInfo: { ...iptsInfo } },
        },
      };
    },
  };

  const btns = {
    M7c_CheckPhone: () => {
      const phone = state?.M7c?.forms?.iptsChanges?.phone_num;

      if (phone) {
        if (phone.length === 11) {
          action.asyncDispatch({ type: 'M7c_AddFbOrder' });

          return state;
        } else {
          return {
            ...state,
            M7c: { ...state.M7c, msgDDDError: true, msgError: false },
          };
        }
      } else {
        return { ...state, M7c: { ...state.M7c, msgError: true } };
      }
    },
  };

  const adds = {
    M7c_SetCentData: () => {
      const prods = state.basePersist.cart.addedProds;
      const ids = [];

      for (const key in prods) {
        ids.push(prods[key].id);
      }

      return {
        ...state,
        M7c: {
          ...state.M7c,
          finalProds: {
            ...state.M7c.finalProds,
            itemsList: ids,
            totalPrice: action.value,
          },
        },
      };
    },

    M7c_AddFbOrder: () => {
      // ---------- set Data
      const cart = state?.basePersist?.cart?.addedProds;
      const shopId = state?.M7?.currShop ?? state?.M10?.shopId;
      const clientPhone = state?.M7c?.forms?.iptsChanges?.phone_num ?? 0;
      const buyerId = state?.baseAuthUser?.docId;
      const buyerAddress = state?.baseAuthUser?.address;
      const finalProds = state?.M7c?.finalProds?.itemsInfo;
      const totalPrice = state?.M7c?.finalProds?.totalPrice;
      const arrProds = [];
      for (const key in finalProds) {
        const item = finalProds[key];
        const itemAmount = cart[key].amountProd;

        arrProds.push({
          id: key,
          price: item.shopPrice,
          amount: itemAmount,
          var: item.idVar,
        });
      }
      const totalAmount = arrProds.length;

      // ---------- set Curr Order
      const dataToAdd = {
        shopId,
        buyerId,
        buyerAddress,
        totalPrice,
        totalAmount,
        statusOrder: 'Confirmar',
        clientPhone: 55 + clientPhone,
        arrProds,
      };

      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        let dataSuccess;
        const dbRef = firestore.collection('orders').doc();
        dataToAdd.docId = dbRef.id;
        dataToAdd.createdAt = firebase.firestore.Timestamp.now();

        // ------ return SUCCESS
        dataSuccess = await dbRef.set(dataToAdd);
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M7c_AddFbOrder_SUCCESS: () => {
      action.asyncDispatch({ type: 'base_setRoute', value: 'purchasedList' });

      return { ...state };
    },
  };

  return {
    ...inits,
    ...forms,
    ...btns,
    ...adds,
  };
};

// ---------- set Exports
export default reducers;
