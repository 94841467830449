// ----------- import Packs
import React from 'react';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF from './Views';
import {
  UseInitData,
  useData,
  UseCondLoader,
  UseList,
  useStl,
} from '../../../../useMorfos';
import { NoItemView } from '../../../comps';
import { Text, TouchableOpacity } from 'react-native';
import { ezLog } from '../../../../useMorfos/utils';

// ----------- set Info Screen
export const infoSc = {
  path: 'tempUsers2',
  groupSc: 'pub',
  condBigSc: true,

  scCode: 'M0a',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('M0a.scContent');

  // ----------- set Return
  const infoView = { content };

  return (
    <UseInitData reducer={'M0a_InitContentData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'M0a.condList'}>
          <UseList data={'M0a.usersList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemBtn infoItem={itemId} content={content} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};

// --------- Temp Item List
const ItemBtn = ({ infoItem }) => {
  // ----------- set Data
  const item = useData('M0a.usersInfo', infoItem);

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Temp Styles
  const stlBtn = [
    useStl.btn,
    useStl.btnMedium,
    useStl.btnPrimary,
    { width: 190, marginBottom: 10 },
  ];
  const stlBtnTxt = [useStl.txtPrimaryInverse];

  const btnLogin = () => dispatch({ type: 'M0a_SetLogin', value: infoItem });

  return (
    <TouchableOpacity style={stlBtn} onPress={btnLogin}>
      <Text style={stlBtnTxt}>{item.name}</Text>
    </TouchableOpacity>
  );
};
