// ----------- import Packs
import React from 'react';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF, {
  MsgCard,
  MsgCvv,
  MsgName,
  MsgFilled,
  MsgPayError,
  MsgPayRefused,
} from './Views';
import {
  useRouter,
  UseInitData,
  useData,
  UseCondLoader,
} from '../../../../useMorfos';
import { useDispatch } from 'react-redux';
import { mountCurrency } from '../M7e_PurchasedPF/helpers';
import { ezLog } from '../../../../useMorfos/utils';

// ----------- set Info Screen
export const infoSc = {
  path: 'payment',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: 'Pagamento',
  },

  scCode: 'M9',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const freeDelivery = useData('M9.condDelivery');
  const totalPrice = useData('M7e.currOrder.totalPrice');
  const currency = mountCurrency(totalPrice);

  // ----------- set Hooks
  const dispatch = useDispatch();
  const { callRouter } = useRouter();

  // ----------- set Routes
  const btnGoto = () => callRouter('signin');
  const btnCancel = () => callRouter('purchasedList');
  const btnValidCard = () => dispatch({ type: 'M9_BtnFieldsFilled' });

  // ----------- set Return
  const infoView = { currency, btnGoto, freeDelivery, btnValidCard, btnCancel };

  return (
    <UseInitData reducer={'M9_InitData'}>
      <ViewDF infoData={infoView}>
        <UseCondLoader data={'M9.condList'}>
          <ValidMsg />
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};

export const ValidMsg = () => {
  const allFields = useData('M9.cardFilled');
  const cardValids = useData('M9.cardValids');
  const condPayError = useData('M9.condPayError');
  const transactionData = useData('M9.transactionData.status');
  const card_number = cardValids?.card_number;
  const card_cvv = cardValids?.card_cvv;
  const card_holder_name = cardValids?.card_holder_name;
  const condNumber = card_number === false;
  const condCvv = card_cvv === false;
  const condName = card_holder_name === false;
  const cardFilled = allFields === false;
  const payError = condPayError === true;
  const refused = transactionData === 'refused';

  if (condNumber) {
    return <MsgCard />;
  }

  if (condName) {
    return <MsgName />;
  }

  if (condCvv) {
    return <MsgCvv />;
  }

  if (cardFilled) {
    return <MsgFilled />;
  }

  if (refused) {
    return <MsgPayRefused />;
  }

  if (payError) {
    return <MsgPayError />;
  }

  return <></>;
};
