/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF, { DayBox } from './Views';
import {
  UseInitData,
  useData,
  UseList,
  UseCondLoader,
} from '../../../../../useMorfos';
import { useDispatch } from 'react-redux';
import { NoItemView } from '../../../../comps';
import { ezLog } from '../../../../../useMorfos/utils';

// ----------- set Info Screen
export const infoSc = {
  path: 'settings',
  groupSc: 'shop1',
  layout: {
    icon: 'gear',
    title: 'Configurações',
    desc: 'Lorem Ipsum',
  },
  sideRight: {
    title: 'Configurações',
    options: false,
  },

  scCode: 'S4',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('S4.scContent');
  const msgs = useData('S4.forms.msgs');
  const condDays = useData('S4.forms.condDays');

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Validation Fields
  let condMsg = false;
  for (const key in msgs) {
    const item = msgs[key];

    const condTrue = item === true;
    if (condTrue) {
      condMsg = true;
    }
  }
  for (const key in condDays) {
    const item = condDays[key];
    const condMin = item.minHour;
    const condMax = item.maxHour;

    if (condMax) {
      condMsg = true;
    }
    if (condMin) {
      condMsg = true;
    }
  }

  // ----------- set Btns
  const btnSave = () => dispatch({ type: 'S4_ADD_Settings' });
  const btnCancel = () => dispatch({ type: 'S4_BtnCancel' });

  // ----------- set Return

  const infoView = {
    content,
    btnSave,
    btnCancel,
    condMsg,
  };

  return (
    <UseInitData reducer={'S4_InitData'}>
      <UseCondLoader data={'S4.condLoader'}>
        <ViewDF info={infoView}>
          <UseList data={'S4.forms.btnsList'}>
            {(itemId, noItem) =>
              noItem ? <NoItemView /> : <DayBox itemId={itemId} />
            }
          </UseList>
        </ViewDF>
      </UseCondLoader>
    </UseInitData>
  );
};
