// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import { useRouter, UseInitData, useData } from '../../../../useMorfos';
import { useDispatch } from 'react-redux';

// ----------- set Info Screen
export const infoSc = {
  path: 'signin2',
  groupSc: 'pubApp',
  condBigSc: true,
  scCode: 'M0',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('M0.scContent');

  // ----------- set Hooks
  const dispatch = useDispatch();
  const { callRouter } = useRouter();

  // ----------- set Btns
  const btnPubEnter = () => dispatch({ type: 'M0_BtnPubEnter' });
  const btnSetLogin = () => dispatch({ type: 'M0_GoogleSignIn' });
  const btnGoToTerms = () => callRouter('terms2');

  // ----------- set Return
  const infoView = { content, btnGoToTerms, btnSetLogin, btnPubEnter };

  return (
    <UseInitData reducer={'M0_InitData'}>
      <ViewDF info={infoView} />
    </UseInitData>
  );
};
