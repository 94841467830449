// ---------- import Internals
import { firestore, firebase } from '../../../../../config/firebase/fbConfig';
import { asyncRefs, currencyMask } from '../../../../../useMorfos/utils';
import { getUrl, admEditProd, mountImgs } from './helpers';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    C2_InitData: () => {
      const scContent = {};
      const condData = true;
      action.asyncDispatch({ type: 'C2_InitForm' });

      return {
        ...state,
        C2: {
          ...state.C2,
          scContent,
          condData,
          loader: true,
          infoAdmPF: {
            ...state.C2.infoAdmPF,
            imgsList: ['1', '2'],
          },
          pickerList: [
            { labels: 'Peso', id: '0' },
            { labels: 'Unidade', id: '1' },
            { labels: 'Dúzia', id: '2' },
            { labels: 'Caixa', id: '3' },
          ],
        },
      };
    },
  };

  const forms = {
    C2_InitForm: () => {
      // ---------- set Edit
      const idToEdit = state?.C2?.idToEdit;
      const isEditable = idToEdit ? true : false;
      const isAdm = state?.baseAuthUser?.typeAccount === 'adm1';
      const condB = state?.B3 ? 'B3' : 'B2';
      const condSc = isAdm ? condB : 'S1';
      const editData = state[condSc]?.itemsInfo[idToEdit];

      // ---------- set Shop
      const typeAccount = state?.baseAuthUser?.typeAccount;
      const isShop = typeAccount === 'shop1';
      isShop && action.asyncDispatch({ type: 'C2_InitShopPF' });

      const pickerList = state?.C2?.pickerList;

      // ------- set Forms Data
      const condData = true;
      const imgsList = state?.C2?.infoAdmPF?.imgsList;
      // const catList = state?.C2?.catList;
      const catList = state?.basePersist?.categs?.itemsInfo;
      const subcatList = state?.basePersist?.subCategs?.itemsInfo;

      const condCurrSubCatg = editData?.categ ?? 0;

      const varBase = (id, typeName, value) => {
        const editPath = `C2.forms.iptsInfo.variation.${id}.typeName.editData`;

        const condCall = pickerList && typeName;
        const condType = condCall && pickerList[typeName].id;

        return {
          id: id,
          typeName: {
            // ----- set Dynamics
            key: id,
            itemId: 'typeName',
            // required: true,
            iptChange: 'C2_IptVarPicker',

            // ----- set Statics
            dataPath: editPath,
            pHolder: 'Selecione...',
            title: 'Tipo de Variação',
            label: 'Categoria',
            pickerList,

            editData: condType,
            isEditable,
          },

          typeValue: {
            // ----- set Dynamics
            key: id,
            itemId: 'typeValue',
            // required: true,
            iptChange: 'C2_IptVarTxt',

            // ----- set Statics
            pHolder: 'Escreva...',
            label: 'Valor da Variação',
            editData: value && value,
            isEditable,
          },
        };
      };

      const condVariation = {};
      const listVars = [];
      const editVars = editData?.variations;

      if (editVars) {
        for (const key in editVars) {
          const element = editVars[key];
          const { typeName, typeValue } = element;
          condVariation[key] = {
            ...varBase(key, typeName, typeValue),
          };
          listVars.push(key);
        }
      } else {
        condVariation[0] = {
          ...varBase(0),
        };
        listVars.push('0');
      }

      const infoData = {
        imgsList,
        isEditable,
        editData,
      };

      const iptsInfo = {
        // ---------- Inputs Base

        name: {
          // ----- set Dynamics
          itemId: 'name',
          required: true,
          iptChange: 'C2_IptValues',

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Nome',
          editData: editData && editData.name,
          isEditable,
        },

        // weight: {
        //   // ----- set Dynamics
        //   itemId: 'weight',
        //   required: true,
        //   iptChange: 'C2_IptMasks',

        //   // ----- set Statics
        //   pHolder: 'Escreva...',
        //   label: 'Peso por Kilo',
        //   editData: editData && editData.weight,
        //   isEditable,
        // },

        categ: {
          // ----- set Dynamics
          itemId: 'categ',
          required: true,
          iptChange: 'C2_IptCateg',

          // ----- set Statics
          dataPath: 'C2.forms.iptsInfo.categ.editData',
          pHolder: 'Selecione...',
          title: 'Escolha a Categoria',
          label: 'Categoria',
          pickerList: catList,

          editData: editData && editData.categ,
          isEditable,
        },

        subCateg: {
          // ----- set Dynamics
          itemId: 'subCateg',
          required: true,
          iptChange: 'C2_IptSubCateg',

          // ----- set Statics
          // dataPath: 'C2.forms.iptsChanges.subCateg',
          dataPath: 'C2.forms.iptsInfo.subCateg.editData',
          pHolder: 'Selecione...',
          title: 'Escolha a Categoria',
          label: 'Categoria',
          pickerList: subcatList && subcatList[condCurrSubCatg],

          editData: editData && editData.subCateg,
          isEditable,
        },

        // Dynamic Fields
        ...mountImgs({ infoData }),
        variation: {
          ...condVariation,
        },

        prices: {},
      };

      const msgs = {};
      for (const key in iptsInfo) {
        const item = iptsInfo[key];
        const condAdd = item.required;

        if (condAdd) {
          msgs[item.itemId] = true;
        }
      }

      return {
        ...state,
        // TEMP GAMBS
        // S1: {
        //   ...state.S1,
        //   condList: true,
        // },
        C2: {
          ...state.C2,
          condData,
          infoAdmPF: {
            ...state.C2.infoAdmPF,
            condList: true,
            infoList: listVars,
          },
          forms: {
            iptsInfo: { ...iptsInfo },
            iptsChanges: {
              currImg: '1',
              imgSelected: '1',
            },
            msgs,
            condMsg: false,
          },
        },
        comps: {
          ...state.comps,
          sideRight: {
            ...state.comps.sideRight,
            options: isEditable,
          },
        },
      };
    },

    C2_InitVar: () => {
      // ------- set List Vars
      const varInfos = state?.C2?.forms?.iptsInfo?.variation;
      const newArr = [];
      for (const key in varInfos) {
        const item = varInfos[key];
        newArr.push(String(item.id));
      }

      // ------- set Cond Edit
      function isEmpty(obj) {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) return false;
        }
        return true;
      }

      // ---------- set Edit
      // const isEdit = isEmpty(varInfos);

      // ---------- set Fields
      const variations = state?.C2?.forms?.iptsInfo?.variation;
      // const keys = Object.keys(variations);

      return {
        ...state,
        C2: {
          ...state.C2,

          infoAdmPF: {
            ...state.C2.infoAdmPF,
            condList: true,
            infoList: newArr,
          },
        },
      };
    },

    // ---------- set Form Changes by Type
    C2_IptSelectImg: () => {
      return {
        ...state,
        C2: {
          ...state.C2,
          forms: {
            ...state.C2.forms,
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              currImg: action.value,
            },
          },
        },
      };
    },

    C2_IptValues: () => {
      const itemLabel = `${action.field}_label`;
      const condLabel = action.label ? { [itemLabel]: action.label } : {};

      // ---------- set Validation
      const value = action.value;
      const condEmpty = value === '';

      return {
        ...state,
        C2: {
          ...state.C2,

          forms: {
            ...state.C2.forms,
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              [action.field]: action.value,
              ...condLabel,
            },
            msgs: {
              ...state.C2.forms.msgs,
              [action.field]: condEmpty,
            },
          },
        },
      };
    },

    C2_IptCateg: () => {
      // const subcatList = state?.C2?.subcatList;
      const subcatList = state?.basePersist?.subCategs?.itemsInfo;

      return {
        ...state,
        C2: {
          ...state.C2,
          forms: {
            ...state.C2.forms,
            iptsInfo: {
              ...state.C2.forms.iptsInfo,
              categ: {
                ...state.C2.forms.iptsInfo.categ,
                dataPath: 'C2.forms.iptsChanges.categ',
              },

              subCateg: {
                ...state.C2.forms.iptsInfo.subCateg,
                pickerList: subcatList[action.value],
              },
            },
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              [action.itemId]: action.value,
            },
            msgs: {
              ...state.C2.forms.msgs,
              [action.itemId]: false,
            },
          },
        },
      };
    },

    C2_IptSubCateg: () => {
      return {
        ...state,
        C2: {
          ...state.C2,
          forms: {
            ...state.C2.forms,
            iptsInfo: {
              ...state.C2.forms.iptsInfo,
              subCateg: {
                ...state.C2.forms.iptsInfo.subCateg,
                dataPath: 'C2.forms.iptsChanges.subCateg',
              },
            },
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              [action.itemId]: action.value,
            },
            msgs: {
              ...state.C2.forms.msgs,
              [action.itemId]: false,
            },
          },
        },
      };
    },

    // ---------- set Form Variations Changes
    C2_IptVarTxt: () => {
      const itemLabel = `${action.field}_label`;
      const condLabel = action.label ? { [itemLabel]: action.label } : {};
      const keepField = state.C2.forms.iptsChanges.variations;
      const newObj = keepField ? keepField[action.key] : null;

      return {
        ...state,
        C2: {
          ...state.C2,

          forms: {
            ...state.C2.forms,
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              variations: {
                ...state.C2.forms.iptsChanges.variations,
                [action.key]: {
                  ...newObj,
                  typeId: action.key,
                  [action.field]: action.value,
                  ...condLabel,
                },
              },
            },
          },
        },
      };
    },

    C2_IptVarPicker: () => {
      const itemLabel = `${action.itemId}_label`;
      const condLabel = action.label ? { [itemLabel]: action.label } : {};
      const keepField = state.C2.forms.iptsChanges.variations;
      const newObj = keepField ? keepField[action.key] : null;

      return {
        ...state,
        C2: {
          ...state.C2,

          forms: {
            ...state.C2.forms,
            key: action.key,
            iptsInfo: {
              ...state.C2.forms.iptsInfo,
              variation: {
                ...state.C2.forms.iptsInfo.variation,
                [action.key]: {
                  ...state.C2.forms.iptsInfo.variation[action.key],
                  typeName: {
                    ...state.C2.forms.iptsInfo.variation[action.key].typeName,
                    dataPath: `C2.forms.iptsChanges.variations.${action.key}.typeName`,
                  },
                },
              },
            },
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              variations: {
                ...state.C2.forms.iptsChanges.variations,
                [action.key]: {
                  ...newObj,
                  typeId: action.key,
                  [action.itemId]: action.value,
                  ...condLabel,
                },
              },
            },
          },
        },
      };
    },

    // ---------- set Form Price Changes
    C2_IptPriceTxt: () => {
      const itemLabel = `${action.field}_label`;
      const condLabel = action.label ? { [itemLabel]: action.label } : {};
      const keepField = state.C2.forms.iptsChanges.prices;
      const newObj = keepField ? keepField[action.key] : null;
      const priceToNum = currencyMask(action.value, true);

      return {
        ...state,
        C2: {
          ...state.C2,

          forms: {
            ...state.C2.forms,
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              variations: {
                ...state.C2.forms.iptsChanges.variations,
                [action.key]: {
                  ...newObj,
                  typeId: action.key,
                  [action.field]: priceToNum,
                  ...condLabel,
                },
              },
            },
          },
        },
      };
    },

    C2_IptPriceMask: () => {
      const itemLabel = `${action.field}_label`;
      const condLabel = action.label ? { [itemLabel]: action.label } : {};
      const keepField = state.C2.forms.iptsChanges.prices;
      const newObj = keepField ? keepField[action.key] : null;

      return {
        ...state,
        C2: {
          ...state.C2,

          forms: {
            ...state.C2.forms,
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              [action.field]: action.numInt,
              variations: {
                ...state.C2.forms.iptsChanges.variations,
                [action.key]: {
                  ...newObj,
                  typeId: action.key,
                  [action.field]: action.numInt,
                  ...condLabel,
                },
              },
            },
          },
        },
      };
    },

    // ---------- set Dynamic Imgs Changes
    C2_ImgChange1: () => {
      return {
        ...state,
        C2: {
          ...state.C2,

          forms: {
            ...state.C2.forms,
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              image1: action.value,
            },
          },
        },
      };
    },

    C2_ImgChange2: () => {
      return {
        ...state,
        C2: {
          ...state.C2,

          forms: {
            ...state.C2.forms,
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              image2: action.value,
            },
          },
        },
      };
    },

    C2_IptMasks: () => {
      const itemLabel = `${action.field}_label`;
      const condLabel = action.label ? { [itemLabel]: action.label } : {};

      // ---------- set Validation
      const value = action.value;
      const condEmpty = value === '';

      return {
        ...state,
        C2: {
          ...state.C2,

          forms: {
            ...state.C2.forms,
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              [action.field]: action.value,
              [`${action.field}_num`]: action.numInt,
              ...condLabel,
            },
            msgs: {
              ...state.C2.forms.msgs,
              [action.field]: condEmpty,
            },
          },
        },
      };
    },
  };

  const shops = {
    // ---------- set Init Shop Profile
    C2_InitShopPF: () => {
      action.asyncDispatch({ type: 'C2_GET_Price' });
      const isAdm = state?.baseAuthUser?.typeAccount === 'adm1';

      // ------ set Fields Exibition
      const name = state?.C2?.forms.iptsInfo?.name?.editData;
      const image = state?.C2?.forms.iptsInfo?.image?.editData;
      const image1 = state?.C2?.forms.iptsInfo?.image1?.editData;
      const image2 = state?.C2?.forms.iptsInfo?.image2?.editData;
      // const weight = state?.C2?.forms.iptsInfo?.weight?.editData;
      const catId = state?.C2?.forms.iptsInfo?.categ?.editData;
      const subCatId = state?.C2?.forms.iptsInfo?.subCateg?.editData;

      // ------ set Edit Data
      const idToEdit = state?.C2?.idToEdit;

      // ----- set Variations
      const admProds = state?.B2?.itemsInfo[idToEdit];
      const shopProds = state?.S1?.itemsInfo[idToEdit];
      const selectProd = isAdm ? admProds : shopProds;

      const variations = selectProd.variations;
      const keys = variations ? Object.keys(variations) : [];
      const imgSelected = selectProd.imgSelected;

      return {
        ...state,
        C2: {
          ...state.C2,
          infoShopPF: {
            name,
            image,
            image1,
            image2,
            // weight,
            catId,
            subCatId,
            imgSelected,
            infoList: [...keys],
            infoVar: { ...variations },
          },
        },
      };
    },

    // ---------- set GET _Price
    C2_GET_Price: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Data Shop
        const shopId = state.baseAuthUser.shopId;
        const prodId = state.C2.idToEdit;

        // ---------- set Cond Edit
        const dataSuccess = await firestore
          .collection('shops')
          .doc(shopId)
          .collection('prods')
          .where('prodId', '==', prodId)
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    C2_GET_Price_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      const prices = {};
      dataDb.forEach(doc => {
        const currVars = doc.data().variations;
        for (const key in currVars) {
          const item = currVars[key];
          prices[key] = item;
        }
      });

      // ------ set Edit Data
      const idToEdit = state?.C2?.idToEdit;
      const isEditable = idToEdit ? true : false;

      // ----- set Variations
      // const selectProd = state?.B2?.prodsInfo[idToEdit];
      const isAdm = state?.baseAuthUser?.typeAccount === 'adm1';
      const admProds = state?.B2?.itemsInfo[idToEdit];
      const shopProds = state?.S1?.itemsInfo[idToEdit];
      const selectProd = isAdm ? admProds : shopProds;
      const variations = selectProd.variations;
      const keys = variations ? Object.keys(variations) : [];

      // ---------- set Price Fields by Var
      const makeObjPrice = {};
      for (const key of keys) {
        const objBase = {
          // ----- set Dynamics
          key: key,
          itemId: 'price',
          required: true,
          iptChange: 'C2_IptPriceMask',

          // ----- set Statics
          pHolder: 'Escreva...',
          label: 'Preço (Ex: 9,54)',
          editData: prices[key]?.price ?? '',
          isEditable,
        };

        makeObjPrice[key] = objBase;
      }

      return {
        ...state,
        C2: {
          ...state.C2,
          forms: {
            ...state.C2.forms,
            iptsInfo: {
              ...state.C2.forms.iptsInfo,
              prices: { ...makeObjPrice },
            },
          },
          infoShopPF: {
            ...state.C2.infoShopPF,
            condList: true,
            prices,
          },
        },
      };
    },
  };

  const adds = {
    // ---------- set ADD C2 Prods
    C2_ADD_Prods: () => {
      // ----- set Add Prod by ADM User
      const addProd = async () => {
        // ---------- set Type Account
        const typeAccount = state.baseAuthUser.typeAccount;
        const isAdm = typeAccount === 'adm1';
        // ---------- set Data to Add or Edit
        const idToEdit = state.C2.idToEdit;
        const dataToAdd = { ...state.C2.forms.iptsChanges };

        // ------ When Image Exist
        const imgsList = state?.C2?.infoAdmPF?.imgsList;
        // loopImgs(imgsList, dataToAdd); // HELPERS NÃO FUNCIONOU

        for (const key of imgsList) {
          const infoImgs = {};
          infoImgs[key] = dataToAdd[`image${key}`];

          if (infoImgs[key]) {
            dataToAdd[`imgUrl${key}`] = await getUrl(infoImgs[key]);

            delete dataToAdd[`image${key}`];
          }
        }

        // ---------- set Cond Edit or Save
        let dataSuccess = null;
        if (idToEdit) {
          const refDbEdit = firestore.collection('prods').doc(idToEdit);
          const newObj = admEditProd(dataToAdd);

          dataSuccess = await refDbEdit.update(newObj);
        } else {
          // Adm Adds All infos but not price
          const refDb = firestore.collection('prods').doc();
          dataToAdd.docId = refDb.id;
          dataToAdd.createdAt = firebase.firestore.Timestamp.now();

          if (isAdm) {
            dataSuccess = await refDb.set(dataToAdd);
          } else {
            // shops updates only price
            dataSuccess = await refDb.update(dataToAdd);
          }
        }

        return dataSuccess;
      };

      // ----- set Add Vars Refs by SHOP User
      const addRefShop = async () => {
        // ---------- set Data to Add
        const shopId = state.baseAuthUser.shopId;
        const prodId = state.C2.idToEdit;

        // ---------- set Firestore Ref To Save
        let dataSuccess = null;
        const refDb = firestore
          .collection('shops')
          .doc(shopId)
          .collection('prods')
          .doc();

        // ---------- set Cond Edit
        const editRef = await firestore
          .collection('shops')
          .doc(shopId)
          .collection('prods')
          .where('prodId', '==', prodId)
          .get();
        const arrRes = [];
        editRef.forEach(doc => {
          arrRes.push(doc.data());
        });
        const isEdit = arrRes.length > 0;
        const docToEdit = arrRes[0] && arrRes[0].docId;

        // ---------- set Data to Add
        const dataToAdd = { ...state.C2.forms.iptsChanges };
        dataToAdd.createdAt = firebase.firestore.Timestamp.now();
        dataToAdd.lastUpdate = firebase.firestore.Timestamp.now();
        if (!isEdit) {
          dataToAdd.docId = refDb.id;
          dataToAdd.shopId = shopId;
          dataToAdd.prodId = prodId;
        }

        // ---------- set Edit or Save
        if (isEdit) {
          const editDb = firestore
            .collection('shops')
            .doc(shopId)
            .collection('prods')
            .doc(docToEdit);

          const newObj = {};
          const variations = dataToAdd.variations;
          for (const key in variations) {
            const item = variations[key];

            newObj[`variations.${key}`] = item;
          }
          newObj.lastUpdate = firebase.firestore.Timestamp.now();

          dataSuccess = await editDb.update(newObj);
        } else {
          dataSuccess = await refDb.set({ ...dataToAdd });
        }
      };

      // ---------- set Cond AsyncFN
      const shopEdits = state.C2.infoShopPF;
      const condAsync = shopEdits ? addRefShop : addProd;

      // ---------- set Async References
      const ref = asyncRefs(action, condAsync);

      // ---------- call Async / Mock
      ref.callAsync();

      // ------ set Return
      return {
        ...state,
        C2: {
          ...state.C2,
          loader: false,
        },
      };
    },

    C2_ADD_Prods_SUCCESS: () => {
      const dataToAdd = { ...state.C2.forms.iptsChanges };
      const setData = admEditProd(dataToAdd);

      return {
        ...state,
        B2: {
          ...state.B2,
          itemsInfo: {
            ...state?.B2?.itemsInfo,
            ...setData,
          },
        },
        C2: {
          ...state.C2,
          dataName: action.value,
          loader: true,
        },
        S1: { ...state.S1, condAddedProd: true },
      };
    },
  };

  const btns = {
    C2_BtnCancel: () => {
      return {
        ...state,
        C2: {
          ...state.C2,
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: null,
        },
      };
    },

    C2_BtnAddVar: () => {
      // ---------- set Edit
      const idToEdit = state?.C2?.idToEdit;
      const isEditable = idToEdit ? true : false;
      const editData = state?.B2?.itemsInfo[idToEdit];
      const pickerList = state?.C2?.pickerList;

      // ---------- set New Fields
      const variations = state?.C2?.forms?.iptsInfo?.variation;
      const keys = Object.keys(variations);
      const lastKey = keys.length - 1;
      const nextKey = Number(keys[lastKey]) + 1;

      const typeName = {
        // ----- set Dynamics
        key: nextKey,
        itemId: 'typeName',
        required: true,
        iptChange: 'C2_IptVarPicker',
        // condNumber: true,

        // ----- set Statics
        dataPath: `C2.forms.iptsChanges.variations.${nextKey}.typeName`,
        pHolder: 'Selecione...',
        title: 'Tipo de Variação',
        label: 'Categoria',
        pickerList,

        editData: editData && editData.typeName,
        isEditable,
      };

      const typeValue = {
        // ----- set Dynamics
        key: nextKey,
        itemId: 'typeValue',
        required: true,
        iptChange: 'C2_IptVarTxt',

        // ----- set Statics
        pHolder: 'Escreva...',
        label: 'Valor da Variação',
        editData: editData && editData.typeValue,
        isEditable,
      };
      action.asyncDispatch({ type: 'C2_InitVar' });

      return {
        ...state,
        C2: {
          ...state.C2,
          forms: {
            ...state.C2.forms,
            iptsInfo: {
              ...state.C2.forms.iptsInfo,
              variation: {
                ...state.C2.forms.iptsInfo.variation,
                [String(nextKey)]: {
                  id: String(nextKey),
                  typeName: { ...typeName },
                  typeValue: { ...typeValue },
                },
              },
            },
          },
        },
      };
    },

    C2_BtnUseImg: () => {
      const currImg = state?.C2?.forms?.iptsChanges?.currImg;

      return {
        ...state,
        C2: {
          ...state.C2,
          forms: {
            ...state.C2.forms,
            iptsChanges: {
              ...state.C2.forms.iptsChanges,
              imgSelected: currImg,
            },
          },
        },
      };
    },
  };

  return {
    ...inits,
    ...forms,
    ...shops,
    ...btns,
    ...adds,
  };
};

// ---------- set Exports
export default reducers;
