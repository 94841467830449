/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, TouchableOpacity, Text, Pressable } from 'react-native';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import {
  useData,
  UseIcoMoon,
  useResize,
  useRouter,
  useStl,
} from '../../../useMorfos';

export default ({ info }) => {
  // ---------- set Data
  const sttMenu = useData('comps.sidemenu.sttMenu');

  // ---------- set Hooks
  const dispatch = useDispatch();

  const { callRouter } = useRouter();

  // ---------- set Conds & Btns
  const goBack = () => dispatch({ type: 'comps_LogoutApp' });

  // ---------- set Btns
  const toggMenu = () => dispatch({ type: 'comps_ToggMenu' });
  const goToHome = () => {
    dispatch({ type: 'comps_HistoryPath' });
    callRouter('home');
    toggMenu();
  };
  const goToPurchList = () => {
    dispatch({ type: 'comps_HistoryPath' });
    callRouter('purchasedList');
    toggMenu();
  };
  const goToBookletList = () => {
    dispatch({ type: 'comps_Historypath' });
    callRouter('bookletList');
    toggMenu();
  };
  const goToShopsInReach = () => {
    dispatch({ type: 'comps_HistoryPath' });
    callRouter('shopsInReach');
    toggMenu();
  };

  const goToMyPF = () => {
    dispatch({ type: 'comps_HistoryPath' });
    callRouter('myprofile');
    toggMenu();
  };

  // ---------- set Return
  return (
    sttMenu && (
      <Pressable style={stlOverlay} onPress={toggMenu}>
        <View style={stlSide}>
          <View style={stlTopBar}>
            <TouchableOpacity style={stlLeft} onPress={toggMenu}>
              <UseIcoMoon name="arrow-left" size={16} color="green" />
            </TouchableOpacity>
            <View style={stlTxtBar}>
              <Text style={stlTxtTitle}>VOLTAR</Text>
            </View>
          </View>

          <View style={stlList}>
            <TouchableOpacity onPress={goToBookletList} style={stlBox}>
              <UseIcoMoon name="map" size={20} color="#444" />
              <Text style={stlTxtBar}>Encartes</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goToHome} style={stlBox}>
              <UseIcoMoon name="pin" size={20} color="#444" />
              <Text style={stlTxtBar}>Orçamentos</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goToPurchList} style={stlBox}>
              <UseIcoMoon name="wallet" size={20} color="#444" />
              <Text style={stlTxtBar}>Compras</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goToShopsInReach} style={stlBox}>
              <UseIcoMoon name="cart" size={22} color="#444" />
              <Text style={stlTxtBar}>Supermercados</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goToMyPF} style={stlBox}>
              <UseIcoMoon name="user1" size={20} color="#444" />{' '}
              <Text style={stlTxtBar}>Meu Perfil</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goBack} style={stlBox}>
              <View>
                <UseIcoMoon name="exit" size={20} color="#444" />
              </View>
              <Text style={stlTxtBar}>Sair</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Pressable>
    )
  );
};

// #region :: STYLEs *********
const stlOverlay = [
  useStl.flex1,
  {
    backgroundColor: 'rgba(0,0,0,0.4)',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 99,
  },
];
const stlSide = [
  useStl.flex1,
  // useStl.flexStart,
  {
    position: 'absolute',
    backgroundColor: '#fdfdfd',
    width: 300,
    height: '100%',
    zIndex: 100,
  },
];
const stlLeft = [{ marginRigth: 10 }];
const stlTopBar = [
  useStl.flexRow,
  useStl.pad20,
  useStl.border(0, 0, 1, 0, '#ddd'),
];
const stlList = [useStl.pad20];
const stlTxtBar = [useStl.flex1, { marginLeft: 10, alignItems: 'flex-start' }];
const stlTxtTitle = [
  useStl.txtPrimaryInverse,
  {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
    color: '#444',
  },
];
const stlBox = [
  useStl.flexRow,
  {
    paddingHorizontal: 7,
    textAlign: 10,
    height: 42,
    width: '100%',
  },
];
// #endregion *********
