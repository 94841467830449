// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import { UseInitData, UseCondLoader, UseList } from '../../../../../useMorfos';
import { ItemList, NoItemView, TitleList } from '../../../../comps';

// ----------- set Info Screen
export const infoSc = {
  path: 'shopBookletList',
  groupSc: 'shop1',
  condBigSc: true,
  layout: {
    icon: 'map',
    title: 'Encartes',
    desc: 'Lista de encartes',
    btn: 'Add. Encarte',
    actionADD: 'S5_BookletADD',
  },

  scCode: 'S5',
};

// ----------- set Default Component
export default () => {
  // ----------- set Hooks

  // ----------- set Return
  const labels = { label1: 'Produto' };
  const infoItem = {
    itemContent: 'S5.scContent',
    itemsInfo: 'S5.itemsInfo',
    pressItem: 'S5_EditProd',
    actionName1: 'S5_DELETE_Booklet',
  };

  return (
    <UseInitData reducer={'S5_InitData'}>
      <ViewDF>
        <TitleList infoData={labels} />

        <UseCondLoader data={'S5.condList'}>
          <UseList data={'S5.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemList infoData={{ ...infoItem, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
