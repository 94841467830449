import { firebase, firestore } from '../../../../../config/firebase/fbConfig';

export const getSettings = async () => {
  // ---------- set Cond Edit
  const editRef = await firestore.collection('settings').get();
  const arrRes = [];
  editRef.forEach(doc => {
    arrRes.push(doc.data());
  });

  return arrRes;
};

export const processData = (dataToAdd, isEdit) => {
  let newObj = {};

  if (isEdit) {
    const days = dataToAdd.days;
    const condHasDays = days !== undefined;

    if (condHasDays) {
      for (const key in days) {
        const item = days[key];
        const { id, minHour, maxHour, maxHour_num, minHour_num } = item;
        const condMin = minHour !== undefined;
        const condMax = maxHour !== undefined;

        if (condMin) {
          newObj[`days.${id}.minHour`] = minHour;
          newObj[`days.${id}.minHour_num`] = minHour_num;
        }
        if (condMax) {
          newObj[`days.${id}.maxHour`] = maxHour;
          newObj[`days.${id}.maxHour_num`] = maxHour_num;
        }
      }
      newObj = { ...dataToAdd, ...newObj };
    } else {
      newObj = { ...dataToAdd };
    }
    delete newObj.days;
  } else {
    newObj = {
      ...dataToAdd,
      createdAt: firebase.firestore.Timestamp.now(),
    };
  }

  return newObj;
};
