// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF, { BtnImg, CondImg, VarTitle } from './Views';
import {
  UseInitData,
  useData,
  UseCondLoader,
  UseList,
} from '../../../../../useMorfos';
import { useDispatch } from 'react-redux';
// import { IptsValidation } from '../../../../comps';

// ----------- set Info Screen
export const infoSc = {
  path: 'bookletADD',
  groupSc: 'shop1',
  sideRight: {
    title: 'Encartes',
  },

  scCode: 'S6',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('S6.scContent');

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Other Lists
  const ListBtnImg = () => {
    return (
      <UseCondLoader data={'S6.condList'}>
        <UseList data={'S6.imgsList'}>
          {(itemId, noItem) =>
            noItem ? <VarTitle /> : <BtnImg infoData={{ itemId }} />
          }
        </UseList>
      </UseCondLoader>
    );
  };

  const DynamicImage = () => {
    return (
      <UseCondLoader data={'S6.condList'}>
        <UseList data={'S6.imgsList'}>
          {(itemId, noItem) =>
            noItem ? <VarTitle /> : <CondImg info={{ itemId }} />
          }
        </UseList>
      </UseCondLoader>
    );
  };

  // ----------- set Btns
  const btnSave = () => dispatch({ type: 'S6_BtnSave' });
  const btnCancel = () => dispatch({ type: 'S6_BtnCancel' });
  const btnUseImg = () => dispatch({ type: 'S6_BtnUseImg' });

  // ----------- set Return
  const infoView = {
    content,
    btnSave,
    btnCancel,
    btnUseImg,

    ListBtnImg,
    DynamicImage,
  };

  return (
    <UseInitData reducer={'S6_InitData'}>
      <ViewDF info={infoView}></ViewDF>
    </UseInitData>
  );
};
