// ----------- import Packs
import React from 'react';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF, { ClearCart } from './Views';
import {
  useRouter,
  UseInitData,
  useData,
  UseList,
  UseCondLoader,
  useSnapDb,
} from '../../../../useMorfos';
import { ItemProd, NoItemView } from '../../../comps';
import { useDispatch } from 'react-redux';

// ----------- set Info Screen
export const infoSc = {
  path: 'cart',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: { title: 'Carrinho', subTitle: 'Detalhes do' },
  scCode: 'M6',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('M6.scContent');
  const totalAmount = useData('basePersist.cart.totalAmount');

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Snap Hooks
  const snapProdsBase = {
    collectionName: 'prods',
    orderField: 'createdAt',
    orderType: 'desc',
    reducerGet: 'M6_GET_ProdsBase',
    reducerSuccess: 'M6_GET_ProdsBase_SUCCESS',
    firstCallData: 'M6.prodsBase.firstCall',
    itemsInfoData: 'M6.prodsBase.itemsInfo',
    condListData: 'M6.prodsBase.condList',
  };

  useSnapDb(snapProdsBase);

  // ----------- set Conds
  const condBtnCancel = totalAmount !== 0;

  // ----------- set Routes
  const btnConfirm = () => dispatch({ type: 'M6_ConfirmClear' });
  const btnCancel = () => dispatch({ type: 'M6_BtnCancelCart' });
  const btnClearCart = () => dispatch({ type: 'M6_BtnClearCart' });

  // ----------- set Return
  const infoView = {
    content,
    btnCancel,
    btnClearCart,
  };
  const infoList = {
    reducer: 'M6.itemsInfo',
  };
  return (
    <UseInitData reducer={'M6_InitData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'M6.condList'}>
          <UseList data={'M6.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemProd infoData={{ ...infoList, itemId }} />
              )
            }
          </UseList>
          <ClearCart info={{ btnConfirm, condBtnCancel }} />
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
