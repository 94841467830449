// ----------- import Packs
import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';

// ----------- import Internals
import {
  useStl,
  UseIcoMoon,
  useRouter,
  useResize,
  useData,
} from '../../../useMorfos';
import { shadowMore, shadowLess, primaryColor } from '../../../config/styles';
import { useDispatch } from 'react-redux';

export default () => {
  // ---------- set Data
  const actValProds = useData('basePersist.cart.addedProds');
  const actValAmount = useData('basePersist.cart.totalAmount');

  // ---------- set Hooks
  const dispatch = useDispatch();
  const resizeBar = useResize({
    mob: {},
    desk: { maxWidth: 500 },
  });
  const { callRouter } = useRouter();

  // ---------- set Conds
  const condAmount = actValAmount !== 0;

  // ---------- set Btns
  const goToAddress = () => {
    dispatch({ type: 'comps_EditAddress' });
    callRouter('address');
  };
  const goToSearch = () => {
    dispatch({ type: 'comps_HistoryPath' });
    callRouter('search');
  };
  const goToCart = () => {
    dispatch({ type: 'comps_InitCart', value: { actValProds, actValAmount } });
    callRouter('cart');
  };

  // ---------- set Return
  return (
    <View style={[stlNav, resizeBar]}>
      <View style={stlAddress}>
        <TouchableOpacity onPress={goToAddress}>
          <UseIcoMoon name="pin" size={22} color="#444" />
        </TouchableOpacity>
      </View>

      <View style={stlCart}>
        <View style={stlBtnBorder}>
          {condAmount && (
            <View style={stlAmount}>
              <Text style={stlAmountTxt}>{actValAmount}</Text>
            </View>
          )}
          <TouchableOpacity style={stlBtnCart} onPress={goToCart}>
            <UseIcoMoon name="cart" size={24} color="white" />
          </TouchableOpacity>
        </View>
      </View>

      <View style={stlSearch}>
        <TouchableOpacity onPress={goToSearch}>
          <UseIcoMoon name="search" size={22} color="#444" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

// #region :: STYLEs *********

const stlNav = [
  useStl.navbarView,
  {
    justifyContent: 'space-between',
    alignItens: 'center',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#fff',
    height: 50,
  },
];

const stlAddress = [useStl.flexCenter, useStl.flex1, { marginRight: -20 }];
const stlCart = [useStl.flexCenter, useStl.flex1];
const stlBtnBorder = [
  useStl.flexCenter,
  {
    marginTop: -28,
    borderRadius: 60,
    width: 68,
    height: 68,
    backgroundColor: 'white',
    ...shadowLess,
    shadowOffset: { width: 0, height: -5 },
  },
];
const stlBtnCart = [
  useStl.flexCenter,
  {
    backgroundColor: 'green',
    borderRadius: 60,
    width: 54,
    height: 54,
    ...shadowMore,
  },
];
const stlSearch = [useStl.flexCenter, useStl.flex1, { marginLeft: -20 }];
const stlAmount = [
  useStl.flexCenter,
  useStl.border(1, 1, 1, 1, primaryColor),
  {
    backgroundColor: '#fff',
    position: 'absolute',
    borderRadius: 60,
    height: 18,
    width: 18,
    zIndex: 3,
    right: 5,
    top: 6,
  },
];
const stlAmountTxt = [
  {
    fontSize: 10,
    fontWeight: 600,
    color: 'darkgreen',
  },
];

// #endregion :: *********
