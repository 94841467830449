// ----------- import Packs
import React from 'react';
import { useDispatch } from 'react-redux';
import { Platform } from 'react-native';

// ----------- import Internals
// import prodsData from './prodsData.json';
import ViewDF, { BtnGoogleView, MailErrorView, SuspensionView } from './Views';
import {
  useRouter,
  UseInitData,
  useData,
  UseCondLoader,
  // BatchDB,
} from '../../../../../useMorfos';
import { ezLog } from '../../../../../useMorfos/utils';

// ----------- set Info Screen
export const infoSc = {
  path: 'signin',
  groupSc: 'pub',
  condBigSc: true,
  scCode: 'A1',
};

// ----------- set Default Component
export default () => {
  const isDesktop = Platform.OS === 'web';
  const { callRedirect } = useRouter();

  const condReturn = isDesktop ? <ScA1 /> : callRedirect('signin2');

  return condReturn;
};

const ScA1 = () => {
  // ----------- set Data

  // ----------- set Hooks
  const { callRouter } = useRouter();

  // ----------- set Functions

  // ----------- set Btns
  const btnGoToTerms = () => callRouter('terms');
  // const initLoop = () => BatchDB(prodsData);
  // const initLoop = () => ezLog('Batch Desligado');

  // ----------- set Return
  const infoView = {
    btnGoToTerms,
    // initLoop,
  };

  return (
    <UseInitData reducer={'A1_InitData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'A1.condList'}>
          <BtnGoogleComp />
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};

const BtnGoogleComp = () => {
  // ----------- set Data
  const condMail = useData('A1.mailNotFound');
  const condSuspension = useData('A1.msgSuspended');

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Btns
  const btnSetLogin = () => dispatch({ type: 'A1_GoogleSignIn' });

  // ----------- set Comps
  const Btn = <BtnGoogleView info={{ btnSetLogin }} />;
  const MsgError = <MailErrorView />;
  const MsgSuspended = <SuspensionView />;

  // ----------- set Return
  const condSusp = condSuspension ? MsgSuspended : Btn;
  const condError = condMail ? MsgError : condSusp;
  const condReturn = condError ?? Btn;
  return condReturn;
};
