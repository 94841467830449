/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF from './Views';
import {
  UseInitData,
  UseCondLoader,
  UseList,
  useData,
  useRouter,
} from '../../../../useMorfos';
import { ItemCateg, ItemProd, ItemSubCateg, NoItemView } from '../../../comps';
import { useDispatch } from 'react-redux';

// ----------- set Info Screen
export const infoSc = {
  path: 'orderEdit',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: <ShopName />,
    subTitle: 'Todos os Produtos',
  },

  scCode: 'M7b',
};

function ShopName() {
  const M10ShopName = useData('M10.shopName');
  const condShopName = M10ShopName ? 'M10.shopName' : 'M7a.shopName';
  const shopName = useData(condShopName);
  return shopName;
}

// ----------- set Default Component
export default () => {
  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Btns
  // const btnOrderFinal = () => dispatch({ type: 'M7b_ConcludePurcharse' });
  const btnOrderFinal = () => dispatch({ type: 'M7_InitData' });

  // ----------- set Return

  const infoView = {
    ListCategs,
    ListSubCategs,
    btnOrderFinal,
  };

  const infoList = {
    itemContent: 'M7b.scContent',
    reducer: 'M7b.itemsInfo',
  };

  return (
    <UseInitData reducer={'M7b_InitData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'M7b.condList'}>
          <UseList data={'M7b.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemProd infoData={{ ...infoList, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};

const ListCategs = () => {
  return (
    <UseList data={'basePersist.categs.itemsList'}>
      {(itemId, noItem) =>
        noItem ? (
          <NoItemView />
        ) : (
          <ItemCateg
            infoData={{
              list: 'basePersist.categs.itemsInfo',
              change: 'M2_ChangeCateg',
              itemId,
            }}
          />
        )
      }
    </UseList>
  );
};

const ListSubCategs = () => {
  return (
    <UseList data={'basePersist.subCategs.itemsList'}>
      {(itemId, noItem) =>
        noItem ? (
          <NoItemView />
        ) : (
          <ItemSubCateg
            infoData={{
              change: 'M7b_ChangeSubCat',
              stl: { height: 10 },
              itemId,
              // getSubCateg
            }}
          />
        )
      }
    </UseList>
  );
};

// const ListResCatg = () => {
//   return (
//     <UseList data={'M4.filterProds.itemsList'}>
//       {(itemId, noItem) =>
//         noItem ? (
//           <NoItemView />
//         ) : (
//           <ItemProd
//             infoData={{ itemId, reducer: 'M4.filterProds.itemsInfo' }}
//           />
//         )
//       }
//     </UseList>
//   );
// };
