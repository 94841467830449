// ----------- import Packs
import React from 'react';
import { View, ScrollView, Image, Text, Pressable } from 'react-native';

// ----------- import Internals
import { useRouter, useStl } from '../../../../useMorfos';
import { NavBar } from '../../../comps';
import SideMenu from '../../../comps/App/SideMenu';
import src3 from '../../../images/banner.svg';
import src4 from '../../../images/imgbn2.png';
import { useDispatch } from 'react-redux';

export default ({ children }) => {
    // ---------- set Hooks
    const dispatch = useDispatch();
    const { callRouter } = useRouter();

  const goToHome = () => {
    dispatch({ type: 'comps_HistoryPath' });
    callRouter('home');
  };

  return (
    <>
      <SideMenu />
      <NavBar />
      <ScrollView style={stlScrollView}>  
        <View style={{  
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Image source={src3} style={stlImg3} />
          <Pressable onPress={goToHome}>
            <Image source={src4} style={stlImg4} />
          </Pressable>
        </View>

        <View style={stlBodyView}>{children}</View>
      </ScrollView>
    </>
  );
};

// #region :: STYLEs *********
const stlImg3 = {
  width: '100%',
  height: 350,
  position: 'absolute',
  backgroundColor: '#f6f6f6',
};
const stlImg4 = {
  display: 'flex',
  flex: 1,
  backgroundColor: '#f6f6f6',
  width: 350,
  height: 200,
  borderRadius: 15,
  marginTop: 60,
  // resizeMode: 'center',
};

const stlBodyView = [{ flex: 1, backgroundColor: '#f6f6f6', marginTop: 62 }];
const stlScrollView = [useStl.bodyViewApp, { marginTop: 0, padding: 0 }];

// #endregion *********
