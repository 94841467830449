// ---------- set Reducers
const reducers = (state, action) => {
  // ---------- set Reducers Groups
  const changes = {
    // ---------- set Dynamic Reducers
    base_setRoute() {
      const routesInfo = state?.baseRoute?.routesInfo[action.value];
      const condRoutesInfo = routesInfo?.condBigSc;
      const objBig = {
        path: action.value,
        pathRight: null,
        currShop: null,
      };
      const objRight = {
        pathRight: action.value,
      };
      const condPath = !condRoutesInfo ? objRight : objBig;
      return {
        ...state,
        baseRoute: {
          ...state.baseRoute,
          ...condPath,
        },
        currShop: null,
      };
    },
  };

  // ---------- set Reducers Return
  return {
    ...changes,
  };
};

// ---------- set Exports
export default reducers;
