// ----------- import Packs
import React from 'react';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF, { TotalPriceView } from './Views';
import {
  UseInitData,
  UseCondLoader,
  UseList,
  useData,
} from '../../../../useMorfos';
import { ItemProd, NoItemView } from '../../../comps';
import { mountCurrency } from '../M7e_PurchasedPF/helpers';
import { ezLog } from '../../../../useMorfos/utils';

// ----------- set Info Screen
export const infoSc = {
  path: 'orderFinal',
  groupSc: 'pubApp',
  condBigSc: true,
  layout: {
    title: <ShopName />,
    subTitle: 'Lista de Compras',
  },

  scCode: 'M7c',
};

function ShopName() {
  const shopName = useData('M7a.shopName');
  return shopName;
}

// ----------- set Default Component
export default () => {
  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Conds
  const condMsg = useData('M7c.msgError');
  const condDDDMsg = useData('M7c.msgDDDError');

  // ----------- set Btns
  const btnOrderFinal = () => dispatch({ type: 'M7c_CheckPhone' });

  // ----------- set Return
  const infoList = {
    itemContent: 'M7c.scContent',
    reducer: 'M7c.finalProds.itemsInfo',
  };

  const infoView = {
    btnOrderFinal,
    TotalPriceComp,
    condMsg,
    condDDDMsg,
  };

  return (
    <UseInitData reducer={'M7c_InitData'}>
      <ViewDF info={infoView}>
        <UseCondLoader data={'M7c.finalProds.condList'}>
          <UseList data={'M7c.finalProds.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemProd infoData={{ ...infoList, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};

const TotalPriceComp = () => {
  // --------- set Data
  const cart = useData('basePersist.cart.addedProds');
  const finalProds = useData('M7c.finalProds.itemsInfo');

  // --------- set Hooks
  const dispatch = useDispatch();

  // --------- set Calc Amount
  const arrPrices = [];
  for (const key in finalProds) {
    if (cart[key]) {
      const item = finalProds[key];
      const itemAmount = cart[key].amountProd;
      const multiplyAmount = item.shopPrice * itemAmount;

      arrPrices.push(multiplyAmount);
    }
  }
  const total = arrPrices.reduce((prev, crr) => prev + crr, 0);

  // -------- set Total in Central Data
  const setCD = () => dispatch({ type: 'M7c_SetCentData', value: total });
  setCD();

  const currency = mountCurrency(total);
  const totalLabel = `Total: ${currency}`;

  return <TotalPriceView info={totalLabel} />;
};
