// ----------- import Packs
import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';

// ----------- import Internals
import imgDefault from '../../images/default.jpg';
import { useStl, useData } from '../../../useMorfos';
import { useDispatch } from 'react-redux';

export default ({ infoData }) => {
  const { itemId, pressItem } = infoData;

  const dispatch = useDispatch();
  const shops = useData('M2.shopsInReach.itemsInfo');

  const shopName = shops[itemId]?.name ?? '...';
  const shopImg = shops[itemId]?.imgUrl;
  const appBuy = shops[itemId]?.appBuy;
  const condThumbnail = shopImg ?? imgDefault;

  const btnSelectShop = () => {
    dispatch({ type: pressItem, value: itemId });
  };

  return (
    <TouchableOpacity style={stlBox2} onPress={btnSelectShop}>
      <View style={[useStl.flexRow, { marginBottom: 8, padding: 20 }]}>
        <Image source={condThumbnail} style={stlImg2} />
        <View style={{ marginLeft: 10 }}>
          <Text style={stlTxt1}>{shopName}</Text>
        </View>
      </View>

      <View style={stlBorder}>
        {appBuy ? (
          <>
            <Text style={stlTxt7}>Compra Online</Text>
          </>
        ) : (
          <Text style={stlTxt7}>Compra Apenas no Local</Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

// #region :: STYLEs *********

const stlImg2 = {
  backgroundColor: '#eee',
  width: 50,
  height: 50,
};
// const stlRow = [useStl.flexRow];
const stlBox2 = [useStl.cardMask];
const stlBorder = [useStl.border(1, 0, 0, 0, '#ddd')];

// # fonts *********
const stlTxt1 = [
  {
    fontSize: 15,
    fontWeight: 600,
    color: '#444',
  },
];

const stlTxt7 = {
  fontSize: 14,
  fontWeight: 600,
  color: '#333',
  margin: 12,
};

// #endregion *********
