// ----------- import Packs
import React from 'react';
import {
  View,
  ScrollView,
  TextInput,
  TouchableOpacity,
  // Text,
} from 'react-native';

// ----------- import Internals
import { useStl, UseIcoMoon } from '../../../../useMorfos';
import { primaryColor } from '../../../../config/styles';
// import { IptText } from '../../../comps';

// #region :: STYLEs *********
const stlBodyView = [
  useStl.flex1,
  { backgroundColor: '#f6f6f6', overflow: 'hidden' },
];

const stlNavBar = [useStl.navbarView, { backgroundColor: '#fff' }];
const stlLeftBox = [useStl.leftBox];
const stlCenterBox = [useStl.centerBox];
const stlRightBox = [useStl.rightBox];

const stlResults = [useStl.pad20, useStl.flexCenter];
const stlWrap = [useStl.pad20, useStl.colView, { width: '96%' }];
const stlInput = [
  {
    backgroundColor: '#fff',
    borderRadius: 8,
    paddingHorizontal: 15,
    paddingVertical: 10,
    width: 250,
    outline: 'none',
  },
];
// #endregion *********

// #region :: EXPORTs *********
export const TxtInputView = ({ info }) => {
  return (
    <>
      <TextInput
        style={stlInput}
        placeholder="Buscar Produtos"
        onChangeText={info.iptChange}
        value={info.autoComplete}
      />
    </>
  );
};
// #endregion :: *********

export default ({ info, children }) => {
  return (
    <View style={stlBodyView}>
      <View style={stlNavBar}>
        <TouchableOpacity style={stlLeftBox} onPress={info.btnBack}>
          <UseIcoMoon name="left" size={18} color={primaryColor} />
        </TouchableOpacity>
        <View style={stlCenterBox}>
          <info.TxtInput />
        </View>
        <View style={stlRightBox}>
          <UseIcoMoon name="search" size={18} color={primaryColor} />
        </View>
      </View>
      <ScrollView>
        {info.condRes ? (
          <View style={stlResults}>
            <View style={stlWrap}>
              <info.ListCategs />
            </View>
          </View>
        ) : (
          <View style={stlResults}>
            <info.AutoComplete />
          </View>
        )}

        {info.condCateg && (
          <View style={stlResults}>
            <info.ListResCatg />
          </View>
        )}
      </ScrollView>
    </View>
  );
};
