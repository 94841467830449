// ----------- import Packs
import React from 'react';
import { ActivityIndicator, View } from 'react-native';

// ----------- import Internals
import { useData } from '.';
import { primaryColor } from '../config/styles';
import useStl from './useStl';
import { ezLog } from './utils';

/**
 * Map Ids and pass them to children components
 * Ex:
 * <UseList infoData={"B1.namesList"}>
 * {(itemId, noItem) => noItem ? <NoItem /> : <Item itemId={itemId} />}
 * </UseList>
 * @type {function}
 * @param {{ data: string, loadNext: string, children: function }} props - Ex: {data, children}
 * @returns {function[]}
 */

// ----------- set Default Component
export default ({ data, loadNext, children }) => {
  const dataToMap = useData(data);
  const loadMore = useData(loadNext);
  const condArr = Array.isArray(dataToMap) && dataToMap;
  const LEIA =
    'Envie um array de strings de ids pro UseList! Você pode usar o INIT DATA pra preparar esse array!';
  if (!condArr) {
    throw new Error('Não é ARRAY!', LEIA);
  }
  const isEmpty = condArr.length === 0;
  const condItemStr = !isEmpty && typeof condArr[0] !== 'string';

  if (condItemStr) {
    throw new Error('Não é um ARRAY com STRINGS!', LEIA);
  }

  if (isEmpty) {
    return children(false, true);
  }
  return (
    <>
      {condArr.map(item => children(item, false))}
      {loadMore && (
        <View style={stlBox}>
          <ActivityIndicator size={25} color={primaryColor} />
        </View>
      )}
    </>
  );
};

// #region :: STYLEs *********
const stlBox = [
  useStl.border(1, 1, 1, 1, '#e4e4e4'),
  useStl.flexCenter,
  { borderRadius: 8, backgroundColor: '#f5f4f4', height: 60 },
];
const stlBtn = [
  useStl.btn,
  {
    borderRadius: 10,
    backgroundColor: '#fbfbfb',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
];
const stlTxt = [{ fontWeight: 400, color: '#555' }];
// #endregion *********

// ***************************************
// #region :: HOW TO USE IT
// ---------------

/*

<UseList data={'D1.productList'}>
  {(itemId, noItem) =>
    noItem ? <NoItemView /> : <ProdItem itemId={itemId} />
  }
</UseList>

*/

// ---------------
// #endregion
// ***************************************
