import React from 'react';
import { View, Text } from 'react-native';
import { useData, useStl } from '../../../useMorfos';

export default ({ infoData }) => {
  // set InfoData
  const label1 = infoData.label1 ?? '';
  const label2 = infoData.label2 ?? '';
  const label3 = infoData.label3 ?? '';

  // set Cond
  const path = useData('baseRoute.path');
  const condAdm = path === 'shopsList' ? stlLabelAdm1 : stlLabel1;
  return (
    <View style={stlItem}>
      <Text style={condAdm}>{label1}</Text>
      <Text style={stlLabel2}>{label2}</Text>
      <Text style={stlLabel3}>{label3}</Text>
    </View>
  );
};

// #region :: STYLEs *********
const stlItem = [useStl.flexRow, { paddingVertical: 15, width: '100%' }];

const stlLabel1 = [{ fontWeight: '600', color: '#9e9e9e', width: 296 }];
const stlLabel2 = [
  {
    fontWeight: '600',
    paddingLeft: 9,
    color: '#9e9e9e',
    borderStyle: 'dashed',
    flex: 4,
  },
  useStl.border(0, 0, 0, 0.5, '#66666636'),
];
const stlLabel3 = [{ fontWeight: '600', paddingLeft: '40%', color: '#9e9e9e' }];

// ADM STYLE
const stlLabelAdm1 = [{ fontWeight: '600', width: 296, color: '#9e9e9e' }];
// #endregion *********
