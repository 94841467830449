// ---------- import Internals
import { firestore } from '../../../../config/firebase/fbConfig';
import { asyncRefs, ezLog } from '../../../../useMorfos/utils';

// ---------- set Reducers
export default (state, action) => {
  const inits = {
    // ---------- set Init Content Data
    M0a_InitContentData: () => {
      const scContent = {
        title: 'Temp Users',
        subTitle: 'Oi Mundo!',
        description: 'Lorem Ipsum!',
        txtBtn: 'Ir para o Signin',
      };
      const condData = true;
      action.asyncDispatch({ type: 'M0a_GET_Users' });

      return {
        ...state,
        M0a: { ...state.M0a, scContent, condData },
      };
    },

    // ---------- set Shops List
    M0a_GET_Users: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('users');
        const dataSuccess = await dbRef
          .where('typeAccount', '==', 'app1')
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M0a_GET_Users_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      const usersInfo = {};
      const usersList = [];

      dataDb.forEach(doc => {
        usersInfo[doc.id] = doc.data();
        usersList.push(doc.id);
      });

      return {
        ...state,
        M0a: {
          ...state.M0a,
          usersList,
          usersInfo,
          condList: true,
        },
      };
    },
  };

  const others = {
    // ------------ set Login
    M0a_SetLogin: () => {
      const currUser = state?.M0a?.usersInfo[action.value];
      const condClient = currUser.typeAccount === 'app1';
      action.asyncDispatch({ type: 'base_setRoute', value: 'onboarding' });

      if (condClient) {
        return {
          ...state,

          // baseRoute: {
          //   ...state.baseRoute,
          //   path: 'onboarding',
          // },
          baseAuthUser: { ...currUser },
        };
      } else {
        return {
          ...state,

          baseRoute: {
            ...state.baseRoute,
            path: 'shopsList',
          },
          baseAuthUser: { ...currUser },
          basePersist: {
            ...state.basePersist,
            userId: action.value,
          },
        };
      }
    },
  };

  return {
    ...inits,
    ...others,
  };
};
