// ---------- import Internals
import { firestore, messaging } from '../../../../config/firebase/fbConfig';
import { asyncRefs, ezLog } from '../../../../useMorfos/utils';
import { checkShopsStatus, testCategs } from './helpers';
const geofire = require('geofire-common');

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data
    M2_InitData: () => {
      const condData = true;
      // ---------- set SEARCH SHOPS IN REACH
      action.asyncDispatch({ type: 'M2_GET_Range' });

      return {
        ...state,
        M10: {},
        M2: {
          ...state.M2,
          condData,
        },
      };
    },
  };

  const gets = {
    // ---------- set GET SHOPS IN REACH
    // Acionado por:
    // - M2_InitData
    M2_GET_Range: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Ref DB (GET ALL SHOPS IN DB)
        const refDb = firestore.collection('shops');
        let dataSuccess = refDb.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        M2: { ...state.M2 },
      };
    },

    M2_GET_Range_SUCCESS: () => {
      // ---------- set Data to Show
      const userCoords = state.baseAuthUser.address.geolocation;
      const dataDb = action.value;

      const allProdsInfos = {};
      const allProdsIds = [];

      const itemsInfo = {};
      const itemsList = [];

      const matchingDocs = [];

      dataDb.forEach(doc => {
        const item = doc.data();
        const lat =
          item.address.geolocation._lat ?? item.address.geolocation.x_;
        const lng =
          item.address.geolocation._long ?? item.address.geolocation.N_;

        const radiuskm = item.address.range.radius;

        const user = [userCoords.latitude, userCoords.longitude];
        const radiusInM = radiuskm * 1000;

        // Filter out a few false positives
        const distanceInKm = geofire.distanceBetween([lat, lng], user);
        const distanceInM = distanceInKm * 1000;

        if (distanceInM <= radiusInM) {
          matchingDocs.push(doc.data());
        }
      });

      for (const key in matchingDocs) {
        const itemMatch = matchingDocs[key];
        const matchId = itemMatch.docId;
        const accStatus = itemMatch.accountStatus;

        // ----- set Filter Account Suspended
        const accAdmStatus = itemMatch.admStatus;
        const condProdsStatus = accStatus !== 'suspended';
        const condAdmStatus = accAdmStatus !== 'suspended';
        if (condProdsStatus) {
          if (condAdmStatus) {
            itemsInfo[matchId] = {
              docId: itemMatch?.docId,
              name: itemMatch?.name,
              imgUrl: itemMatch?.imgUrl,
              settings: itemMatch?.settings,
              appBuy: itemMatch?.appBuy ?? false,
              accountStatus: accStatus,
            };

            itemsList.push(matchId);
          }
        }

        // ----- set All Prods To Check
        allProdsInfos[matchId] = {
          docId: itemMatch?.docId,
          name: itemMatch?.name,
          imgUrl: itemMatch?.imgUrl,
          settings: itemMatch?.settings,
          appBuy: itemMatch?.appBuy ?? false,
          accountStatus: accStatus,
        };
        allProdsIds.push(matchId);
      }

      // aqui pega apenas produtos de mercados válidos
      action.asyncDispatch({ type: 'M2_GET_ShopProds' });

      // aqui pega todos os produtos de mercados da região
      // mesmo suspensos ---- esses prods dessa chamada não
      // são mostrados, são usados apenas p/ verificar se
      // continua suspenso... senão atualiza p/ active ou alert
      action.asyncDispatch({ type: 'M2_GET_ProdsToCheck', allProdsIds });

      return {
        ...state,
        M2: {
          ...state.M2,
          shopsInReach: {
            condList: true,
            itemsInfo,
            itemsList,
          },
          shopsToCheck: {
            itemsInfo: allProdsInfos,
            itemsList: allProdsIds,
          },
        },
      };
    },

    M2_GET_ProdsToCheck: () => {
      const allProdsIds = action.allProdsIds;

      const asyncFn = async () => {
        const itemRefs = allProdsIds.map(id => {
          return firestore
            .collection('shops')
            .doc(id)
            .collection('prods')
            .get();
        });
        const finalRes = Promise.all(itemRefs);

        return finalRes;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M2_GET_ProdsToCheck_SUCCESS: () => {
      const itemsInfo = {};
      // ---------- set Data
      const dataDb = action.value;
      let idx = 0;
      dataDb.forEach(docShop => {
        for (const item of docShop.docs) {
          idx = idx + 1;
          const currProd = item.data();
          itemsInfo[`${currProd.docId}`] = currProd;
        }
      });

      // ----------- set Check Account Status
      // ----------- set Update Database / Account Status
      const fnUpdate = (accountStatus, currShop) => {
        // ---------- set Prevent Unnecessary Call
        // compara status anterior com "novo" status
        const prevShopStatus =
          state?.M2?.shopsToCheck?.itemsInfo[currShop]?.accountStatus;
        const condCall = prevShopStatus !== accountStatus;

        if (condCall) {
          action.asyncDispatch({
            type: 'M2_UpdtAccount',
            accountStatus,
            currShop,
          });
        }
      };
      checkShopsStatus(itemsInfo, fnUpdate);

      return state;
    },

    // ---------- set GET SHOP PRODS
    // Acionado por:
    // - M2_GET_Range
    M2_GET_ShopProds: () => {
      const rangeShops = state?.M2?.shopsInReach.itemsList;

      const asyncFn = async () => {
        const itemRefs = rangeShops.map(id => {
          return firestore
            .collection('shops')
            .doc(id)
            .collection('prods')
            .get();
        });
        const finalRes = Promise.all(itemRefs);

        return finalRes;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        M2: { ...state.M2, prodsBase: { firstCall: true } },
      };
    },

    M2_GET_ShopProds_SUCCESS: () => {
      // ---------- set Data
      const dataDb = action.value;

      const itemsInfo = {};
      const arrIdsBaseProds = [];
      let itemsList;
      let idx = 0;

      dataDb.forEach(docShop => {
        for (const item of docShop.docs) {
          idx = idx + 1;
          const currProd = item.data();
          itemsInfo[`${currProd.prodId}_${idx}`] = currProd;
          arrIdsBaseProds.push(currProd.prodId);
        }
      });
      itemsList = [...Object.keys(itemsInfo)];

      action.asyncDispatch({ type: 'M2_MAKE_VarsGroup' });
      return {
        ...state,
        M2: {
          ...state.M2,
          arrIdsBaseProds,
          allShopProds: {
            itemsInfo,
            itemsList,
          },
        },
      };
    },

    // ---------- set GET MERGE ALL PRODS INFO
    // Acionado por:
    // - M2_GET_ShopProds
    M2_MAKE_VarsGroup: () => {
      const allShopProds = state?.M2?.arrIdsBaseProds;

      // ---------- set GET Base Prods (Apenas Prods de ShopsInReach)
      const asyncFn = async () => {
        const itemRefs = allShopProds.map(id => {
          return firestore.collection('prods').doc(id).get();
        });
        const finalRes = Promise.all(itemRefs);
        return finalRes;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        M2: { ...state.M2 },
      };
    },

    M2_MAKE_VarsGroup_SUCCESS: () => {
      action.asyncDispatch({ type: 'M2_FILTER_Prods' });

      // ---------- set Data to Show
      const dataBaseProds = action.value;
      const dataShopsInfo = state?.M2?.allShopProds.itemsInfo;
      const itemsInfo = {};
      const baseProds = {};
      let initNum = 0;

      // ---------- set All ProdsBase
      dataBaseProds.forEach(docProds => {
        const item = docProds.data();
        baseProds[item.docId] = item;
      });

      // ---------- set Merge ProdsBase with ShopProd Data
      for (const keyShop in dataShopsInfo) {
        const item = dataShopsInfo[keyShop];
        const idx = initNum;
        initNum = initNum + 1;

        // ---------- laço do produto
        const prodShop = item;
        const prodShopVars = prodShop.variations;
        for (const key in prodShopVars) {
          // ---------- laço das vars do produto
          const currVar = prodShopVars[key];
          const uniqueIdProds = `${prodShop.prodId}_${currVar.typeId}_${idx}`;
          const currProdBase = baseProds[prodShop?.prodId];
          const prodImg = currProdBase[`imgUrl${prodShop.imgSelected}`];
          const currVarBase = currProdBase.variations[currVar.typeId];

          // montar obj separando produtos por var e shop
          // AQUI INCLUI APENAS PRODUTOS QUE NÃO ESTEJAM COM VALOR 0
          // Preço Maior que 0 (zero)
          if (currVar.price > 0) {
            itemsInfo[uniqueIdProds] = {
              dataShops: {
                [`${prodShop.shopId}`]: {
                  shopId: prodShop.shopId,
                  // prodId: prodShop.prodId,
                  prodId: `${prodShop.prodId}_${currVar.typeId}`,
                  prodShopId: prodShop.docId,
                  idVariation: currVar.typeId,
                  shopPrice: currVar.price,
                },
              },
              dataProd: {
                idVar: currVar.typeId,
                renderItem: true,
                varName: currVarBase.typeName_label,
                varValue: currVarBase.typeValue,
                prodId: prodShop.prodId,
                img: prodImg,
                avgPrice: 0,
                name: currProdBase.name,
                categ: currProdBase.categ,
                subCateg: currProdBase.subCateg,
                prodIdVar: `${prodShop.prodId}_${currVar.typeId}`,
              },
            };
          }
        }
      }

      const itemsList = [...Object.keys(itemsInfo)];

      // ---------- set Average Prices
      let avgPrice = {};
      for (const key1 in itemsInfo) {
        const item1 = itemsInfo[key1];
        const dataShops = item1.dataShops;
        const dataProd = item1.dataProd;

        for (const keyShop in dataShops) {
          const itemShop = dataShops[keyShop];

          // ---------- alimentar avgPrice organizando arrays por prod e var
          const idVariation = itemShop.idVariation;

          const prevPrices =
            avgPrice[dataProd.prodId] && avgPrice[dataProd.prodId][idVariation];
          const condPrev = prevPrices !== undefined ? prevPrices : [];

          avgPrice[dataProd.prodId] = {
            ...avgPrice[dataProd.prodId],
            [idVariation]: [...condPrev, itemShop.shopPrice],
          };
        }
      }

      // ---------- set Merge avgPrice with respective dataProd.avgPrice
      for (const key1 in itemsInfo) {
        const item1 = itemsInfo[key1];
        const dataShops = item1.dataShops;
        const dataProd = item1.dataProd;

        for (const keyShop in dataShops) {
          const itemShop = dataShops[keyShop];
          const currAvgPrc = avgPrice[dataProd.prodId][itemShop.idVariation];

          // ------ calc AvgPrice
          const priceSum = currAvgPrc.reduce((prev, crr) => prev + crr, 0);
          const calcAvg = priceSum / currAvgPrc.length;

          dataProd.avgPrice = calcAvg;
        }
      }

      action.asyncDispatch({ type: 'M2_GET_Categs' });

      return {
        ...state,
        M2: {
          ...state.M2,
          mergedVars: {
            condList: true,
            itemsList,
            itemsInfo,
          },
        },
      };
    },

    // ---------- set GET CATEGS + SUBCATEGS
    // Acionado por:
    // - M2_MAKE_VarsGroup
    M2_GET_Categs: () => {
      // ---------- set Async Function1
      const asyncBase = async () => {
        const dbRef = firestore.collection('settings');
        const dataSuccess = await dbRef.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M2_GET_Categs_SUCCESS: () => {
      // ---------- set Data
      const baseProds = state?.M2?.mergedVars?.itemsInfo;
      const dataDb1 = Object.values(baseProds);
      const dataDb = action.value;
      const catInfo = [];
      const subCatInfo = [];
      const condKey = dataDb1[0]?.dataProd?.categ;

      let newCatArr;
      let newSubCatArr;
      dataDb.forEach(doc => {
        // --- Items Info
        const catListX = doc.data().catList;
        const subcatListX = doc.data().subcatList[condKey];
        catInfo.push(...catListX);
        subCatInfo.push(...Object.values(doc.data().subcatList));

        // --- Items List
        // ------------- Só Adiciona SubCategs De Produtos Válidos
        // ---- ou seja Prods de Mercados da Região e com Preço
        newCatArr = testCategs({
          arrCatOrSub: catListX,
          baseProds,
          prop: 'categ',
        });
        newSubCatArr = testCategs({
          arrCatOrSub: subcatListX,
          baseProds,
          prop: 'subCateg',
        });
      });

      return {
        ...state,
        M2: {
          ...state.M2,
          currCateg: condKey,
        },
        basePersist: {
          ...state.basePersist,
          categs: {
            itemsInfo: catInfo,
            itemsList: newCatArr,
          },
          subCategs: {
            itemsInfo: subCatInfo,
            itemsList: newSubCatArr,
          },
        },
      };
    },

    // Acionado Por:
    // - M2_GET_Categs // TALVEZ EXCLUIR
    // - M2_ChangeCateg
    M2_GET_SubCategs: () => {
      // ---------- set Data
      const subcatList = state?.basePersist?.subCategs?.itemsInfo;
      const baseProds = state?.M2?.mergedVars?.itemsInfo ?? {};
      const dataDb = Object.values(baseProds);
      const initCat = dataDb[0]?.dataProd?.categ;
      const initSub = dataDb[0]?.dataProd?.subCateg;
      const currCateg = state?.M2?.currCateg;
      const condKey = currCateg ?? initCat;

      // ------------- Só Adiciona SubCategs De Produtos Válidos
      // ---- ou seja Prods de Mercados da Região e com Preço
      const arrSubCatList = testCategs({
        arrCatOrSub: subcatList,
        condKey,
        baseProds,
        prop: 'subCateg',
      });

      return {
        ...state,
        M2: {
          ...state.M2,
          filterProds: {
            ...state.M2.filterProds,
            subCategActive: initSub,
          },
        },
        basePersist: {
          ...state.basePersist,
          subCategs: {
            ...state.basePersist.subCategs,
            itemsList: arrSubCatList,
          },
        },
      };
    },
  };

  const sets = {
    M2_TokenPermission: () => {
      messaging
        .getToken()
        .then(token => {
          action.asyncDispatch({ type: 'M2_SET_Token', value: token });
        })
        .catch(err =>
          action.asyncDispatch({ type: 'M2_SET_Token_Error', value: err }),
        );

      return state;
    },

    M2_SET_Token: () => {
      const token = action.value;
      const currUser = state?.baseAuthUser?.docId;

      // ---------- set Async Function
      const asyncFn = async () => {
        const dbRef = firestore.collection('users').doc(currUser);
        const dataSuccess = await dbRef.update({ msgToken: token });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M2_SET_Token_SUCCESS: () => {
      return state;
    },

    M2_SET_Token_Error: () => {
      return state;
    },
  };

  const edits = {
    // Acionado por:
    // - Quando Clica ItemCateg
    M2_ChangeCateg: () => {
      const mergedVars = state?.M2?.mergedVars?.itemsInfo;
      const value = action?.value;
      let initSubCateg;

      for (const key in mergedVars) {
        const currProd = mergedVars[key];
        const currCateg = currProd?.dataProd?.categ;
        const currSubCateg = currProd?.dataProd?.subCateg;
        const condSnap = value === currCateg;

        if (condSnap) {
          initSubCateg = currSubCateg;
        }
      }

      action.asyncDispatch({ type: 'M2_GET_SubCategs' });
      action.asyncDispatch({ type: 'M2_FILTER_Prods', value: initSubCateg });

      return {
        ...state,
        M2: {
          ...state.M2,
          currCateg: value,
        },
      };
    },

    // Acionado por:
    // - Clique ItemSubCateg
    // - M2_ChangeCateg
    // - M2_MAKE_VarsGroup
    M2_FILTER_Prods: () => {
      // ---------- set Data
      const value = action.value;
      const mergedVars = state?.M2?.mergedVars?.itemsInfo;
      const dataDb = Object.values(mergedVars);
      const initSub = dataDb[0]?.dataProd?.subCateg;
      const condAction = value ?? initSub;
      const condSubCateg = value !== undefined ? value : initSub;
      let itemsInfo = {};
      const itemsList = [];

      // ---------- set Filter SubCateg.
      const filteredRes = dataDb.filter(doc => {
        const dataProd = doc?.dataProd;

        return dataProd.subCateg === condAction;
      });

      // ---------- set Filter Var
      filteredRes.forEach(item => {
        const prodId = item.dataProd.prodId;
        const idVar = item.dataProd.idVar;

        itemsInfo[`${prodId}_${idVar}`] = item.dataProd;
      });

      for (const key in itemsInfo) {
        itemsList.push(key);
      }

      const path = state?.baseRoute?.path;
      const condSc = path === 'orderEdit';
      const itemsList2 = [];
      if (condSc) {
        const shopProds = state?.M7b?.itemsInfo;
        for (const key in shopProds) {
          const item = shopProds[key];
          const condFilter = item.subCateg === value;

          condFilter && itemsList2.push(key);
        }
      }

      return {
        ...state,
        M2: {
          ...state.M2,
          filterProds: {
            ...state.M2.filterProds,
            subCategActive: condSubCateg,
            condList: true,
            itemsInfo,
            itemsList,
          },
        },
        M7b: {
          ...state.M7b,
          itemsList: itemsList2,
        },
      };
    },

    M2_UpdtAccount: () => {
      const currShop = action.currShop;
      const accountStatus = action.accountStatus;
      const condUpdt = accountStatus === 'suspended';

      /// ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('shops').doc(currShop);
        const dataSuccess = await dbRef.update({
          accountStatus: accountStatus,
          prodsOutdated: condUpdt,
        });

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    M2_UpdtAccount_SUCCESS: () => {
      return state;
    },
  };

  return {
    ...inits,
    ...gets,
    ...sets,
    ...edits,
  };
};

// ---------- set Exports
export default reducers;
