// ----------- import Packs
import React from 'react';
import { View, Text } from 'react-native';

// ----------- import Internals
import { useStl } from '../../../../useMorfos';

export default ({ children }) => {
  return (
    <>
      <View style={stlCateg}></View>
      <View style={stlCateg}></View>
      <View style={stlCateg}></View>
    </>
  );
};

// #region :: STYLEs *********

// const stlPad = [{ width: '100%', paddingHorizontal: 30 }];
// const stlCard = [
//   useStl.cardItemList,
//   useStl.flexRow,
//   useStl.border(1, 1, 1, 1, '#ddd'),
//   { backgroundColor: '#f6f6f6', shadowOpacity: 0 },
// ];
const stlCateg = [
  useStl.cardList,
  useStl.flexRow,
  {
    width: 120,
    height: 55,
    marginHorizontal: 5,
    marginVertical: 5,
    overflow: 'hidden',
  },
];

// #endregion *********
