// ----------- import Packs
import React from 'react';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF, { BtnSaveView, TxtInputView } from './Views';
import {
  UseInitData,
  useData,
  UseCondLoader,
  useRouter,
} from '../../../../useMorfos';
import { ezLog } from '../../../../useMorfos/utils';

// ----------- set Info Screen
export const infoSc = {
  path: 'address',
  groupSc: 'pubApp',
  condBigSc: true,

  scCode: 'M1',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('M1.scContent');
  const isEdit = useData('baseAuthUser.address.edit');
  const condVal = useData('M1.forms.iptsChanges.address.street_number');
  const address = useData('baseAuthUser.address') ?? [];
  const noShops = useData('M1.noShops');

  // ----------- set Hooks
  const { callRouter } = useRouter();
  const goHome = () => callRouter('home');
  // ----------- set Return
  const infoView = {
    isEdit,
    content,
    BtnSave,
    noShops,
    condVal,
    goHome,
  };

  const returnView = (
    <UseInitData reducer={'M1_InitData'}>
      <ViewDF info={infoView}>
        <TxtInput />
      </ViewDF>
    </UseInitData>
  );
  const noAddress = Object.keys(address).length === 0;
  const condJump = isEdit ? returnView : <Redirect />;

  const condReturn = noAddress ? returnView : condJump;
  return condReturn;
};

const Redirect = () => {
  // ----------- set Hooks
  const { callRedirect } = useRouter();

  return callRedirect('bookletList');
};

const TxtInput = () => {
  // ----------- set Data
  const autocomplete = useData('M1.forms.iptsChanges.autocomplete');

  // ----------- set Hooks
  const dispatch = useDispatch();

  // ----------- set Forms
  const iptChange = txt =>
    dispatch({ type: 'M1_IptChange', value: txt, field: 'autocomplete' });

  // ----------- set Return
  const infoReturn = { iptChange, autocomplete };
  return <TxtInputView info={infoReturn} />;
};

const BtnSave = () => {
  // ---------- set Data
  const address = useData('M1.forms.iptsChanges.address.street_number');

  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Btns
  const btnSave = () => dispatch({ type: 'M1_ADD_Address' });

  // ---------- set Return
  return (
    <UseCondLoader data={'M1.condBtn'}>
      <BtnSaveView info={{ address, btnSave }} />
    </UseCondLoader>
  );
};
