// ---------- import Internals
import { firestore } from '../../../../../config/firebase/fbConfig';

import { asyncRefs, ezLog } from '../../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data _Name_
    B2_InitData: () => {
      const condCateg = state?.basePersist?.categs.itemsInfo;
      const condCategs = Object.keys(condCateg).length === 0;
      const initProds = state?.B2?.itemsInfo ?? {};
      const condInitProds = Object.keys(initProds).length === 0;
      const condShow = condInitProds ? false : true;
      const scContent = {
        // --- ItemList
        act1: 'Ver Produtos',
        act2: 'Ver Vendas',
      };

      const condData = true;
      condCategs && action.asyncDispatch({ type: 'B2_GET_Categs' });
      condInitProds && action.asyncDispatch({ type: 'B2_GET_InitProds' }); // começa trazer prods base
      // action.asyncDispatch({ type: 'B2_GET_Prods' });

      return {
        ...state,
        B2: {
          ...state.B2,
          scContent,
          condData,
          condList: condShow,
        },
      };
    },
  };

  const btns = {
    B2_ProdADD: () => {
      return {
        ...state,
        C2: {
          ...state.C2,
          isEditable: false,
          idToEdit: null,
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: 'prodADD',
        },
      };
    },
  };

  const gets = {
    // ---------- set GET CATEGS + SUBCATEGS
    B2_GET_Categs: () => {
      // ---------- set Async Function1
      const asyncBase = async () => {
        const dbRef = firestore.collection('settings');
        const dataSuccess = await dbRef.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    B2_GET_Categs_SUCCESS: () => {
      // ---------- set Data
      const dataDb = action.value;
      let catInfo = [];
      let subCatInfo = [];

      dataDb.forEach(doc => {
        // --- Items Info
        const catListX = doc.data().catList;
        const subcatListX = doc.data().subcatList;
        catInfo.push(...catListX);
        subCatInfo.push(...Object.values(subcatListX));
      });

      return {
        ...state,
        basePersist: {
          ...state.basePersist,
          categs: {
            ...state.basePersist.categs,
            itemsInfo: catInfo,
          },
          subCategs: {
            ...state.basePersist.subCategs,
            itemsInfo: subCatInfo,
          },
        },
      };
    },

    // ---------- set GET BASE PRODS
    B2_GET_InitProds: () => {
      const asyncBase = async () => {
        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('prods')
          .orderBy('createdAt', 'desc')
          .orderBy('docId', 'desc')
          .limit(30)
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    B2_GET_InitProds_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;

      const itemsInfo = {};
      const itemsList = [];
      let lastDocId;
      let lastDate;

      dataDb.forEach(doc => {
        itemsInfo[doc.id] = doc.data();
        itemsList.push(doc.id);

        lastDocId = doc.id;
        lastDate = doc.data().createdAt;
      });
      // console.log('last', lastDocId);

      return {
        ...state,
        B2: {
          ...state.B2,
          itemsList,
          itemsInfo,
          condList: true,
          lastDocId,
          lastDate,
        },
      };
    },

    B2_GET_NextProds: () => {
      const lastDocId = state?.B2?.lastDocId;
      const lastDate = state?.B2?.lastDate;

      const asyncBase = async () => {
        // ---------- set Async Call
        const dataSuccess = await firestore
          .collection('prods')
          .orderBy('createdAt', 'desc')
          .orderBy('docId', 'desc')
          .startAfter(lastDate, lastDocId)
          .limit(30)
          .get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncBase);

      // ---------- call Async / Mock
      ref.callAsync();

      return {
        ...state,
        B2: {
          ...state.B2,
          loadNext: true,
        },
      };
    },

    B2_GET_NextProds_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;

      const itemsInfo = state?.B2?.itemsInfo;
      const itemsList = state?.B2?.itemsList;
      let lastDocId;
      let lastDate;

      dataDb.forEach(doc => {
        itemsInfo[doc.id] = doc.data();
        itemsList.push(doc.id);
        lastDocId = doc.id;
        lastDate = doc.data().createdAt;
      });
      // console.log('last', lastDocId);

      return {
        ...state,
        B2: {
          ...state.B2,
          itemsList,
          itemsInfo,
          loadNext: false,
          lastDocId,
          lastDate,
        },
      };
    },
  };

  const edits = {
    B2_EditProd: () => {
      action.asyncDispatch({ type: 'C2_InitForm' });

      return {
        ...state,
        C2: {
          ...state.C2,
          idToEdit: action.itemId,
        },
        baseRoute: {
          ...state.baseRoute,
          pathRight: 'prodADD',
        },
      };
    },
  };

  return {
    ...inits,
    ...btns,
    ...gets,
    ...edits,
  };
};

// ---------- set Exports
export default reducers;
