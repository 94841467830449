// ----------- import Packs
import React from 'react';
// import { useDispatch } from 'react-redux';

// ----------- import Internals
import ViewDF from './Views';
import { useRouter, UseInitData, useData } from '../../../../useMorfos';

// ----------- set Info Screen
export const infoSc = {
  path: 'terms2',
  groupSc: 'pubApp',
  condBigSc: true,

  scCode: 'M0c',
};

// ----------- set Default Component
export default () => {
  // ----------- set Data
  const content = useData('M0c.scContent');

  // ----------- set Hooks
  const { callRouter } = useRouter();

  // ----------- set Routes
  const btnGoBack = () => callRouter('signin2');

  // ----------- set Return
  const infoView = { content, btnGoBack };

  return (
    <UseInitData reducer={'M0c_InitData'}>
      <ViewDF info={infoView} />
    </UseInitData>
  );
};
