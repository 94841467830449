// ----------- import Packs
import React from 'react';
import { Text, Image, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import { useStl, useData } from '../../../useMorfos';
import cat0 from '../../../project/images/categs/categ_0.png';
import cat1 from '../../../project/images/categs/categ_1.png';
import cat2 from '../../../project/images/categs/categ_2.png';
import cat3 from '../../../project/images/categs/categ_3.png';
import cat4 from '../../../project/images/categs/categ_4.png';
import cat5 from '../../../project/images/categs/categ_5.png';
import cat6 from '../../../project/images/categs/categ_6.png';
import cat7 from '../../../project/images/categs/categ_7.png';
import cat8 from '../../../project/images/categs/categ_8.png';
import cat9 from '../../../project/images/categs/categ_2.png';
import { ezLog } from '../../../useMorfos/utils';

// #region :: STYLEs *********
const stlCateg = [
  useStl.cardList,
  useStl.flexRow,
  {
    width: 120,
    height: 55,
    marginHorizontal: 5,
    marginVertical: 5,
    overflow: 'hidden',
  },
];

const stlImg3 = { width: 60, height: 60, left: -7, position: 'absolute' };
const stlTxt = [
  {
    marginLeft: 36,
    zIndex: 2,
    fontWeight: 600,
    fontSize: 13,
  },
];

// #endregion *********

export default ({ infoData }) => {
  const { list, change, itemId } = infoData;

  const allCategs = useData(list);
  const currCateg = allCategs[itemId];

  const objImg = {
    cat0,
    cat1,
    cat2,
    cat3,
    cat4,
    cat5,
    cat6,
    cat7,
    cat8,
    cat9,
  };

  const selCat = `cat${itemId}`;
  const condSrc = objImg[selCat];

  const dispatch = useDispatch();
  const btnChangeCateg = () => dispatch({ type: change, value: itemId });

  return (
    <TouchableOpacity style={stlCateg} onPress={btnChangeCateg}>
      <Image source={condSrc} style={stlImg3}></Image>
      <Text style={stlTxt}>{currCateg.labels}</Text>
    </TouchableOpacity>
  );
};
