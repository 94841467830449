// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import {
  UseInitData,
  UseCondLoader,
  UseList,
  useData,
} from '../../../../../useMorfos';
import {
  FilterBar,
  ItemList,
  LoadMoreItems,
  NoItemView,
  TitleList,
} from '../../../../comps';
import { useDispatch } from 'react-redux';

// ----------- set Info Screen
export const infoSc = {
  path: 'prodsShopList',
  groupSc: 'shop1',
  condBigSc: true,
  layout: {
    icon: 'carrot',
    title: 'Produtos',
    desc: 'Lista de Produtos',
  },

  scCode: 'S1',
};

// ----------- set Default Component
export default () => {
  // ----------- set Hooks
  const dispatch = useDispatch();
  const isSearch = useData('S1.forms.iptsChanges.terms');

  // ----------- set Btns
  // const btnNextProds = () => dispatch({ type: 'S1_GET_NextProds' });
  const btnNextProds = () => {
    isSearch
      ? dispatch({ type: 'comps_nextQuery' })
      : dispatch({ type: 'S1_GET_NextProds' });
  };

  // ----------- set Return
  const labels = { label1: 'Produto' };
  const infoItem = {
    itemContent: 'S1.scContent',
    itemsInfo: 'S1.itemsInfo',
    pressItem: 'S1_EditShopProds',
  };
  return (
    <UseInitData reducer={'S1_InitData'}>
      <ViewDF>
        <FilterBar />

        <TitleList infoData={labels} />
        <UseCondLoader data={'S1.condList'}>
          <UseList data={'S1.itemsList'} loadNext={'S1.loadNext'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemList infoData={{ ...infoItem, itemId }} />
              )
            }
          </UseList>
          <LoadMoreItems
            info={{
              btnNextProds,
              lastItem: 'S1.lastDocId',
              condBtn: 'S1.loadNext',
            }}
          />
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
