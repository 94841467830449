// ----------- import Internals
import { useRouter, useData } from '../../../useMorfos';

// ----------- set Group
export default ({ children }) => {
  // ----------- set Data
  const authUser = useData(`basePersist.userId`);

  // ----------- set Hooks
  const { callRedirect } = useRouter();

  // ----------- set Return
  const condReturn = authUser ? callRedirect('shopsList') : children;
  return condReturn;
};
