/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { useState } from 'react';
import { View, Text, Switch } from 'react-native';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import { useData, useStl } from '../../../../../useMorfos';
import { ezLog } from '../../../../../useMorfos/utils';
import { IptTxt, IptImg, Btn1 } from '../../../../comps';

// #region :: STYLEs *********
const stlBodyView = [useStl.flex1, { padding: 10 }];
const stlBoxAddress = [
  useStl.border(1, 1, 1, 1, '#eee'),
  {
    padding: 10,
    borderRadius: 6,
    marginTop: 15,
  },
];
const stlAddressTxt = [{ fontSize: 12, color: '#666' }];

const stlMsg = [
  useStl.border(1, 1, 1, 1, '#d9caca'),
  {
    borderRadius: 6,
    color: '#ef4444',
    fontSize: 12,
    marginTop: 10,
    backgroundColor: '#f9f0ed',
    padding: 5,
  },
];

const toggStl = { fontSize: 10 };

// #endregion *********

export const MsgView = () => {
  return <Text style={stlMsg}>Preencha todos os campos</Text>;
};

export default ({ info, children }) => {
  return (
    <View style={stlBodyView}>
      {/* ----------- FIELDs */}

      <Form info={info} />
      {children}
    </View>
  );
};

const Form = info => {
  const { accStatus, appBuy, fnAccStatus, fnAppBuy } = info.info;

  return (
    <>
      <IptImg infoData={'C1.forms.iptsInfo.image'} />
      <View style={{ bottom: 11 }}>
        <View style={[useStl.flexRow, useStl.flexBetween]}>
          <Text style={{ marginTop: -2 }}>Status do Mercado:</Text>
          <View style={{ alignItems: 'flex-end' }}>
            <Switch
              trackColor={{ false: '#999', true: '#ccc' }}
              thumbColor={accStatus ? '#f5dd4b' : '#f4f3f4'}
              onValueChange={fnAccStatus}
              value={accStatus}
              size={12}
            />
            <Text style={toggStl}>Inativo/Ativo</Text>
          </View>
        </View>
      </View>
      <View style={[useStl.flexRow, useStl.flexBetween]}>
        <Text>Compras pelo APP:</Text>
        <View>
          <Switch
            trackColor={{ false: '#999', true: '#ccc' }}
            thumbColor={appBuy ? '#f5dd4b' : '#f4f3f4'}
            onValueChange={fnAppBuy}
            value={appBuy}
          />
          <Text style={toggStl}>Não/Sim</Text>
        </View>
      </View>

      <IptTxt infoData={'C1.forms.iptsInfo.name'} />
      <IptTxt
        sc={'C1'}
        fieldName={'phone'}
        type={'phone'}
        infoData={'C1.forms.iptsInfo.phone'}
      />

      <IptTxt infoData={'C1.forms.iptsInfo.email'} />

      <View style={stlBoxAddress}>
        <Text style={stlAddressTxt}>Coordenadas:</Text>
        <IptTxt infoData={'C1.forms.iptsInfo.lat'} />
        <IptTxt infoData={'C1.forms.iptsInfo.long'} />
      </View>

      <IptTxt infoData={'C1.forms.iptsInfo.answerable'} />
      <IptTxt
        sc={'C1'}
        fieldName={'cnpj'}
        type={'cnpj'}
        infoData={'C1.forms.iptsInfo.cnpj'}
      />
      <IptTxt infoData={'C1.forms.iptsInfo.idpagme'} />
      <IptTxt infoData={'C1.forms.iptsInfo.details'} />
    </>
  );
};
