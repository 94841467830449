// ---------- import Packs
import geohash from 'ngeohash';
import { firestore } from '../../../../../config/firebase/fbConfig';

export const getSettings = async currShop => {
  // ---------- set Cond Edit
  const editRef = await firestore.collection('shops').doc(currShop).get();
  const objRes = {
    settings: editRef.data()?.settings,
    address: editRef.data()?.address,
  };

  return objRes;
};

export const processDays = (dataToAdd, isEdit) => {
  let newObj = {};

  if (isEdit) {
    const days = dataToAdd.days;
    const condHasDays = days !== undefined;

    if (condHasDays) {
      for (const key in days) {
        const item = days[key];
        const { id, minHour, maxHour, maxHour_num, minHour_num } = item;
        const condMin = minHour !== undefined;
        const condMax = maxHour !== undefined;

        if (condMin) {
          newObj[`settings.days.${id}.minHour`] = minHour;
          newObj[`settings.days.${id}.minHour_num`] = minHour_num;
        }
        if (condMax) {
          newObj[`settings.days.${id}.maxHour`] = maxHour;
          newObj[`settings.days.${id}.maxHour_num`] = maxHour_num;
        }
      }
      newObj = { ...dataToAdd, ...newObj };
    } else {
      newObj = { ...dataToAdd };
    }
    delete newObj.days;
  } else {
    newObj = {
      ...dataToAdd,
    };
  }

  return newObj;
};

export const getGeohashRange = (latitude, longitude, distanceKm) => {
  const lat = 0.0144927536231884; // degrees latitude per mile
  const lon = 0.0181818181818182; // degrees longitude per mile
  // const distance = distanceKm / 1.609;
  const distance = distanceKm * 0.621371; // convert km in mile

  const lowerLat = latitude - lat * distance;
  const lowerLon = longitude - lon * distance;

  const upperLat = latitude + lat * distance;
  const upperLon = longitude + lon * distance;

  const lower = geohash.encode(lowerLat, lowerLon);
  const upper = geohash.encode(upperLat, upperLon);

  return {
    lower,
    upper,
  };
};

export const getCoords = async currShop => {
  const refDb = await firestore.collection('shops').doc(currShop).get();

  const coords = refDb.data()?.address?.geolocation;

  const lat = coords?.x_;
  const long = coords?.N_;

  const condError = lat === undefined || long === undefined;
  const condReturn = condError ? undefined : { lat, long };

  return condReturn;
};
