// ----------- import Packs
import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { useDispatch } from 'react-redux';

// ----------- import Internals
import { primaryColor } from '../../../../../config/styles';
import {
  UseCondLoader,
  useData,
  UseList,
  useStl,
} from '../../../../../useMorfos';
import { ezLog } from '../../../../../useMorfos/utils';
import {
  IptImg,
  IptPicker,
  IptTxt,
  IptTxt2,
  NoItemView,
  VarFields,
} from '../../../../comps';
import defaultImg from '../../../../images/default.jpg';

// #region :: EXPORTs *********

export const VarTitle = () => <Text style={stlVarTxt}>VARIAÇÕES</Text>;

export const BtnImg = ({ infoData }) => {
  // ---------- set Data
  const { itemId } = infoData;
  const currImg = useData('C2.forms.iptsChanges.currImg');

  // ---------- set Hooks
  const dispatch = useDispatch();

  // ---------- set Btns
  const btnSelectImg = () =>
    dispatch({ type: 'C2_IptSelectImg', value: itemId });

  // ---------- set Cond Stl Active
  const condId = currImg === itemId;
  const condStl1 = condId ? stlBtnImgAct : stlBtnImg;
  const condStl2 = condId ? stlBtnImgActTxt : stlBtnImgTxt;

  return (
    <TouchableOpacity style={condStl1} onPress={btnSelectImg}>
      <Text style={condStl2}>{itemId}</Text>
    </TouchableOpacity>
  );
};

export const CondImg = ({ info }) => {
  // ---------- set Data
  const { itemId } = info;
  const currImg = useData('C2.forms.iptsChanges.currImg');

  // ---------- set Cond Stl Active
  const condId = currImg === itemId;
  const condStl1 = condId ? stlShowView : stlHideView;

  return (
    <View style={condStl1}>
      <IptImg infoData={`C2.forms.iptsInfo.image${itemId}`} />
    </View>
  );
};

// #endregion *********

export default ({ info, children }) => {
  return (
    <View style={stlBodyView}>
      {/* ----------- set Form to ADM User */}
      <FormToAdm info={info} />

      {/* ----------- set Form to SHOP User */}
      <FormToShop info={info} />

      {/* ----------- set BTNs SAVE & CANCEL */}
      {/* Msg de Validação + Btns */}
      {children}
    </View>
  );
};

const FormToAdm = ({ info }) => {
  // ---------- set Access Only ADM Accounts
  const typeAccount = useData('baseAuthUser.typeAccount');
  const isAdm = typeAccount === 'adm1';

  // ----- set View Return
  const ViewAdm = () => {
    return (
      <>
        <View style={stlRowExpand}>
          <View style={stlBoxBtRow}>
            <info.ListBtnImg />
          </View>
        </View>
        <info.DynamicImage />

        <View style={stlBottomSpace}>
          <IptTxt infoData={'C2.forms.iptsInfo.name'} />
          {/* <IptTxt
            sc={'C2'}
            fieldName={'weight'}
            type={'comma'}
            infoData={'C2.forms.iptsInfo.weight'}
          /> */}
        </View>
        <IptPicker infoData={'C2.forms.iptsInfo.categ'} />
        <IptPicker infoData={'C2.forms.iptsInfo.subCateg'} />

        <info.DynamicPicker info={info} />

        <View style={stlBoxBtn}>
          <TouchableOpacity style={stlBtnAddVar} onPress={info.btnAddVar}>
            <Text style={stlBtnVarTxt}>Incluir Variação</Text>
          </TouchableOpacity>
        </View>
      </>
    );
  };

  const condAdm = isAdm ? <ViewAdm /> : null;
  return condAdm;
};

const FormToShop = () => {
  // ---------- set Access Only Shop Accounts
  const typeAccount = useData('baseAuthUser.typeAccount');
  const isShop = typeAccount === 'shop1';

  // ----- set View Return
  const ViewShop = ({ children }) => {
    const { name, image1, image2, imgSelected, catId, subCatId } =
      useData('C2.infoShopPF');

    const image = {
      1: image1,
      2: image2,
    };
    const condThumbnail = image[imgSelected] ?? defaultImg;

    // Lists To Select Label
    const catList = useData('basePersist.categs.itemsInfo');
    const subcatList = useData('basePersist.subCategs.itemsInfo');

    const condCateg = catId && catList.find(item => item.id === catId).labels;
    const condSubCateg =
      catId && subcatList[catId].find(item => item.id === subCatId).labels;

    const lblCateg = condCateg ?? '---';
    const lblSubCateg = condSubCateg ?? '---';

    return (
      <>
        <View style={stlImgBox}>
          <Image source={condThumbnail} style={stlImgProd} />
        </View>
        <View style={stlBottomSpace}>
          <Text style={stlSmallTxt}>Nome</Text>
          <Text>{name}</Text>
        </View>

        {/* <View style={stlBottomSpace}>
          <Text style={stlSmallTxt}>Peso por Kilo</Text>
          <Text>{weight}</Text>
        </View> */}

        <View style={stlBottomSpace}>
          <Text style={stlSmallTxt}>Categoria</Text>
          <Text>{lblCateg}</Text>
        </View>

        <View style={stlBottomSpace}>
          <Text style={stlSmallTxt}>Sub Categoria</Text>
          <Text>{lblSubCateg}</Text>
        </View>

        <Text style={stlVarTxt}>VARIAÇÕES</Text>
        {children}
      </>
    );
  };

  // ----- set Init Data Fields + Var List
  const CompInitData = () => {
    return (
      <ViewShop>
        <UseCondLoader data={'C2.infoShopPF.condList'}>
          <UseList data={'C2.infoShopPF.infoList'}>
            {(itemId, noItem) =>
              noItem ? (
                <View style={stlMgT10}>
                  <NoItemView />
                </View>
              ) : (
                <VarFields itemId={itemId} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewShop>
    );
  };

  const condShop = isShop ? <CompInitData /> : null;
  return condShop;
};

// #region :: STYLEs *********

// ------------- set Views
const stlBodyView = [useStl.flex1, { padding: 10 }];
const stlBottomSpace = [useStl.mgB20];
const stlMgT10 = [{ marginTop: 10 }];
const stlImgBox = [
  useStl.mgB20,
  {
    borderWidth: 2,
    borderColor: '#eee',
    minHeight: 100,
    borderRadius: 16,
    padding: 10,
    paddingBottom: 4,
    alignItems: 'center',
  },
];
const stlImgProd = [{ width: '100%', height: 130, borderRadius: 10 }];
const stlBoxBtRow = [useStl.flexRow];
const stlShowView = [{}];
const stlHideView = [{ display: 'none' }];
const stlRowExpand = [useStl.flexRow, { marginBottom: 5 }];

// ------------- set Txts
const stlSmallTxt = [{ fontSize: 10 }];
const stlVarTxt = [
  { color: primaryColor, fontWeight: 600, fontSize: 10, textAlign: 'center' },
];

// ------------- set Btns
const stlBoxBtn = [useStl.flexCenter];
const stlBtnAddVar = [useStl.btn, { width: 100, padding: 4, marginTop: 6 }];
const stlBtnVarTxt = [{ fontSize: 12, color: '#444' }];
const stlBtnImgBase = [
  useStl.flexCenter,
  { borderRadius: 60, width: 20, height: 20, marginRight: 3 },
];
const stlBtnImg = [...stlBtnImgBase, useStl.border(2, 2, 2, 2, '#ccc')];
const stlBtnImgAct = [...stlBtnImgBase, useStl.border(2, 2, 2, 2, 'green')];
const stlBtnImgTxt = [{ fontSize: 12, color: '#666' }];
const stlBtnImgActTxt = [{ fontSize: 12, color: 'dargreen' }];

// #endregion *********
