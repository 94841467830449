// ----------- import Internals
import { useData, useRouter } from '../../../useMorfos';

// ----------- set Group
export default ({ children }) => {
  const { callRedirect } = useRouter();
  const userPermission = useData(`baseAuthUser.typeAccount`);

  const condAdm = userPermission === 'adm1';
  const condShop = userPermission === 'shop1';
  const condCall = () =>
    condShop ? callRedirect('prodsShopList') : callRedirect('signin');
  const condReturn = condAdm ? children : condCall();

  return condReturn;
};
