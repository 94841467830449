// ----------- import Internals
import { MobileLimiter } from '../comps';
import {
  Public,
  Private,
  Adm1,
  Shop1,
  App1,
  Print,
  CheckShop,
} from './prjGroups';
import { NavControl } from '../comps';

// ----------- set Return
export default {
  clear: [],
  print: [Print],
  pub: [Public],
  adm1: [Private, Adm1, NavControl],
  shop1: [Private, Shop1, CheckShop, NavControl],
  pubApp: [Public, MobileLimiter],
  app1: [App1],
};
