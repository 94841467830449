// ----------- import Packs
import React from 'react';
import { useDispatch } from 'react-redux';
// import { FirebaseFirestore } from '@firebase/firestore-types';

// ---------- import Internals
import { firestore } from '../config/firebase/fbConfig';
import { useData } from './';
import { ezLog, hasData } from './utils';

export default info => {
  const {
    collectionName,
    orderField,
    orderType,
    reducerGet,
    reducerSuccess,
    firstCallData,
    itemsInfoData,
    condListData,
  } = info;

  // ----------- set Effects
  const fxCallSnap = () => {
    ezLog('SNAP IN!!!');

    const dbRef = firestore
      .collection(collectionName)
      .orderBy(orderField, orderType);

    if (!firstCall) {
      ezLog('CALL WITH LIMIT 100!!!');
      dispatch({ type: reducerGet });
      return;
    }

    const snapListen = dbRef.onSnapshot(
      snap => {
        !itemsInfo ||
          (!condList && dispatch({ type: reducerSuccess, value: snap }));
      },

      error => {
        ezLog('ERROR: ', error.message);
      },
    );

    const snapOut = () => {
      ezLog('SNAP OUT!!!');

      snapListen();
    };

    // const condSnapOut = info === 'home' ? () => {} : snapOut;

    // return condSnapOut;
    return snapOut;
  };

  // ----------- set Hooks
  const dispatch = useDispatch();
  React.useEffect(fxCallSnap, []);

  // ----------- set Data
  const firstCall = useData(firstCallData);
  const itemsInfo = useData(itemsInfoData);
  const condList = useData(condListData);
};
