// ---------- import Packs
import geohash from 'ngeohash';

// ----------- import Internals
import { firebase, storage } from '../../../../../config/firebase/fbConfig';
// import defaultImg from '../../../../images/default.jpg';

export const getUrl = async infoImg => {
  await storage.ref(`images/${infoImg.name}`).put(infoImg);
  const url = await storage
    .ref('images')
    .child(infoImg.name)
    .getDownloadURL();
  return url;
};

export const processData = async (dataToAdd, idToEdit) => {
  const getGeohash = async () => geohash.encode(dataToAdd.lat, dataToAdd.long);
  const waitHash = await getGeohash();

  const mountObj = async () => {
    const lat = Number(dataToAdd.lat);
    const long = Number(dataToAdd.long);
    // const condLat = dataToAdd.lat !== undefined;
    // const condLong = dataToAdd.long !== undefined;
    const condLat = dataToAdd.lat === undefined;
    const condLong = dataToAdd.long === undefined;
    let geoError = false;

    const tempObj = {
      ...dataToAdd,
    };

    // lat and long, both need to be filled to add geopoint
    if (!condLat && !condLong) {
      const addAddress = {
        geohash: waitHash,
        geolocation: new firebase.firestore.GeoPoint(lat, long),
      };

      if (idToEdit) {
        tempObj['address.geohash'] = addAddress.geohash;
        tempObj['address.geolocation'] = addAddress.geolocation;
      } else {
        tempObj.address = addAddress;
      }
    }

    if (condLat && condLong) {
      geoError = false;
    }

    // when lat or long not filled, set error true
    if (condLat && !condLong) {
      geoError = true;
    }
    if (!condLat && condLong) {
      geoError = true;
    }

    delete tempObj.lat;
    delete tempObj.long;

    return {
      tempObj,
      geoError,
    };
  };

  const mountRes = await mountObj();
  const newObj = mountRes.tempObj;
  const geoError = mountRes.geoError;

  return { newObj, geoError };
};
