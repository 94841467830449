// ---------- import Internals
import { firestore } from '../../../../../config/firebase/fbConfig';
import { asyncRefs, ezLog } from '../../../../../useMorfos/utils';

// ---------- set Reducers
const reducers = (state, action) => {
  const inits = {
    // ---------- set Init Data
    B1_InitData: () => {
      const condCateg = state?.basePersist?.categs.itemsInfo;
      const condGet = Object.keys(condCateg).length === 0;
      const scContent = {
        title: 'Signin',
        subTitle: 'Oi Mundo!',
        description: 'Lorem Ipsum!',
        txtBtn: 'Ir para os TERMOS',
        // --- ItemList
        mktName: 'Carrefour',
        act1: 'Ver Produtos',
        act2: 'Ver Vendas',
        txtDetails:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',

        // --- ItemList
        bnTitle: 'Lista de Mercados',
        bnSubTitle: 'Lorem Ipsum is simply text',
        bnAct: 'Add. Mercado',
      };
      const condData = true;
      condGet && action.asyncDispatch({ type: 'B1_GET_Categs' });
      action.asyncDispatch({ type: 'B1_GET_Shops' });

      return {
        ...state,
        B1: { ...state.B1, scContent, condData },
        B2: {},
      };
    },

    // ---------- set GET Categs
    B1_GET_Categs: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('settings');
        const dataSuccess = await dbRef.get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    B1_GET_Categs_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      let catInfo = [];
      let subCatInfo = [];

      dataDb.forEach(doc => {
        // --- Items Info
        const catListX = doc.data().catList;
        const subcatListX = doc.data().subcatList;
        catInfo.push(...catListX);
        subCatInfo.push(...Object.values(subcatListX));
      });

      return {
        ...state,
        basePersist: {
          ...state.basePersist,
          categs: {
            ...state.basePersist.categs,
            itemsInfo: catInfo,
          },
          subCategs: {
            ...state.basePersist.subCategs,
            itemsInfo: subCatInfo,
          },
        },
      };
    },

    // ---------- set GET Shops
    B1_GET_Shops: () => {
      // ---------- set Async Function
      const asyncFn = async () => {
        // ---------- set Async Call
        const dbRef = firestore.collection('shops');
        const dataSuccess = await dbRef.orderBy('createdAt', 'desc').get();

        // ------ return SUCCESS
        return dataSuccess;
      };

      // ---------- set Async References
      const ref = asyncRefs(action, asyncFn);

      // ---------- call Async / Mock
      ref.callAsync();

      return state;
    },

    B1_GET_Shops_SUCCESS: () => {
      // ---------- set Data to Show
      const dataDb = action.value;
      const itemsInfo = {};
      const itemsList = [];
      dataDb.forEach(doc => {
        itemsInfo[doc.id] = doc.data();
        itemsList.push(doc.id);
      });

      return {
        ...state,
        B1: {
          ...state.B1,
          itemsList,
          itemsInfo,
          condList: true,
        },
      };
    },
  };

  const btns = {
    B1_ShopsADD: () => {
      const baseRoute = state.baseRoute;
      const C1 = state.C1;

      return {
        ...state,
        C1: {
          ...C1,
          isEditable: false,
          idToEdit: null,
        },
        baseRoute: {
          ...baseRoute,
          pathRight: 'shopADD',
        },
      };
    },
    B1_ShopProds: () => {
      // ---------- set Data
      const currShop = state?.B1?.itemsInfo[action.itemId];

      return {
        ...state,
        baseRoute: {
          ...state.baseRoute,
          path: 'shopProdsList',
          currShop: {
            ...state.currShop,
            docId: currShop.docId,
            imgUrl: currShop.imgUrl,
            name: currShop.name,
          },
        },
      };
    },
  };

  const edits = {
    B1_EditMkt: () => {
      action.asyncDispatch({ type: 'C1_InitForm' });
      action.asyncDispatch({ type: 'base_setRoute', value: 'shopADD' });

      return {
        ...state,
        C1: {
          ...state.C1,
          idToEdit: action.itemId,
        },
        // baseRoute: {
        //   ...state.baseRoute,
        //   pathRight: 'shopADD',
        // },
      };
    },
  };

  return {
    ...inits,
    ...edits,
    ...btns,
  };
};

// ---------- set Exports
export default reducers;
