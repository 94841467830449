// ----------- import Packs
import React from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';

// ----------- import Internals
import { useStl, useResize, useData } from '../../../../useMorfos';

// ----------- import Images
import src1 from '../../../images/logo_wt.svg';
import src2 from '../../../images/banner.svg';

// #region :: STYLEs *********
const stlBodyView = [useStl.flex1, useStl.flexCenter];
const stlLogo = {
  color: 'white',
  width: '80%',
  height: 80,
  resizeMode: 'contain',
};

const stlBg = {
  desk: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  mob: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
};

// --------- BUTTONS
const stlBtnTerms = [
  {
    position: 'absolute',
    bottom: 95,
    zIndex: 20,
  },
];
const stlBtTxtWhite = [
  useStl.txtPrimaryInverse,
  {
    color: '#eee',
    fontSize: 11,
    fontWeight: 600,
  },
];

const stlBtTxtWhite1 = [
  useStl.txtPrimaryInverse,
  {
    position: 'absolute',
    bottom: 70,
    color: '#eee',
    zIndex: 20,
    fontSize: 11,
    fontWeight: 400,
  },
];

const stlBtn = [
  useStl.flexCenter,
  {
    backgroundColor: '#2a2f21',
    width: '100%',
    height: 65,
    position: 'absolute',
    bottom: 0,
  },
];

const stlTxtBt = [
  { textAlign: 'center', color: 'white', fontSize: 16, fontWeight: 400 },
];

// #endregion *********

export default ({ info }) => {
  // ----------- set Data
  const condLoader = useData('M0.condLoader');

  // ----------- set Return
  return (
    <View style={stlBodyView}>
      <Image source={src2} style={useResize(stlBg)} />
      <Image source={src1} style={stlLogo} />
      {condLoader && <ActivityIndicator size="large" color="white" />}
      <TouchableOpacity style={stlBtnTerms} onPress={info.btnGoToTerms}>
        <Text style={stlBtTxtWhite}> Termos de Uso</Text>
      </TouchableOpacity>
      <Text style={stlBtTxtWhite1}> Entre usando sua conta</Text>
      <TouchableOpacity style={stlBtn} onPress={info.btnSetLogin}>
        <Text style={stlTxtBt}>GOOGLE</Text>
      </TouchableOpacity>
    </View>
  );
};
