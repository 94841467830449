// ---------- import Internals
import { useData } from '../../../../useMorfos';
import { ezLog } from '../../../../useMorfos/utils';

// ---------- set update Cart / Remove Unavailable Prod
export const removeDiffer = (arr1, arr2) => {
  // ---- find Unavailable Prod Ids
  let cartArr = [];
  let availArr = [];
  for (const key1 in arr1) {
    cartArr.push({ value: arr1[key1].id });
  }
  for (const key2 in arr2) {
    availArr.push({ value: arr2[key2] });
  }
  const idsToRemove = cartArr.filter(
    ({ value: id1 }) => !availArr.some(({ value: id2 }) => id2 === id1),
  );

  // --- Exclude Cart
  const newCart = { ...arr1 };
  idsToRemove.map(item => delete newCart[item.value]);

  return newCart;
};
