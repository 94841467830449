// ----------- import Packs
import React from 'react';

// ----------- import Internals
import ViewDF from './Views';
import { UseInitData, UseCondLoader, UseList } from '../../../../../useMorfos';
import { ItemList, NoItemView } from '../../../../comps';

// ----------- set Info Screen
export const infoSc = {
  path: 'shopProdsList',
  groupSc: 'adm1',
  condBigSc: true,
  scCode: 'B3',
};

// ----------- set Default Component
export default () => {
  // ----------- set Return
  const infoList = {
    itemContent: 'B3.scContent',
    itemsInfo: 'B3.itemsInfo',
    pressItem: 'B3_EditShopProds',
  };

  return (
    <UseInitData reducer={'B3_InitData'}>
      <ViewDF>
        <UseCondLoader data={'B3.condList'}>
          <UseList data={'B3.itemsList'}>
            {(itemId, noItem) =>
              noItem ? (
                <NoItemView />
              ) : (
                <ItemList infoData={{ ...infoList, itemId }} />
              )
            }
          </UseList>
        </UseCondLoader>
      </ViewDF>
    </UseInitData>
  );
};
