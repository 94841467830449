/* eslint-disable import/no-anonymous-default-export */
// ----------- import Packs
import React from 'react';
import { View, Image, TouchableOpacity, Text, TextInput } from 'react-native';

// ----------- import Internals
import {
  useData,
  UseIcoMoon,
  useResize,
  useRouter,
  useStl,
} from '../../../useMorfos';
import SideMenu from './SideMenu';
import src1 from '../../images/logo_wt.svg';
import src3 from '../../images/banner.svg';
import { useDispatch } from 'react-redux';
import { ezLog } from '../../../useMorfos/utils';

// #region :: STYLEs *********

const stlImg1 = [
  {
    width: 79,
    height: 25,
  },
];
const stlImg3 = {
  width: '100%',
  height: 350,
  position: 'absolute',
  backgroundColor: '#f6f6f6',
  position: 'absolute',
  top: 0,
};

const stlNAV1 = [
  useStl.navbarView,
  {
    shadowOpacity: 0,
    overflow: 'hidden',
  },
];

const stlFixed = {
  position: 'fixed',
  top: 0,
};

const stlLeft = [useStl.leftBox];
const stlCenter = [useStl.centerBox];
const stlRight = [useStl.rightBox];

const stlTxtBar = [useStl.flex1, { alignItems: 'flex-start' }];
const stlTxtTitle = [
  useStl.txtPrimaryInverse,
  { textAlign: 'left', fontSize: 16, fontWeight: 600, margin: 0 },
];
const stlTxtSubTitle = [
  useStl.txtPrimaryInverse,
  { textAlign: 'left', fontSize: 10, margin: 0, lineHeight: 10 },
];
const stlSearch = [
  useStl.flexRow,
  useStl.flexBetween,
  {
    backgroundColor: '#fff',
    width: '100%',
    height: 60,
    position: 'absolute',
    top: 0,
    left: 0,
  },
];

const stlTxtInput = [
  {
    marginLeft: 60,
    width: '68%',
    height: 40,
    color: '#444',
    outline: 'none',
  },
];
// #endregion *********

export default () => {
  // ---------- set Data
  const path = useData('baseRoute.path');
  const currScreen = useData('baseRoute.routesInfo', path);
  const title = currScreen?.layout?.title ?? '...';
  const subTitle = currScreen?.layout?.subTitle;
  // const historySc = useData('baseRoute.historyPath');

  // ---------- set Hooks
  const dispatch = useDispatch();
  // const { callRouter } = useRouter();
  const resizeBar = useResize({
    mob: {},
    desk: { maxWidth: 500 },
  });

  // ---------- set Conds & Btns
  // const goBack = () => callRouter(historySc);
  const goBack = () => dispatch({ type: 'comps_goBack' });
  const condBar = path !== 'home';
  const condMenu =
    path === 'purchasedList' ||
    path === 'bookletList' ||
    path === 'myprofile' ||
    path === 'shopsInReach';
  const condOrderEdit = path !== 'orderEdit';
  const toggMenu = () => dispatch({ type: 'comps_ToggMenu' });

  const [sttSearch, setSearch] = React.useState(false);
  const btnInitSearch = () => setSearch(!sttSearch);
  const condIcon = sttSearch ? 'clear' : 'search';
  const condColor = sttSearch ? 'green' : '#fff';
  const getTxt = val => dispatch({ type: 'comps_Search', value: val });
  const stlMgTop = { marginTop: -4 };
  const condMgTop = !subTitle ? stlMgTop : {};

  // ---------- set Return
  return condBar ? (
    <View style={[stlNAV1, resizeBar, stlFixed]}>
      <Image source={src3} style={stlImg3} />
      {sttSearch && (
        <View style={stlSearch}>
          <TextInput
            placeholder="Buscar"
            onChangeText={getTxt}
            style={stlTxtInput}
          />
        </View>
      )}
      {condMenu ? (
        <TouchableOpacity style={stlLeft} onPress={toggMenu}>
          <UseIcoMoon name="menu1" size={26} color="#fff" />
        </TouchableOpacity>
      ) : (
        <TouchableOpacity style={stlLeft} onPress={goBack}>
          <UseIcoMoon name="left" size={26} color={condColor} />
        </TouchableOpacity>
      )}

      <View style={stlTxtBar}>
        {!sttSearch && (
          <>
            {subTitle && <Text style={stlTxtSubTitle}>{subTitle}</Text>}
            <Text style={[stlTxtTitle, condMgTop]}>{title}</Text>
          </>
        )}
      </View>
      {condOrderEdit ? (
        <View style={stlRight} />
      ) : (
        <View style={stlRight}>
          <TouchableOpacity onPress={btnInitSearch}>
            <UseIcoMoon name={condIcon} size={18} color={condColor} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  ) : (
    <>
      <View style={[stlNAV1, resizeBar, stlFixed]}>
        <Image source={src3} style={stlImg3} />
        <TouchableOpacity style={stlLeft} onPress={toggMenu}>
          <UseIcoMoon name="menu1" size={26} color="#fff" />
        </TouchableOpacity>
        <View style={stlCenter}>
          <Image source={src1} style={stlImg1} />
        </View>
        <View style={stlRight} />
      </View>
    </>
  );
};
